<template>
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
        <!--begin::Logo-->
        <!-- Logo -->
            <Link class="navbar-brand " :href="route('dashboard')">
                <!-- <jet-application-mark class="h-30px logo-minimize" /> -->
                <div class="logo-default">
                    <div class="logo-wrapper d-flex">
                        <div class="logo-mark py-3">
                            <jet-application-mark class="h-30px w-35px" />
                        </div>
                        <div
                            class="logo-text d-flex align-items-center p-3 text-black"
                            :style="{ fontSize: logoTextFontSize }"
                        >
                            Creaitor.ai
                        </div>
                    </div>
                </div>
            </Link>
            <button class="menu-toggle-button text-gray-700" @click="menuToggle">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.6207 23.9879C20.9265 23.9447 21.3411 23.8173 21.6692 23.6659C22.7521 23.1662 23.5832 22.1853 23.9137 21.0172C23.9961 20.726 24 20.316 24 12.0058C24 3.69562 23.9961 3.28561 23.9137 2.99442C23.522 1.60991 22.447 0.526573 21.0431 0.101677C20.7762 0.0209026 20.3148 0.0157394 12.1972 0.00269127C4.51687 -0.00965118 3.59151 -0.00336266 3.23269 0.0638008C1.76975 0.337616 0.561235 1.46061 0.104094 2.97096C0.0233192 3.23787 0.0181561 3.69924 0.00510788 11.8169C-0.00723457 19.4972 -0.000946045 20.4225 0.0662174 20.7814C0.340033 22.2443 1.46299 23.4528 2.97338 23.9099C3.24072 23.9909 3.69073 23.9955 11.8675 24.0015C16.6043 24.0049 20.5433 23.9988 20.6207 23.9879ZM17.0302 12.0018L17.0302 2.00083L18.7576 2.01655L20.485 2.03227L20.8413 2.20842C21.2462 2.40859 21.6339 2.78688 21.8214 3.16451L21.9349 3.39337L21.947 11.9558L21.9591 20.5182L21.8202 20.815C21.5409 21.4119 20.9204 21.8912 20.3409 21.9575C20.185 21.9754 19.3764 21.9929 18.5439 21.9964L17.0302 22.0028V12.0018ZM3.17534 21.8032C2.7703 21.603 2.37802 21.2203 2.19328 20.845L2.08163 20.6183L2.06952 12.0368L2.05741 3.45546L2.2282 3.13044C2.42453 2.7568 2.81761 2.36504 3.16694 2.19476C3.37054 2.09559 3.48647 2.07968 4.21714 2.05091C4.66889 2.03307 7.28252 2.01631 10.0253 2.01364L15.0121 2.0088L15.0121 12.0103L15.0121 22.0118L9.27189 21.9955L3.53167 21.9793L3.17534 21.8032Z"
                        fill="currentColor"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.59776 15.8981C9.39214 15.8089 6.31876 12.7403 6.12399 12.4297C5.92461 12.1119 5.9248 11.85 6.12465 11.5329C6.3243 11.2161 9.3798 8.17165 9.59936 8.07069C9.8329 7.96335 10.2092 7.96955 10.4344 8.08444C10.8863 8.31498 11.116 8.89143 10.9316 9.3326C10.8899 9.43257 10.3814 9.98339 9.63268 10.7397L8.40235 11.9825L9.55444 13.1436C10.8192 14.418 11.0009 14.6493 10.9993 14.9826C10.9959 15.6807 10.2378 16.176 9.59776 15.8981Z"
                        fill="currentColor"
                    />
                </svg>
            </button>

        <!--end::Logo-->
        <!--begin::Aside toggler-->
        <!--<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
      <span class="svg-icon svg-icon-1 rotate-180">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="black" />
          <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="black" />
        </svg>
      </span>
    </div>!-->
        <!--end::Aside toggler-->
    </div>
</template>

<script>
import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import JetApplicationMark from '@/Jetstream/ApplicationMark.vue';
import { Link } from '@inertiajs/vue3';

export default {
    name: 'Logo',

    props: {
        markWidth: {
            type: Number,
            default: 32,
        },
    },

    components: {
        JetApplicationLogo,
        JetApplicationMark,
        Link,
    },

    computed: {
        logoTextFontSize() {
            let factor = ((100 / 32) * this.markWidth) / 100;
            let fontSize = 1.25 * factor;
            return fontSize + 'rem';
        },
    },

    methods: {
        menuToggle() {
            emitter.emit('menu-toggle-clicked');
        },
    },
};
</script>

<style lang="scss">
.logo-wrapper {
    .logo-text {
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 1;
    }
}
</style>
