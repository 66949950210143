<template>
    <app-layout
        :title="__('Keyword Research')"
        :view="__('List')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Keyword Research') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1 class="text-2xl">{{ __('Keyword Research') }}</h1>
                <div class="text-sm text-gray-400 fw-bold">
                    {{
                        __('Here you can find the overview of your researches.')
                    }}
                </div>
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-sm-12 col-md-4">
                <KeywordResearchesCard
                    :is-loading="isKeywordResearchesLoading"
                    :total="totalKeywordResearches"
                ></KeywordResearchesCard>
            </div>
            <div class="col-sm-12 col-md-4">
                <KeywordsCard
                    :is-loading="isKeywordLoading"
                    :total="totalKeyword"
                ></KeywordsCard>
            </div>
            <div class="col-sm-12 col-md-4">
                <CreditsCard
                    :is-loading="isCreditsLoading"
                    :total-credits="totalCredits"
                    buy-more-credits-modal="buy-more-keyword-research-credits"
                ></CreditsCard>
            </div>
        </div>

        <KeywordClustersTable
            :total-credits="totalCredits"
            @status-update="statusUpdate"
        ></KeywordClustersTable>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import Multiselect from '@vueform/multiselect';
import KeywordClustersTable from '@/Components/KeywordResearch/KeywordClustersTable.vue';
import KeywordResearchesCard from '../../Components/Cards/KeywordResearchesCard.vue';
import KeywordsCard from '../../Components/Cards/KeywordsCard.vue';
import CreditsCard from '../../Components/Cards/CreditsCard.vue';

export default defineComponent({
    components: {
        CreditsCard,
        KeywordsCard,
        KeywordResearchesCard,
        Link,
        AppLayout,
        Multiselect,
        KeywordClustersTable,
    },

    data() {
        return {
            isCreditsLoading: false,
            isKeywordResearchesLoading: false,
            totalKeywordResearches: 0,
            isKeywordLoading: false,
            totalKeyword: 0,
        };
    },

    computed: {
        totalCredits() {
            let teamPlanCredits =
                this.$attrs.user?.current_team?.team_plan_credits
                    ?.keyword_research_credits || 0;
            let teamBonusCredits =
                this.$attrs.user?.current_team?.team_bonus_credits
                    ?.keyword_research_credits || 0;

            return teamPlanCredits + teamBonusCredits;
        },
    },

    mounted() {
        this.fetchKeywordCount();
    },

    methods: {
        fetchKeywordCount() {
            this.isKeywordLoading = true;
            axios
                .get(route('keyword-researches.keyword-count'))
                .then((response) => {
                    this.totalKeyword = response.data.keywordCount;
                    this.isKeywordLoading = false;
                })
                .finally(() => {
                    this.isKeywordLoading = false;
                });
        },
        statusUpdate(ev) {
            this.isKeywordResearchesLoading = ev.loading;
            this.totalKeywordResearches = ev.total;
        },
    },
});
</script>
