import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "13",
      height: "2",
      x: "18",
      y: "13",
      opacity: ".5",
      rx: "1",
      transform: "rotate(-180 18 13)"
    }, null, -1),
    _createElementVNode("path", { d: "M15.434 12.566 11.25 16.75a1.06 1.06 0 0 0 1.5 1.5l5.543-5.543a1 1 0 0 0 0-1.414L12.75 5.75a1.06 1.06 0 0 0-1.5 1.5l4.184 4.184a.8.8 0 0 1 0 1.132" }, null, -1)
  ])))
}
export default { render: render }