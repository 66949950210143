<template>
    <!--begin::Aside-->
    <div
        id="kt_aside"
        class="aside aside-light-gray aside-hoverable p-1"
        :class="[
            asideMenuClasses,
            { 'menu-collapsed': isMenuCollapsed, 'hover-state': isMenuHover },
        ]"
        data-kt-drawer="false"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_mobile_toggle"
        @mouseenter="toggleOnHover(true)"
        @mouseleave="toggleOnHover(false)"
    >
        <Logo />
        <Menu :user="user" :is-menu-collapsed="isMenuCollapsed" :is-menu-hover="isMenuHover" />
        <div class="aside-footer flex-column-auto pt-5 pb-7 px-5"></div>
    </div>
    <!--end::Aside-->
</template>

<script>
import Logo from '@/Layouts/Aside/Logo.vue';
import Menu from '@/Layouts/Aside/Menu.vue';

export default {
    components: {
        Menu,
        Logo,
    },

    props: {
        user: Object,
    },

    data() {
        return {
            mobileDrawer: 'drawer drawer-start',
            documentBodyWidth: document.body.clientWidth,
            overlay: {},
            isMenuCollapsed: false,
            isMenuHover: false,
        };
    },

    mounted() {
        let vm = this;
        emitter.on('open-aside-mobile-menu', function () {
            vm.mobileDrawer = 'drawer drawer-start drawer-on';
            document.body.setAttribute('data-kt-drawer', 'on');
        });
        emitter.on('close-aside-mobile-menu', function () {
            vm.mobileDrawer = 'drawer drawer-start';
            document.body.setAttribute('data-kt-drawer', 'off');
        });
        emitter.on('menu-toggle-clicked', function () {
            vm.isMenuCollapsed = !vm.isMenuCollapsed;
            if (vm.isMenuCollapsed) {
                vm.isMenuHover = true;
            }
        });

        window.addEventListener('resize', this.windowResize);
    },

    beforeUnmount() {
        emitter.off('open-aside-mobile-menu');
        emitter.off('close-aside-mobile-menu');
    },

    methods: {
        windowResize() {
            this.documentBodyWidth = document.body.clientWidth;
        },
        toggleOnHover(toggleState) {
            if(this.isMenuCollapsed && toggleState) {
                this.isMenuHover = toggleState;
            } else{
                this.isMenuHover = false;
            }
        },
    },

    computed: {
        asideMenuClasses() {
            return this.mobileView ? this.mobileDrawer : '';
        },

        mobileView() {
            return this.documentBodyWidth < 992;
        },
    },
};
</script>

<style lang="scss">
#kt_aside {
    z-index: 1002;
}
</style>
