<template>
    <div
        class="card card-flush"
        :class="{ 'card-bordered': view !== 'minimal' }"
    >
        <!--begin::Card header-->
        <div
            v-if="view !== 'minimal'"
            class="card-header align-items-center py-5 gap-2 gap-md-5"
        >
            <!--begin::Card title-->
            <div class="card-title">
                <!--begin::Search-->
                <div class="d-flex align-items-center position-relative my-1">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                    <span class="svg-icon svg-icon-1 position-absolute ms-4">
                        <IconSearch />
                    </span>
                    <!--end::Svg Icon-->
                    <input
                        type="text"
                        v-model="search"
                        @input="onChangeSearch"
                        class="form-control form-control-solid w-250px ps-14 text-sm rounded-xl"
                        :placeholder="__('Search Document')"
                    />
                </div>
                <!--end::Search-->
            </div>
            <!--end::Card title-->
            <!--begin::Card toolbar-->
            <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                <!--begin::Add product-->
                <!--begin::Add product-->
                <a
                    href="#"
                    @click.prevent="sendBulkDataToModal"
                    class="btn btn-danger text-sm"
                    :class="{ disabled: serpBeaterIds.length === 0 }"
                >
                    {{ __('Delete') }}
                </a>
                <!--begin::Add product-->
                <div
                    v-tooltip="
                        totalCredits === 0
                            ? __('You do not have enough credits')
                            : ''
                    "
                    class="cursor-pointer"
                >
                    <Link
                        href="#"
                        class="btn btn-primary text-sm"
                        :class="{ disabled: totalCredits === 0 }"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_serp_beater_wizard_modal"
                        @click.stop="openSerpBeaterModal('new')"
                    >
                        {{ __('New') }}
                    </Link>
                </div>
            </div>
            <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div v-if="isLoading" class="card-body">
            <div class="d-flex align-items-center justify-content-center p-10">
                <div
                    role="status"
                >
                    <IconLoading class="animate-spin" />
                    <span class="visually-hidden">{{ __('Loading...') }}</span>
                </div>
            </div>
        </div>
        <div v-else class="card-body pt-0">
            <!--begin::Table-->
            <div
                id="serp_beaters_table_wrapper"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
                <div class="bootstrap-table-responsive w-full">
                    <table
                        class="bootstrap-table align-middle table-row-dashed fs-6 dataTable no-footer w-full"
                        :class="{
                            'gy-3': view === 'minimal',
                            'gy-5': view !== 'minimal',
                        }"
                        id="serp_beaters_table"
                    >
                        <!--begin::Table head-->
                        <thead>
                            <!--begin::Table row-->
                            <tr
                                v-if="serpBeaters.length > 0"
                                class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                            >
                                <th
                                    v-if="columns.includes('Checkbox')"
                                    class="w-10px pe-2 sorting_disabled"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label=""
                                >
                                    <div
                                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="allSelected"
                                            @change="selectAll"
                                        />
                                    </div>
                                </th>
                                <th
                                    v-if="columns.includes('Keyword')"
                                    class="sorting !text-sm"
                                    :class="{ 'mw-250px': view === 'minimal' }"
                                    tabindex="0"
                                    aria-controls="serp_beaters_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Keyword"
                                >
                                    {{ __('Keyword') }}
                                </th>
                                <th
                                    v-if="
                                        columns.includes('Keyword difficulty')
                                    "
                                    class="text-center min-w-100px sorting !text-sm"
                                    tabindex="0"
                                    aria-controls="serp_beaters_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Keyword difficulty"
                                >
                                    {{ __('Keyword difficulty') }}
                                </th>
                                <th
                                    v-if="columns.includes('Search engine')"
                                    class="text-center min-w-100px sorting !text-sm"
                                    tabindex="0"
                                    aria-controls="serp_beaters_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Search engine"
                                >
                                    {{ __('Search engine') }}
                                </th>
                                <th
                                    v-if="columns.includes('Status')"
                                    class="text-center min-w-100px sorting !text-sm"
                                    tabindex="0"
                                    aria-controls="serp_beaters_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Status"
                                >
                                    {{ __('Status') }}
                                </th>
                                <th
                                    v-if="columns.includes('Analyses At')"
                                    class="text-center min-w-100px sorting !text-sm"
                                    tabindex="0"
                                    aria-controls="serp_beaters_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Created At"
                                >
                                    {{ __('Analyses At') }}
                                </th>
                                <th
                                    v-if="columns.includes('Actions')"
                                    class="text-end min-w-70px sorting_disabled !text-sm"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Actions"
                                >
                                    {{ __('Actions') }}
                                </th>
                                <th
                                    v-if="columns.includes('View')"
                                    class="text-center"
                                    tabindex="0"
                                    aria-controls="serp_beaters_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="View"
                                ></th>
                            </tr>

                            <tr
                                v-else
                                class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                            >
                                <th
                                    class="sorting_disabled !text-sm"
                                    tabindex="0"
                                    aria-controls="documents_table"
                                    rowspan="1"
                                    colspan="1"
                                    :aria-label="__('Keyword')"
                                >
                                    {{ __('Keyword') }}
                                </th>
                            </tr>

                            <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                            <!--begin::Table row-->

                            <!--end::Table row-->
                            <tr
                                v-if="serpBeaters.length > 0"
                                v-for="(serpBeater, index) in serpBeaters"
                            >
                                <!--begin::Checkbox-->
                                <td v-if="columns.includes('Checkbox')">
                                    <div
                                        class="form-check form-check-sm form-check-custom form-check-solid"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="serpBeaterIds"
                                            @change="select"
                                            :value="serpBeater.uuid"
                                        />
                                    </div>
                                </td>
                                <!--end::Checkbox-->
                                <!--begin::Keyword=-->
                                <td v-if="columns.includes('Keyword')">
                                    <div class="d-flex align-items-center">
                                        <!--begin::Keyword-->
                                        <Link
                                            v-if="
                                                serpBeater.wizard_status ==='completed'
                                                && serpBeater.document
                                                && serpBeater.document.uuid
                                            "
                                            :href="
                                                '/documents/' +
                                                serpBeater.document?.uuid
                                            "
                                            class="text-gray-700"
                                        >
                                            {{ serpBeater.keyword }}
                                        </Link>
                                        <Link
                                            v-else
                                            href="#"
                                            class="text-gray-700"
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_serp_beater_wizard_modal"
                                            @click="
                                                openSerpBeaterModal(
                                                    'continue',
                                                    serpBeater.uuid
                                                )
                                            "
                                        >
                                            {{ serpBeater.keyword }}
                                        </Link>
                                        <!--end::Keyword-->
                                    </div>
                                </td>
                                <!--end::Keyword=-->
                                <!--begin::Keyword Difficulty=-->
                                <td
                                    v-if="
                                        columns.includes('Keyword difficulty')
                                    "
                                >
                                    <div class="text-center">
                                        <span
                                            class="badge badge-circle w-30px h-30px"
                                            :class="
                                                difficultyClass(
                                                    serpBeater.keyword_difficulty
                                                )
                                            "
                                            >{{
                                                serpBeater.keyword_difficulty
                                            }}</span
                                        >
                                    </div>
                                </td>
                                <!--end::Keyword Difficulty=-->
                                <!--begin::Search Engine=-->
                                <td v-if="columns.includes('Search engine')">
                                    <div
                                        v-if="serpBeater.se_domain"
                                        class="text-center"
                                    >
                                        {{ serpBeater.se_domain }} |
                                        {{ serpBeater.language_code }}
                                    </div>
                                    <div v-else class="text-center">-</div>
                                </td>
                                <!--end::Search Engine=-->
                                <!--begin::Status=-->
                                <td
                                    v-if="columns.includes('Status')"
                                    class="text-center pe-0"
                                >
                                    <span
                                        v-if="
                                            serpBeater.wizard_status === 'completed'
                                            && serpBeater.document
                                            && serpBeater.document.uuid
                                        "
                                        class="badge py-3 px-4 fs-7 badge-light-success"
                                        >{{ __('Completed') }}</span
                                    >
                                    <span
                                        v-else
                                        class="badge py-3 px-4 fs-7 badge-light-primary"
                                        >{{ __('In Progress') }}</span
                                    >
                                </td>
                                <!--end::Status=-->
                                <!--begin::Creaited At=-->
                                <td
                                    v-if="columns.includes('Analyses At')"
                                    class="text-center pe-0"
                                    data-order="41"
                                >
                                    {{
                                        moment(serpBeater.created_at).format(
                                            'L LTS'
                                        )
                                    }}
                                </td>
                                <!--end::Creaited At=-->
                                <!--begin::Action=-->
                                <td v-if="columns.includes('Actions')" class="text-end">
                                    <ActionsDropdown
                                        :modelValue="activeMenuIndex === index"
                                        @toggle="(isOpen) => activeMenuIndex = isOpen ? index : null"
                                    >
                                        <!--begin::Menu item-->
                                        <div class="menu-item px-3">
                                            <Link
                                                v-if="
                                                    serpBeater.wizard_status === 'completed'
                                                    && serpBeater.document
                                                    && serpBeater.document.uuid
                                                "
                                                :href="
                                                    '/documents/' +
                                                    serpBeater.document?.uuid
                                                "
                                                class="menu-link text-start px-3 py-1"
                                                >{{ __('Edit Document') }}</Link
                                            >
                                        </div>
                                        <!--end::Menu item-->

                                        <!--begin::Menu item-->
                                        <div class="menu-item px-3">
                                            <Link
                                                href="#"
                                                class="menu-link text-start px-3 py-1 !text-sm"
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_serp_beater_wizard_modal"
                                                @click.stop="
                                                    openSerpBeaterModal(
                                                        'continue',
                                                        serpBeater.uuid
                                                    )
                                                "
                                                >{{ __('Open Wizard') }}</Link
                                            >
                                        </div>
                                        <!--end::Menu item-->

                                        <!--begin::Menu item-->
                                        <div class="menu-item px-3">
                                            <a
                                                href="#"
                                                class="menu-link text-start px-3 py-1 text-danger !text-sm"
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_delete"
                                                @click.prevent="
                                                    sendDataToModal(
                                                        serpBeater.uuid
                                                    )
                                                "
                                                >{{ __('Delete') }}</a
                                            >
                                        </div>
                                        <!--end::Menu item-->
                                    </ActionsDropdown>
                                </td>
                                <!--end::Action=-->
                                <td v-if="columns.includes('View')">
                                    <a
                                        :href="
                                            '/serp-beater/' + serpBeater.uuid
                                        "
                                        class="btn btn-sm btn-icon w-30px h-30px"
                                    >
                                        <span
                                            class="svg-icon svg-icon-muted svg-icon-2"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="1em"
                                                viewBox="0 0 448 512"
                                            >
                                                <path
                                                    class="fa-primary"
                                                    opacity="0.4"
                                                    d="M312 144H160c-13.3 0-24 10.7-24 24s10.7 24 24 24h94.1L119 327c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V328c0 13.3 10.7 24 24 24s24-10.7 24-24V168c0-13.3-10.7-24-24-24z"
                                                />
                                                <path
                                                    class="fa-secondary"
                                                    d="M384 32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H384zM160 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h94.1L119 327c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V328c0 13.3 10.7 24 24 24s24-10.7 24-24V168c0-13.3-10.7-24-24-24H160z"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </td>
                            </tr>

                            <tr v-else>
                                <td
                                    colspan="1"
                                    class="text-center text-gray-500 !text-sm"
                                >
                                    {{ __('No SERP Beater articles found.') }}
                                </td>
                            </tr>
                        </tbody>
                        <!--end::Table body-->
                    </table>
                </div>
                <div v-if="view !== 'minimal'" class="row">
                    <div
                        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                        <div
                            class="dataTables_length"
                            id="serp_beaters_table_length"
                        >
                            <div class="d-flex flex-1 align-items-center">
                                <select
                                    class="form-select form-select-sm mw-70px me-4"
                                    v-model="selectedViewLengthOption"
                                    @change="onChangeViewLength"
                                >
                                    <option
                                        v-for="option in listViewLengthOptions"
                                        :value="option"
                                    >
                                        {{ option }}
                                    </option>
                                </select>
                                <label
                                    class="text-sm w-52"
                                    >{{ __('Showing') }} {{ from }}
                                    {{ __('to') }} {{ to }} {{ __('of') }}
                                    {{ total }} {{ __('entries') }}</label
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                    >
                        <div
                            class="dataTables_paginate paging_simple_numbers"
                            id="serp_beaters_table_paginate"
                        >
                            <ul class="pagination">
                                <li
                                    v-for="(link, index) in pageLinks"
                                    class="page-item text-xs"
                                    :class="{
                                        'prev previous': index === 0,
                                        next: index === 14,
                                        active: link.label == currentPage,
                                    }"
                                >
                                    <a
                                        href="#"
                                        @click.prevent="
                                            fetchSerpBeaters(link.url)
                                        "
                                        class="page-link !text-sm"
                                    >
                                        <span v-if="index === 0"
                                            ><i class="previous"></i
                                        ></span>
                                        <span
                                            v-else-if="
                                                index ===
                                                Object.keys(pageLinks).length -
                                                    1
                                            "
                                            ><i class="next"></i
                                        ></span>
                                        <span v-else>{{ link.label }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="view === 'minimal'" class="text-end">
                <Link :href="route('serp-beater.list')">
                    {{ __('All SERP Beater Articles') }}
                </Link>
            </div>
            <!--end::Table-->
        </div>
        <!--end::Card body-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import Multiselect from '@vueform/multiselect';
import { directive } from 'vue3-click-away';
import moment from 'moment/moment';
import ActionsDropdown from '@/Components/Common/Table/ActionsDropdown.vue';
import IconLoading from '@/icons/loading.svg?component';
import IconSearch from '@/icons/search.svg?component';

export default defineComponent({
    components: {
        Link,
        AppLayout,
        Multiselect,
        ActionsDropdown,
        IconLoading,
        IconSearch,
    },

    directives: {
        ClickAway: directive,
    },

    props: {
        view: {
            type: String,
            default: 'default',
        },
        columns: {
            type: Array,
            default: () => [
                'Checkbox',
                'Keyword',
                'Keyword difficulty',
                'Search engine',
                'Status',
                'Analyses At',
                'Actions',
            ],
        },
        initialSelectedViewLengthOption: {
            type: Number,
            default: 10,
        },
        totalCredits: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            search: '',
            serpBeatersUrl: '/api/serp-beaters',
            serpBeaters: [],
            copyClicked: false,
            currentPage: 1,
            firstPageUrl: '',
            from: 1,
            to: 1,
            total: 1,
            lastPage: 1,
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            pageLinks: {},
            selected: [],
            allSelected: false,
            selectedViewLengthOption: this.initialSelectedViewLengthOption,
            listViewLengthOptions: [10, 25, 50, 100, 500],
            serpBeaterIds: [],
            activeMenuIndex: null,
            moment: moment,
            isLoading: true,
        };
    },

    mounted() {
        this.fetchSerpBeaters(this.serpBeatersUrl);
        let vm = this;
        emitter.on('new-serp-beater-added', function () {
            vm.fetchSerpBeaters(vm.serpBeatersUrl);
        });
    },

    beforeUnmount() {
        emitter.off('new-serp-beater-added');
    },

    methods: {
        openSerpBeaterModal(action, uuid = null) {
            emitter.emit('opened-serp-beater-wizard-modal', {
                action: action,
                uuid: uuid,
            });
        },

        sendDataToModal(uuid) {
            emitter.emit('set-delete-candidate', {
                uuid: uuid,
                url: this.serpBeatersUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this serpBeater? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
            this.$page.props.deleteCandidate = uuid;
        },

        sendBulkDataToModal() {
            emitter.emit('set-delete-candidate', {
                ids: this.serpBeaterIds,
                url: this.serpBeatersUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this serpBeater? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },

        deleteCallback(uuid) {
            this.fetchSerpBeaters(this.serpBeatersUrl);
        },

        fetchSerpBeaters(url) {
            if (url) {
                let vm = this;
                this.isLoading = true;

                let filterParams =
                    'per-page=' +
                    this.selectedViewLengthOption +
                    '&search=' +
                    this.search;
                let requestUrl = url.includes('page')
                    ? url + '&' + filterParams
                    : url + '?' + filterParams;

                axios
                    .get(requestUrl)
                    .then((response) => {
                        vm.serpBeaters = response.data.data;
                        vm.currentPage = response.data.current_page;
                        vm.firstPageUrl = response.data.first_page_url;
                        vm.from = response.data.from;
                        vm.to = response.data.to;
                        vm.total = response.data.total;
                        vm.lastPage = response.data.last_page;
                        vm.lastPageUrl = response.data.last_page_url;
                        vm.nextPageUrl = response.data.next_page_url;
                        vm.prevPageUrl = response.data.prev_page_url;
                        vm.pageLinks = response.data.links;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },

        onChangeSearch(ev) {
            this.fetchSerpBeaters(this.serpBeatersUrl);
        },

        onChangeViewLength(ev) {
            this.fetchSerpBeaters(this.serpBeatersUrl);
        },

        selectAll() {
            let vm = this;
            vm.serpBeaterIds = [];
            if (this.allSelected) {
                this.serpBeaters.forEach(function (serpBeater) {
                    vm.serpBeaterIds.push(serpBeater.uuid);
                });
            }
        },

        select() {
            this.allSelected = false;
        },

        difficultyClass(difficulty) {
            let difficultyClass = 'badge-light-white';
            switch (true) {
                case difficulty < 1:
                    difficultyClass = 'badge-light-white';
                    break;
                case difficulty < 33:
                    difficultyClass = 'badge-light-success';
                    break;
                case difficulty < 63:
                    difficultyClass = 'badge-light-warning';
                    break;
                case difficulty < 100:
                    difficultyClass = 'badge-light-danger';
                    break;
            }
            return difficultyClass;
        },
    },
    computed: {},
});
</script>
