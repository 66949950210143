<template>
    <div class="row">
        <div class="col-10">
        <h1>{{ __('Bulk Processing') }}</h1>
        <div class="text-muted fs-7 fw-bold">
            {{ __('Import your CSV to generate AI content from it') }}
        </div>
    </div>
    <div class="col-2 text-end flex justify-end" v-if="bulkProcessingIds.length">
        <div>
            <button class="btn btn-danger" @click.prevent="sendBulkDataToModal">
                {{ __('Delete') }}
            </button>
        </div>
        
    </div>
    </div>
   
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BulkProcessingListingHeader',

    data() {
        return {
            bulkProcessingIds: [],
        };
    },

    mounted() {
        const vm = this;

        // eslint-disable-next-line no-undef
        emitter.on('bulk-processing-uuids-selected', (uuids) => {
            vm.bulkProcessingIds = uuids;
        });
    },

    beforeUnmount() {
        // eslint-disable-next-line no-undef
        emitter.off('bulk-processing-uuids-selected');
    },

    methods: {
        sendBulkDataToModal() {
            // eslint-disable-next-line no-undef
            emitter.emit('set-delete-candidate', {
                ids: this.bulkProcessingIds,
                url: '/api/bulk-processings/bulk-destroy',
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this file? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },
        deleteCallback() {
            this.$emit('reload-data');
        },
    },
});
</script>
