<template>
    <div>
        <video
            ref="videoPlayer"
            class="video-js vjs-layout-large vjs-theme-sea"
        ></video>
    </div>
</template>

<script>
import videojs from 'video.js';
import 'videojs-youtube/dist/Youtube.min.js';

export default {
    name: 'VideoPlayer',
    props: {
        options: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            player: null,
        };
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
            this.player.log('onPlayerReady', this);
        });
    },
    beforeUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    },
};
</script>
<style>
@import 'video.js/dist/video-js.css';
@import '@videojs/themes/dist/sea/index.css';
</style>
