<template>
    <Loader v-if="isLoading" :loading-text="loadingText" />
    <app-layout
        v-if="userStore.getUser"
        :title="__('Dashboard')"
        :user="userStore.getUser"
        :class="{ blur: isLoading }"
    >
        <template #header>
            <h2 class="h4 font-weight-bold">
                {{ __('Dashboard') }}
            </h2>
        </template>
        <div class="row gx-5 gx-xl-6 py-2">
            <div class="col-12">
                <div
                    v-if="ltd.newAppSumo"
                    class="alert alert-success alert-dismissible d-flex align-items-center p-5"
                >
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.3"
                                d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                fill="currentColor"
                            ></path>
                            <path
                                d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </span>
                    <!--end::Icon-->

                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column">
                        <!--begin::Title-->
                        <h4 class="mb-1 text-dark">
                            {{ __('Congratulations!') }}
                        </h4>
                        <!--end::Title-->
                        <!--begin::Content-->
                        <span>{{
                            __(
                                'You have successfully activated your AppSumo plan.'
                            )
                        }}</span>
                        <span>{{
                            __(
                                'Your plan includes all the features of the standard plan, plus you have unlimited number of characters.'
                            )
                        }}</span>
                        <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->

                    <!--begin::Close-->
                    <button
                        type="button"
                        class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                        data-bs-dismiss="alert"
                        @click="closeLtdAlert"
                    >
                        <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="currentColor"
                                ></rect>
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="currentColor"
                                ></rect>
                            </svg>
                        </span>
                    </button>
                    <!--end::Close-->
                </div>

                <div class="row gx-5 gx-xl-6 py-2">
                    <div class="col-lg-4">
                        <h1 class="text-2xl">
                            👋 {{ __('Welcome') }} {{ userStore.getUser?.name }}
                        </h1>
                    </div>
                    <div class="col-lg-8" v-if="creditsExhausted">
                        <div
                            class="alert bg-white border border-danger alert-dismissible fade show text-danger d-flex p-5"
                            role="alert"
                        >
                            <div class="me-4 mt-1">
                                <i
                                    class="bi bi-exclamation-circle text-danger fs-3"
                                ></i>
                            </div>
                            <div>
                                <strong>
                                    {{ __('Your credits have expired.') }}
                                </strong>
                                <br />
                                {{
                                    __(
                                        "This means that only some of Creaitor's features are available now. To get unlimited access:"
                                    )
                                }}
                                <a
                                    href="#"
                                    @click.prevent="skipTrial"
                                    class="d-flex align-items-center mt-3"
                                >
                                    {{ __('Start Subscription Now') }}
                                    <i
                                        class="bi bi-arrow-right-short ms-3 text-primary fs-2"
                                    ></i>
                                </a>
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="alert"
                                    aria-label="Close"
                                ></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gy-5 g-xl-10">
                    <div class="col-sm-12 col-md-8">
                        <div class="row gx-5 gx-xl-6 py-2">
                            <!--begin::Col-->
                            <div class="col-sm-12 col-xl-6">
                                <div
                                    v-if="isLoadingCharacterUsage"
                                    class="card card-flush my-1"
                                >
                                    <div class="card-body">
                                        <div
                                            class="d-flex align-items-center justify-content-center p-5"
                                        >
                                            <div
                                                role="status"
                                            >
                                                <IconLoading class="animate-spin" />
                                                <span class="visually-hidden">{{
                                                    __('Loading...')
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="card card-flush my-1">
                                    <!--begin::Header-->
                                    <div class="card-body">
                                        <!--begin::Title-->
                                        <div class="d-flex flex-column">
                                            <!--begin::Info-->
                                            <div
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <div
                                                    v-if="
                                                        subscriptionStore.hasUnlimitedUsage
                                                    "
                                                    class="d-flex"
                                                >
                                                    <!--begin::Amount-->
                                                    <span
                                                        class="text-3xl fw-bold text-dark me-2 lh-1 ls-n2"
                                                        >{{
                                                            __('Unlimited')
                                                        }}</span
                                                    >
                                                    <!--end::Amount-->
                                                </div>
                                                <div v-else class="d-flex">
                                                    <template
                                                        v-if="
                                                            $page.props
                                                                .trial_character_credit_exhausted
                                                        "
                                                    >
                                                        <span
                                                            class="text-3xl fw-bold text-dark me-2 lh-1 ls-n2"
                                                        >
                                                            0
                                                        </span>
                                                    </template>
                                                    <template v-else>
                                                        <!--begin::Amount-->
                                                        <span
                                                            class="text-3xl fw-bold text-dark me-2 lh-1 ls-n2"
                                                            >{{
                                                                totalCharacterCredits.toLocaleString(
                                                                    userStore.getUserLocale
                                                                )
                                                            }}</span
                                                        >
                                                        <span
                                                            class="fs-3 fw-bold text-gray-700 align-self-end mb-1 me-2 lh-1 ls-n2"
                                                            >/
                                                            {{
                                                                (
                                                                    totalCharacterCredits +
                                                                    teamStore.getTotalCharacterCount
                                                                ).toLocaleString(
                                                                    userStore.getUserLocale
                                                                )
                                                            }}</span
                                                        >
                                                        <!--end::Amount-->
                                                    </template>
                                                </div>

                                                <div class="d-flex">
                                                    <Link
                                                        href="/billing#characters-credits"
                                                        class="btn btn-sm btn-icon w-30px h-30px"
                                                    >
                                                        <span
                                                            class="svg-icon svg-icon-muted svg-icon-2"
                                                        >
                                                            <IconLinkArrow class="scale-90" />
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <!--end::Info-->

                                            <!--begin::Subtitle-->
                                            <span
                                                class="text-gray-400 pt-1 fw-semibold fs-7 mb-4"
                                                >{{
                                                    __(
                                                        'Remaining characters credits'
                                                    )
                                                }}
                                            </span>
                                            <div
                                                class="d-flex justify-content-between"
                                                v-if="
                                                    $page.props
                                                        .trial_character_credit_exhausted
                                                "
                                            >
                                                <div
                                                    class="d-flex align-items-center text-danger me-3"
                                                >
                                                    <i
                                                        class="bi bi-exclamation-circle me-2 text-danger"
                                                    ></i>
                                                    {{
                                                        __(
                                                            'Trial Credits Expired'
                                                        )
                                                    }}
                                                </div>
                                                <a
                                                    href="#"
                                                    @click.prevent="skipTrial"
                                                    class=""
                                                >
                                                    <u>
                                                        <strong>
                                                            {{
                                                                __(
                                                                    'Start subscription now'
                                                                )
                                                            }}
                                                        </strong>
                                                    </u>
                                                </a>
                                            </div>
                                            <!--end::Subtitle-->
                                        </div>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Header-->
                                </div>
                            </div>
                            <!--end::Col-->

                            <div class="col-sm-12 col-xl-6">
                                <div
                                    v-if="isLoadingImageUsage"
                                    class="card card-flush my-1"
                                >
                                    <div class="card-body">
                                        <div
                                            class="d-flex align-items-center justify-content-center p-5"
                                        >
                                            <div
                                                role="status"
                                            >
                                                <IconLoading class="animate-spin" />
                                                <span class="visually-hidden">{{
                                                    __('Loading...')
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="card card-flush my-1">
                                    <!--begin::Header-->
                                    <div class="card-body">
                                        <!--begin::Title-->
                                        <div class="d-flex flex-column">
                                            <!--begin::Info-->
                                            <div
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <div class="d-flex">
                                                    <template
                                                        v-if="
                                                            $page.props
                                                                .trial_character_image_exhausted
                                                        "
                                                    >
                                                        <span
                                                            class="text-3xl fw-bold text-dark me-2 lh-1 ls-n2"
                                                        >
                                                            0
                                                        </span>
                                                    </template>
                                                    <template v-else>
                                                        <!--begin::Amount-->
                                                        <span
                                                            class="text-3xl fw-bold text-dark me-2 lh-1 ls-n2"
                                                            >{{
                                                                teamTotalImageCredits.toLocaleString(
                                                                    userStore.getUserLocale
                                                                )
                                                            }}</span
                                                        >
                                                        <span
                                                            class="fs-3 fw-bold text-gray-700 align-self-end mb-1 me-2 lh-1 ls-n2"
                                                            >/
                                                            {{
                                                                (
                                                                    teamTotalImageCredits +
                                                                    teamStore.getTotalImageCount
                                                                ).toLocaleString(
                                                                    userStore.getUserLocale
                                                                )
                                                            }}</span
                                                        >
                                                        <!--end::Amount-->
                                                    </template>
                                                </div>

                                                <div class="d-flex">
                                                    <Link
                                                        href="/billing#image-credits"
                                                        class="btn btn-sm btn-icon w-30px h-30px"
                                                    >
                                                        <span
                                                            class="svg-icon svg-icon-muted svg-icon-2"
                                                        >
                                                            <IconLinkArrow class="scale-90" />
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <!--end::Info-->

                                            <!--begin::Subtitle-->
                                            <span
                                                class="text-gray-400 pt-1 fw-semibold fs-7 mb-4"
                                                >{{
                                                    __(
                                                        'Remaining images credits'
                                                    )
                                                }}
                                            </span>
                                            <div
                                                class="d-flex justify-content-between"
                                                v-if="
                                                    $page.props
                                                        .trial_character_image_exhausted
                                                "
                                            >
                                                <div
                                                    class="d-flex align-items-center text-danger me-3"
                                                >
                                                    <i
                                                        class="bi bi-exclamation-circle me-2 text-danger"
                                                    ></i>
                                                    {{
                                                        __(
                                                            'Trial Credits Expired'
                                                        )
                                                    }}
                                                </div>
                                                <a
                                                    href="#"
                                                    @click.prevent="skipTrial"
                                                    class=""
                                                >
                                                    <u>
                                                        <strong>
                                                            {{
                                                                __(
                                                                    'Start subscription now'
                                                                )
                                                            }}
                                                        </strong>
                                                    </u>
                                                </a>
                                            </div>
                                            <!--end::Subtitle-->
                                        </div>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Header-->
                                </div>
                            </div>
                        </div>
                        <div class="row gx-5 gx-xl-6 py-2">
                            <div class="col-sm-12 col-xl-6">
                                <div class="card card-flush my-1 h-lg-100">
                                    <div
                                        class="card-header pt-3 align-items-center border-0"
                                    >
                                        <!--begin::Title-->
                                        <h3
                                            class="card-title align-items-start flex-column"
                                        >
                                            <span
                                                class="card-label fw-bolder text-dark"
                                                >{{ __('AI Tools') }}</span
                                            >
                                            <span
                                                class="text-gray-400 fw-bold text-sm"
                                                >{{
                                                    __('Most Used AI Tools')
                                                }}</span
                                            >
                                        </h3>
                                        <!--end::Title-->
                                    </div>
                                    <div class="card-body">
                                        <!--begin::Item-->
                                        <div class="d-flex">
                                            <!--begin::Article Wizard-->
                                            <Link
                                                :href="route('wizard')"
                                                class="d-flex flex-1 justify-content-between text-primary opacity-75-hover text-sm fw-bold"
                                            >
                                                <span
                                                    class="text-gray-700 parent-hover-primary text-sm fw-bold"
                                                >
                                                    {{ __('Article Wizard') }}
                                                </span>
                                                <span class="w-20px h-20px">
                                                    <span
                                                        class="svg-icon svg-icon-muted svg-icon-2"
                                                    >
                                                        <IconLinkArrow class="scale-90" />
                                                    </span>
                                                </span>
                                            </Link>
                                            <!--end::Article Wizard-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Separator-->
                                        <div
                                            class="separator separator-dashed my-3"
                                        ></div>
                                        <!--end::Separator-->
                                        <!--begin::Item-->
                                        <div class="d-flex">
                                            <!--begin::Article Wizard-->
                                            <Link
                                                :href="
                                                    route(
                                                        'ai-assistant.selection'
                                                    )
                                                "
                                                class="d-flex flex-1 justify-content-between text-primary opacity-75-hover text-sm fw-bold"
                                            >
                                                <span
                                                    class="text-gray-700 parent-hover-primary text-sm fw-bold"
                                                >
                                                    {{ __('AI Assistant') }}
                                                </span>
                                                <span class="w-20px h-20px">
                                                    <span
                                                        class="svg-icon svg-icon-muted svg-icon-2"
                                                    >
                                                        <IconLinkArrow class="scale-90" />
                                                    </span>
                                                </span>
                                            </Link>
                                            <!--end::Article Wizard-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Separator-->
                                        <div
                                            class="separator separator-dashed my-3"
                                        ></div>
                                        <!--end::Separator-->
                                        <!--begin::Item-->
                                        <div class="d-flex">
                                            <!--begin::Article Wizard-->
                                            <Link
                                                :href="
                                                    route(
                                                        'image-generator-collections.list'
                                                    )
                                                "
                                                class="d-flex flex-1 justify-content-between text-primary opacity-75-hover text-sm fw-bold"
                                            >
                                                <span
                                                    class="text-gray-700 parent-hover-primary text-sm fw-bold"
                                                >
                                                    {{ __('Image Generator') }}
                                                </span>
                                                <span class="w-20px h-20px">
                                                    <span
                                                        class="svg-icon svg-icon-muted svg-icon-2"
                                                    >
                                                        <IconLinkArrow class="scale-90" />
                                                    </span>
                                                </span>
                                            </Link>
                                            <!--end::Article Wizard-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Separator-->
                                        <div
                                            class="separator separator-dashed my-3"
                                        ></div>
                                        <!--end::Separator-->
                                        <!--begin::Item-->
                                        <div class="d-flex">
                                            <!--begin::Article Wizard-->
                                            <Link
                                                :href="route('chat-gpt')"
                                                class="d-flex flex-1 justify-content-between text-primary opacity-75-hover text-sm fw-bold"
                                            >
                                                <span
                                                    class="text-gray-700 parent-hover-primary text-sm fw-bold"
                                                >
                                                    {{ __('AI Chat') }}
                                                </span>
                                                <span class="w-20px h-20px">
                                                    <span
                                                        class="svg-icon svg-icon-muted svg-icon-2"
                                                    >
                                                        <IconLinkArrow class="scale-90" />
                                                    </span>
                                                </span>
                                            </Link>
                                            <!--end::Article Wizard-->
                                        </div>
                                        <!--end::Item-->
                                    </div>
                                </div>
                            </div>
                            <!--begin::Col-->
                            <div class="col-sm-12 col-xl-6">
                                <!--begin::List widget 2-->
                                <div class="card card-flush my-1 h-lg-100">
                                    <!--begin::Header-->
                                    <div class="card-header pt-3">
                                        <!--begin::Title-->
                                        <h3
                                            class="card-title align-items-start flex-column"
                                        >
                                            <span
                                                class="card-label fw-bolder text-dark"
                                                >{{ __('AI Assistants') }}</span
                                            >
                                            <span
                                                class="text-gray-400 fw-bold text-sm"
                                                >{{
                                                    __('Popular AI Assistants')
                                                }}</span
                                            >
                                        </h3>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-5">
                                        <!--begin::Item-->
                                        <div class="d-flex flex-stack">
                                            <Link
                                                :href="'/ai-assistant/2596c5a7-1dcf-49f9-a6c6-4ab8ff8ff44b'"
                                                class="d-flex flex-1 justify-content-between text-primary opacity-75-hover text-sm fw-bold"
                                            >
                                                <span
                                                    class="text-gray-700 parent-hover-primary text-sm fw-bold"
                                                >
                                                    {{ __('Headline') }}
                                                </span>
                                                <span class="w-20px h-20px">
                                                    <span
                                                        class="svg-icon svg-icon-muted svg-icon-2"
                                                    >
                                                        <IconLinkArrow class="scale-90" />
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                        <!--begin::Separator-->
                                        <div
                                            class="separator separator-dashed my-3"
                                        ></div>
                                        <!--end::Separator-->
                                        <!--begin::Item-->
                                        <div class="d-flex flex-stack">
                                            <Link
                                                :href="'/ai-assistant/80cba8ff-de75-4d65-8a13-0d8f815b57be'"
                                                class="d-flex flex-1 justify-content-between text-primary opacity-75-hover text-sm fw-bold"
                                            >
                                                <span
                                                    class="text-gray-700 parent-hover-primary text-sm fw-bold"
                                                >
                                                    {{ __('LinkedIn Post') }}
                                                </span>
                                                <span class="w-20px h-20px">
                                                    <span
                                                        class="svg-icon svg-icon-muted svg-icon-2"
                                                    >
                                                        <IconLinkArrow class="scale-90" />
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Separator-->
                                        <div
                                            class="separator separator-dashed my-3"
                                        ></div>
                                        <!--end::Separator-->
                                        <!--begin::Item-->
                                        <div class="d-flex flex-stack">
                                            <Link
                                                :href="'/ai-assistant/ffca1875-7725-4d50-926c-a8669a9bd6d1'"
                                                class="d-flex flex-1 justify-content-between text-primary opacity-75-hover text-sm fw-bold"
                                            >
                                                <span
                                                    class="text-gray-700 parent-hover-primary text-sm fw-bold"
                                                >
                                                    {{ __('Facebook Ad Text') }}
                                                </span>
                                                <span class="w-20px h-20px">
                                                    <span
                                                        class="svg-icon svg-icon-muted svg-icon-2"
                                                    >
                                                        <IconLinkArrow class="scale-90" />
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Separator-->
                                        <div
                                            class="separator separator-dashed my-3"
                                        ></div>
                                        <!--end::Separator-->
                                        <!--begin::Item-->
                                        <div class="d-flex flex-stack">
                                            <Link
                                                :href="'/ai-assistant/fa1e0a12-26a2-49fb-9711-cfe81e80399b'"
                                                class="d-flex flex-1 justify-content-between text-primary opacity-75-hover text-sm fw-bold"
                                            >
                                                <span
                                                    class="text-gray-700 parent-hover-primary text-sm fw-bold"
                                                >
                                                    {{ __('Google Ad Text') }}
                                                </span>
                                                <span class="w-20px h-20px">
                                                    <span
                                                        class="svg-icon svg-icon-muted svg-icon-2"
                                                    >
                                                        <IconLinkArrow class="scale-90" />
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                        <!--end::Item-->
                                    </div>
                                    <!--end::Body-->
                                </div>
                                <!--end::List widget 2-->
                            </div>
                            <!--end::Col-->
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="row gx-5 gx-xl-6 py-2">
                            <div class="col-12">
                                <div
                                    class="card bg-blue-100 my-2 h-md-100"
                                    dir="ltr"
                                >
                                    <!--begin::Body-->
                                    <div
                                        class="card-body d-flex flex-column flex-center"
                                    >
                                        <!--begin::Heading-->
                                        <div class="mb-0">
                                            <!--begin::Title-->
                                            <h1
                                                class="text-2xl font-normal text-gray-800 text-center lh-lg"
                                                v-html="textTriedArticleWizard"
                                            ></h1>
                                            <!--end::Title-->

                                            <!--begin::Illustration-->
                                            <div class="py-10 text-center">
                                                <img
                                                    src="/media/svg/illustrations/easy/2.svg"
                                                    class="theme-light-show w-200px"
                                                    alt=""
                                                />
                                            </div>
                                            <!--end::Illustration-->
                                        </div>
                                        <!--end::Heading-->

                                        <!--begin::Links-->
                                        <div class="text-center mb-3">
                                            <!--begin::Link-->
                                            <a
                                                href="/wizard"
                                                class="btn btn-sm btn-primary me-2"
                                            >
                                                {{ __('Try now') }}
                                            </a>
                                            <!--end::Link-->
                                        </div>
                                        <!--end::Links-->
                                    </div>
                                    <!--end::Body-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="
                        !subscriptionStore.hasAddons &&
                        subscriptionStore.hasRecurringSubscription &&
                        !subscriptionStore.hasLifetimeSubscription &&
                        !subscriptionStore.planBasedLtd
                    "
                    class="card border-transparent my-5"
                    data-theme="light"
                    style="background-color: #1c325e"
                >
                    <!--begin::Body-->
                    <div class="card-body d-flex ps-xl-15">
                        <!--begin::Wrapper-->
                        <div class="m-0">
                            <!--begin::Title-->
                            <div
                                class="position-relative fs-2x z-index-2 fw-bolder text-white mb-7"
                            >
                                <span class="me-2"
                                    >{{ __('Get the') }}
                                    <span
                                        class="position-relative d-inline-block text-danger"
                                    >
                                        <span class="text-danger fw-bolder">{{
                                            __('Image Generator')
                                        }}</span>
                                        <!--begin::Separator-->
                                        <span
                                            class="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"
                                        ></span>
                                        <!--end::Separator-->
                                    </span></span
                                >
                                <br />{{
                                    __('Create unique and royalty-free images')
                                }}
                            </div>
                            <!--end::Title-->
                            <!--begin::Action-->
                            <div class="mb-3">
                                <a
                                    href="#"
                                    @click.prevent="addImageGeneratorToPlan"
                                    class="btn btn-danger fw-semibold me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_upgrade_plan"
                                    >{{ __('Get it now') }}</a
                                >
                                <!--                  <a href="/image-generator" class="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold">How to</a>-->
                            </div>
                            <!--begin::Action-->
                        </div>
                        <!--begin::Wrapper-->
                        <img
                            src="/media/images/image_generator_small.png"
                            class="position-absolute me-5 bottom-0 end-0 h-200px"
                            alt=""
                        />
                    </div>
                </div>

                <div class="row gx-5 gx-xl-6 mt-12">
                    <div class="col-12">
                        <h2 class="text-lg text-gray-700">{{ __('AI Tools') }}</h2>
                    </div>
                </div>

                <div class="row gx-5 gx-xl-6 py-2">
                    <div class="col-sm-12 col-md-4">
                        <div class="card h-lg-100 my-2">
                            <div
                                class="card-header align-items-center border-0"
                            >
                                <!--begin::Title-->
                                <h3 class="fw-bold text-lg text-gray-900 m-0">
                                    {{ __('My Documents') }}
                                </h3>
                                <!--end::Title-->
                            </div>
                            <DocumentsTable
                                view="minimal"
                                :columns="['Name', 'View']"
                                :initialSelectedViewLengthOption="5"
                            ></DocumentsTable>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4">
                        <div class="card h-lg-100 my-2">
                            <div
                                class="card-header align-items-center border-0"
                            >
                                <!--begin::Title-->
                                <h3 class="fw-bold text-lg text-gray-900 m-0">
                                    {{ __('My Image Generator Collections') }}
                                </h3>
                                <!--end::Title-->
                            </div>
                            <ImageCollectionsTable
                                view="minimal"
                                :columns="['Images', 'View']"
                                :initialSelectedViewLengthOption="4"
                            ></ImageCollectionsTable>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4">
                        <div class="card h-lg-100 my-2">
                            <div
                                class="card-header align-items-center border-0"
                            >
                                <!--begin::Title-->
                                <h3 class="fw-bold text-lg text-gray-900 m-0">
                                    {{ __('My AI Chat Conversations') }}
                                </h3>
                                <!--end::Title-->
                            </div>
                            <ConversationsTable
                                view="minimal"
                                :columns="['Name', 'View']"
                                :initialSelectedViewLengthOption="5"
                            ></ConversationsTable>
                        </div>
                    </div>
                </div>

                <div class="row gx-5 gx-xl-6 mt-15 mb-4 d-none">
                    <div class="col-12">
                        <h2 class="text-gray-700">{{ __('SEO Suite') }}</h2>
                    </div>
                </div>

                <div class="row gx-5 gx-xl-6 py-2 d-none">
                    <div class="col-sm-12 col-md-4">
                        <div class="card h-lg-100 my-2">
                            <div
                                class="card-header align-items-center border-0"
                            >
                                <!--begin::Title-->
                                <h3 class="fw-bold text-gray-900 m-0">
                                    {{ __('My Keyword Clusters') }}
                                </h3>
                                <!--end::Title-->
                            </div>
                            <KeywordClustersTable
                                view="minimal"
                                :columns="['Keyword', 'Location', 'View']"
                                :initialSelectedViewLengthOption="5"
                            ></KeywordClustersTable>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4">
                        <div class="card h-lg-100 my-2">
                            <div
                                class="card-header align-items-center border-0"
                            >
                                <!--begin::Title-->
                                <h3 class="fw-bold text-gray-900 m-0">
                                    {{ __('My SERP Beater Articles') }}
                                </h3>
                                <!--end::Title-->
                            </div>
                            <SerpBeatersTable
                                view="minimal"
                                :columns="['Keyword', 'Status', 'View']"
                                :initialSelectedViewLengthOption="5"
                            ></SerpBeatersTable>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4">
                        <div class="card h-lg-100 my-2">
                            <div
                                class="card-header align-items-center border-0"
                            >
                                <!--begin::Title-->
                                <h3 class="fw-bold text-gray-900 m-0">
                                    {{ __('My Position Trackings') }}
                                </h3>
                                <!--end::Title-->
                            </div>
                            <div class="card card-flush h-lg-100">
                                <div class="card-body pt-0">
                                    <div
                                        id="position_trackings_table_wrapper"
                                        class="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div class="bootstrap-table-responsive">
                                            <table
                                                id="position_trackings_table"
                                                class="bootstrap-table align-middle table-row-dashed gy-3 text-sm dataTable no-footer"
                                            >
                                                <!--begin::Table head-->
                                                <thead>
                                                    <!--begin::Table row-->
                                                    <tr
                                                        class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                                    >
                                                        <th
                                                            class="sorting mw-250px"
                                                            tabindex="0"
                                                            aria-controls="position_trackings_table"
                                                            rowspan="1"
                                                            colspan="1"
                                                            aria-label="Keyword"
                                                        >
                                                            {{ __('Keyword') }}
                                                        </th>
                                                    </tr>
                                                    <!--end::Table row-->
                                                </thead>
                                                <!--end::Table head-->
                                                <!--begin::Table body-->
                                                <tbody
                                                    class="fw-bold text-gray-600"
                                                >
                                                    <!--begin::Table row-->

                                                    <!--end::Table row-->
                                                    <tr>
                                                        <!--begin::Keyword=-->
                                                        <td>
                                                            <div
                                                                class="d-flex align-items-center justify-content-center text-center fw-bolder text-gray-400"
                                                            >
                                                                {{
                                                                    __(
                                                                        'Coming soon'
                                                                    )
                                                                }}
                                                            </div>
                                                        </td>
                                                        <!--end::Keyword=-->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gx-5 gx-xl-6 py-2">
                    <!--begin::Col-->
                    <div class="col-sm-4 mb-5 mb-xl-6 d-none">
                        <!--begin::List Widget 2-->
                        <div class="card card-flush my-1 h-lg-100">
                            <!--begin::Header-->
                            <div class="card-header border-0">
                                <h3 class="card-title fw-bolder text-dark">
                                    Team
                                </h3>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body pt-2">
                                <!--begin::Item-->
                                <div
                                    v-if="teamStore.getTeamMembersCount === 0"
                                    class="d-flex align-items-center mb-7"
                                >
                                    <!--begin::Text-->
                                    <div class="flex-grow-1">
                                        <span
                                            class="text-muted text-center d-block fw-bold"
                                            >No Team member yet</span
                                        >
                                    </div>
                                    <!--end::Text-->
                                </div>
                                <!--begin::Item-->
                                <div
                                    v-for="teamMember in teamStore.getTeamMembers"
                                    :key="teamMember.id"
                                    class="d-flex align-items-center mb-7"
                                >
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-50px me-5">
                                        <img
                                            :src="teamMember.profile_photo_url"
                                            class=""
                                            alt=""
                                        />
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Text-->
                                    <div class="flex-grow-1">
                                        <a
                                            href="#"
                                            class="text-dark fw-bolder text-hover-primary text-sm"
                                            >{{ teamMember.name }}</a
                                        >
                                    </div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Item-->
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::List Widget 2-->
                    </div>
                    <!--end::Col-->
                </div>

                <div class="row gx-5 gx-xl-6 mt-8">
                    <div class="col-12">
                        <h2 class="text-lg text-gray-700">
                            {{ __('AI Assistant Favorites') }}
                        </h2>
                    </div>
                </div>
                <div
                    v-if="favoriteTemplates.length > 0"
                    class="row gx-5 gx-xl-6 mb-10"
                >
                    <div
                        v-for="(template, index) in favoriteTemplates"
                        @click="onClickTemplate(template.uuid)"
                        class="col-md-4 d-flex flex-row align-items-stretch"
                    >
                        <div
                            class="card card-flush card-bordered card-ai-assistant w-100 hoverable my-5"
                        >
                            <div class="card-header flex-row">
                                <div class="d-flex flex-column">
                                    <div
                                        class="d-flex flex-center w-60px h-60px rounded-circle bg-opacity-90 mt-10 mb-5"
                                        :style="{
                                            backgroundColor:
                                                template
                                                    .ai_assistant_template_category
                                                    .color,
                                        }"
                                    >
                                        <img
                                            :src="
                                                '/storage/' +
                                                template.template_icon_path
                                            "
                                        />
                                    </div>
                                    <h3
                                        class="card-title card-label fw-bolder text-dark"
                                    >
                                        {{ template.name[$page.props.locale] }}
                                    </h3>
                                </div>
                                <div class="d-flex pt-10">
                                    <a
                                        href="#"
                                        @click.stop.prevent="
                                            onClickFavorite(template.uuid)
                                        "
                                        :class="{
                                            'is-favorite': template.is_favorite,
                                        }"
                                        class="favorite"
                                    >
                                        <span
                                            class="svg-icon svg-icon-3 favorite"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div class="card-body text-gray-700 py-5">
                                {{ template.description[$page.props.locale] }}
                            </div>
                            <div class="card-footer">
                                <span class="text-gray-400 mt-1 fw-bold fs-8"
                                    ><i class="bi bi-tag-fill"></i>
                                    {{
                                        template.ai_assistant_template_category
                                            .name[$page.props.locale]
                                    }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row gx-5 gx-xl-6 mb-10">
                    <div class="col-12 d-flex justify-content-center">
                        <span class="text-gray-500 text-sm">{{
                            __('You have not selected any favorites.')
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import ApexCharts from 'apexcharts';
import AppLayout from '@/Layouts/AppLayout.vue';
import Welcome from '@/Jetstream/Welcome.vue';
import Loader from './Loader.vue';
import { Link } from '@inertiajs/vue3';
import DocumentsTable from '@/Components/Documents/DocumentsTable.vue';
import ImageCollectionsTable from '@/Components/ImageGenerator/ImageCollectionsTable.vue';
import KeywordClustersTable from '@/Components/KeywordResearch/KeywordClustersTable.vue';
import SerpBeatersTable from '@/Components/SerpBeater/SerpBeatersTable.vue';
import ConversationsTable from '@/Components/AiChat/ConversationsTable.vue';
import {router as Inertia} from '@inertiajs/vue3';
import { useOnboardProcessStore } from '@/Stores/OnboardProcessStore';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';
import IconLoading from '@/icons/loading.svg?component';
import IconLinkArrow from '@/icons/link-arrow.svg?component';

export default defineComponent({
    components: {
        Link,
        AppLayout,
        Welcome,
        Loader,
        DocumentsTable,
        ImageCollectionsTable,
        KeywordClustersTable,
        SerpBeatersTable,
        ConversationsTable,
        IconLoading,
        IconLinkArrow,
    },

    props: {
        height: {
            type: Number,
            default: 400,
        },
        labelColor: {
            type: String,
            default: '#7E8299',
        },
        borderColor: {
            type: String,
            default: '#EFF2F5',
        },
        baseColor: {
            type: String,
            default: '#2bc5d3',
        },
        lightColor: {
            type: String,
            default: '#54d5aa75',
        },
    },

    setup() {
        // Get onboardProcess store
        const onboardProcessStore = useOnboardProcessStore();

        // Get toast interface
        const toast = useToast();

        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            onboardProcessStore,
            toast,
            userStore,
            teamStore,
            subscriptionStore,
        };
    },

    data() {
        return {
            aiAssistantTemplateCategories: [],
            aiAssistantTemplates: [],
            selectedCategory: 'all',
            search: '',
            ltd: {
                newAppSumo: false,
            },
            user: {},
            session: '',
            totalCharacterCredits: 0,
            planCharacterCredits: 0,
            bonusCharacterCredits: 0,
            percentageCharacterUsageChange: 0,
            isLoadingCharacterUsage: false,
            teamTotalImageCredits: 0,
            teamPlanImageCredits: 0,
            teamBonusImageCredits: 0,
            teamAddOnImageCredits: 0,
            isLoadingImageUsage: false,
            percentageImageUsageChange: 0,
            isLoading: false,
            loadingText: this.__('Loading...'),
            textTriedArticleWizard: this.__(
                'Have you already tried our <br><span class="fw-bolder"> Article Wizard</span>?'
            ),
        };
    },

    mounted() {
        if (!this.$attrs.user.current_project_id) {
            this.isLoading = true;
            emitter.emit('close-choose-plan');
            Inertia.visit(route('onboard.process'));
        }

        emitter.on('open-initial-choose-plan-finished', () => {
            this.isLoading = false;
        });

        emitter.on('reload-image-usage-card', () => {
            this.fetchImageUsage('lastFilled');
        });

        this.fetchTemplateCategories();
        this.fetchTemplates();
        if (
            this.$cookies.get('ltd') &&
            this.$cookies.get('ltd').hasOwnProperty('newAppSumo')
        ) {
            this.ltd = this.$cookies.get('ltd');
        }

        this.fetchCharacterUsage('lastFilled');
        this.fetchImageUsage('lastFilled');

        if (this.onboardProcessStore.getCurrentStep === 2) {
            /*Swal.fire({
        text: this.__('Everything is set up. You can start now.'),
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: this.__('Ok'),
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      });*/

            this.onboardProcessStore.resetState();
        }

        this.userStore.setUser(this.$attrs.user);
        this.subscriptionStore.setPlans(this.getPlans() || []);
        this.subscriptionStore.setCurrentPlan(this.$page.props.plan);
    },

    methods: {
        fetchTemplateCategories() {
            axios
                .get('/api/ai-assistant-template-categories')
                .then((response) => {
                    this.aiAssistantTemplateCategories = response.data.filter(
                        (category) => parseInt(category.highlight) === 1
                    );
                });
        },
        fetchTemplates() {
            axios.get('/api/ai-assistant-templates').then((response) => {
                this.aiAssistantTemplates = response.data;
            });
        },
        fetchCharacterUsage(range) {
            this.isLoadingCharacterUsage = true;

            axios
                .get(`/api/statistics/used-characters?range=${range}`)
                .then((response) => {
                    this.totalCharacterCredits = response.data.totalCredits;

                    this.planCharacterCredits = response.data.planCredits;

                    this.bonusCharacterCredits = response.data.bonusCredits;

                    this.teamStore.setTotalCharacterCount(
                        response.data.totalCharacterCount
                    );

                    this.percentageCharacterUsageChange =
                        response.data.percentageChange;
                })
                .finally(() => {
                    this.isLoadingCharacterUsage = false;
                });
        },

        fetchImageUsage(range) {
            this.isLoadingImageUsage = true;

            axios
                .get(`/api/statistics/used-images?range=${range}`)
                .then((response) => {
                    this.teamTotalImageCredits = response.data.totalCredits;

                    this.teamPlanImageCredits = response.data.planCredits;

                    this.teamBonusImageCredits = response.data.bonusCredits;

                    this.teamAddOnImageCredits = response.data.addOnCredits;

                    this.teamStore.setTotalImageCount(
                        response.data.totalImageCount
                    );

                    this.percentageImageUsageChange =
                        response.data.percentageChange;
                })
                .finally(() => {
                    this.isLoadingImageUsage = false;
                });
        },
        onClickCategory(category) {
            this.selectedCategory = category;
        },
        onClickTemplate(uuid) {
            window.location.href = '/ai-assistant/' + uuid;
        },
        closeLtdAlert() {
            this.$cookies.set('ltd', { newAppSumo: false });
        },

        /**
         * Add Image Generator to plan.
         */
        addImageGeneratorToPlan() {
            emitter.emit('open-add-image-generator-to-plan');
        },

        async readmelogin() {
            let response;

            try {
                response = await axios.get('/api/session');
            } catch (error) {
                console.error('Error fetching session:', error);
                return;
            }

            this.session = response.data;

            if (!this.session || !this.session.previous) {
                console.error('Session or previous URL is undefined');
                return;
            }

            let previousUrlLength = this.session.previous.length;
            if (previousUrlLength === 0) {
                console.error('Previous URLs array is empty');
                return;
            }

            let previousUrl = this.session.previous[previousUrlLength - 1];

            if (
                sessionStorage.getItem('flag') === true ||
                previousUrl.includes('redirect') === true ||
                previousUrl.includes('docs.creaitor.ai') === true
            ) {
                this.loadingText = this.__('Connecting with API');
                this.isLoading = true;

                await axios.get('/api/get-api-tokens').then((response) => {
                    this.user = response.data;
                });
                const jwt = require('jsonwebtoken');

                const user = {
                    name: this.user.name,
                    email: this.user.email,
                    keys: this.user.keys,
                    allowedProjects: ['creaitor'],
                    version: 1,
                };

                let secret = 'jvdJWCfHEJhpYQNtI9NM';
                const auth_token = jwt.sign(user, secret.toString('utf-8'));
                const readmeUrl = 'https://docs.creaitor.ai/';
                const url = `${readmeUrl}?auth_token=${auth_token}`;
                window.location.href = url;
                sessionStorage.removeItem('flag');
            }
            setTimeout(() => {
                this.isLoading = false;
            }, 10000);
        },
        skipTrial() {
            Swal.fire({
                title: this.__('Are you sure?'),
                text: this.__('This will results in an immediate charge!'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: this.__('Cancel'),
                confirmButtonText: this.__('Start subscription now'),
            }).then((result) => {
                if (result.isConfirmed) {
                    const vm = this;
                    // eslint-disable-next-line no-undef
                    Paddle.Spinner.show();

                    let payload = {
                        billableType: this.$page.props.billable_type,
                        billableId: this.$page.props.billable_id,
                    };

                    // eslint-disable-next-line no-undef
                    axios
                        // eslint-disable-next-line no-undef
                        .put(route('billing.subscription.skip-trial'), payload)
                        .then(() => {
                            Inertia.reload({
                                only: [
                                    'trial_character_credit_exhausted',
                                    'trial_character_image_exhausted',
                                ],
                            });
                        })
                        .catch((error) => {
                            let errorMessage =
                                error.response.data?.message ||
                                vm.__('Failed to start subscription!');
                            vm.toast.error(errorMessage);
                        })
                        .then(() => {
                            // eslint-disable-next-line no-undef
                            Paddle.Spinner.hide();
                        });
                }
            });
        },
    },

    computed: {
        remainingPercentages() {
            return this.totalCredits > 0
                ? Math.round((100 / this.totalCredits) * this.totalWordCount)
                : 0;
        },
        filteredTemplates() {
            return this.selectedCategory !== 'all'
                ? this.aiAssistantTemplates.filter(
                      (template) =>
                          template.ai_assistant_template_category
                              .machine_name === this.selectedCategory
                  )
                : this.aiAssistantTemplates;
        },
        favoriteTemplates() {
            return this.aiAssistantTemplates.filter(
                (template) => template.is_favorite
            );
        },
        templateCategories() {
            return this.aiAssistantTemplateCategories.filter(
                (category) => category.highlight
            );
        },
    },

    beforeMount() {
        if (sessionStorage.getItem('flag') === true) {
            this.isLoading = true;
        }

        this.readmelogin();

        emitter.off('reload-image-usage-card');
    },
});
</script>

<style lang="scss">
.nav-line-tabs {
    &.nav-line-tabs-2x {
        .nav-item {
            .nav-link {
                border: none;
                &.active,
                &:hover {
                    border: none;
                    .symbol-label {
                        background-color: #ffffff;
                    }
                    .triangle {
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 10px 10px 10px;
                        border-color: transparent transparent #f3f4f6
                            transparent;

                        @media (max-width: 605px) {
                            display: none;
                        }
                    }
                }
                .symbol-label {
                    background-color: #f3f4f6;
                }
            }
        }
    }
}
.card-ai-assistant {
    &:hover {
        box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.1) !important;
    }
}
.blur {
    filter: blur(3px);
    pointer-events: none;
}
</style>
