import './bootstrap';
import '../sass/app.scss';
import '../css/tailwind.css';
import 'remixicon/fonts/remixicon.css';

// Import modules...
import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import { createInertiaApp } from '@inertiajs/vue3';
import { createGtm } from 'vue-gtm';
import VueCookies from 'vue3-cookies';
import Toast from 'vue-toastification/dist/index.mjs';
import 'vue-toastification/dist/index.css';
import tooltipDirective from './Directives/tooltipDirective';
import postHog from './Plugins/posthog';
import baseMixin from './base';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { all } from '@awesome.me/kit-9286fd9f41/icons'

/* add icons to the library */
library.add(...all)

const pinia = createPinia();
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

let gtmOptions = {
    id: 'GTM-P978NC5',
    defer: false,
    compatibility: false,
    enabled: true,
    debug: true,
    loadScript: true,
    trackOnNextTick: false,
};

let cookieOptions = {
    expireTimes: '30d',
    path: '/',
    domain: '',
    secure: false,
    sameSite: 'None',
};

import {router as Inertia} from '@inertiajs/vue3';

Inertia.on('error', (event) => {
  if (event.detail.response && event.detail.response.status === 419) {
    console.error('CSRF token mismatch. Refreshing the page.');
    window.location.reload();
  }
});

createInertiaApp({
    progress: {
        color: '#009EF7',
    },
    title: (title) => `${title} - ${appName}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        return pages[`./Pages/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })

        vueApp
            .use(pinia)
            .use(plugin)
            .use(createGtm(gtmOptions))
            .use(VueCookies, cookieOptions)
            .use(Toast)
            .use(postHog)
            .component('font-awesome-icon', FontAwesomeIcon)
            .directive('tooltip', tooltipDirective)
            .mixin(baseMixin)
            .mixin({ methods: { route } })
            .mount(el);
    },
});
