<template>
    <app-layout
        :title="__('Billing')"
        :user="$attrs.user"
        kt-toolbar-height="55px"
        kt-toolbar-height-tablet-and-mobile="55px"
    >
        <template #header>
            <h2 class="h4 font-weight-bold">
                {{ __('Billing') }}
            </h2>
        </template>
        <div class="row">
            <div class="d-flex flex-column flex-lg-row">
                <div
                    class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0"
                >
                    <!--begin::Content-->
                    <div
                        v-if="
                            !subscriptionStore.hasAddons &&
                            subscriptionStore.hasRecurringSubscription &&
                            !subscriptionStore.hasLifetimeSubscription &&
                            !subscriptionStore.planBasedLtd
                        "
                        class="card border-transparent mb-5"
                        data-theme="light"
                        style="background-color: #1c325e"
                    >
                        <!--begin::Body-->
                        <div class="card-body d-flex ps-xl-15">
                            <!--begin::Wrapper-->
                            <div class="m-0">
                                <!--begin::Title-->
                                <div
                                    class="position-relative fs-2x z-index-2 fw-bolder text-white mb-7"
                                >
                                    <span class="me-2"
                                        >{{ __('Get the') }}
                                        <span
                                            class="position-relative d-inline-block text-danger"
                                        >
                                            <span
                                                class="text-danger fw-bolder"
                                                >{{
                                                    __('Image Generator')
                                                }}</span
                                            >
                                            <!--begin::Separator-->
                                            <span
                                                class="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"
                                            ></span>
                                            <!--end::Separator-->
                                        </span></span
                                    >
                                    <br />{{
                                        __(
                                            'Create unique and royalty-free images'
                                        )
                                    }}
                                </div>
                                <!--end::Title-->
                                <!--begin::Action-->
                                <div class="mb-3">
                                    <a
                                        href="#"
                                        @click.prevent="addImageGeneratorToPlan"
                                        class="btn btn-danger fw-semibold me-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_upgrade_plan"
                                        >{{ __('Get it now') }}</a
                                    >
                                    <!--                  <a href="/image-generator" class="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold">How to</a>-->
                                </div>
                                <!--begin::Action-->
                            </div>
                            <!--begin::Wrapper-->
                            <img
                                src="/media/images/image_generator_small.png"
                                class="position-absolute me-5 bottom-0 end-0 h-200px"
                                alt=""
                            />
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--begin::Card-->
                    <div
                        class="card card-flush card-bordered pt-3 mb-5 mb-xl-10"
                    >
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title">
                                <h2 class="fw-bolder">
                                    {{ __('Subscription Details') }}
                                </h2>
                            </div>
                            <!--begin::Card title-->
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body pt-3">
                            <div
                                v-if="showNotice"
                                class="notice d-flex bg-light-warning rounded border-warning border border-dashed rounded-3 p-6 mb-10"
                            >
                                <!--begin::Wrapper-->
                                <div class="d-flex flex-stack flex-grow-1">
                                    <!--begin::Content-->
                                    <div
                                        v-if="
                                            !subscriptionStore.isUserOnTrial &&
                                            !subscriptionStore.hasSubscription
                                        "
                                        class="fw-bold"
                                    >
                                        <h4 class="text-gray-900 fw-bolder">
                                            {{
                                                __('Your trial period is over!')
                                            }}
                                        </h4>
                                        <div class="fs-6 text-gray-700">
                                            {{
                                                __(
                                                    'We hope you were able to get an idea of the power of creaitor.'
                                                )
                                            }}<br />{{
                                                __(
                                                    'Please choose a plan to continue using Creaitor.'
                                                )
                                            }}<br />
                                            <a
                                                href="#"
                                                @click.prevent="choosePlan"
                                                class="btn btn-primary mt-5"
                                                >{{
                                                    __('Choose a plan now')
                                                }}</a
                                            >
                                        </div>
                                    </div>
                                    <!--end::Content-->
                                    <div
                                        v-else-if="
                                            subscriptionStore.isUserOnTrial &&
                                            !teamStore.hasCharacterCredits
                                        "
                                        class="fw-bold"
                                    >
                                        <h4 class="text-gray-900 fw-bolder">
                                            {{
                                                __(
                                                    'Your trial credits are over!'
                                                )
                                            }}
                                        </h4>
                                        <div class="fs-6 text-gray-700">
                                            {{
                                                __(
                                                    'We hope you were able to get an idea of the power of creaitor.'
                                                )
                                            }}<br />{{
                                                __(
                                                    'Please choose a plan to continue using Creaitor.'
                                                )
                                            }}<br />
                                            <a
                                                href="#"
                                                @click.prevent="skipTrial"
                                                class="btn btn-success mt-5"
                                                >{{ __('Activate Now') }}</a
                                            >
                                        </div>
                                    </div>
                                    <!--end::Content-->
                                    <div v-else class="fw-bold">
                                        <h4 class="text-gray-900 fw-bolder">
                                            {{
                                                __(
                                                    'Your credit has been used up!'
                                                )
                                            }}
                                        </h4>
                                        <div class="fs-6 text-gray-700">
                                            {{
                                                __(
                                                    'Looks like you already used all your credits.'
                                                )
                                            }}<br />{{
                                                __(
                                                    'The credits will be refilled in the next cycle period but you also could upgrade to a higher plan.'
                                                )
                                            }}<br />
                                        </div>
                                        <div class="d-flex">
                                            <div class="d-flex me-4">
                                                <a
                                                    href="#"
                                                    @click.prevent="changePlan"
                                                    class="btn btn-primary mt-5"
                                                    >{{
                                                        __('Switch Plan Now')
                                                    }}</a
                                                >
                                            </div>
                                            <div class="d-flex">
                                                <a
                                                    href="#"
                                                    @click.prevent="
                                                        buyMoreCredits
                                                    "
                                                    class="btn btn-primary mt-5"
                                                    >{{
                                                        __('Buy More Credits')
                                                    }}</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Content-->
                                </div>
                                <!--end::Wrapper-->
                            </div>

                            <div
                                v-if="$page.props.plan"
                                class="d-flex flex-column align-items-center"
                            >
                                <div
                                    class="card bg-light rounded col-12 col-sm-8 col-xl-5 mb-10"
                                >
                                    <div class="card-body">
                                        <div
                                            class="d-flex flex-column align-items-center fs-2 fw-bolder mb-5"
                                        >
                                            {{ $page.props.plan.name }}
                                            <span
                                                v-if="
                                                    subscriptionStore.planBasedLtd
                                                "
                                                >({{ __('Lifetime') }})
                                            </span>
                                        </div>
                                        <div
                                            class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            v-for="feature in $page.props.plan.features"
                                        >
                                            <div
                                                class="d-flex ml-2 text-sm text-gray-600"
                                                v-html="feature"
                                            ></div>
                                            <div class="d-flex">
                                                <svg
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    class="text-success w-5 h-5"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex flex-column align-items-center"
                                        >
                                            <a
                                                v-if="
                                                    subscriptionStore.isUserOnTrial
                                                "
                                                href="#"
                                                @click.prevent="skipTrial"
                                                class="btn btn-success mt-5"
                                                >{{ __('Activate Now') }}</a
                                            >
                                            <a
                                                v-else-if="
                                                    $page.props.state ==
                                                    'onGracePeriod'
                                                "
                                                href="#"
                                                @click.prevent="
                                                    resumeSubscription
                                                "
                                                class="btn btn-primary mt-5"
                                                >{{
                                                    __('Resume Subscription')
                                                }}</a
                                            >
                                            <a
                                                v-else
                                                href="#"
                                                @click.prevent="changePlan"
                                                class="btn btn-primary mt-5"
                                                >{{
                                                    __(
                                                        'Change Subscription Plan'
                                                    )
                                                }}</a
                                            >
                                        </div>
                                        <div
                                            v-if="
                                                !subscriptionStore.hasProfessionalPlan &&
                                                !subscriptionStore.planBasedLtdProfessional
                                            "
                                            class="d-flex flex-column align-items-center"
                                        >
                                            <a
                                                href="#"
                                                @click.prevent="buyMoreCredits"
                                                class="btn btn-primary mt-5"
                                                >{{ __('Buy More Credits') }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="subscriptionStore.hasAddons"
                                class="mb-10"
                            >
                                <h5 class="mb-4">{{ __('Addons') }}:</h5>
                                <!--end::Title-->
                                <!--begin::Details-->
                                <div class="bootstrap-table-responsive">
                                    <table
                                        class="bootstrap-table table-striped bootstrap-table-striped gy-7 gs-7 w-100"
                                    >
                                        <thead>
                                            <tr
                                                class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200"
                                            >
                                                <th class="w-75">
                                                    {{ __('Addon') }}
                                                </th>
                                                <th class="w-15">
                                                    {{ __('Price') }}
                                                </th>
                                                <th class="w-10 text-end">
                                                    {{ __('Status') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="addon in $attrs.user
                                                    .current_team
                                                    .subscriptions[0].addons"
                                            >
                                                <td class="w-75">
                                                    {{ __(addon.description) }}
                                                </td>
                                                <td class="w-15">
                                                    {{
                                                        parseFloat(
                                                            addon.amount
                                                        ).toFixed(2)
                                                    }}
                                                    {{ addon.currency }}
                                                </td>
                                                <td class="w-10 text-end">
                                                    <span
                                                        class="badge badge-light-success"
                                                        >{{
                                                            __('Active')
                                                        }}</span
                                                    >
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                v-if="
                                    subscriptionStore.hasLifetimeSubscription &&
                                    !subscriptionStore.planBasedLtd
                                "
                                class="row"
                            >
                                <div class="col-6 col-sm-6 col-xl-6">
                                    <div class="card bg-light rounded mb-10">
                                        <div class="card-body">
                                            <div
                                                class="d-flex flex-column align-items-center fs-2 fw-bolder mb-5"
                                            >
                                                {{ __('Lifetime') }}
                                            </div>
                                            <div
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{
                                                        __(
                                                            'Unlimited characters per month'
                                                        )
                                                    }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"

                                                    v-if="$attrs.user.current_team
                                                        .lifetime_subscriptions
                                                        .length > 3"
                                                >
                                                    {{
                                                        __(
                                                            'Up to 20 Active Users'
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                    v-else
                                                >
                                                    {{
                                                        __(
                                                            'Up to 5 Active Users'
                                                        )
                                                    }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{
                                                        __('Unlimited Projects')
                                                    }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{
                                                        __(
                                                            'Unlimited Output Storage'
                                                        )
                                                    }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{
                                                        __(
                                                            'Unlimited Translations'
                                                        )
                                                    }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{ __('Open AI Form') }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{ __('Translation Form') }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{ __('SEO Features') }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    $attrs.user.current_team
                                                        .lifetime_subscriptions
                                                        .length > 1
                                                "
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{
                                                        __(
                                                            'SERP Beater with competitor analysis'
                                                        )
                                                    }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    $attrs.user.current_team
                                                        .lifetime_subscriptions
                                                        .length > 2
                                                "
                                                class="d-flex flex-stack fs-6 mb-5 align-items-center"
                                            >
                                                <div
                                                    class="d-flex ml-2 text-sm text-gray-600"
                                                >
                                                    {{
                                                        __(
                                                            '1000 generated AI images per month'
                                                        )
                                                    }}
                                                </div>
                                                <div class="d-flex">
                                                    <svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        class="text-success w-5 h-5"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-column align-items-center"
                                            >
                                                <a
                                                    v-if="
                                                        $page.props.state ==
                                                        'onGracePeriod'
                                                    "
                                                    href="#"
                                                    @click.prevent="
                                                        resumeSubscription
                                                    "
                                                    class="btn btn-primary mt-5"
                                                    >{{
                                                        __(
                                                            'Resume Subscription'
                                                        )
                                                    }}</a
                                                >
                                                <a
                                                    v-else
                                                    href="#"
                                                    @click.prevent="choosePlan"
                                                    class="btn btn-primary mt-5"
                                                    >{{
                                                        __(
                                                            'Change Subscription Plan'
                                                        )
                                                    }}</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-6 d-flex justify-content-stretch"
                                >
                                    <div
                                        class="card card-flush card-bordered mb-5 mb-xl-10"
                                        style="
                                            background: linear-gradient(
                                                134deg,
                                                rgb(42 157 226) 0%,
                                                rgb(9 74 156) 100%
                                            );
                                        "
                                    >
                                        <div class="card-header pt-5">
                                            <!--begin::Title-->
                                            <div
                                                class="card-title d-flex flex-column"
                                            >
                                                <!--begin::Amount-->
                                                <span
                                                    class="d-flex flex-column align-items-center fs-2 fw-bolder text-white mb-5"
                                                    >{{
                                                        __(
                                                            'AppSumo Code Stacking'
                                                        )
                                                    }}</span
                                                >
                                                <!--end::Amount-->
                                            </div>
                                            <!--end::Title-->
                                        </div>
                                        <div
                                            class="card-body d-flex flex-column justify-content-between pt-0"
                                        >
                                            <!--begin::Progress-->
                                            <div class="text-white">
                                                {{
                                                    __(
                                                        "If you purchased more than one AppSumo code, then simply enter your other code in the form below and we'll stack it on top of your existing account"
                                                    )
                                                }}
                                            </div>
                                            <div class="text-white">
                                                {{ __('Active codes:') }}
                                                {{
                                                    $attrs.user.current_team
                                                        .lifetime_subscriptions
                                                        .length
                                                }}
                                            </div>
                                            <form
                                                class="form"
                                                novalidate="novalidate"
                                                @submit.prevent="submitRedeem"
                                            >
                                                <div
                                                    class="alert bg-light-success text-sm p-2"
                                                    role="alert"
                                                    v-if="form.succeeded"
                                                >
                                                    <div
                                                        class="font-weight-bold"
                                                        v-html="
                                                            __(
                                                                'Congratulations!.'
                                                            )
                                                        "
                                                    ></div>
                                                    <div
                                                        class=""
                                                        v-html="
                                                            __(
                                                                'You applied another code.'
                                                            )
                                                        "
                                                    ></div>
                                                </div>
                                                <div
                                                    class="alert bg-light-warning text-sm p-2"
                                                    role="alert"
                                                    v-if="
                                                        Object.keys(form.errors)
                                                            .length > 0
                                                    "
                                                >
                                                    <div
                                                        class="font-weight-bold"
                                                        v-html="
                                                            __(
                                                                'Whoops! Something went wrong.'
                                                            )
                                                        "
                                                    ></div>
                                                    <ul>
                                                        <li
                                                            v-for="(
                                                                error, key
                                                            ) in form.errors"
                                                            :key="key"
                                                        >
                                                            {{ error[0] }}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <label
                                                    class="form-label fw-bolder text-white fs-6"
                                                    >{{
                                                        __('AppSumo Code')
                                                    }}</label
                                                >
                                                <input
                                                    v-model="form.couponCode"
                                                    class="form-control form-control-solid bg-opacity-50 border-opacity-50 text-white"
                                                    type="text"
                                                    placeholder=""
                                                    name="coupon-code"
                                                    autocomplete="off"
                                                />
                                                <button
                                                    type="submit"
                                                    id="redeem_code_submit"
                                                    class="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold mt-10"
                                                >
                                                    <span
                                                        class="indicator-label"
                                                        >{{
                                                            __('Redeem')
                                                        }}</span
                                                    >
                                                    <span
                                                        v-show="form.processing"
                                                        class="indicator-progress"
                                                        >{{
                                                            __('Please wait...')
                                                        }}
                                                        <ALoading />
                                                    </span>
                                                </button>
                                            </form>
                                            <!--end::Progress-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--begin::Section-->
                            <div class="mb-10">
                                <!--begin::Title-->
                                <h5 class="mb-4">
                                    {{ __('Billing Information') }}:
                                </h5>
                                <!--end::Title-->
                                <!--begin::Details-->
                                <div class="d-flex flex-wrap py-5">
                                    <!--begin::Row-->
                                    <div class="flex-equal me-5">
                                        <!--begin::Details-->
                                        <table
                                            class="bootstrap-table fs-6 fw-bold gs-0 gy-2 gx-2 m-0"
                                        >
                                            <!--begin::Row-->
                                            <tbody>
                                                <tr>
                                                    <td
                                                        class="text-gray-400 min-w-175px w-175px"
                                                    >
                                                        {{ __('Bill to') }}:
                                                    </td>
                                                    <td
                                                        class="text-gray-800 min-w-200px"
                                                    >
                                                        <div
                                                            class="text-gray-800 text-hover-primary"
                                                        >
                                                            {{
                                                                this.$attrs.user
                                                                    .email
                                                            }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400">
                                                        {{
                                                            __('Customer Name')
                                                        }}:
                                                    </td>
                                                    <td class="text-gray-800">
                                                        {{
                                                            this.$attrs.user
                                                                .name
                                                        }}
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400">
                                                        {{ __('Team') }}:
                                                    </td>
                                                    <td class="text-gray-800">
                                                        {{
                                                            this.$page.props
                                                                .billableName
                                                        }}
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <!--                  <tr>
                                            <td class="text-gray-400">Address:</td>
                                            <td class="text-gray-800">Floor 10, 101 Avenue of the Light Square, New York, NY, 10050.</td>
                                          </tr>
                                          &lt;!&ndash;end::Row&ndash;&gt;
                                          &lt;!&ndash;begin::Row&ndash;&gt;
                                          <tr>
                                            <td class="text-gray-400">Phone:</td>
                                            <td class="text-gray-800">(555) 555-1234</td>
                                          </tr>-->
                                                <!--end::Row-->
                                            </tbody>
                                        </table>
                                        <!--end::Details-->
                                    </div>
                                    <!--end::Row-->
                                    <!--begin::Row-->
                                    <div
                                        v-if="$page.props.plan"
                                        class="flex-equal"
                                    >
                                        <!--begin::Details-->
                                        <table
                                            class="bootstrap-table fs-6 fw-bold gs-0 gy-2 gx-2 m-0"
                                        >
                                            <!--begin::Row-->
                                            <tbody>
                                                <tr>
                                                    <td
                                                        class="text-gray-400 min-w-175px w-175px"
                                                    >
                                                        {{
                                                            __(
                                                                'Subscribed Product'
                                                            )
                                                        }}:
                                                    </td>
                                                    <td
                                                        class="text-gray-800 min-w-200px"
                                                    >
                                                        <a
                                                            href="#"
                                                            class="text-gray-800 text-hover-primary"
                                                            >{{
                                                                $page.props.plan
                                                                    .name
                                                            }}</a
                                                        >
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400">
                                                        {{
                                                            __(
                                                                'Subscription Fees'
                                                            )
                                                        }}:
                                                    </td>
                                                    <td class="text-gray-800">
                                                        {{
                                                            $page.props.plan
                                                                .price
                                                        }}
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400">
                                                        {{
                                                            __(
                                                                'Billing method'
                                                            )
                                                        }}:
                                                    </td>
                                                    <td class="text-gray-800">
                                                        {{
                                                            $page.props.plan
                                                                .interval
                                                        }}
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400">
                                                        {{ __('Currency') }}:
                                                    </td>
                                                    <td class="text-gray-800">
                                                        {{
                                                            $page.props.plan
                                                                .currency
                                                        }}
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                            </tbody>
                                        </table>
                                        <!--end::Details-->
                                    </div>
                                    <!--end::Row-->
                                </div>
                                <!--end::Row-->
                            </div>
                            <!--end::Section-->
                            <!--begin::Section-->
                            <div
                                v-if="
                                    $page.props.plan &&
                                    !subscriptionStore.planBasedLtd &&
                                    $page.props.state != 'none'
                                "
                                class="mb-10"
                            >
                                <!--begin::Title-->
                                <h5 class="mb-4">
                                    {{ __('Payment Method') }}:
                                </h5>
                                <div
                                    class="max-w-2xl text-sm text-gray-600"
                                    v-if="$page.props.paymentMethod == 'card'"
                                >
                                    {{
                                        __(
                                            'Your current payment method is a credit card ending in'
                                        )
                                    }}
                                    <span class="fw-bolder">
                                        {{ $page.props.cardLastFour }}</span
                                    >
                                    {{ __('that expires on') }}
                                    <span class="fw-bolder">
                                        {{
                                            $page.props.cardExpirationDate
                                        }}</span
                                    >.
                                </div>

                                <div
                                    class="max-w-2xl text-sm text-gray-600"
                                    v-if="$page.props.paymentMethod == 'paypal'"
                                >
                                    {{ __('Your current payment method is') }}
                                    <span class="fw-bolder">
                                        {{ $page.props.paypal }}</span
                                    >.
                                </div>
                                <a
                                    href="#"
                                    @click.prevent="updatePaymentMethod"
                                    class="btn btn-primary mt-5"
                                    >{{ __('Update Payment Method') }}</a
                                >
                            </div>
                            <!--end::Section-->
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                    <div class="col-sm-12 mb-5 mb-xl-10">
                        <div
                            id="character-credits"
                            class="card card-flush card-bordered h-100"
                        >
                            <!--begin::Header-->
                            <div class="card-header border-0">
                                <h3
                                    class="card-title align-items-start flex-column"
                                >
                                    <span
                                        class="card-label fw-bolder text-dark"
                                        >{{
                                            __('Available Character Credits')
                                        }}</span
                                    >
                                </h3>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body pt-2">
                                <div class="row">
                                    <div
                                        class="d-flex flex-column justify-content-between col-md-4 bg-lighten rounded p-10"
                                    >
                                        <!--begin::Header-->
                                        <div
                                            class="d-flex justify-content-center align-items-stretch flex-wrap mb-5"
                                        >
                                            <h3
                                                class="d-flex align-items-middle text-center justify-content-center flex-column"
                                            >
                                                <span
                                                    class="card-label fw-bolder text-dark"
                                                    >{{
                                                        __('Total Credits')
                                                    }}</span
                                                >
                                                <span
                                                    class="text-gray-400 mt-1 fw-bold fs-6"
                                                    >{{
                                                        __(
                                                            'Credits from Plan and Bonus'
                                                        )
                                                    }}</span
                                                >
                                            </h3>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->
                                        <div
                                            class="d-flex justify-content-center pt-2"
                                        >
                                            <!--begin::Info-->
                                            <div
                                                class="d-flex flex-column align-items-middle text-center"
                                            >
                                                <!--begin::Amount-->
                                                <span
                                                    v-if="
                                                        subscriptionStore.hasUnlimitedUsage
                                                    "
                                                    class="fs-2x fw-bolder text-dark me-2 lh-1 ls-n2"
                                                    >{{ __('Unlimited') }}</span
                                                >
                                                <span
                                                    v-else
                                                    class="fs-2x fw-bolder text-dark me-2 lh-1 ls-n2"
                                                    >{{
                                                        totalCharacterCredits
                                                    }}</span
                                                >
                                                <!--end::Amount-->
                                                <!--begin::Subtitle-->
                                                <span
                                                    class="text-gray-400 pt-1 fw-bold fs-6"
                                                    >{{
                                                        __(
                                                            'Characters remaining'
                                                        )
                                                    }}</span
                                                >
                                                <!--end::Subtitle-->
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <div
                                        class="d-flex flex-column justify-content-between col-md-4 rounded p-10"
                                    >
                                        <!--begin::Header-->
                                        <div
                                            class="d-flex justify-content-center align-items-stretch flex-wrap mb-5"
                                        >
                                            <h3
                                                class="d-flex align-items-middle text-center justify-content-center flex-column"
                                            >
                                                <span
                                                    class="card-label fw-bolder text-dark"
                                                    >{{
                                                        __('Plan Credits')
                                                    }}</span
                                                >
                                                <span
                                                    class="text-gray-400 mt-1 fw-bold fs-6"
                                                    >{{
                                                        __(
                                                            'Credits from Plan which reset on billing cycle'
                                                        )
                                                    }}</span
                                                >
                                            </h3>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->
                                        <div
                                            class="d-flex justify-content-center pt-2"
                                        >
                                            <!--begin::Info-->
                                            <div
                                                class="d-flex flex-column align-items-middle text-center"
                                            >
                                                <!--begin::Amount-->
                                                <span
                                                    v-if="
                                                        subscriptionStore.hasUnlimitedUsage
                                                    "
                                                    class="fs-2x fw-bolder text-dark me-2 lh-1 ls-n2"
                                                    >{{ __('Unlimited') }}</span
                                                >
                                                <span
                                                    v-else
                                                    class="fs-2x fw-bolder text-dark me-2 lh-1 ls-n2"
                                                    >{{
                                                        planCharacterCredits
                                                    }}</span
                                                >
                                                <!--end::Amount-->
                                                <!--begin::Subtitle-->
                                                <span
                                                    class="text-gray-400 pt-1 fw-bold fs-6"
                                                    >{{
                                                        __(
                                                            'Characters remaining'
                                                        )
                                                    }}</span
                                                >
                                                <!--end::Subtitle-->
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <div
                                        class="d-flex flex-column justify-content-between col-md-4 rounded p-10"
                                    >
                                        <!--begin::Header-->
                                        <div
                                            class="d-flex justify-content-center align-items-stretch flex-wrap mb-5"
                                        >
                                            <h3
                                                class="d-flex align-items-middle text-center justify-content-center flex-column"
                                            >
                                                <span
                                                    class="card-label fw-bolder text-dark"
                                                    >{{
                                                        __('Bonus Credits')
                                                    }}</span
                                                >
                                                <span
                                                    class="text-gray-400 mt-1 fw-bold fs-6"
                                                    >{{
                                                        __('Credits from Bonus')
                                                    }}</span
                                                >
                                            </h3>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->
                                        <div
                                            class="d-flex justify-content-center pt-2"
                                        >
                                            <!--begin::Info-->
                                            <div
                                                class="d-flex flex-column align-items-middle text-center"
                                            >
                                                <!--begin::Amount-->
                                                <span
                                                    class="fs-2x fw-bolder text-dark me-2 lh-1 ls-n2"
                                                    >{{
                                                        bonusCharacterCredits
                                                    }}</span
                                                >
                                                <!--end::Amount-->
                                                <!--begin::Subtitle-->
                                                <span
                                                    class="text-gray-400 pt-1 fw-bold fs-6"
                                                    >{{
                                                        __(
                                                            'Characters remaining'
                                                        )
                                                    }}</span
                                                >
                                                <!--end::Subtitle-->
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                </div>
                            </div>
                            <!--end::Body-->
                        </div>
                    </div>

                    <div class="card card-bordered card-xxl-stretch mb-xl-8">
                        <!--begin::Beader-->
                        <div class="card-header border-0 py-5">
                            <h3
                                class="card-title align-items-start flex-column"
                            >
                                <span class="card-label fw-bolder fs-3 mb-1">{{
                                    __('Characters')
                                }}</span>
                                <span class="text-muted fw-bold fs-7">{{
                                    __('Used characters in your team')
                                }}</span>
                            </h3>
                            <!--begin::Toolbar-->
                            <div class="card-toolbar">
                                <!--begin::Tab nav-->
                                <ul
                                    class="nav nav-stretch fs-5 fw-bold nav-line-tabs nav-line-tabs-2x border-transparent"
                                    role="tablist"
                                >
                                    <ul
                                        class="nav nav-stretch fs-5 fw-bold nav-line-tabs nav-line-tabs-2x border-transparent"
                                        role="tablist"
                                    >
                                        <li
                                            class="nav-item"
                                            role="presentation"
                                        >
                                            <a
                                                class="nav-link text-active-primary"
                                                :class="{
                                                    active:
                                                        selectedUsedCharactersRange ===
                                                        'last7Days',
                                                }"
                                                @click="
                                                    switchUsedCharactersChartRange(
                                                        'last7Days'
                                                    )
                                                "
                                                >{{ __('Last 7 Days') }}</a
                                            >
                                        </li>
                                        <li
                                            class="nav-item"
                                            role="presentation"
                                        >
                                            <a
                                                class="nav-link text-active-primary"
                                                :class="{
                                                    active:
                                                        selectedUsedCharactersRange ===
                                                        'last30Days',
                                                }"
                                                @click="
                                                    switchUsedCharactersChartRange(
                                                        'last30Days'
                                                    )
                                                "
                                                >{{ __('Last 30 Days') }}</a
                                            >
                                        </li>
                                        <li
                                            class="nav-item"
                                            role="presentation"
                                        >
                                            <a
                                                class="nav-link text-active-primary"
                                                :class="{
                                                    active:
                                                        selectedUsedCharactersRange ===
                                                        'last90Days',
                                                }"
                                                @click="
                                                    switchUsedCharactersChartRange(
                                                        'last90Days'
                                                    )
                                                "
                                                >{{ __('Last 90 Days') }}</a
                                            >
                                        </li>
                                    </ul>
                                </ul>
                                <!--end::Tab nav-->
                            </div>
                            <!--end::Toolbar-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body d-flex flex-column">
                            <!--begin::Chart-->
                            <div id="chart"></div>
                            <!--end::Chart-->
                        </div>
                        <!--end::Body-->
                    </div>

                    <div class="col-sm-12 mb-5 mb-xl-10">
                        <div
                            id="image-credits"
                            class="card card-flush card-bordered h-100"
                        >
                            <!--begin::Header-->
                            <div class="card-header border-0">
                                <h3
                                    class="card-title align-items-start flex-column"
                                >
                                    <span
                                        class="card-label fw-bolder text-dark"
                                        >{{
                                            __('Available Image Credits')
                                        }}</span
                                    >
                                </h3>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body pt-2">
                                <div class="row">
                                    <div
                                        class="d-flex flex-column justify-content-between col-md-4 bg-lighten rounded p-10"
                                    >
                                        <!--begin::Header-->
                                        <div
                                            class="d-flex justify-content-center align-items-stretch flex-wrap mb-5"
                                        >
                                            <h3
                                                class="d-flex align-items-middle text-center justify-content-center flex-column"
                                            >
                                                <span
                                                    class="card-label fw-bolder text-dark"
                                                    >{{
                                                        __('Total Credits')
                                                    }}</span
                                                >
                                                <span
                                                    class="text-gray-400 mt-1 fw-bold fs-6"
                                                    >{{
                                                        __(
                                                            'Credits from Plan and Bonus'
                                                        )
                                                    }}</span
                                                >
                                            </h3>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->
                                        <div
                                            class="d-flex justify-content-center pt-2"
                                        >
                                            <!--begin::Info-->
                                            <div
                                                class="d-flex flex-column align-items-middle text-center"
                                            >
                                                <!--begin::Amount-->
                                                <span
                                                    class="fs-2x fw-bolder text-dark me-2 lh-1 ls-n2"
                                                    >{{
                                                        totalImageCredits
                                                    }}</span
                                                >
                                                <!--end::Amount-->
                                                <!--begin::Subtitle-->
                                                <span
                                                    class="text-gray-400 pt-1 fw-bold fs-6"
                                                    >{{
                                                        __('Images remaining')
                                                    }}</span
                                                >
                                                <!--end::Subtitle-->
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <div
                                        class="d-flex flex-column justify-content-between col-md-4 rounded p-10"
                                    >
                                        <!--begin::Header-->
                                        <div
                                            class="d-flex justify-content-center align-items-stretch flex-wrap mb-5"
                                        >
                                            <h3
                                                class="d-flex align-items-middle text-center justify-content-center flex-column"
                                            >
                                                <span
                                                    class="card-label fw-bolder text-dark"
                                                    >{{
                                                        __('Plan Credits')
                                                    }}</span
                                                >
                                                <span
                                                    class="text-gray-400 mt-1 fw-bold fs-6"
                                                    >{{
                                                        __(
                                                            'Credits from Plan which reset on billing cycle'
                                                        )
                                                    }}</span
                                                >
                                            </h3>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->
                                        <div
                                            class="d-flex justify-content-center pt-2"
                                        >
                                            <!--begin::Info-->
                                            <div
                                                class="d-flex flex-column align-items-middle text-center"
                                            >
                                                <!--begin::Amount-->
                                                <span
                                                    class="fs-2x fw-bolder text-dark me-2 lh-1 ls-n2"
                                                    >{{
                                                        planImageCredits
                                                    }}</span
                                                >
                                                <!--end::Amount-->
                                                <!--begin::Subtitle-->
                                                <span
                                                    class="text-gray-400 pt-1 fw-bold fs-6"
                                                    >{{
                                                        __('Images remaining')
                                                    }}</span
                                                >
                                                <!--end::Subtitle-->
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <div
                                        class="d-flex flex-column justify-content-between col-md-4 rounded p-10"
                                    >
                                        <!--begin::Header-->
                                        <div
                                            class="d-flex justify-content-center align-items-stretch flex-wrap mb-5"
                                        >
                                            <h3
                                                class="d-flex align-items-middle text-center justify-content-center flex-column"
                                            >
                                                <span
                                                    class="card-label fw-bolder text-dark"
                                                    >{{
                                                        __('AddOn Credits')
                                                    }}</span
                                                >
                                                <span
                                                    class="text-gray-400 mt-1 fw-bold fs-6"
                                                    >{{
                                                        __('Credits from AddOn')
                                                    }}</span
                                                >
                                            </h3>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->
                                        <div
                                            class="d-flex justify-content-center pt-2"
                                        >
                                            <!--begin::Info-->
                                            <div
                                                class="d-flex flex-column align-items-middle text-center"
                                            >
                                                <!--begin::Amount-->
                                                <span
                                                    class="fs-2x fw-bolder text-dark me-2 lh-1 ls-n2"
                                                    >{{
                                                        addOnImageCredits
                                                    }}</span
                                                >
                                                <!--end::Amount-->
                                                <!--begin::Subtitle-->
                                                <span
                                                    class="text-gray-400 pt-1 fw-bold fs-6"
                                                    >{{
                                                        __('Images remaining')
                                                    }}</span
                                                >
                                                <!--end::Subtitle-->
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                </div>
                            </div>
                            <!--end::Body-->
                        </div>
                    </div>

                    <!--begin::Card-->
                    <div
                        class="card card-flush card-bordered pt-3 mb-5 mb-xl-10"
                    >
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title">
                                <h2>{{ __('Invoices') }}</h2>
                            </div>
                            <!--end::Card title-->
                            <!--begin::Toolbar-->
                            <div class="card-toolbar">
                                <!--begin::Tab nav-->
                                <ul
                                    class="nav nav-stretch fs-5 fw-bold nav-line-tabs nav-line-tabs-2x border-transparent"
                                    role="tablist"
                                >
                                    <li class="nav-item" role="presentation">
                                        <a
                                            id="kt_referrals_year_tab"
                                            class="nav-link text-active-primary active"
                                            data-bs-toggle="tab"
                                            role="tab"
                                            href="#kt_customer_details_invoices_1"
                                            >{{ __('This Year') }}</a
                                        >
                                    </li>
                                </ul>
                                <!--end::Tab nav-->
                            </div>
                            <!--end::Toolbar-->
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body pt-2">
                            <!--begin::Tab Content-->
                            <div
                                id="kt_referred_users_tab_content"
                                class="tab-content"
                            >
                                <!--begin::Tab panel-->
                                <div
                                    id="kt_customer_details_invoices_1"
                                    class="tab-pane fade show active"
                                    role="tabpanel"
                                >
                                    <!--begin::Table wrapper-->
                                    <div class="bootstrap-table-responsive">
                                        <!--begin::Table-->
                                        <table
                                            id="kt_customer_details_invoices_table_1"
                                            class="bootstrap-table align-middle table-row-dashed fs-6 fw-bolder gs-0 gy-4 p-0 m-0"
                                        >
                                            <!--begin::Thead-->
                                            <thead
                                                class="border-bottom border-gray-200 fs-7 text-uppercase fw-bolder"
                                            >
                                                <tr
                                                    class="text-start text-gray-400"
                                                >
                                                    <th class="min-w-100px">
                                                        {{ __('Order ID') }}
                                                    </th>
                                                    <th class="min-w-100px">
                                                        {{ __('Amount') }}
                                                    </th>
                                                    <th class="min-w-125px">
                                                        {{ __('Date') }}
                                                    </th>
                                                    <th class="w-100px">
                                                        {{ __('Invoice') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <!--end::Thead-->
                                            <!--begin::Tbody-->
                                            <tbody
                                                class="fs-6 fw-bold text-gray-600"
                                            >
                                                <tr v-if="$page.props.receipts"
                                                    v-for="receipt in $page.props.receipts.data"
                                                >
                                                    <td>
                                                        <a
                                                            href="#"
                                                            class="text-gray-600 text-hover-primary"
                                                            >{{
                                                                receipt?.order_id
                                                            }}</a
                                                        >
                                                    </td>
                                                    <td class="text-success">
                                                        {{ receipt?.amount }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            new Date(
                                                                receipt?.paid_at
                                                            ).toLocaleString()
                                                        }}
                                                    </td>
                                                    <td class="">
                                                        <a
                                                            :href="
                                                                receipt?.receipt_url
                                                            "
                                                            target="_blank"
                                                            class="btn btn-sm btn-light btn-active-primary btn-outline btn-outline-highlighted"
                                                            >{{
                                                                __('Download')
                                                            }}</a
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <!--end::Tbody-->
                                        </table>
                                        <!--end::Table-->
                                    </div>
                                    <!--end::Table wrapper-->
                                </div>
                                <!--end::Tab panel-->
                            </div>
                            <!--end::Tab Content-->
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                    <!--begin::Card-->
                    <ReferralOverview :user="$attrs.user"></ReferralOverview>
                    <!--end::Card-->

                    <!--begin::Card-->
                    <TeamOverview
                        v-if="$page.props.plan"
                        :user="$attrs.user"
                        :plan="$page.props.plan"
                    ></TeamOverview>
                    <!--end::Card-->

                    <!--begin::Card-->
                    <div
                        v-if="
                            $page.props.plan &&
                            !subscriptionStore.planBasedLtd &&
                            $page.props.state != 'none'
                        "
                        class="card card-flush card-bordered pt-3 mb-5 mb-xl-10"
                    >
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title">
                                <h2>{{ __('Cancel Subscription') }}</h2>
                            </div>
                            <!--end::Card title-->
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div
                            v-if="
                                $page.props.state == 'active' ||
                                $page.props.state == 'past_due' ||
                                !!$page.props.plan
                            "
                            class="card-body pt-2"
                        >
                            <!--begin::Table-->
                            <p>
                                {{
                                    __(
                                        'You may cancel your subscription at any time. Once your subscription has been cancelled, you will have the option to resume the subscription until the end of your current billing cycle.'
                                    )
                                }}
                            </p>
                            <!--end::Table-->
                            <a
                                href="#"
                                @click.prevent="cancelSubscription"
                                class="btn btn-danger mt-5"
                                >{{ __('Cancel Subscription') }}</a
                            >
                        </div>
                        <div
                            v-if="$page.props.state == 'onGracePeriod'"
                            class="card-body pt-2"
                        >
                            <!--begin::Table-->
                            <p>
                                {{
                                    __(
                                        'Having second thoughts about cancelling your subscription? You can instantly reactive your subscription at any time until the end of your current billing cycle. After your current billing cycle ends, you may choose an entirely new subscription plan.'
                                    )
                                }}
                            </p>
                            <!--end::Table-->
                            <a
                                href="#"
                                @click.prevent="resumeSubscription"
                                class="btn btn-primary mt-5"
                                >{{ __('Resume Subscription') }}</a
                            >
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Content-->
                <!--begin::Sidebar-->
                <div
                    class="flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2"
                >
                    <!--begin::Card-->
                    <div
                        class="card card-flush card-bordered position-sticky mb-0"
                        style="top: 75px"
                    >
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title">
                                <h2>{{ __('Summary') }}</h2>
                            </div>
                            <!--end::Card title-->
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body pt-0 fs-6">
                            <!--begin::Section-->
                            <div class="mb-7">
                                <!--begin::Details-->
                                <div class="d-flex align-items-center">
                                    <!--begin::Avatar-->
                                    <div
                                        class="symbol symbol-60px symbol-circle me-3"
                                    >
                                        <img
                                            alt="Pic"
                                            :src="
                                                this.$attrs.user
                                                    .profile_photo_url
                                            "
                                        />
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-column">
                                        <!--begin::Name-->
                                        <div
                                            class="fs-4 fw-bolder text-gray-900 text-hover-primary me-2"
                                        >
                                            {{ this.$attrs.user.name }}
                                        </div>
                                        <!--end::Name-->
                                        <!--begin::Email-->
                                        <div
                                            class="fw-bold text-gray-600 text-hover-primary"
                                        >
                                            {{ this.$attrs.user.email }}
                                        </div>
                                        <!--end::Email-->
                                    </div>
                                    <!--end::Info-->
                                </div>
                                <!--end::Details-->
                            </div>
                            <!--end::Section-->
                            <!--begin::Seperator-->
                            <div class="separator separator-dashed mb-7"></div>
                            <!--end::Seperator-->
                            <!--begin::Section-->
                            <div class="mb-7">
                                <!--begin::Title-->
                                <h5 class="mb-4">
                                    {{ __('Subscription Details') }}
                                </h5>
                                <!--end::Title-->
                                <!--begin::Details-->
                                <table
                                    v-if="
                                        $page.props.plan &&
                                        !subscriptionStore.planBasedLtd
                                    "
                                    class="bootstrap-table fs-6 fw-bold gs-0 gy-2 gx-2"
                                >
                                    <!--begin::Row-->
                                    <tbody>
                                        <tr class="">
                                            <td class="text-gray-400">
                                                {{ __('Package') }}:
                                            </td>
                                            <td class="text-gray-800">
                                                {{ $page.props.plan.name }}
                                            </td>
                                        </tr>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <tr class="">
                                            <td class="text-gray-400">
                                                {{ __('Status') }}:
                                            </td>
                                            <td>
                                                <span
                                                    class="badge"
                                                    :class="{
                                                        'badge-light-success':
                                                            $page.props.state ==
                                                            'active',
                                                        'badge-light-warning':
                                                            $page.props.state ==
                                                                'onGracePeriod' ||
                                                            $page.props.state ==
                                                                'pending',
                                                        'badge-light-danger':
                                                            $page.props.state ==
                                                                'hasHighRiskPayment' ||
                                                            $page.props.state ==
                                                                'past_due',
                                                    }"
                                                >
                                                    <span
                                                        v-if="
                                                            subscriptionStore.isUserOnTrial
                                                        "
                                                        >{{ __('Trial') }}
                                                    </span>
                                                    <span
                                                        v-else-if="
                                                            $page.props
                                                                .state ===
                                                            'past_due'
                                                        "
                                                        >{{
                                                            __('Past Due')
                                                        }}</span
                                                    >
                                                    <span v-else>{{
                                                        __($page.props.state)
                                                    }}</span>
                                                </span>
                                            </td>
                                        </tr>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <tr class="">
                                            <td class="text-gray-400">
                                                {{ __('Price') }}:
                                            </td>
                                            <td>
                                                <span class="text-gray-800">{{
                                                    $page.props.plan.price
                                                }}</span>
                                            </td>
                                        </tr>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <tr class="">
                                            <td class="text-gray-400">
                                                {{ __('Interval') }}:
                                            </td>
                                            <td>
                                                <span class="text-gray-800">{{
                                                    $page.props.plan.interval
                                                }}</span>
                                            </td>
                                        </tr>
                                        <!--end::Row-->
                                    </tbody>
                                </table>
                                <div
                                    v-else-if="
                                        subscriptionStore.hasLifetimeSubscription
                                    "
                                    class="d-flex flex-column"
                                >
                                    <div class="d-flex text-gray-800">
                                        {{ __('You are on lifetime plan.') }}
                                    </div>
                                    <div class="d-flex">
                                        <a
                                            href="#"
                                            @click.prevent="choosePlan"
                                            class="btn btn-primary mt-5"
                                            >{{
                                                __('Change Subscription Plan')
                                            }}</a
                                        >
                                    </div>
                                </div>
                                <div v-else class="d-flex flex-column">
                                    <div class="d-flex text-gray-800">
                                        {{
                                            __(
                                                'It looks like you do not have an active subscription. Subscription plans may be changed or cancelled at your convenience.'
                                            )
                                        }}
                                    </div>
                                    <div class="d-flex">
                                        <a
                                            href="#"
                                            @click.prevent="choosePlan"
                                            class="btn btn-primary mt-5"
                                            >{{ __('Choose a Plan') }}</a
                                        >
                                    </div>
                                </div>
                                <!--end::Details-->
                            </div>
                            <!--end::Section-->
                            <!--begin::Seperator-->
                            <div
                                v-if="
                                    $page.props.plan &&
                                    !subscriptionStore.planBasedLtd
                                "
                                class="separator separator-dashed mb-7"
                            ></div>
                            <!--end::Seperator-->
                            <!--begin::Section-->
                            <div
                                v-if="
                                    $page.props.plan &&
                                    !subscriptionStore.planBasedLtd &&
                                    $page.props.state != 'none'
                                "
                                class="mb-10"
                            >
                                <!--begin::Title-->
                                <h5 class="mb-4">
                                    {{ __('Payment Details') }}
                                </h5>
                                <!--end::Title-->
                                <!--begin::Details-->
                                <div class="mb-0">
                                    <!--begin::Card info-->
                                    <div
                                        class="fw-bold text-gray-600 d-flex align-items-center"
                                    >
                                        {{ __('Card') }}:
                                        <img
                                            :src="
                                                '/media/svg/card-logos/' +
                                                $page.props.cardBrand +
                                                '.svg'
                                            "
                                            class="w-35px ms-2"
                                            alt=""
                                        />
                                    </div>
                                    <!--end::Card info-->
                                    <!--begin::Card expiry-->
                                    <div class="fw-bold text-gray-600">
                                        {{ __('Expires') }}:
                                        {{ $page.props.cardExpirationDate }}
                                    </div>
                                    <!--end::Card expiry-->
                                </div>
                                <!--end::Details-->
                                <div class="mb-10">
                                    <div
                                        class="d-flex text-danger mt-5"
                                        v-if="$page.props.state === 'past_due'"
                                    >
                                        {{
                                            __(
                                                'It looks like your billing details are not up to date. Please update your billing method to continue using your subscription.'
                                            )
                                        }}
                                    </div>
                                    <a
                                        href="#"
                                        @click.prevent="updatePaymentMethod"
                                        class="btn btn-primary mt-5"
                                        >{{ __('Update Payment Method') }}</a
                                    >
                                </div>
                            </div>
                            <!--end::Section-->
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Sidebar-->
            </div>
        </div>
    </app-layout>
</template>

<script>
import { ref, defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import ReferralOverview from '@/Layouts/Billing/Referral/Overview.vue';
import TeamOverview from '@/Layouts/Billing/Team/TeamOverview.vue';
import moment from 'moment';
import ApexCharts from 'apexcharts';
import {router as Inertia} from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';
import Swal from 'sweetalert2';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';
import ALoading from "@/Components/Atoms/ALoading.vue";

export default defineComponent({
    setup() {
        let vm = this;
        // Get toast interface
        const toast = useToast();

        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            toast,
            userStore,
            teamStore,
            subscriptionStore,
        };
    },

    components: {
        ALoading,
        AppLayout,
        ReferralOverview,
        TeamOverview,
    },

    props: {
        height: {
            type: Number,
            default: 400,
        },
        labelColor: {
            type: String,
            default: '#7E8299',
        },
        borderColor: {
            type: String,
            default: '#EFF2F5',
        },
        baseColor: {
            type: String,
            default: '#2bc5d3',
        },
        lightColor: {
            type: String,
            default: '#54d5aa75',
        },
    },

    data() {
        return {
            billableId: this.$page.props.billableId,
            billableType: this.$page.props.billableType,
            selectingNewPlan: false,
            totalCharacterCredits: 0,
            planCharacterCredits: 0,
            bonusCharacterCredits: 0,
            totalCharacterCount: 0,
            totalImageCredits: 0,
            planImageCredits: 0,
            addOnImageCredits: 0,
            totalImageCount: 0,
            usedCharactersChart: {},
            options: {
                series: [
                    {
                        name: 'Net Profit',
                        data: [30, 40, 40, 90, 90, 70, 70],
                    },
                ],
                chart: {
                    fontFamily: 'inherit',
                    type: 'area',
                    height: this.height,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {},
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: 'solid',
                    opacity: 1,
                },
                stroke: {
                    curve: 'smooth',
                    show: true,
                    width: 3,
                    colors: [this.baseColor],
                },
                xaxis: {
                    categories: [
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                    ],
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        style: {
                            colors: this.labelColor,
                            fontSize: '12px',
                        },
                    },
                    crosshairs: {
                        position: 'front',
                        stroke: {
                            color: this.baseColor,
                            width: 1,
                            dashArray: 3,
                        },
                    },
                    tooltip: {
                        enabled: true,
                        formatter: undefined,
                        offsetY: 0,
                        style: {
                            fontSize: '12px',
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: this.labelColor,
                            fontSize: '12px',
                        },
                    },
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                },
                tooltip: {
                    style: {
                        fontSize: '12px',
                    },
                    y: {
                        formatter: function (val) {
                            return val + ' characters';
                        },
                    },
                },
                colors: [this.lightColor],
                grid: {
                    borderColor: this.borderColor,
                    strokeDashArray: 4,
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                markers: {
                    strokeColor: this.baseColor,
                    strokeWidth: 3,
                },
            },
            form: {
                couponCode: '',
                errors: {},
                succeeded: false,
                processing: false,
            },
            selectedUsedCharactersRange: 'last7Days',
        };
    },

    mounted() {
        this.fetchCreations('last7Days');
        this.fetchImageUsage('last7Days');

        Paddle.Setup({
            vendor: parseInt(import.meta.env.VITE_PADDLE_VENDOR_ID, 10),
        });

        if (this.$page.props.sandbox) {
            Paddle.Environment.set('sandbox');
        }

        let vm = this;
        emitter.on('update-credits', function () {
            Inertia.reload({ only: ['user'] });
            vm.fetchCreations('last30Days');
            vm.toast.success(vm.__('Credits added successfully'));
        });

        this.userStore.setUser(this.$attrs.user);
        this.subscriptionStore.setPlans(this.getPlans() || []);
        this.subscriptionStore.setCurrentPlan(this.$page.props.plan);
    },

    methods: {
        submitRedeem() {
            let vm = this;
            this.form.processing = true;
            axios
                .post(this.route('ltd-redeem'), {
                    couponCode: this.form.couponCode,
                })
                .then((response) => {
                    vm.form.succeeded = true;
                    vm.form.errors = {};
                    vm.form.processing = false;
                    Inertia.reload({ only: ['user'] });
                })
                .catch((error) => {
                    vm.form.processing = false;
                    vm.form.errors = error.response.data.errors;
                });
        },

        /**
         * Toggle the plan intervals that are being displayed.
         */
        toggleDisplayedPlanIntervals() {
            if (this.showingPlansOfInterval === 'monthly') {
                this.showingPlansOfInterval = 'yearly';
            } else {
                this.showingPlansOfInterval = 'monthly';
            }
        },

        /**
         * Subscribe to the given plan.
         */
        subscribeToPlan(plan) {
            Paddle.Spinner.show();

            window.history.pushState(
                {},
                document.title,
                window.location.pathname + '?subscribe=' + plan.id
            );

            this.request('POST', '/spark/subscription', {
                plan: plan.id,
            }).then((response) => {
                Paddle.Checkout.open({
                    override: response.data.link,
                    disableLogout: true,
                    successCallback: (response) => {
                        this.$page.props.state = 'pending';

                        window.history.pushState(
                            {},
                            document.title,
                            window.location.pathname
                        );

                        this.request('POST', '/spark/pending-checkout', {
                            checkout_id: response.checkout.id,
                        });
                    },
                    closeCallback: () => {
                        window.history.pushState(
                            {},
                            document.title,
                            window.location.pathname
                        );
                    },
                });
            });
        },

        /**
         * Change the plan.
         */
        choosePlan() {
            emitter.emit('open-choose-plan');
        },
        buyMoreCredits() {
            emitter.emit('open-buy-more-character-credits');
        },

        /**
         * Change the plan.
         */
        changePlan() {
            emitter.emit('open-choose-plan', {
                intention: 'switch',
                currentPlan: this.subscriptionStore.getCurrentPlan?.id,
            });
        },
        skipTrial() {
            let vm = this;
            Swal.fire({
                title: this.__('Are you sure?'),
                text: this.__(
                    'Skipping the test period results in an immediate charge!'
                ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: this.__('Cancel'),
                confirmButtonText: this.__('Activate Now'),
            }).then((result) => {
                if (result.isConfirmed) {
                    Paddle.Spinner.show();

                    let payload = {
                        billableType: this.billableType,
                        billableId: this.billableId,
                    };

                    axios
                        .put(route('billing.subscription.skip-trial'), payload)
                        .then((response) => {
                            Inertia.reload({ only: ['user'] });
                        })
                        .catch((error) => {
                            let errorMessage =
                                error.response.data?.message ||
                                vm.__('Failed to skip trial!');
                            vm.toast.error(errorMessage);
                        })
                        .then(() => {
                            Paddle.Spinner.hide();
                        });
                }
            });
        },

        /**
         * Switch to the given plan.
         */
        switchToPlan(plan) {
            Paddle.Spinner.show();

            this.request('PUT', '/spark/subscription', {
                plan: plan.id,
            }).then((response) => {
                this.reloadData();
            });
        },

        /**
         * Add Image Generator to plan.
         */
        addImageGeneratorToPlan() {
            emitter.emit('open-add-image-generator-to-plan');
        },

        /**
         * Update the customer's payment method.
         */
        updatePaymentMethod() {
            Paddle.Spinner.show();
            let vm = this;

            this.request(
                'PUT',
                route('billing.subscription.payment-method')
            ).then((response) => {
                if (vm.isPaddleBillingType) {
                    Paddle.Checkout.open({
                        transactionId: response.data.transaction_id,
                        settings: {
                            allowLogout: false,
                            displayMode: 'overlay',
                        },
                    });
                } else {
                    Paddle.Checkout.open({
                        override: response.data.link,
                        successCallback: (response) => {
                            this.reloadData();
                        },
                    });
                }
            });
        },

        /**
         * Cancel the customer's subscription.
         */
        cancelSubscription() {
            Paddle.Spinner.show();

            if (this.subscriptionStore.isUserOnTrial) {
                this.$posthog.capture('cancel_trial', {
                    user_id: this.$attrs.user.id,
                    cancellation_date: new Date().toISOString(),
                });
                /*
                this.request('PUT', '/api/billing/subscription/cancel').then(
                    (response) => {
                        this.reloadData();
                    }
                );
                */
                Intercom('trackEvent', 'trial-cancellation', {
                    plan: this.subscriptionStore.getCurrentPlan?.name,
                });

                setTimeout(() => {
                    this.reloadData();

                    Paddle.Spinner.hide();
                }, 3000);
            } else {
                Intercom('trackEvent', 'subscription-cancellation', {
                    plan: this.subscriptionStore.getCurrentPlan?.name,
                });

                this.$posthog.capture('cancel_subscription', {
                    user_id: this.$attrs.user.id,
                    cancellation_date: new Date().toISOString(),
                });

                setTimeout(() => {
                    this.reloadData();

                    Paddle.Spinner.hide();
                }, 3000);
            }
        },

        resumeSubscription() {
            Paddle.Spinner.show();

            this.request('PUT', route('billing.subscription.resume'), {}).then(
                (response) => {
                    this.reloadData();
                }
            );
        },

        /**
         * Start periodically reloading the page's data.
         */
        startReloadingData() {
            setTimeout(() => {
                this.reloadData();
            }, 2000);
        },

        /**
         * Reload the page's data, while maintaining any current state.
         */
        reloadData() {
            return this.$inertia.reload({
                onSuccess: () => {
                    if (this.$page.props.state == 'pending') {
                        this.startReloadingData();
                    }

                    if (this.selectingNewPlan) {
                        this.selectingNewPlan = false;
                    }

                    Paddle.Spinner.hide();
                },
            });
        },

        /**
         * Make an outgoing request to the Laravel application.
         */
        request(method, url, data = {}) {
            this.errors = [];

            data.billableType = this.billableType;
            data.billableId = this.billableId;

            return axios
                .request({
                    url: url,
                    method: method,
                    data: data,
                })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    Paddle.Spinner.hide();

                    if (error.response.status == 422) {
                        this.errors = _.flatMap(error.response.data.errors);
                    } else {
                        this.errors = [
                            this.__(
                                'An unexpected error occurred and we have notified our support team. Please try again later.'
                            ),
                        ];
                    }
                });
        },

        fetchCreations(range) {
            // reset the chart
            this.options.series = [];
            this.options.xaxis.categories = [];

            axios
                .get(`/api/statistics/used-characters?range=${range}`)
                .then((response) => {
                    this.options.series = [
                        {
                            name: 'Used characters in Web',
                            data: response.data.webCharacterCount,
                        },
                        {
                            name: 'Used characters in API',
                            data: response.data.apiCharacterCount,
                        },
                    ];

                    this.options.colors = ['#66DA26', '#2E93fA'];

                    this.options.fill.type = 'gradient';

                    this.options.xaxis.categories = response.data.dates;

                    // check if object is empty
                    if (Object.keys(this.usedCharactersChart).length !== 0) {
                        // update chart
                        this.usedCharactersChart.updateOptions(this.options);
                    } else {
                        this.usedCharactersChart = new ApexCharts(
                            document.querySelector('#chart'),
                            this.options
                        );
                        this.usedCharactersChart.render();
                    }

                    this.totalCharacterCredits = response.data.totalCredits;

                    this.planCharacterCredits = response.data.planCredits;

                    this.bonusCharacterCredits = response.data.bonusCredits;

                    this.totalCharacterCount =
                        response.data.totalCharacterCount;
                });
        },

        fetchImageUsage(range) {
            // reset the chart
            this.options.series = [];
            this.options.xaxis.categories = [];

            axios
                .get(`/api/statistics/used-images?range=${range}`)
                .then((response) => {
                    /* this.options.series = [{
            name: 'Used images in Web',
            data: response.data.webCharacterCount,
          }, {
              name: 'Used images in API',
              data: response.data.apiCharacterCount,
          }]

          this.options.colors  = ['#66DA26', '#2E93fA'];

          this.options.fill.type = "gradient";

          this.options.xaxis.categories = response.data.dates;

          // check if object is empty
          if (Object.keys(this.usedCharactersChart).length !== 0){
            // update chart
            this.usedCharactersChart.updateOptions(this.options);

          }else {
            this.usedCharactersChart = new ApexCharts(document.querySelector("#chart"), this.options);
            this.usedCharactersChart.render();
          }
          */

                    this.totalImageCredits = response.data.totalCredits;

                    this.planImageCredits = response.data.planCredits;

                    this.addOnImageCredits = response.data.addOnCredits;

                    this.totalImageCount = response.data.totalImageCount;
                });
        },

        switchUsedCharactersChartRange(range) {
            this.selectedUsedCharactersRange = range;
            this.fetchCreations(this.selectedUsedCharactersRange);
        },
    },

    computed: {
        showCancelButton() {
            return (
                (this.$page.props.plan && !this.planBasedLtd) ||
                this.$page.props.state == 'past_due'
            );
        },
        remainingPercentages() {
            return this.totalCharacterCredits > 0
                ? Math.round(
                      (100 / this.totalCharacterCredits) *
                          this.totalCharacterCount
                  )
                : 0;
        },
        showNotice() {
            return (
                (!this.subscriptionStore.hasSubscription &&
                    !this.subscriptionStore.isUserOnTrial) ||
                (!this.teamStore.hasCharacterCredits &&
                    !this.subscriptionStore.hasUnlimitedUsage)
            );
        },
        isPaddleBillingType() {
            return paddleType == 'billing';
        },
    },
});
</script>

<style lang="scss"></style>
