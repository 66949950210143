<template>
    <!--begin::Col-->
    <div
        v-if="isLoading"
        class="card card-flush my-2"
        :class="{ 'card-bordered': border }"
    >
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-center p-5">
                <div
                    role="status"
                >
                    <IconLoading class="animate-spin" />
                    <span class="visually-hidden">{{ __('Loading...') }}</span>
                </div>
            </div>
        </div>
    </div>

    <div
        v-else
        class="card card-flush my-2"
        :class="{ 'card-bordered': border }"
    >
        <!--begin::Header-->
        <div class="card-body">
            <!--begin::Title-->
            <div class="d-flex flex-column">
                <!--begin::Info-->
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                        <!--begin::Amount-->
                        <span class="fs-1 fw-bold text-dark me-2 lh-1 ls-n2">
                            {{ total.toLocaleString(userLocale) }}
                        </span>
                        <!--end::Amount-->
                    </div>

                    <div v-if="detailLink" class="d-flex">
                        <Link
                            :href="detailLink"
                            class="btn btn-sm btn-icon w-30px h-30px"
                        >
                            <span class="svg-icon svg-icon-muted svg-icon-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        class="fa-primary"
                                        opacity="0.4"
                                        d="M312 144H160c-13.3 0-24 10.7-24 24s10.7 24 24 24h94.1L119 327c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V328c0 13.3 10.7 24 24 24s24-10.7 24-24V168c0-13.3-10.7-24-24-24z"
                                    />
                                    <path
                                        class="fa-secondary"
                                        d="M384 32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H384zM160 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h94.1L119 327c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V328c0 13.3 10.7 24 24 24s24-10.7 24-24V168c0-13.3-10.7-24-24-24H160z"
                                    />
                                </svg>
                            </span>
                        </Link>
                    </div>
                </div>
                <!--end::Info-->

                <!--begin::Subtitle-->
                <span class="text-gray-400 pt-1 fw-semibold text-sm">
                    <slot>
                        {{ __('Total') }}
                    </slot>
                </span>
                <!--end::Subtitle-->
            </div>
            <!--end::Title-->
        </div>
        <!--end::Header-->
    </div>
    <!--end::Col-->
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import IconLoading from '@/icons/loading.svg?component';

export default defineComponent({
    components: {
        Link,
        IconLoading,
    },

    props: {
        border: {
            type: Boolean,
            default: true,
        },
        total: {
            type: Number,
            default: 0,
        },
        detailLink: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            user: {},
            userLocale: 'en-US',
        };
    },

    mounted() {
        if (this.$page.props.user) {
            this.user = this.$page.props.user;
            this.userLocale =
                this.$page.props.user.user_info.language +
                '-' +
                this.$page.props.user.user_info.country;
        }
    },
});
</script>
