<template>
    <Head :title="__('Log in')" />

    <div class="d-flex flex-column flex-root">
        <!--begin::Authentication - Sign-in -->
        <div
            class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style="background-image: url(assets/media/illustrations/sketchy-1/14.png"
        >
            <!--begin::Content-->
            <div
                class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
            >
                <!--begin::Logo-->
                <a href="/" class="mb-12">
                    <jet-authentication-card-logo
                        mark-width="64"
                        logo-text-classes="p-3 text-black"
                    />
                </a>
                <!--end::Logo-->
                <!--begin::Wrapper-->
                <div
                    class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto"
                >
                    <!--begin::Form-->
                    <form
                        class="form w-100"
                        novalidate="novalidate"
                        action="#"
                        @submit.prevent="submit"
                    >
                        <!--begin::Heading-->
                        <div class="text-center mb-10">
                            <!--begin::Title-->
                            <h1 class="text-dark mb-3">
                                {{ __('Sign In to Creaitor.ai') }}
                            </h1>
                            <!--end::Title-->
                            <!--begin::Link-->
                            <div class="text-gray-400 fw-bold fs-6">
                                {{ __('New Here?') }}
                                <a
                                    href="/register"
                                    class="link-primary fw-bolder"
                                    >{{ __('Create an Account') }}</a
                                >
                            </div>
                            <!--end::Link-->
                        </div>
                        <jet-validation-errors class="mb-3" />
                        <!--begin::Heading-->
                        <div v-if="testUsers && testUsers.length > 0" class="mb-10">
                            <label for="test-user-select">{{ __('Select Test User') }}</label>
                            <select
                                class="form-select form-select-lg"
                                v-model="selectedTestUser"
                                @change="fillTestUser"
                            >
                                <option value="">{{ __('Select Test User') }}</option>
                                <option
                                    v-for="user in testUsers"
                                    :key="user.email"
                                    :value="user"
                                >
                                    {{ user.name }} ({{ user.email }})
                                </option>
                            </select>
                        </div>
                        <!--begin::Input group-->
                        <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label
                                class="form-label fs-6 fw-bolder text-dark"
                                >{{ __('Email') }}</label
                            >
                            <!--end::Label-->
                            <!--begin::Input-->
                            <input
                                v-model="form.email"
                                class="form-control form-control-solid"
                                type="text"
                                name="email"
                                autocomplete="off"
                            />
                            <!--end::Input-->
                        </div>
                        <!--end::Input group-->
                        <!--begin::Input group-->
                        <div class="fv-row mb-10">
                            <!--begin::Wrapper-->
                            <div class="d-flex flex-stack mb-2">
                                <!--begin::Label-->
                                <label
                                    class="form-label fw-bolder text-dark fs-6 mb-0"
                                    >{{ __('Password') }}</label
                                >
                                <!--end::Label-->
                                <!--begin::Link-->
                                <a
                                    href="/forgot-password"
                                    class="link-primary fs-6 fw-bolder"
                                    >{{ __('Forgot Password ?') }}</a
                                >
                                <!--end::Link-->
                            </div>
                            <!--end::Wrapper-->
                            <!--begin::Input-->
                            <input
                                v-model="form.password"
                                class="form-control form-control-solid"
                                type="password"
                                name="password"
                                autocomplete="off"
                            />
                            <!--end::Input-->
                        </div>
                        <!--end::Input group-->
                        <!--begin::Actions-->
                        <div class="text-center">
                            <!--begin::Submit button-->
                            <button
                                type="submit"
                                id="kt_sign_in_submit"
                                class="btn btn-lg btn-primary w-100 mb-5"
                            >
                                <span class="indicator-label">{{
                                    __('Continue')
                                }}</span>
                                <span
                                    v-show="form.processing"
                                    class="indicator-progress"
                                    >{{ __('Please wait...') }}
                                    <ALoading />
                                </span>
                            </button>
                        </div>
                        <!--end::Actions-->
                    </form>
                    <div class="col-md-12 social-media-btn-group">
                        <a
                            href="/login/google"
                            class="btn btn-sm btn-primary w-100 mb-5 google-login"
                            ><span
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        d="M16.319 13.713v5.487h9.075c-0.369 2.356-2.744 6.9-9.075 6.9-5.463 0-9.919-4.525-9.919-10.1s4.456-10.1 9.919-10.1c3.106 0 5.188 1.325 6.375 2.469l4.344-4.181c-2.788-2.612-6.4-4.188-10.719-4.188-8.844 0-16 7.156-16 16s7.156 16 16 16c9.231 0 15.363-6.494 15.363-15.631 0-1.050-0.113-1.85-0.25-2.65l-15.113-0.006z"
                                    /></svg></span
                            ><span>{{ __('Login with Google') }}</span></a
                        >
                        <a
                            href="/login/facebook"
                            class="btn btn-sm btn-primary w-100 mb-5 facebook-login"
                            ><span
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        d="M19 6h5v-6h-5c-3.86 0-7 3.14-7 7v3h-4v6h4v16h6v-16h5l1-6h-6v-3c0-0.542 0.458-1 1-1z"
                                    /></svg></span
                            ><span>{{ __('Login with Facebook') }}</span></a
                        >
                        <a
                            href="/login/linkedin"
                            class="btn btn-sm btn-primary w-100 mb-5 linkedin-login"
                        >
                            <span
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        d="M12 12h5.535v2.837h0.079c0.77-1.381 2.655-2.837 5.464-2.837 5.842 0 6.922 3.637 6.922 8.367v9.633h-5.769v-8.54c0-2.037-0.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509v8.688h-5.767v-18z"
                                    />
                                    <path d="M2 12h6v18h-6v-18z" />
                                    <path
                                        d="M8 7c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                                    /></svg></span
                            ><span>{{ __('Login with Linkedin') }}</span></a
                        >
                        <a
                            href="/login/twitter"
                            class="btn btn-sm btn-primary w-100 mb-5 twitter-login"
                        >
                            <span
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z"
                                    /></svg></span
                            ><span>{{ __('Login with Twitter') }}</span></a
                        >
                    </div>
                    <!--end::Form-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Authentication - Sign-in-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import { Head, Link } from '@inertiajs/vue3';
import ALoading from "@/Components/Atoms/ALoading.vue";

export default defineComponent({
    components: {
        ALoading,
        Head,
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        JetValidationErrors,
        Link,
    },

    props: {
        canResetPassword: Boolean,
        status: String,
        testUsers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            form: this.$inertia.form({
                email: '',
                password: '',
                remember: false,
            }),
            selectedTestUser: null,
        };
    },

    methods: {
        submit() {
            this.form
                .transform((data) => ({
                    ...data,
                    remember: this.form.remember ? 'on' : '',
                }))
                .post(this.route('login'), {
                    preserveState: true,
                    preserveScroll: true,
                    onFinish: () => this.form.reset('password'),
                });
        },

        fillTestUser() {
            if (this.selectedTestUser) {
                this.form.email = this.selectedTestUser.email;
                this.form.password = 'password';
            }
        },
    },

    beforeMount() {
        const currentURL = window.location.href;

        const queryString = currentURL.split('?')[1] || '';
        if (queryString.includes('redirect')) {
            sessionStorage.setItem('flag', true);
        }
    },
});
</script>
