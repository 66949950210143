<template>
    <Head :title="__('Welcome')" />

    <div>
        <!--begin::Main-->
        <!--begin::Root-->
        <div class="d-flex flex-column flex-root">
            <!--begin::Header Section-->
            <div class="mb-0" id="home">
                <!--begin::Wrapper-->
                <div
                    class="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                    style="
                        background-image: url(/media/svg/illustrations/landing.svg);
                    "
                >
                    <!--begin::Header-->
                    <div
                        class="landing-header"
                        data-kt-sticky="true"
                        data-kt-sticky-name="landing-header"
                        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                    >
                        <!--begin::Container-->
                        <div class="container">
                            <!--begin::Wrapper-->
                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <!--begin::Logo-->
                                <div
                                    class="d-flex align-items-center flex-equal"
                                >
                                    <!--begin::Mobile menu toggle-->
                                    <button
                                        class="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                        id="kt_landing_menu_toggle"
                                    >
                                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                                        <span class="svg-icon svg-icon-2hx">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="black"
                                                />
                                                <path
                                                    opacity="0.3"
                                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                    </button>
                                    <!--end::Mobile menu toggle-->
                                    <!--begin::Logo image-->
                                    <a href="/">
                                        <ApplicationLogo
                                            mark-width="32"
                                            logo-text-classes="p-3 text-white"
                                        ></ApplicationLogo>
                                    </a>
                                    <!--end::Logo image-->
                                </div>
                                <!--end::Logo-->
                                <!--begin::Menu wrapper-->
                                <div
                                    class="d-lg-block"
                                    id="kt_header_nav_wrapper"
                                >
                                    <div
                                        class="d-lg-block p-5 p-lg-0"
                                        :class="menuClasses"
                                        data-kt-drawer="true"
                                        data-kt-drawer-name="landing-menu"
                                        data-kt-drawer-activate="{default: true, lg: false}"
                                        data-kt-drawer-overlay="true"
                                        data-kt-drawer-width="200px"
                                        data-kt-drawer-direction="start"
                                        data-kt-drawer-toggle="#kt_landing_menu_toggle"
                                        data-kt-swapper="true"
                                        data-kt-swapper-mode="prepend"
                                        data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                                    >
                                        <!--begin::Menu-->
                                        <div
                                            class="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold"
                                            id="kt_landing_menu"
                                        >
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link active py-3 px-4 px-xxl-6"
                                                    href="/de/#kt_body"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Home</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="/de/#how-it-works"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Wie es funktioniert</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="/de/#how-we-help"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Wie wir helfen</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="/de/#team"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Team</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="/de/#pricing"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Preis</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                        <!--end::Menu-->
                                    </div>
                                </div>
                                <!--end::Menu wrapper-->
                                <!--begin::Toolbar-->
                                <div class="flex-equal text-end ms-1">
                                    <Link
                                        :href="route('dashboard')"
                                        class="btn btn-success"
                                    >
                                        Sign In
                                    </Link>
                                </div>
                                <!--end::Toolbar-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Container-->
                    </div>
                    <!--end::Header-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Curve bottom-->
                <div class="landing-curve landing-dark-color mb-10 mb-lg-20">
                    <svg
                        viewBox="15 12 1470 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
                <!--end::Curve bottom-->
            </div>
            <!--end::Header Section-->
            <!--begin::How It Works Section-->
            <div class="mb-n10 vh-100 mb-lg-n20 z-index-2">
                <!--begin::Container-->
                <div class="container">
                    <!--begin::Heading-->
                    <div class="text-center mb-17">
                        <!--begin::Title-->
                        <h3
                            class="fs-2hx text-dark mb-5"
                            id="how-it-works"
                            data-kt-scroll-offset="{default: 100, lg: 150}"
                        >
                            Impressum
                        </h3>
                        <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Row-->
                    <div class="row w-100 gy-10 mb-md-20">
                        <!--begin::Col-->
                        <div class="offset-md-2 col-md-8 px-5">
                            <!--begin::Story-->
                            <div class="mb-10 mb-md-0">
                                <!--begin::Heading-->
                                <div class="mb-5">
                                    <h2>Inhaber und Betreiber</h2>

                                    <p>
                                        Creaitor.ai<br />
                                        Eine eingetragene Marke der Funk
                                        Solutions GmbH<br />
                                        Pilatusstrasse 41<br />
                                        6003 Luzern<br />
                                        Schweiz<br />
                                        Tel: +41 41 511 42 42<br />
                                        Web:&nbsp;<a
                                            href="https://www.creaitor.ai/"
                                            >www.creaitor.ai</a
                                        ><br />
                                        E-Mail: contact@creaitor.ai
                                    </p>

                                    <p>
                                        <br />
                                        Unternehmens-Identifikationsnummer (UID)
                                        CHE-114.547.021<br />
                                        Bankangaben:&nbsp;UBS AG,&nbsp;CH-8098
                                        ZUERICH<br />
                                        IBAN: CH62 0023 1231 4391 7701 Y
                                    </p>
                                </div>
                            </div>
                            <!--end::Story-->
                        </div>
                        <!--end::Col-->
                    </div>
                    <!--end::Row-->
                </div>
                <!--end::Container-->
            </div>
            <Footer></Footer>
            <!--begin::Scrolltop-->
            <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                <span class="svg-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <rect
                            opacity="0.5"
                            x="13"
                            y="6"
                            width="13"
                            height="2"
                            rx="1"
                            transform="rotate(90 13 6)"
                            fill="black"
                        />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </div>
            <!--end::Scrolltop-->
        </div>
        <!--end::Root-->
        <!--end::Main-->
    </div>
</template>

<style scoped></style>

<script>
import { defineComponent } from 'vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import Typed from 'typed.js';
import ApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import Footer from './Partials/de/Footer.vue';
import { Carousel } from 'bootstrap';

export default defineComponent({
    components: {
        Footer,
        Head,
        Link,
        ApplicationLogo,
    },

    props: {
        canLogin: Boolean,
        canRegister: Boolean,
        laravelVersion: String,
        phpVersion: String,
    },

    data() {
        return {
            productCarousel: {},
            teamCarousel: {},
        };
    },

    mounted() {
        this.productCarousel = Carousel(
            document.querySelector('#product-carousel')
        );
        this.teamCarousel = Carousel(
            document.querySelector('#team-carousel')
        );
        let typed = new Typed('#hero-text', {
            strings: [
                'Blog headlines',
                'Blog posts',
                'Topic ideas',
                'Facebook ads',
                'Stunning Headlines',
            ],
            typeSpeed: 60,
            loop: true,
        });
    },

    methods: {
        nextSlide(element) {
            bootstrap.Carousel.getInstance(element).next();
        },
        prevSlide(element) {
            bootstrap.Carousel.getInstance(element).prev();
        },
    },
});
</script>
