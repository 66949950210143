<template>
    <!--begin::Timeline-->
    <div class="timeline" v-if="!isLoading">
        <!--begin::Timeline item-->
        <div
            v-if="notifications.length > 0"
            class="timeline-item"
            v-for="notification in notifications"
            :key="notification"
        >
            <!--begin::Timeline line-->
            <div class="timeline-line w-40px"></div>
            <!--end::Timeline line-->

            <!--begin::Timeline icon-->
            <div class="timeline-icon symbol symbol-circle symbol-40px me-4">
                <div class="symbol-label bg-light">
                    <DynamicIcons
                        :icon="notification.data.symbol"
                    ></DynamicIcons>
                </div>
            </div>
            <!--end::Timeline icon-->

            <!--begin::Timeline content-->
            <div class="timeline-content mb-10 mt-n1">
                <!--begin::Timeline heading-->
                <div class="pe-3 mb-5">
                    <div
                        class="d-flex flex-wrap align-items-center justify-content-between mt-1 fs-6"
                    >
                        <div class="d-flex flex-column">
                            <!--begin::Title-->
                            <div
                                class="fs-5"
                                :class="[
                                    notification.read_at ? '' : 'fw-semibold',
                                ]"
                            >
                                {{ getTitle(notification.data?.title) }}
                            </div>
                            <!--end::Title-->

                            <!--begin::Subtitle-->
                            <div
                                class="fs-5 mb-2 text-muted"
                                :class="[
                                    notification.read_at ? '' : 'fw-semibold',
                                ]"
                            >
                                {{ getSubtitle(notification.data?.sub_title) }}
                            </div>
                            <!--end::Title-->
                        </div>

                        <div>
                            <button
                                v-if="notification.data.view_url"
                                @click="
                                    markAsReadAndRedirectToView(notification)
                                "
                                class="btn btn-sm btn-light btn-active-light-primary"
                            >
                                {{ __('View') }}
                            </button>
                            <button
                                v-else
                                @click="
                                    openNotificationDetailModal(notification)
                                "
                                class="btn btn-sm btn-light btn-active-light-primary"
                            >
                                {{ __('View') }}
                            </button>
                        </div>
                    </div>

                    <!--begin::Description-->
                    <div class="d-flex align-items-center mt-1 fs-6">
                        <!--begin::Info-->
                        <div class="badge badge-light fs-8">
                            {{ __('Added') }} {{ age(notification.created_at) }}
                        </div>
                        <!--end::Info-->
                    </div>
                    <!--end::Description-->
                </div>
                <!--end::Timeline heading-->
            </div>
            <!--end::Timeline content-->
        </div>
        <div v-else>
            <div
                class="alert bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 border-none"
            >
                {{ __('There are no notifications yet.') }}
            </div>
        </div>
        <!--end::Timeline item-->
    </div>
    <!--end::Timeline-->
    <div class="row" v-if="!isLoading">
        <div
            class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
        >
            <div class="dataTables_length text-sm w-44" id="documents_table_length">
                <label>
                    {{ __('Showing') }} {{ pageDetails.from }} {{ __('to') }}
                    {{ pageDetails.to }} {{ __('of') }} {{ pageDetails.total }}
                    {{ __('entries') }}
                </label>
            </div>
        </div>
        <div
            class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
        >
            <div
                class="dataTables_paginate paging_simple_numbers"
                id="documents_table_paginate"
            >
                <ul class="pagination">
                    <li
                        v-for="(link, index) in pageLinks"
                        class="page-item"
                        :key="index"
                        :class="{
                            'prev previous': index === 0,
                            next: index === 14,
                            active: link.label == pageDetails.currentPage,
                            disabled: !link.url,
                        }"
                    >
                        <a
                            href="#"
                            @click.prevent="
                                $emit('pagination-clicked-event', link.url)
                            "
                            class="page-link"
                        >
                            <span v-if="index === 0">
                                <i class="previous"></i>
                            </span>
                            <span
                                v-else-if="
                                    index === Object.keys(pageLinks).length - 1
                                "
                            >
                                <i class="next"></i>
                            </span>
                            <span v-else-if="link.url">
                                {{ link.label }}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import DynamicIcons from '@/Components/Icons/DynamicIcons.vue';
import moment from 'moment';
import { Link } from '@inertiajs/vue3';
import {router as Inertia} from '@inertiajs/vue3';

export default {
    props: {
        notifications: {
            type: Array,
            default: [],
        },
        pageLinks: {
            type: Array,
            default: [],
        },
        pageDetails: {
            type: Object,
            default: {
                currentPage: 1,
                from: 1,
                to: 1,
                total: 1,
            },
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        DynamicIcons,
        Link,
    },

    data() {
        return {
            language: this.$page?.props?.user?.user_info?.language
                ? this.$page.props.user.user_info.language
                : 'en',
        };
    },

    mounted() {
        moment.locale(this.language);
    },

    methods: {
        getTitle(title) {
            try {
                return title[this.language]
                    ? title[this.language]
                    : this.__('No title');
            } catch (e) {
                return title ? title : this.__('No title');
            }
        },
        getSubtitle(subtitle) {
            try {
                return subtitle[this.language]
                    ? subtitle[this.language]
                    : this.__('No subtitle');
            } catch (e) {
                return subtitle ? subtitle : this.__('No subtitle');
            }
        },
        async markAsReadAndRedirectToView(notification) {
            await this.markAsRead(notification);
            Inertia.visit(notification.data.view_url);
        },
        async openNotificationDetailModal(notification) {
            await this.markAsRead(notification);
            emitter.emit('open-notification-detail-modal', notification.id);
        },
        age(created) {
            return created ? moment(created).fromNow() : this.__('now');
        },
        async markAsRead(notification) {
            axios.put('/api/notifications/read', { id: notification.id });
            notification.read_at = true;
            Inertia.reload({ only: ['user'] });
        },
    },
};
</script>
