import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "8.155",
      height: "2",
      x: "17.037",
      y: "15.122",
      fill: "gray",
      class: "!fill-gray-700",
      opacity: ".5",
      rx: "1",
      transform: "rotate(45 17.037 15.122)"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#000",
      d: "M11 19c-4.444 0-8-3.556-8-8s3.556-8 8-8 8 3.556 8 8-3.556 8-8 8m0-14c-3.467 0-6 2.533-6 6s2.533 6 6 6 6-2.533 6-6-2.533-6-6-6",
      class: "!fill-black"
    }, null, -1)
  ])))
}
export default { render: render }