<template>
    <div
        class="d-flex flex-column flex-sm-row align-items-sm-center position-relative my-1 py-2"
    >
        <!--begin::Label-->
        <div class="col-2 text-sm text-gray-700 fw-bolder">
            {{ __('Target audience') }}:
        </div>
        <!--end::Label-->
        <div class="col-10">
            <input
                type="text"
                class="form-control form-control-sm form-control-solid bg-white w-250px ps-4 ms-0 ms-sm-6"
                name="Search Team"
                @input="$emit('target-audience-change', targetAudience)"
                v-model="targetAudience"
                :placeholder="__('e.g. a six year old child')"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        pageType: '',
        defaultTargetAudience: String | undefined,
    },

    watch: {
        defaultTargetAudience(newValue) {
            this.targetAudience = newValue;
        },
    },

    data() {
        return {
            targetAudience: this.defaultTargetAudience || '',
        };
    },
});
</script>

<style lang="scss"></style>
