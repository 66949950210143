import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#0186D2",
      d: "M0 15.997C0 7.18 7.131.03 15.944 0A2.003 2.003 0 0 0 14 2c0 1.106.894 2 2 2C9.375 4 4 9.372 4 15.996s5.375 11.997 12 11.997c4.356 0 8.169-2.318 10.281-5.786-.187.413-.206.925-.062 1.387.075.232.187.45.337.644.075.094.163.188.256.269.05.044.1.08.15.118.026.02.05.038.082.057q.045.029.081.05c.313.18.663.268 1 .268.688 0 1.363-.356 1.731-1C27.087 28.782 21.92 32 16 32c-8.837-.006-16-7.167-16-16.003",
      opacity: ".4"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#0186D2",
      d: "M14 2c0-1.106.894-2 2-2 8.837 0 16 7.161 16 15.997 0 2.912-.781 5.649-2.144 7.998a2 2 0 0 1-3.463-2A11.95 11.95 0 0 0 28 15.998c0-6.624-5.375-11.998-12-11.998-1.106 0-2-.893-2-2"
    }, null, -1)
  ])))
}
export default { render: render }