<template>
    <div class="card card-flush">
        <!--begin::Card header-->
        <div class="card-header p-0">
            <!--begin::Card title-->
            <div class="card-title w-100 m-0">
                <!--begin::Search-->
                <div class="w-100 table-search-input position-relative">
                    <input
                        v-model="searchInput"
                        @input="sendSearchInput($event.target.value)"
                        type="search"
                        class="form-control form-control-solid ps-14"
                        :placeholder="__('Search File')"
                    />
                </div>
                <!--end::Search-->
            </div>
            <!--end::Card title-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div v-if="isLoading" class="card-body">
            <div class="text-center">
                <div
                    class="spinner-border spinner-border text-primary"
                    role="status"
                ></div>
                <span class="text-black fs-5 d-block mt-2">
                    {{ __('Searching file...') }}
                </span>
            </div>
        </div>
        <div v-else class="card-body p-0">
            <!--begin::Table-->
            <div
                id="bulk-processing-table-wrapper"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
                <div class="table-responsive">
                    <table
                        class="table align-middle table-row-dashed fs-6 dataTable no-footer gy-5"
                        id="bulk-processing-table"
                    >
                        <!--begin::Table head-->
                        <thead>
                            <!--begin::Table row-->
                            <tr
                                v-if="bulkProcessings.length > 0"
                                class="text-start text-gray-600 fw-bolder fs-7 text-uppercase gs-0"
                            >
                                <th
                                    class="w-10px pe-2 sorting_disabled"
                                    rowspan="1"
                                    colspan="1"
                                    :aria-label="__('Checkbox')"
                                >
                                    <div
                                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="allSelected"
                                            @change="selectAll"
                                        />
                                    </div>
                                </th>
                                <th
                                    tabindex="0"
                                    aria-controls="bulk-processing-table"
                                    rowspan="1"
                                    colspan="1"
                                    :aria-label="__('Name')"
                                >
                                    {{ __('Name') }}
                                </th>
                                <th
                                    class="text-end min-w-100px sorting"
                                    tabindex="0"
                                    aria-controls="bulk-processing-table"
                                    rowspan="1"
                                    colspan="1"
                                    :aria-label="__('Status')"
                                >
                                    {{ __('Status') }}
                                </th>
                                <th
                                    class="text-end min-w-100px sorting"
                                    tabindex="0"
                                    aria-controls="bulk-processing-table"
                                    rowspan="1"
                                    colspan="1"
                                    :aria-label="__('Created At')"
                                >
                                    {{ __('Created At') }}
                                </th>
                                <th
                                    class="text-end min-w-100px sorting"
                                    tabindex="0"
                                    aria-controls="bulk-processing-table"
                                    rowspan="1"
                                    colspan="1"
                                    :aria-label="__('Author')"
                                >
                                    {{ __('Author') }}
                                </th>
                                <th
                                    class="text-end min-w-70px sorting_disabled"
                                    rowspan="1"
                                    colspan="1"
                                ></th>
                            </tr>

                            <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-black">
                            <!--begin::Table row-->

                            <!--end::Table row-->
                            <template v-if="bulkProcessings.length > 0">
                                <tr
                                    v-for="bulkProcessing in bulkProcessings"
                                    :key="bulkProcessing.id"
                                >
                                    <td>
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="bulkProcessingIds"
                                                @change="select"
                                                :value="bulkProcessing.uuid"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            {{ bulkProcessing.name }}
                                        </div>
                                    </td>
                                    <td class="text-end pe-0">
                                        <span
                                            v-if="
                                                bulkProcessing.progress >= 100
                                            "
                                            class="badge badge-light-success fs-7"
                                        >
                                            <i
                                                class="bi bi-check-circle-fill me-2"
                                            ></i>
                                            {{ bulkProcessing.status }}
                                        </span>
                                        <span
                                            v-else
                                            class="badge badge-light-info fs-7"
                                        >
                                            <div
                                                class="spinner-border spinner-border w-10px h-10px mt-1"
                                            >
                                                <div
                                                    class="d-flex align-items-center justify-content-center"
                                                    role="status"
                                                ></div>
                                            </div>
                                            {{ __('Processing') }}:
                                            {{ bulkProcessing.progress }}%
                                        </span>
                                    </td>
                                    <td class="text-end pe-0 custom-blue-color">
                                        {{ bulkProcessing.date }}
                                    </td>
                                    <td class="text-end pe-0 custom-blue-color">
                                        {{ bulkProcessing.authorName }}
                                    </td>
                                    <td
                                        class="text-end position-relative action-dropdown-menu w-125px"
                                    >
                                        <div
                                            class="dropdown table-action-dropdown"
                                        >
                                            <button
                                                class="dropdown-toggle p-0 border-0 bg-transparent"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i
                                                    class="bi bi-three-dots-vertical fs-3 text-black"
                                                ></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a
                                                        class="dropdown-item fs-6"
                                                        :href="
                                                            route(
                                                                'bulk-processings.download',
                                                                bulkProcessing.uuid
                                                            )
                                                        "
                                                        target="_blank"
                                                        ><i
                                                            class="bi bi-download me-2 text-black"
                                                        ></i>
                                                        {{ __('Download') }}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="dropdown-item fs-6"
                                                        @click.prevent="
                                                            sendDataToModal(
                                                                bulkProcessing.uuid
                                                            )
                                                        "
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_delete"
                                                        ><i
                                                            class="bi bi-trash me-2 text-danger"
                                                        ></i>
                                                        {{ __('Delete') }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </template>

                            <template v-else>
                                <tr>
                                    <td
                                        colspan="1"
                                        class="text-center text-gray-500"
                                    >
                                        {{ __('No documents found.') }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        <!--end::Table body-->
                    </table>
                </div>
            </div>
            <!--end::Table-->
        </div>
        <!--end::Card body-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { debounce } from 'lodash';

export default defineComponent({
    name: 'BulkProcessingListingTable',
    props: {
        bulkProcessings: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            allSelected: false,
            bulkProcessingIds: [],
            searchInput: null,
        };
    },

    watch: {
        bulkProcessingIds(bulkProcessingIds) {
            // eslint-disable-next-line no-undef
            emitter.emit('bulk-processing-uuids-selected', bulkProcessingIds);
        },
    },

    methods: {
        selectAll() {
            let vm = this;
            vm.bulkProcessingIds = [];
            if (this.allSelected) {
                this.bulkProcessings.forEach(function (bulkProcessing) {
                    vm.bulkProcessingIds.push(bulkProcessing.uuid);
                });
            }
        },
        select() {
            this.allSelected = false;
        },
        sendSearchInput: debounce(function (value) {
            this.$emit('on-search', value);
        }, 300),
        sendDataToModal(uuid) {
            // eslint-disable-next-line no-undef
            emitter.emit('set-delete-candidate', {
                uuid: uuid,
                url: '/api/bulk-processings',
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this file? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
            this.$page.props.deleteCandidate = uuid;
        },
        deleteCallback() {
            this.$emit('reload-data');
        },
    },
});
</script>

<style lang="scss">
.table-search-input {
    &::before {
        font-family: 'bootstrap-icons';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        color: #45577b;
        content: '\F52A';
        left: 1rem;
    }

    .form-control {
        &::placeholder {
            color: #45577b !important;
        }
    }
}

.table-action-dropdown {
    .dropdown-toggle {
        &::after {
            display: none;
        }
    }
}
.custom-blue-color{
    color:rgb(69, 87, 123) !important;
}

.badge {
    &.badge-light-success {
        background-color: #e3efea;
        color: #147c4c;

        i {
            color: #147c4c;
        }
    }

    &.badge-light-danger {
        background-color: #f8e8e7;
        color: #c23f3a;

        i {
            color: #c23f3a;
        }
    }

    &.badge-light-info {
        background-color: #e7edff;
        color: #2951d8;

        i {
            color: #2951d8;
        }
    }
}
</style>
