<template>
    <div
        class="stepper stepper-links d-flex flex-column align-items-end gap-5"
        id="add_addon_stepper"
        data-kt-stepper="true"
    >
        <!--begin::Nav-->
        <div
            class="stepper-nav bg-white"
            :class="{ fullwidth: true == headerFullwidth }"
        >
            <div
                v-for="(stepDetail, index) in stepDetails"
                :key="stepDetail.title"
                :class="{
                    current: index === step,
                    pending: index !== step,
                    completed: index <= finishedSteps,
                }"
                class="stepper-item"
            >
                <h3 class="stepper-title">
                    <span v-if="stepNumber == true">
                        <span class="step-count">
                            <i
                                v-if="index <= finishedSteps"
                                class="bi bi-check-lg"
                            ></i>
                            <span v-if="index > finishedSteps">{{
                                index + 1
                            }}</span>
                        </span> </span
                    >{{ __(stepDetail.title) }}
                </h3>
                <span v-if="stepNaviagationIcon == true">
                    <span class="stepper-next">
                        <i class="bi bi-chevron-right"></i>
                    </span>
                </span>
            </div>
        </div>
        <!--end::Nav-->
        <div
            class="step-content d-flex"
            :class="{ fullwidth: true == contentFullwidth }"
        >
            <!-- Slot for tab contents begins-->
            <slot name="tabs"></slot>
            <!-- Slot for tab contents ends -->
        </div>
        <div class="step-footer">
            <div class="d-flex justify-content-between w-100">
                <!--begin::Actions-->
                <div class="d-flex flex-stack">
                    <!--begin::Wrapper-->
                    <div class="me-2">
                        <button
                            type="button"
                            class="btn btn-lg btn-white btn-large me-3"
                            @click.stop="previousStep"
                            v-show="step > 0 && step !== stepDetails.length"
                        >
                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
                            <span class="svg-icon-3 me-1">
                               <IconArrowRight class="relative rotate-180 !fill-current !text-current" />
                            </span>
                            <!--end::Svg Icon-->
                            {{ __('Back') }}
                        </button>
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Wrapper-->
                    <div>
                        <button
                            type="button"
                            :class="{
                                disabled: true === loading || !canProceed,
                            }"
                            class="btn btn-lg btn-primary btn-large"
                            @click.stop="wizardSubmit"
                            v-if="step === stepDetails.length - 1"
                        >
                            <span
                                v-if="loading === false"
                                class="indicator-label flex items-center"
                            >
                                {{ __('Submit') }}
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                                <span class="svg-icon svg-icon-3 ms-2 me-0">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x="18"
                                            y="13"
                                            width="13"
                                            height="2"
                                            rx="1"
                                            transform="rotate(-180 18 13)"
                                            fill="currentColor"
                                        ></rect>
                                        <path
                                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                            </span>
                            <span v-if="loading === true">
                                {{ __('Please wait...') }}
                                <ALoading />
                            </span>
                        </button>
                        <button
                            type="button"
                            :class="{
                                disabled: true === loading || !canProceed,
                            }"
                            class="btn btn-lg btn-primary btn-large !flex items-center gap-1"
                            @click.stop="nextStep"
                            v-if="step < stepDetails.length - 1"
                            data-kt-stepper-action="next"
                            :disabled="!canProceed"
                        >
                            <span
                                class="svg-icon svg-icon-3 ms-1 me-0 flex gap-1 py-[4px]"
                                v-if="loading === false"
                            >
                                <span>{{ __('Continue') }}</span>
                                <IconArrowRight class="relative -top-0.5 h-8" />
                            </span>
                            <!--end::Svg Icon-->
                            <span v-if="loading === true">
                                {{ __('Please wait...') }}
                                <ALoading />
                            </span>
                        </button>
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Actions-->
                <div>
                    <slot name="footer-middle"></slot>
                </div>
                <div>
                    <slot name="footer-right"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconArrowRight from "@/icons/arrow-right.svg?component";
import ALoading from "@/Components/Atoms/ALoading.vue";

export default {
    components: {
        ALoading,
        IconArrowRight,
    },
    props: {
        user: Object | null,
        stepDetails: {
            type: Array,
            default: [],
        },
        step: {
            type: Number,
            default: 0,
        },
        finishedSteps: {
            type: Number,
            default: -1,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        stepNumber: {
            type: Boolean,
            default: true,
        },
        stepNaviagationIcon: {
            type: Boolean,
            default: true,
        },
        headerFullwidth: {
            type: Boolean,
            default: true,
        },
        contentFullwidth: {
            type: Boolean,
            default: true,
        },
        canProceed: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async nextStep() {
            this.$emit('horizontal-wizard-next-step');
        },
        previousStep() {
            this.$emit('horizontal-wizard-previous-step');
        },
        wizardSubmit() {
            this.$emit('horizontal-wizard-submit');
        },
    },
};
</script>

<style lang="scss">
.stepper [data-kt-stepper-action='submit'] {
    display: inline-block;
}

.stepper [data-kt-stepper-action='previous'] {
    display: inline-block;
}
</style>
