<template>
    <div class="card card-flush mb-10">
        <!--start::Card body-->
        <div class="card-body px-5 py-3">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center gap-3">
                    <i class="bi bi-file-earmark-ruled fs-1 text-success"></i>
                    <div>
                        <h6 class="mb-0 text-capitalize">
                            {{ fileDetails.file_name }}
                        </h6>
                        <p class="text-muted mb-0">
                            {{ fileDetails.size }}, {{ fileDetails.columns }},
                            {{ fileDetails.rows }}
                        </p>
                    </div>
                </div>
                <span class="text-success">
                    <i class="bi bi-check-circle-fill text-success me-1"></i>
                    {{ __('Upload completed') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BulkProcessingFileMeta',

    props: {
        fileDetails: {
            type: Object,
            default: () => ({}),
        },
    },
});
</script>
