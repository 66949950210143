<template>
    <Head :title="__('Welcome')" />

    <div>
        <!--begin::Main-->
        <!--begin::Root-->
        <div class="d-flex flex-column flex-root">
            <!--begin::Header Section-->
            <div class="mb-0" id="home">
                <!--begin::Wrapper-->
                <div
                    class="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                    style="
                        background-image: url(/media/svg/illustrations/landing.svg);
                    "
                >
                    <!--begin::Header-->
                    <div
                        class="landing-header"
                        data-kt-sticky="true"
                        data-kt-sticky-name="landing-header"
                        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                    >
                        <!--begin::Container-->
                        <div class="container">
                            <!--begin::Wrapper-->
                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <!--begin::Logo-->
                                <div
                                    class="d-flex align-items-center flex-equal"
                                >
                                    <!--begin::Mobile menu toggle-->
                                    <button
                                        class="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                        id="kt_landing_menu_toggle"
                                    >
                                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                                        <span class="svg-icon svg-icon-2hx">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="black"
                                                />
                                                <path
                                                    opacity="0.3"
                                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                    </button>
                                    <!--end::Mobile menu toggle-->
                                    <!--begin::Logo image-->
                                    <a href="/">
                                        <ApplicationLogo
                                            mark-width="32"
                                            logo-text-classes="p-3 text-white"
                                        ></ApplicationLogo>
                                    </a>
                                    <!--end::Logo image-->
                                </div>
                                <!--end::Logo-->
                                <!--begin::Menu wrapper-->
                                <div
                                    class="d-lg-block"
                                    id="kt_header_nav_wrapper"
                                >
                                    <div
                                        class="d-lg-block p-5 p-lg-0"
                                        data-kt-drawer="true"
                                        data-kt-drawer-name="landing-menu"
                                        data-kt-drawer-activate="{default: true, lg: false}"
                                        data-kt-drawer-overlay="true"
                                        data-kt-drawer-width="200px"
                                        data-kt-drawer-direction="start"
                                        data-kt-drawer-toggle="#kt_landing_menu_toggle"
                                        data-kt-swapper="true"
                                        data-kt-swapper-mode="prepend"
                                        data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                                    >
                                        <!--begin::Menu-->
                                        <div
                                            class="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold"
                                            id="kt_landing_menu"
                                        >
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link active py-3 px-4 px-xxl-6"
                                                    href="#kt_body"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Home</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="#how-it-works"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >How it Works</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="#how-we-help"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >How we help</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="#team"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Team</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="#pricing"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Pricing</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                        <!--end::Menu-->
                                    </div>
                                </div>
                                <!--end::Menu wrapper-->
                                <!--begin::Toolbar-->
                                <div class="flex-equal text-end ms-1">
                                    <Link
                                        :href="route('dashboard')"
                                        class="btn btn-success"
                                    >
                                        Sign In
                                    </Link>
                                </div>
                                <!--end::Toolbar-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Container-->
                    </div>
                    <!--end::Header-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Curve bottom-->
                <div class="landing-curve landing-dark-color mb-10 mb-lg-20">
                    <svg
                        viewBox="15 12 1470 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
                <!--end::Curve bottom-->
            </div>
            <!--end::Header Section-->
            <!--begin::How It Works Section-->
            <div class="mb-n10 mb-lg-n20 z-index-2">
                <!--begin::Container-->
                <div class="container">
                    <!--begin::Heading-->
                    <div class="text-center mb-17">
                        <!--begin::Title-->
                        <h3
                            class="fs-2hx text-dark mb-5"
                            id="how-it-works"
                            data-kt-scroll-offset="{default: 100, lg: 150}"
                        >
                            Terms And Conditions
                        </h3>
                        <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Row-->
                    <div class="row w-100 gy-10 mb-md-20">
                        <!--begin::Col-->
                        <div class="offset-md-2 col-md-8 px-5">
                            <!--begin::Story-->
                            <div class="mb-10 mb-md-0">
                                <!--begin::Heading-->
                                <div class="mb-5">
                                    <p>Welcome to Creaitor.ai!</p>
                                    <p>
                                        These terms and conditions outline the
                                        rules and regulations for the use of
                                        Funk Solutions GmbH's Website, located
                                        at https://www.creaitor.ai/.
                                    </p>
                                    <p>
                                        By accessing this website we assume you
                                        accept these terms and conditions. Do
                                        not continue to use Creaitor.ai if you
                                        do not agree to take all of the terms
                                        and conditions stated on this page.
                                    </p>
                                    <p>
                                        The following terminology applies to
                                        these Terms and Conditions, Privacy
                                        Statement and Disclaimer Notice and all
                                        Agreements: "Client", "You" and "Your"
                                        refers to you, the person log on this
                                        website and compliant to the Company’s
                                        terms and conditions. "The Company",
                                        "Ourselves", "We", "Our" and "Us",
                                        refers to our Company. "Party",
                                        "Parties", or "Us", refers to both the
                                        Client and ourselves. All terms refer to
                                        the offer, acceptance and consideration
                                        of payment necessary to undertake the
                                        process of our assistance to the Client
                                        in the most appropriate manner for the
                                        express purpose of meeting the Client’s
                                        needs in respect of provision of the
                                        Company’s stated services, in accordance
                                        with and subject to, prevailing law of
                                        Netherlands. Any use of the above
                                        terminology or other characters in the
                                        singular, plural, capitalization and/or
                                        he/she or they, are taken as
                                        interchangeable and therefore as
                                        referring to same.
                                    </p>
                                    <h3><strong>Cookies</strong></h3>
                                    <p>
                                        We employ the use of cookies. By
                                        accessing Creaitor.ai, you agreed to use
                                        cookies in agreement with the Funk
                                        Solutions GmbH's Privacy Policy.
                                    </p>
                                    <p>
                                        Most interactive websites use cookies to
                                        let us retrieve the user’s details for
                                        each visit. Cookies are used by our
                                        website to enable the functionality of
                                        certain areas to make it easier for
                                        people visiting our website. Some of our
                                        affiliate/advertising partners may also
                                        use cookies.
                                    </p>
                                    <h3><strong>License</strong></h3>
                                    <p>
                                        Unless otherwise stated, Funk Solutions
                                        GmbH and/or its licensors own the
                                        intellectual property rights for all
                                        material on Creaitor.ai. All
                                        intellectual property rights are
                                        reserved. You may access this from
                                        Creaitor.ai for your own personal use
                                        subjected to restrictions set in these
                                        terms and conditions.
                                    </p>
                                    <p>You must not:</p>
                                    <ul>
                                        <li>
                                            Republish material from Creaitor.ai
                                        </li>
                                        <li>
                                            Sell, rent or sub-license material
                                            from Creaitor.ai
                                        </li>
                                        <li>
                                            Reproduce, duplicate or copy
                                            material from Creaitor.ai
                                        </li>
                                        <li>
                                            Redistribute content from
                                            Creaitor.ai
                                        </li>
                                    </ul>
                                    <p>
                                        Parts of this website offer an
                                        opportunity for users to post and
                                        exchange opinions and information in
                                        certain areas of the website. Funk
                                        Solutions GmbH does not filter, edit,
                                        publish or review Comments prior to
                                        their presence on the website. Comments
                                        do not reflect the views and opinions of
                                        Funk Solutions GmbH,its agents and/or
                                        affiliates. Comments reflect the views
                                        and opinions of the person who post
                                        their views and opinions. To the extent
                                        permitted by applicable laws, Funk
                                        Solutions GmbH shall not be liable for
                                        the Comments or for any liability,
                                        damages or expenses caused and/or
                                        suffered as a result of any use of
                                        and/or posting of and/or appearance of
                                        the Comments on this website.
                                    </p>
                                    <p>
                                        Funk Solutions GmbH reserves the right
                                        to monitor all Comments and to remove
                                        any Comments which can be considered
                                        inappropriate, offensive or causes
                                        breach of these Terms and Conditions.
                                    </p>
                                    <p>You warrant and represent that:</p>
                                    <ul>
                                        <li>
                                            You are entitled to post the
                                            Comments on our website and have all
                                            necessary licenses and consents to
                                            do so;
                                        </li>
                                        <li>
                                            The Comments do not invade any
                                            intellectual property right,
                                            including without limitation
                                            copyright, patent or trademark of
                                            any third party;
                                        </li>
                                        <li>
                                            The Comments do not contain any
                                            defamatory, libelous, offensive,
                                            indecent or otherwise unlawful
                                            material which is an invasion of
                                            privacy
                                        </li>
                                        <li>
                                            The Comments will not be used to
                                            solicit or promote business or
                                            custom or present commercial
                                            activities or unlawful activity.
                                        </li>
                                    </ul>
                                    <p>
                                        You hereby grant Funk Solutions GmbH a
                                        non-exclusive license to use, reproduce,
                                        edit and authorize others to use,
                                        reproduce and edit any of your Comments
                                        in any and all forms, formats or media.
                                    </p>
                                    <div class="post-text w-richtext">
                                        <p>
                                            &zwj;<strong
                                                ><h3>
                                                    Placing Orders for Goods
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            By placing an Order for Goods
                                            through the Service, You warrant
                                            that You are legally capable of
                                            entering into binding contracts.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><h3>
                                                    Your Information
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            If You wish to place an Order for
                                            Goods available on the Service, You
                                            may be asked to supply certain
                                            information relevant to Your Order
                                            including, without limitation, Your
                                            name, Your email, Your phone number,
                                            Your credit card number, the
                                            expiration date of Your credit card,
                                            Your billing address, and Your
                                            shipping information.
                                        </p>
                                        <p>
                                            You represent and warrant that: (i)
                                            You have the legal right to use any
                                            credit or debit card(s) or other
                                            payment method(s) in connection with
                                            any Order; and that (ii) the
                                            information You supply to us is
                                            true, correct and complete.
                                        </p>
                                        <p>
                                            By submitting such information, You
                                            grant us the right to provide the
                                            information to payment processing
                                            third parties for purposes of
                                            facilitating the completion of Your
                                            Order.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><h3>
                                                    Order Cancellation
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            We reserve the right to refuse or
                                            cancel Your Order at any time for
                                            certain reasons including but not
                                            limited to:
                                        </p>
                                        <ul role="list">
                                            <li>Goods availability</li>
                                            <li>
                                                Errors in the description or
                                                prices for Goods
                                            </li>
                                            <li>Errors in Your Order</li>
                                        </ul>
                                        <p>
                                            We reserve the right to refuse or
                                            cancel Your Order if fraud or an
                                            unauthorized or illegal transaction
                                            is suspected.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><h3>
                                                    Your Order Cancellation
                                                    Rights
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            Any Goods you purchase can only be
                                            returned in accordance with these
                                            Terms and Conditions and Our Returns
                                            Policy.
                                        </p>
                                        <p>
                                            Our Returns Policy forms a part of
                                            these Terms and Conditions. Please
                                            read our Returns Policy to learn
                                            more about your right to cancel Your
                                            Order.
                                        </p>
                                        <p>
                                            Your right to cancel an Order only
                                            applies to Goods that are returned
                                            in the same condition as You
                                            received them. You should also
                                            include all of the products
                                            instructions, documents and
                                            wrappings. Goods that are damaged or
                                            not in the same condition as You
                                            received them or which are worn
                                            simply beyond opening the original
                                            packaging will not be refunded. You
                                            should therefore take reasonable
                                            care of the purchased Goods while
                                            they are in Your possession.
                                        </p>
                                        <p>
                                            We will reimburse You no later than
                                            14 days from the day on which We
                                            receive the returned Goods. We will
                                            use the same means of payment as You
                                            used for the Order, and You will not
                                            incur any fees for such
                                            reimbursement.
                                        </p>
                                        <p>
                                            You will not have any right to
                                            cancel an Order for the supply of
                                            any of the following Goods:
                                        </p>
                                        <ul role="list">
                                            <li>
                                                The supply of Goods made to Your
                                                specifications or clearly
                                                personalized.
                                            </li>
                                            <li>
                                                The supply of Goods which
                                                according to their nature are
                                                not suitable to be returned,
                                                deteriorate rapidly or where the
                                                date of expiry is over.
                                            </li>
                                            <li>
                                                The supply of Goods which are
                                                not suitable for return due to
                                                health protection or hygiene
                                                reasons and were unsealed after
                                                delivery.
                                            </li>
                                            <li>
                                                The supply of Goods which are,
                                                after delivery, according to
                                                their nature, inseparably mixed
                                                with other items.
                                            </li>
                                            <li>
                                                The supply of digital content
                                                which is not supplied on a
                                                tangible medium if the
                                                performance has begun with Your
                                                prior express consent and You
                                                have acknowledged Your loss of
                                                cancellation right.
                                            </li>
                                        </ul>
                                        <p>
                                            &zwj;<strong
                                                ><h3>
                                                    Availability, Errors and
                                                    Inaccuracies
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            We are constantly updating Our
                                            offerings of Goods on the Service.
                                            The Goods available on Our Service
                                            may be mispriced, described
                                            inaccurately, or unavailable, and We
                                            may experience delays in updating
                                            information regarding our Goods on
                                            the Service and in Our advertising
                                            on other websites.
                                        </p>
                                        <p>
                                            We cannot and do not guarantee the
                                            accuracy or completeness of any
                                            information, including prices,
                                            product images, specifications,
                                            availability, and services. We
                                            reserve the right to change or
                                            update information and to correct
                                            errors, inaccuracies, or omissions
                                            at any time without prior notice.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><h3>Prices Policy</h3></strong
                                            >
                                        </p>
                                        <p>
                                            The Company reserves the right to
                                            revise its prices at any time prior
                                            to accepting an Order.
                                        </p>
                                        <p>
                                            The prices quoted may be revised by
                                            the Company subsequent to accepting
                                            an Order in the event of any
                                            occurrence affecting delivery caused
                                            by government action, variation in
                                            customs duties, increased shipping
                                            charges, higher foreign exchange
                                            costs and any other matter beyond
                                            the control of the Company. In that
                                            event, You will have the right to
                                            cancel Your Order.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><h3>Payments</h3></strong
                                            >
                                        </p>
                                        <p>
                                            All Goods purchased are subject to a
                                            one-time payment. Payment can be
                                            made through various payment methods
                                            we have available, such as Visa,
                                            MasterCard, Affinity Card, American
                                            Express cards or online payment
                                            methods (PayPal, for example).
                                        </p>
                                        <p>
                                            Payment cards (credit cards or debit
                                            cards) are subject to validation
                                            checks and authorization by Your
                                            card issuer. If we do not receive
                                            the required authorization, We will
                                            not be liable for any delay or
                                            non-delivery of Your Order.
                                        </p>
                                        <h3>
                                            <strong
                                                ><h3>Subscriptions</h3></strong
                                            >
                                        </h3>
                                        <p>
                                            &zwj;<strong
                                                >Subscription period</strong
                                            >
                                        </p>
                                        <p>
                                            The Service or some parts of the
                                            Service are available only with a
                                            paid Subscription. You will be
                                            billed in advance on a recurring and
                                            periodic basis (such as daily,
                                            weekly, monthly or annually),
                                            depending on the type of
                                            Subscription plan you select when
                                            purchasing the Subscription.
                                        </p>
                                        <p>
                                            At the end of each period, Your
                                            Subscription will automatically
                                            renew under the exact same
                                            conditions unless You cancel it or
                                            the Company cancels it.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                >Subscription
                                                cancellations</strong
                                            >
                                        </p>
                                        <p>
                                            You may cancel Your Subscription
                                            renewal either through Your Account
                                            settings page or by contacting the
                                            Company. You will not receive a
                                            refund for the fees You already paid
                                            for Your current Subscription period
                                            and You will be able to access the
                                            Service until the end of Your
                                            current Subscription period.
                                        </p>
                                        <h5><strong>Billing</strong></h5>
                                        <p>
                                            You shall provide the Company with
                                            accurate and complete billing
                                            information including full name,
                                            address, state, zip code, telephone
                                            number, and a valid payment method
                                            information.
                                        </p>
                                        <p>
                                            Should automatic billing fail to
                                            occur for any reason, the Company
                                            will issue an electronic invoice
                                            indicating that you must proceed
                                            manually, within a certain deadline
                                            date, with the full payment
                                            corresponding to the billing period
                                            as indicated on the invoice.
                                        </p>
                                        <p>&zwj;<strong>Fee Changes</strong></p>
                                        <p>
                                            The Company, in its sole discretion
                                            and at any time, may modify the
                                            Subscription fees. Any Subscription
                                            fee change will become effective at
                                            the end of the then-current
                                            Subscription period.
                                        </p>
                                        <p>
                                            The Company will provide You with
                                            reasonable prior notice of any
                                            change in Subscription fees to give
                                            You an opportunity to terminate Your
                                            Subscription before such change
                                            becomes effective.
                                        </p>
                                        <p>
                                            Your continued use of the Service
                                            after the Subscription fee change
                                            comes into effect constitutes Your
                                            agreement to pay the modified
                                            Subscription fee amount.
                                        </p>
                                        <p>&zwj;<strong>Refunds</strong></p>
                                        <p>
                                            Except when required by law, paid
                                            Subscription fees are
                                            non-refundable.
                                        </p>
                                        <p>
                                            Certain refund requests for
                                            Subscriptions may be considered by
                                            the Company on a case-by-case basis
                                            and granted at the sole discretion
                                            of the Company.
                                        </p>
                                        <h5><strong>Free Trial</strong></h5>
                                        <p>
                                            The Company may, at its sole
                                            discretion, offer a Subscription
                                            with a Free Trial for a limited
                                            period of time.
                                        </p>
                                        <p>
                                            You will not be required to enter
                                            Your billing information in order to
                                            sign up for the Free Trial.
                                        </p>
                                        <p>
                                            At any time and without notice, the
                                            Company reserves the right to (i)
                                            modify the terms and conditions of
                                            the Free Trial offer, or (ii) cancel
                                            such Free Trial offer.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em>Promotions</em></strong
                                            >
                                        </p>
                                        <p>
                                            Any Promotions made available
                                            through the Service may be governed
                                            by rules that are separate from
                                            these Terms.
                                        </p>
                                        <p>
                                            If You participate in any
                                            Promotions, please review the
                                            applicable rules as well as our
                                            Privacy policy. If the rules for a
                                            Promotion conflict with these Terms,
                                            the Promotion rules will apply.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em>User Accounts</em></strong
                                            >
                                        </p>
                                        <p>
                                            When You create an account with Us,
                                            You must provide Us information that
                                            is accurate, complete, and current
                                            at all times. Failure to do so
                                            constitutes a breach of the Terms,
                                            which may result in immediate
                                            termination of Your account on Our
                                            Service.
                                        </p>
                                        <p>
                                            You are responsible for safeguarding
                                            the password that You use to access
                                            the Service and for any activities
                                            or actions under Your password,
                                            whether Your password is with Our
                                            Service or a Third-Party Social
                                            Media Service.
                                        </p>
                                        <p>
                                            You agree not to disclose Your
                                            password to any third party. You
                                            must notify Us immediately upon
                                            becoming aware of any breach of
                                            security or unauthorized use of Your
                                            account.
                                        </p>
                                        <p>
                                            You may not use as a username the
                                            name of another person or entity or
                                            that is not lawfully available for
                                            use, a name or trademark that is
                                            subject to any rights of another
                                            person or entity other than You
                                            without appropriate authorization,
                                            or a name that is otherwise
                                            offensive, vulgar or obscene.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em>Content</em></strong
                                            >
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                >Your Right to Post
                                                Content</strong
                                            >
                                        </p>
                                        <p>
                                            Our Service allows You to post
                                            Content. You are responsible for the
                                            Content that You post to the
                                            Service, including its legality,
                                            reliability, and appropriateness.
                                        </p>
                                        <p>
                                            You retain any and all of Your
                                            rights to any Content You submit,
                                            post or display on or through the
                                            Service and You are responsible for
                                            protecting those rights.
                                        </p>
                                        <p>
                                            You represent and warrant that: (i)
                                            the Content is Yours (You own it) or
                                            You have the right to use it, and
                                            (ii) the posting of Your Content on
                                            or through the Service does not
                                            violate the privacy rights,
                                            publicity rights, copyrights,
                                            contract rights or any other rights
                                            of any person.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                >Content Restrictions</strong
                                            >
                                        </p>
                                        <p>
                                            The Company is not responsible for
                                            the content of the Service's users.
                                            You expressly understand and agree
                                            that You are solely responsible for
                                            the Content and for all activity
                                            that occurs under your account,
                                            whether done so by You or any third
                                            person using Your account.
                                        </p>
                                        <p>
                                            You may not transmit any Content
                                            that is unlawful, offensive,
                                            upsetting, intended to disgust,
                                            threatening, libelous, defamatory,
                                            obscene or otherwise objectionable.
                                            Examples of such objectionable
                                            Content include, but are not limited
                                            to, the following:
                                        </p>
                                        <ul role="list">
                                            <li>
                                                Unlawful or promoting unlawful
                                                activity.
                                            </li>
                                            <li>
                                                Defamatory, discriminatory, or
                                                mean-spirited content, including
                                                references or commentary about
                                                religion, race, sexual
                                                orientation, gender,
                                                national/ethnic origin, or other
                                                targeted groups.
                                            </li>
                                            <li>
                                                Spam, machine – or randomly –
                                                generated, constituting
                                                unauthorized or unsolicited
                                                advertising, chain letters, any
                                                other form of unauthorized
                                                solicitation, or any form of
                                                lottery or gambling.
                                            </li>
                                            <li>
                                                Containing or installing any
                                                viruses, worms, malware, trojan
                                                horses, or other content that is
                                                designed or intended to disrupt,
                                                damage, or limit the functioning
                                                of any software, hardware or
                                                telecommunications equipment or
                                                to damage or obtain unauthorized
                                                access to any data or other
                                                information of a third person.
                                            </li>
                                            <li>
                                                Infringing on any proprietary
                                                rights of any party, including
                                                patent, trademark, trade secret,
                                                copyright, right of publicity or
                                                other rights.
                                            </li>
                                            <li>
                                                Impersonating any person or
                                                entity including the Company and
                                                its employees or
                                                representatives.
                                            </li>
                                            <li>
                                                Violating the privacy of any
                                                third person.
                                            </li>
                                            <li>
                                                False information and features.
                                            </li>
                                        </ul>
                                        <p>
                                            The Company reserves the right, but
                                            not the obligation, to, in its sole
                                            discretion, determine whether or not
                                            any Content is appropriate and
                                            complies with this Terms, refuse or
                                            remove this Content. The Company
                                            further reserves the right to make
                                            formatting and edits and change the
                                            manner any Content. The Company can
                                            also limit or revoke the use of the
                                            Service if You post such
                                            objectionable Content. As the
                                            Company cannot control all content
                                            posted by users and/or third parties
                                            on the Service, you agree to use the
                                            Service at your own risk. You
                                            understand that by using the Service
                                            You may be exposed to content that
                                            You may find offensive, indecent,
                                            incorrect or objectionable, and You
                                            agree that under no circumstances
                                            will the Company be liable in any
                                            way for any content, including any
                                            errors or omissions in any content,
                                            or any loss or damage of any kind
                                            incurred as a result of your use of
                                            any content.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                >Content Backups</strong
                                            >
                                        </p>
                                        <p>
                                            Although regular backups of Content
                                            are performed, the Company do not
                                            guarantee there will be no loss or
                                            corruption of data.
                                        </p>
                                        <p>
                                            Corrupt or invalid backup points may
                                            be caused by, without limitation,
                                            Content that is corrupted prior to
                                            being backed up or that changes
                                            during the time a backup is
                                            performed.
                                        </p>
                                        <p>
                                            The Company will provide support and
                                            attempt to troubleshoot any known or
                                            discovered issues that may affect
                                            the backups of Content. But You
                                            acknowledge that the Company has no
                                            liability related to the integrity
                                            of Content or the failure to
                                            successfully restore Content to a
                                            usable state.
                                        </p>
                                        <p>
                                            You agree to maintain a complete and
                                            accurate copy of any Content in a
                                            location independent of the Service.
                                        </p>
                                        <h2>
                                            &zwj;<strong
                                                ><em
                                                    >Copyright Policy</em
                                                ></strong
                                            >
                                        </h2>
                                        <h5>
                                            <strong
                                                >Intellectual Property
                                                Infringement</strong
                                            >
                                        </h5>
                                        <p>
                                            We respect the intellectual property
                                            rights of others. It is Our policy
                                            to respond to any claim that Content
                                            posted on the Service infringes a
                                            copyright or other intellectual
                                            property infringement of any person.
                                        </p>
                                        <p>
                                            If You are a copyright owner, or
                                            authorized on behalf of one, and You
                                            believe that the copyrighted work
                                            has been copied in a way that
                                            constitutes copyright infringement
                                            that is taking place through the
                                            Service, You must submit Your notice
                                            in writing to the attention of our
                                            copyright agent via email at
                                            hey@jasper.ai and include in Your
                                            notice a detailed description of the
                                            alleged infringement.
                                        </p>
                                        <p>
                                            You may be held accountable for
                                            damages (including costs and
                                            attorneys' fees) for misrepresenting
                                            that any Content is infringing Your
                                            copyright.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                >DMCA Notice and DMCA Procedure
                                                for Copyright Infringement
                                                Claims</strong
                                            >
                                        </p>
                                        <p>
                                            You may submit a notification
                                            pursuant to the Digital Millennium
                                            Copyright Act (DMCA) by providing
                                            our Copyright Agent with the
                                            following information in writing
                                            (see 17 U.S.C 512(c)(3) for further
                                            detail):
                                        </p>
                                        <ul role="list">
                                            <li>
                                                An electronic or physical
                                                signature of the person
                                                authorized to act on behalf of
                                                the owner of the copyright's
                                                interest.
                                            </li>
                                            <li>
                                                A description of the copyrighted
                                                work that You claim has been
                                                infringed, including the URL
                                                (i.e., web page address) of the
                                                location where the copyrighted
                                                work exists or a copy of the
                                                copyrighted work.
                                            </li>
                                            <li>
                                                Identification of the URL or
                                                other specific location on the
                                                Service where the material that
                                                You claim is infringing is
                                                located.
                                            </li>
                                            <li>
                                                Your address, telephone number,
                                                and email address.
                                            </li>
                                            <li>
                                                A statement by You that You have
                                                a good faith belief that the
                                                disputed use is not authorized
                                                by the copyright owner, its
                                                agent, or the law.
                                            </li>
                                            <li>
                                                A statement by You, made under
                                                penalty of perjury, that the
                                                above information in Your notice
                                                is accurate and that You are the
                                                copyright owner or authorized to
                                                act on the copyright owner's
                                                behalf.
                                            </li>
                                        </ul>
                                        <p>
                                            You can contact our copyright agent
                                            via email at contact@creaitor.ai.
                                            Upon receipt of a notification, the
                                            Company will take whatever action,
                                            in its sole discretion, it deems
                                            appropriate, including removal of
                                            the challenged content from the
                                            Service.
                                        </p>
                                        <h3>
                                            &zwj;<strong
                                                ><em
                                                    >Intellectual Property</em
                                                ></strong
                                            >
                                        </h3>
                                        <p>
                                            The Service and its original content
                                            (excluding Content provided by You
                                            or other users), features and
                                            functionality are and will remain
                                            the exclusive property of the
                                            Company and its licensors.
                                        </p>
                                        <p>
                                            The Service is protected by
                                            copyright, trademark, and other laws
                                            of both the Country and foreign
                                            countries.
                                        </p>
                                        <p>
                                            Our trademarks and trade dress may
                                            not be used in connection with any
                                            product or service without the prior
                                            written consent of the Company.
                                        </p>
                                        <h3>
                                            <strong
                                                ><em
                                                    >Links to Other Websites</em
                                                ></strong
                                            >
                                        </h3>
                                        <p>
                                            Our Service may contain links to
                                            third-party web sites or services
                                            that are not owned or controlled by
                                            the Company.
                                        </p>
                                        <p>
                                            The Company has no control over, and
                                            assumes no responsibility for, the
                                            content, privacy policies, or
                                            practices of any third party web
                                            sites or services. You further
                                            acknowledge and agree that the
                                            Company shall not be responsible or
                                            liable, directly or indirectly, for
                                            any damage or loss caused or alleged
                                            to be caused by or in connection
                                            with the use of or reliance on any
                                            such content, goods or services
                                            available on or through any such web
                                            sites or services.
                                        </p>
                                        <p>
                                            We strongly advise You to read the
                                            terms and conditions and privacy
                                            policies of any third-party web
                                            sites or services that You visit.
                                        </p>
                                        <h3>
                                            &zwj;<strong
                                                ><em>Termination</em></strong
                                            >
                                        </h3>
                                        <p>
                                            We may terminate or suspend Your
                                            Account immediately, without prior
                                            notice or liability, for any reason
                                            whatsoever, including without
                                            limitation if You breach these Terms
                                            and Conditions.
                                        </p>
                                        <p>
                                            Upon termination, Your right to use
                                            the Service will cease immediately.
                                            If You wish to terminate Your
                                            Account, You may simply discontinue
                                            using the Service.
                                        </p>
                                        <h3>
                                            &zwj;<strong
                                                ><em
                                                    >Limitation of Liability</em
                                                ></strong
                                            >
                                        </h3>
                                        <p>
                                            Notwithstanding any damages that You
                                            might incur, the entire liability of
                                            the Company and any of its suppliers
                                            under any provision of this Terms
                                            and Your exclusive remedy for all of
                                            the foregoing shall be limited to
                                            the amount actually paid by You
                                            through the Service or 100 USD if
                                            You haven't purchased anything
                                            through the Service.
                                        </p>
                                        <p>
                                            To the maximum extent permitted by
                                            applicable law, in no event shall
                                            the Company or its suppliers be
                                            liable for any special, incidental,
                                            indirect, or consequential damages
                                            whatsoever (including, but not
                                            limited to, damages for loss of
                                            profits, loss of data or other
                                            information, for business
                                            interruption, for personal injury,
                                            loss of privacy arising out of or in
                                            any way related to the use of or
                                            inability to use the Service,
                                            third-party software and/or
                                            third-party hardware used with the
                                            Service, or otherwise in connection
                                            with any provision of this Terms),
                                            even if the Company or any supplier
                                            has been advised of the possibility
                                            of such damages and even if the
                                            remedy fails of its essential
                                            purpose.
                                        </p>
                                        <p>
                                            Some states do not allow the
                                            exclusion of implied warranties or
                                            limitation of liability for
                                            incidental or consequential damages,
                                            which means that some of the above
                                            limitations may not apply. In these
                                            states, each party's liability will
                                            be limited to the greatest extent
                                            permitted by law.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em
                                                    >"AS IS" and "AS AVAILABLE"
                                                    Disclaimer</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            The Service is provided to You "AS
                                            IS" and "AS AVAILABLE" and with all
                                            faults and defects without warranty
                                            of any kind. To the maximum extent
                                            permitted under applicable law, the
                                            Company, on its own behalf and on
                                            behalf of its Affiliates and its and
                                            their respective licensors and
                                            service providers, expressly
                                            disclaims all warranties, whether
                                            express, implied, statutory or
                                            otherwise, with respect to the
                                            Service, including all implied
                                            warranties of merchantability,
                                            fitness for a particular purpose,
                                            title and non-infringement, and
                                            warranties that may arise out of
                                            course of dealing, course of
                                            performance, usage or trade
                                            practice. Without limitation to the
                                            foregoing, the Company provides no
                                            warranty or undertaking, and makes
                                            no representation of any kind that
                                            the Service will meet Your
                                            requirements, achieve any intended
                                            results, be compatible or work with
                                            any other software, applications,
                                            systems or services, operate without
                                            interruption, meet any performance
                                            or reliability standards or be error
                                            free or that any errors or defects
                                            can or will be corrected.
                                        </p>
                                        <p>
                                            Without limiting the foregoing,
                                            neither the Company nor any of the
                                            company's provider makes any
                                            representation or warranty of any
                                            kind, express or implied: (i) as to
                                            the operation or availability of the
                                            Service, or the information,
                                            content, and materials or products
                                            included thereon; (ii) that the
                                            Service will be uninterrupted or
                                            error-free; (iii) as to the
                                            accuracy, reliability, or currency
                                            of any information or content
                                            provided through the Service; or
                                            (iv) that the Service, its servers,
                                            the content, or e-mails sent from or
                                            on behalf of the Company are free of
                                            viruses, scripts, trojan horses,
                                            worms, malware, timebombs or other
                                            harmful components.
                                        </p>
                                        <p>
                                            Some jurisdictions do not allow the
                                            exclusion of certain types of
                                            warranties or limitations on
                                            applicable statutory rights of a
                                            consumer, so some or all of the
                                            above exclusions and limitations may
                                            not apply to You. But in such a case
                                            the exclusions and limitations set
                                            forth in this section shall be
                                            applied to the greatest extent
                                            enforceable under applicable law.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em>Governing Law</em></strong
                                            >
                                        </p>
                                        <p>
                                            The laws of the Country, excluding
                                            its conflicts of law rules, shall
                                            govern this Terms and Your use of
                                            the Service. Your use of the
                                            Application may also be subject to
                                            other local, state, national, or
                                            international laws.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em
                                                    >Disputes Resolution</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            If You have any concern or dispute
                                            about the Service, You agree to
                                            first try to resolve the dispute
                                            informally by contacting the
                                            Company.
                                        </p>
                                        <h3>
                                            <strong
                                                ><em
                                                    >For European Union (EU)
                                                    Users</em
                                                ></strong
                                            >
                                        </h3>
                                        <p>
                                            If You are a European Union
                                            consumer, you will benefit from any
                                            mandatory provisions of the law of
                                            the country in which you are
                                            resident in.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em
                                                    >United States Federal
                                                    Government End Use
                                                    Provisions</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            If You are a U.S. federal government
                                            end user, our Service is a
                                            "Commercial Item" as that term is
                                            defined at 48 C.F.R. §2.101.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em
                                                    >United States Legal
                                                    Compliance</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            You represent and warrant that (i)
                                            You are not located in a country
                                            that is subject to the United States
                                            government embargo, or that has been
                                            designated by the United States
                                            government as a "terrorist
                                            supporting" country, and (ii) You
                                            are not listed on any United States
                                            government list of prohibited or
                                            restricted parties.
                                        </p>
                                        <h3>
                                            &zwj;<strong
                                                ><em
                                                    >Severability and Waiver</em
                                                ></strong
                                            >
                                        </h3>
                                        <p>
                                            &zwj;<strong>Severability</strong>
                                        </p>
                                        <p>
                                            If any provision of these Terms is
                                            held to be unenforceable or invalid,
                                            such provision will be changed and
                                            interpreted to accomplish the
                                            objectives of such provision to the
                                            greatest extent possible under
                                            applicable law and the remaining
                                            provisions will continue in full
                                            force and effect.
                                        </p>
                                        <p>&zwj;<strong>Waiver</strong></p>
                                        <p>
                                            Except as provided herein, the
                                            failure to exercise a right or to
                                            require performance of an obligation
                                            under this Terms shall not effect a
                                            party's ability to exercise such
                                            right or require such performance at
                                            any time thereafter nor shall be the
                                            waiver of a breach constitute a
                                            waiver of any subsequent breach.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em
                                                    >Translation
                                                    Interpretation</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            These Terms and Conditions may have
                                            been translated if We have made them
                                            available to You on our Service. You
                                            agree that the original English text
                                            shall prevail in the case of a
                                            dispute.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em
                                                    >Changes to These Terms and
                                                    Conditions</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            We reserve the right, at Our sole
                                            discretion, to modify or replace
                                            these Terms at any time. If a
                                            revision is material We will make
                                            reasonable efforts to provide at
                                            least 30 days' notice prior to any
                                            new terms taking effect. What
                                            constitutes a material change will
                                            be determined at Our sole
                                            discretion.
                                        </p>
                                        <p>
                                            By continuing to access or use Our
                                            Service after those revisions become
                                            effective, You agree to be bound by
                                            the revised terms. If You do not
                                            agree to the new terms, in whole or
                                            in part, please stop using the
                                            website and the Service.
                                        </p>
                                        <p>
                                            &zwj;<strong
                                                ><em>Contact Us</em></strong
                                            >
                                        </p>
                                        <p>
                                            If you have any questions about
                                            these Terms and Conditions, You can
                                            contact us:
                                        </p>
                                    </div>
                                    <h3>
                                        <strong
                                            >Hyperlinking to our Content</strong
                                        >
                                    </h3>
                                    <p>
                                        The following organizations may link to
                                        our Website without prior written
                                        approval:
                                    </p>
                                    <ul>
                                        <li>Government agencies;</li>
                                        <li>Search engines;</li>
                                        <li>News organizations;</li>
                                        <li>
                                            Online directory distributors may
                                            link to our Website in the same
                                            manner as they hyperlink to the
                                            Websites of other listed businesses;
                                            and
                                        </li>
                                        <li>
                                            System wide Accredited Businesses
                                            except soliciting non-profit
                                            organizations, charity shopping
                                            malls, and charity fundraising
                                            groups which may not hyperlink to
                                            our Web site.
                                        </li>
                                    </ul>
                                    <p>
                                        These organizations may link to our home
                                        page, to publications or to other
                                        Website information so long as the link:
                                        (a) is not in any way deceptive; (b)
                                        does not falsely imply sponsorship,
                                        endorsement or approval of the linking
                                        party and its products and/or services;
                                        and (c) fits within the context of the
                                        linking party’s site.
                                    </p>
                                    <p>
                                        We may consider and approve other link
                                        requests from the following types of
                                        organizations:
                                    </p>
                                    <ul>
                                        <li>
                                            commonly-known consumer and/or
                                            business information sources;
                                        </li>
                                        <li>dot.com community sites;</li>
                                        <li>
                                            associations or other groups
                                            representing charities;
                                        </li>
                                        <li>online directory distributors;</li>
                                        <li>internet portals;</li>
                                        <li>
                                            accounting, law and consulting
                                            firms; and
                                        </li>
                                        <li>
                                            educational institutions and trade
                                            associations.
                                        </li>
                                    </ul>
                                    <p>
                                        We will approve link requests from these
                                        organizations if we decide that: (a) the
                                        link would not make us look unfavorably
                                        to ourselves or to our accredited
                                        businesses; (b) the organization does
                                        not have any negative records with us;
                                        (c) the benefit to us from the
                                        visibility of the hyperlink compensates
                                        the absence of Funk Solutions GmbH; and
                                        (d) the link is in the context of
                                        general resource information.
                                    </p>
                                    <p>
                                        These organizations may link to our home
                                        page so long as the link: (a) is not in
                                        any way deceptive; (b) does not falsely
                                        imply sponsorship, endorsement or
                                        approval of the linking party and its
                                        products or services; and (c) fits
                                        within the context of the linking
                                        party’s site.
                                    </p>
                                    <p>
                                        If you are one of the organizations
                                        listed in paragraph 2 above and are
                                        interested in linking to our website,
                                        you must inform us by sending an e-mail
                                        to Funk Solutions GmbH. Please include
                                        your name, your organization name,
                                        contact information as well as the URL
                                        of your site, a list of any URLs from
                                        which you intend to link to our Website,
                                        and a list of the URLs on our site to
                                        which you would like to link. Wait 2-3
                                        weeks for a response.
                                    </p>
                                    <p>
                                        Approved organizations may hyperlink to
                                        our Website as follows:
                                    </p>
                                    <ul>
                                        <li>
                                            By use of our corporate name; or
                                        </li>
                                        <li>
                                            By use of the uniform resource
                                            locator being linked to; or
                                        </li>
                                        <li>
                                            By use of any other description of
                                            our Website being linked to that
                                            makes sense within the context and
                                            format of content on the linking
                                            party’s site.
                                        </li>
                                    </ul>
                                    <p>
                                        No use of Funk Solutions GmbH's logo or
                                        other artwork will be allowed for
                                        linking absent a trademark license
                                        agreement.
                                    </p>
                                    <h3><strong>iFrames</strong></h3>
                                    <p>
                                        Without prior approval and written
                                        permission, you may not create frames
                                        around our Webpages that alter in any
                                        way the visual presentation or
                                        appearance of our Website.
                                    </p>
                                    <h3><strong>Content Liability</strong></h3>
                                    <p>
                                        We shall not be hold responsible for any
                                        content that appears on your Website.
                                        You agree to protect and defend us
                                        against all claims that is rising on
                                        your Website. No link(s) should appear
                                        on any Website that may be interpreted
                                        as libelous, obscene or criminal, or
                                        which infringes, otherwise violates, or
                                        advocates the infringement or other
                                        violation of, any third party rights.
                                    </p>
                                    <h3><strong>Your Privacy</strong></h3>
                                    <p>Please read Privacy Policy</p>
                                    <h3>
                                        <strong>Reservation of Rights</strong>
                                    </h3>
                                    <p>
                                        We reserve the right to request that you
                                        remove all links or any particular link
                                        to our Website. You approve to
                                        immediately remove all links to our
                                        Website upon request. We also reserve
                                        the right to amen these terms and
                                        conditions and it’s linking policy at
                                        any time. By continuously linking to our
                                        Website, you agree to be bound to and
                                        follow these linking terms and
                                        conditions.
                                    </p>
                                    <h3>
                                        <strong
                                            >Removal of links from our
                                            website</strong
                                        >
                                    </h3>
                                    <p>
                                        If you find any link on our Website that
                                        is offensive for any reason, you are
                                        free to contact and inform us any
                                        moment. We will consider requests to
                                        remove links but we are not obligated to
                                        or so or to respond to you directly.
                                    </p>
                                    <p>
                                        We do not ensure that the information on
                                        this website is correct, we do not
                                        warrant its completeness or accuracy;
                                        nor do we promise to ensure that the
                                        website remains available or that the
                                        material on the website is kept up to
                                        date.
                                    </p>
                                    <h3><strong>Disclaimer</strong></h3>
                                    <p>
                                        To the maximum extent permitted by
                                        applicable law, we exclude all
                                        representations, warranties and
                                        conditions relating to our website and
                                        the use of this website. Nothing in this
                                        disclaimer will:
                                    </p>
                                    <ul>
                                        <li>
                                            limit or exclude our or your
                                            liability for death or personal
                                            injury;
                                        </li>
                                        <li>
                                            limit or exclude our or your
                                            liability for fraud or fraudulent
                                            misrepresentation;
                                        </li>
                                        <li>
                                            limit any of our or your liabilities
                                            in any way that is not permitted
                                            under applicable law; or
                                        </li>
                                        <li>
                                            exclude any of our or your
                                            liabilities that may not be excluded
                                            under applicable law.
                                        </li>
                                    </ul>
                                    <p>
                                        The limitations and prohibitions of
                                        liability set in this Section and
                                        elsewhere in this disclaimer: (a) are
                                        subject to the preceding paragraph; and
                                        (b) govern all liabilities arising under
                                        the disclaimer, including liabilities
                                        arising in contract, in tort and for
                                        breach of statutory duty.
                                    </p>
                                    <p>
                                        As long as the website and the
                                        information and services on the website
                                        are provided free of charge, we will not
                                        be liable for any loss or damage of any
                                        nature.
                                    </p>
                                </div>
                            </div>
                            <!--end::Story-->
                        </div>
                        <!--end::Col-->
                    </div>
                    <!--end::Row-->
                </div>
                <!--end::Container-->
            </div>
            <Footer></Footer>
            <!--begin::Scrolltop-->
            <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                <span class="svg-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <rect
                            opacity="0.5"
                            x="13"
                            y="6"
                            width="13"
                            height="2"
                            rx="1"
                            transform="rotate(90 13 6)"
                            fill="black"
                        />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </div>
            <!--end::Scrolltop-->
        </div>
        <!--end::Root-->
        <!--end::Main-->
    </div>
</template>

<style scoped></style>

<script>
import { defineComponent } from 'vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import Typed from 'typed.js';
import ApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import Footer from './Partials/en/Footer.vue';
import { Carousel } from 'bootstrap';

export default defineComponent({
    components: {
        Footer,
        Head,
        Link,
        ApplicationLogo,
    },

    props: {
        canLogin: Boolean,
        canRegister: Boolean,
        laravelVersion: String,
        phpVersion: String,
    },

    data() {
        return {
            productCarousel: {},
            teamCarousel: {},
        };
    },

    mounted() {
        this.productCarousel = Carousel(
            document.querySelector('#product-carousel')
        );
        this.teamCarousel = Carousel(
            document.querySelector('#team-carousel')
        );
        let typed = new Typed('#hero-text', {
            strings: [
                'Blog headlines',
                'Blog posts',
                'Topic ideas',
                'Facebook ads',
                'Stunning Headlines',
            ],
            typeSpeed: 60,
            loop: true,
        });
    },

    methods: {
        nextSlide(element) {
            bootstrap.Carousel.getInstance(element).next();
        },
        prevSlide(element) {
            bootstrap.Carousel.getInstance(element).prev();
        },
    },
});
</script>
