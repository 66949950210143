<template>
    <app-layout
        :title="__('Bulk Processing')"
        :view="__('Generate in Bulk')"
        :user="$attrs.user"
    >
        <FileMeta :fileDetails="fileDetails" />
        <OutputContainer :get-input-options="getInputOptions" />
        <LanguageContainer />
        <OutputPreviewModal />
        <div class="text-end flex justify-end">
            <a
                class="btn mt-10 btn-secondary mr-5"
                :href="route('bulk-processings.index')"
            >
                {{ __('Cancel') }}
            </a>
            <button class="btn mt-10 btn-primary" @click="startBulkProcessing">
                {{ __('Continue') }}
            </button>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import FileMeta from '@/Pages/BulkProcessing/Partials/Create/FileMeta.vue';
import OutputContainer from '@/Pages/BulkProcessing/Partials/Create/OutputContainer.vue';
import LanguageContainer from '@/Pages/BulkProcessing/Partials/Create/LanguageContainer.vue';
import OutputPreviewModal from '@/Pages/BulkProcessing/Partials/Modals/OutputPreviewModal.vue';
import { usePage } from '@inertiajs/vue3';
import { useBulkProcessingStore } from '@/Stores/BulkProcessingStore';

export default defineComponent({
    name: 'BulkProcessingCreate',
    components: {
        AppLayout,
        FileMeta,
        OutputContainer,
        OutputPreviewModal,
        LanguageContainer,
    },

    setup() {
        const bulkProcessingStore = useBulkProcessingStore();
        const page = usePage();

        return {
            bulkProcessingStore,
            page,
        };
    },

    mounted() {
        this.bulkProcessingStore.fileHeaders = this.fileDetails.headers;
        this.bulkProcessingStore.languages = this.page.props.languages;
        this.bulkProcessingStore.aiAssistantTemplates =
            this.page.props.ai_templates;
        this.bulkProcessingStore.outputFormats = this.page.props.output_formats;
    },

    beforeUnmount() {
        this.bulkProcessingStore.resetStore();
    },

    computed: {
        fileDetails() {
            return this.page.props.file_details;
        },
    },

    methods: {
        startBulkProcessing() {
            // hasBeenSubmitted is used to check and see if the validation errors should be shown or not
            this.bulkProcessingStore.hasBeenSubmitted = true;
            // check to make sure that input options are not messed by reordering or deleting
            this.checkInputsOrder();

            if (!this.bulkProcessingStore?.isValidSubmission) return;
            // eslint-disable-next-line no-undef
            emitter.emit('open-bulk-processing-output-preview-modal');
        },
        checkInputsOrder() {
            this.bulkProcessingStore.outputSelections.forEach(
                (output, index) => {
                    const inputs = [...output.inputs];
                    const inputOptions = this.getInputOptions(index);
                    this.bulkProcessingStore.outputSelections[index].inputs =
                        inputs.filter((input) => {
                            return inputOptions.includes(input);
                        });
                }
            );
        },
        getInputOptions(index) {
            // add previous outputs to the input options
            const previousOutputs = [];
            [...this.bulkProcessingStore.outputSelections]
                .slice(0, index)
                .forEach((input) => {
                    if (input.column_name === '') return;
                    previousOutputs.push(
                        input.column_name.toLowerCase().replaceAll(' ', '_')
                    );
                });

            return [
                ...previousOutputs,
                ...this.bulkProcessingStore.fileHeaders,
            ];
        },
    },
});
</script>
