<template>
    <div v-show="showPromoBox" class="promo-box-container mb-10">
        <div class="promo-box">
            <div
                class="promo-box-content d-flex justify-content-center fs-2 p-5"
            >
                <div class="d-flex flex-column flex-center">
                    <div class="d-flex justify-content-center">
                        <span class="d-flex align-items-center fw-bolder">{{
                            __('60% Easter Saving Promotion!')
                        }}</span>
                        <span
                            class="d-flex d-none d-sm-flex align-items-center fw-light fs-3"
                            >&nbsp;|
                            {{ __('A special offer for new customers') }}</span
                        >
                    </div>
                    <div class="d-flex flex-row fw-bolder mb-0">
                        <div class="countdown-text d-flex">
                            {{ __('Ends in') }}
                        </div>
                        <div class="countdown d-flex flex-row">
                            <span v-if="days != 0" class="days"
                                >&nbsp;{{ days }}d</span
                            >
                            <span v-if="hours != 0" class="hours"
                                >&nbsp;{{ hours }}h</span
                            >
                            <span class="minutes">&nbsp;{{ minutes }}m</span>
                            <span class="seconds">&nbsp;{{ seconds }}s</span>...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';

export default {
    setup() {
        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            userStore,
            teamStore,
            subscriptionStore,
        };
    },

    data() {
        return {
            eventTime: moment().add(6, 'hours').unix(),
            currentTime: moment().unix(),
            diffTime: 0,
            duration: 0,
            interval: 1000,
            closed: false,
        };
    },

    mounted() {
        this.userStore.setUser(this.$page.props.user);
        this.subscriptionStore.setPlans(this.getPlans() || {});

        if (this.subscriptionStore.hasSubscription) {
            this.closed = true;
        }

        if (this.getCookie('promo')) {
            this.eventTime = this.getCookie('promo');
        }

        this.diffTime = this.eventTime - this.currentTime;
        this.duration = moment.duration(this.diffTime * 1000, 'milliseconds');

        // if time to countdown
        if (this.diffTime > 0) {
            let vm = this;

            setInterval(function () {
                vm.duration = moment.duration(
                    vm.duration.asMilliseconds() - vm.interval,
                    'milliseconds'
                );
                emitter.emit('update-promo-duration', {
                    duration: vm.duration,
                });
            }, this.interval);
        }
    },
    methods: {
        getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + '=');
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(';', c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return null;
        },
    },

    computed: {
        days() {
            return moment.duration(this.duration).days();
        },
        hours() {
            return moment.duration(this.duration).hours();
        },
        minutes() {
            return moment.duration(this.duration).minutes();
        },
        seconds() {
            return moment.duration(this.duration).seconds();
        },
        showPromoBox() {
            return this.duration > 0 && !this.closed;
        },
    },
};
</script>

<style scoped></style>
