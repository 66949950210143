<template>
    <div class="card card-flush card-bordered pt-3 mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div class="card-header">
            <!--begin::Card title-->
            <div class="card-title">
                <h2 class="fw-bolder">{{ __('Team Details') }}</h2>
            </div>
            <!--begin::Card title-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-3">
            <!--begin::Section-->
            <div class="mb-10">
                <!--begin::Details-->
                <div class="d-flex flex-wrap py-5">
                    <!--begin::Row-->
                    <div class="flex-equal me-5">
                        <!--begin::Details-->
                        <table
                            class="bootstrap-table fs-6 fw-bold gs-0 gy-2 gx-2 m-0"
                            aria-label="teamDetails"
                        >
                            <!--begin::Row-->
                            <tbody>
                                <!--begin::Row-->
                                <tr>
                                    <td class="text-gray-400">
                                        {{ __('Team name') }}:
                                    </td>
                                    <td class="text-gray-800">
                                        {{ user?.current_team?.name }}
                                    </td>
                                </tr>
                                <!--end::Row-->
                                <!--begin::Row-->
                                <tr>
                                    <td
                                        class="text-gray-400 min-w-175px w-175px"
                                    >
                                        {{ __('Occupied seats') }}:
                                    </td>
                                    <td class="text-gray-800 min-w-200px">
                                        <div
                                            class="text-gray-800 text-hover-primary"
                                        >
                                            {{ occupiedSeats }}
                                        </div>
                                    </td>
                                </tr>
                                <!--end::Row-->
                                <!--begin::Row-->
                                <tr>
                                    <td
                                        class="text-gray-400 min-w-175px w-175px"
                                    >
                                        {{ __('Pending invitiations') }}:
                                    </td>
                                    <td class="text-gray-800 min-w-200px">
                                        <div
                                            class="text-gray-800 text-hover-primary"
                                        >
                                            {{ pendingInvitations }}
                                        </div>
                                    </td>
                                </tr>
                                <!--end::Row-->
                                <!--begin::Row-->
                                <tr>
                                    <td
                                        class="text-gray-400 min-w-175px w-175px"
                                    >
                                        {{ __('Available Seats') }}:
                                    </td>
                                    <td class="text-gray-800 min-w-200px">
                                        <div
                                            class="text-gray-800 text-hover-primary"
                                        >
                                            {{ availableSeats }} /
                                            {{ totalSeats }}
                                        </div>
                                    </td>
                                </tr>
                                <!--end::Row-->
                                <!--begin::Row-->
                                <tr>
                                    <td class="text-gray-400">
                                        {{ __('Added members') }}:
                                    </td>
                                    <td class="text-gray-800">
                                        {{ teamMemberEmails }}
                                    </td>
                                </tr>
                                <!--end::Row-->
                            </tbody>
                        </table>
                        <!--end::Details-->
                        <div class="flex text-end mt-10">
                            <button
                                v-if="isAbleToDecrementSeats"
                                class="btn btn-danger me-10"
                                @click="toggleConfirmationModal(true)"
                                :disabled="isRemoving"
                            >
                                <ButtonLoading
                                    v-if="isRemoving"
                                ></ButtonLoading>
                                <span v-else> {{ __('Remove a seat') }}</span>
                            </button>
                            <button
                                class="btn btn-primary me-10"
                                @click="buySeats"
                                :disabled="isBuying"
                            >
                                <ButtonLoading v-if="isBuying"></ButtonLoading>
                                <span v-else>{{ __('Buy seats') }}</span>
                            </button>
                            <Link
                                v-if="canAddTeamMembers"
                                :href="`/teams/${user?.current_team?.id}`"
                                class="btn btn-primary"
                            >
                                {{ __('Add members') }}
                            </Link>
                        </div>
                    </div>
                    <!--end::Row-->
                </div>
                <!--end::Row-->
            </div>
            <!--end::Section-->
        </div>
        <!--end::Card body-->
    </div>
    <ConfirmationModal
        :showModal="confirmationModalSettings.show_modal"
        :title="confirmationModalSettings.title"
        :description="confirmationModalSettings.description"
        :buttonLabels="confirmationModalSettings.button_labels"
        @close-modal="toggleConfirmationModal(false)"
        @confirmed="decrementSeat"
    >
    </ConfirmationModal>
</template>

<script>
import { useToast } from 'vue-toastification';
import {router as Inertia} from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import ConfirmationModal from '@/Modals/ConfirmationModal.vue';
import ButtonLoading from '@/Components/Loading/ButtonLoading.vue';
export default {
    props: {
        user: Object,
        plan: Object,
    },
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        Link,
        ConfirmationModal,
        ButtonLoading,
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    data() {
        return {
            isBuying: false,
            isRemoving: false,
            teamId: this.user.current_team_id,
            seatPurchaseResponse: {},
            decrementSeatType: 'extra_seats',
            confirmationModalSettings: {
                show_modal: false,
                title: this.__('Are you sure?'),
                description: this.__('Do you really want to remove the seat?'),
                button_labels: {
                    cancel: this.__('Cancel'),
                    confirm: this.__('Confirm'),
                },
            },
        };
    },
    methods: {
        buySeats() {
            let vm = this;
            this.isBuying = true;

            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .post(route('team.purchase-team-seat', this.teamId))
                .then(() => {
                    Inertia.reload({ only: ['user'] });
                    vm.toast.success(vm.__('Seat purchased successfully'));
                })
                .catch((error) => {
                    vm.toast.error(
                        error?.response?.data?.error ||
                            vm.__(
                                'Something went wrong. Please try again later'
                            )
                    );
                })
                .then(() => {
                    vm.isBuying = false;
                });
        },
        toggleConfirmationModal(show) {
            this.confirmationModalSettings.show_modal = show;
        },
        decrementSeat() {
            let vm = this;
            let payload = {
                type: this.decrementSeatType,
                amount: -1,
            };
            this.isRemoving = true;

            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .put(route('team.update-member-count', this.teamId), payload)
                .then(() => {
                    vm.toast.success(vm.__('Seat decremented'));
                    Inertia.reload({ only: ['user'] });
                })
                .catch((error) => {
                    let errorMessage =
                        error.response.data?.message ||
                        vm.__('Some error occured');

                    if (422 == error.response.status) {
                        let errorObject = error.response.data.errors;
                        let firstKey = Object.keys(errorObject)[0];
                        errorMessage = errorObject[firstKey][0];
                    }

                    vm.toast.error(errorMessage);
                    Inertia.reload({ only: ['user'] });
                })
                .then(() => {
                    vm.toggleConfirmationModal(false);
                    this.isRemoving = false;
                });
        },
    },
    computed: {
        maxMembers() {
            return this.user?.current_team?.max_members || 0;
        },
        extraSeats() {
            return this.user?.current_team?.extra_seats || 0;
        },
        totalSeats() {
            return this.maxMembers + this.extraSeats;
        },
        pendingInvitations() {
            return this.user.current_team?.team_invitations?.length || 0;
        },
        occupiedSeats() {
            return this.user.team_members?.length || 0;
        },
        availableSeats() {
            return this.occupiedSeats + this.pendingInvitations;
        },
        hasBasicPlan() {
            return this.user.current_team.subscriptions.length > 0
                ? this.getPlanIds('basic').includes(
                      this.user.current_team.subscriptions[0].paddle_plan.toString()
                  )
                : false;
        },
        hasStandardPlan() {
            return (
                (this.user.current_team.subscriptions.length > 0
                    ? this.getPlanIds('standard').includes(
                          this.user.current_team.subscriptions[0].paddle_plan.toString()
                      )
                    : false) || this.hasStandardPlan
            );
        },
        hasProfessionalPlan() {
            return (
                (this.user.current_team.subscriptions.length > 0
                    ? this.getPlanIds('professional').includes(
                          this.user.current_team.subscriptions[0].paddle_plan.toString()
                      )
                    : false) || this.hasProfessionalPlan
            );
        },
        teamMemberEmails() {
            let emails =
                this.user.team_members?.map((member) => member.email) || [];
            return emails.join(', ');
        },
        canAddTeamMembers() {
            return this.totalSeats > this.availableSeats;
        },
        isAbleToDecrementSeats() {
            return this.extraSeats > 0;
        },
    },
};
</script>
