<template>
    <div class="w-100">
        <!--begin::Heading-->

        <ul
            class="nav nav-tabs article-title-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5"
        >
            <li
                class="nav-item"
            >
                <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_1"
                >
                    <h2 class="mb-0">{{ __('Parsed URL Title') }}</h2>
                </a>
            </li>
        </ul>

        <div class="tab-content" id="myTabContent">
            <div
                class="tab-pane active show fade"
                id="kt_tab_pane_1"
                role="tabpanel"
            >
                <div>
                    <!--begin::Input group-->
                    <div
                        class="fv-row mb-15 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        data-kt-buttons="true"
                        data-kt-initialized="1"
                    >
                        <div class="d-flex flex-column select-item-box">
                            <draggable
                                v-model="parsedUrlTitles"
                                :group="{
                                    name: 'titles',
                                    pull: 'clone',
                                    put: false,
                                }"
                                filter=".badge"
                                @start="onDragStart"
                                @end="onDragStop"
                                :clone="clone"
                                class="d-flex flex-column select-item-box"
                            >
                                <div
                                    class="cursor-pointer py-1"
                                    v-for="(
                                        title, index
                                    ) in parsedUrlTitles"
                                    :key="title?.h"
                                    @click.stop="onTitleSelected(title)"
                                    @mouseover="showRephraseButton(index)"
                                    @mouseleave="hideRephraseButton(index)"
                                >
                                    <div
                                        class="d-flex align-items-center justify-content-between"
                                    >
                                        <div
                                            class="h1-element d-flex align-items-center ms-0"
                                        >
                                            <div
                                                class="d-flex align-items-baseline me-1"
                                            >
                                                <span
                                                    class="arrow-icon text-muted bi bi-grip-vertical"
                                                ></span>
                                            </div>
                                            <div class="d-flex flex-1">
                                                <div
                                                    class="select-item-content choice-text py-1 fs-4 w-100"
                                                    v-html="title?.h"
                                                ></div>
                                            </div>
                                        </div>

                                        <button
                                            class="btn btn-sm btn-primary px-3 py-2"
                                            :class="{ disabled: loading }"
                                            v-show="
                                                isRephraseButtonVisible[index]
                                            "
                                            @click.stop="
                                                regenerateTitle(title?.h, index)
                                            "
                                        >
                                            <ALoading v-if="loading" />
                                            <span v-else>
                                                {{ __('Rephrase') }}
                                            </span>
                                        </button>
                                    </div>
                                    <div
                                        v-if="rephraseTitle[index]"
                                        class="regenerated-title ps-5 py-2"
                                        @click.stop="
                                            onRephraseTitleSelect(
                                                rephraseTitle[index]
                                            )
                                        "
                                    >
                                        {{ rephraseTitle[index] }}
                                    </div>
                                </div>
                            </draggable>
                        </div>
                    </div>
                    <!--end::Input group-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { useToast } from 'vue-toastification';
import ALoading from "@/Components/Atoms/ALoading.vue";

export default {
    setup() {
        const articleWizardStore = useArticleWizardStore();
        const toast = useToast();

        const onTitleSelected = (title) => {
            articleWizardStore.setArticleTitle(title.h);
        };

        return { articleWizardStore, toast, onTitleSelected };
    },

    components: {
        ALoading,
        draggable: VueDraggableNext,
    },

    props: {
        aiGeneratedTitles: Array,
        serpAnalysisTask: Object,
    },

    data() {
        return {
            serpTitles: [],
            loading: false,
            rephraseTitle: [],
            isRephraseButtonVisible: [],
        };
    },

    methods: {
        onDragStart() {
            this.articleWizardStore.setArticleTitleDragging(true);
        },

        onDragStop() {
            this.articleWizardStore.setArticleTitleDragging(false);
        },

        getOpeningRate(rate) {
            // Replace commas with periods and remove non-numeric characters
            const numericStr = rate.replace(',', '.').replace(/[^0-9.]/g, '');
            const parsedRate = parseFloat(numericStr);

            if (!isNaN(parsedRate)) {
                return Math.round(parsedRate);
            } else {
                return 0;
            }
        },

        clone({ h }) {
            return { h };
        },

        onTitleSelected(title) {
            this.$emit('article-title-selected', title.h);
            this.articleWizardStore.setArticleTitle(title.h);
        },

        showRephraseButton(index) {
            this.isRephraseButtonVisible[index] = true;
        },

        hideRephraseButton(index) {
            this.isRephraseButtonVisible[index] = false;
        },

        regenerateTitle(title, index) {
            if (!title) {
                return false;
            }

            let vm = this;
            this.loading = true;
            this.articleWizardStore.setLoading(true);

            let options = this.articleWizardStore.getOpenAiOption;

            let payload = {
                options: { ...options, ...{ outputs: 1 } },
                prompts: {
                    description: title,
                    name: '',
                },
                lang: this.articleWizardStore.getLanguage,
                formality: this.articleWizardStore.getFormality,
            };

            axios
                .post('/api/article/ai-assistant/regenerate-headline', payload)
                .then((response) => {
                    let repharsedTitleObject = JSON.parse(
                        response?.data?.choices[0]?.text
                    );
                    vm.rephraseTitle[index] = repharsedTitleObject[0]?.h;
                })
                .catch((error) => {
                    vm.toast.error(vm.__('Some error occurred'));
                })
                .then(() => {
                    vm.articleWizardStore.setLoading(false);
                    vm.loading = false;
                });
        },

        onRephraseTitleSelect(title) {
            this.articleWizardStore.setArticleTitle(title);
        },
    },

    computed: {
        parsedUrlTitles: {
            get() {
                return this.articleWizardStore.getParsedUrlTitles;
            },
            set(value) {
                this.articleWizardStore.setParsedUrlTitles(value);
            },
        },
    },
};
</script>

<style lang="scss">
.article-title-tabs {
    &.nav-line-tabs {
        &.nav-line-tabs-2x {
            .nav-item {
                flex: 1;

                .nav-link {
                    &.active,
                    &:hover,
                    &:active {
                        border-bottom: 2px solid;
                        border-color: #3699ff;
                    }
                }
            }
        }
    }
}

.select-item-box {
    width: 100%;

    li {
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 10px;

        &:hover,
        &:focus {
            background-color: rgba($color: #3699ff, $alpha: 0.3);
        }

        textarea {
            background: transparent;
            background-color: transparent;
            border: none;
        }
    }

    input {
        visibility: hidden;
        position: absolute;
        width: 100%;
    }

    .check {
        display: inline-block;
        transform: rotate(45deg);
        height: 24px;
        width: 12px;
        border-bottom: 7px solid #78b13f;
        border-right: 7px solid #78b13f;
    }

    label {
        width: 100%;
        position: absolute;
        height: 100%;

        &:after {
            content: '';
            height: 12px;
            width: 9px;
            border-bottom: 4px solid #78b13f;
            border-right: 4px solid #78b13f;
            position: absolute;
            opacity: 0;
            right: 20px;
            top: 3px;
            transform: rotate(45deg);
        }
    }

    input:checked + label {
        &:after {
            opacity: 1;
        }
    }
}

.sortable-drag {
    .control-elements,
    .badge,
    .arrow-icon {
        display: none;
    }
}
</style>
