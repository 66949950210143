<template>
    <Head title="Allgemeien Geschäftsbedingungen" />

    <div>
        <!--begin::Main-->
        <!--begin::Root-->
        <div class="d-flex flex-column flex-root">
            <!--begin::Header Section-->
            <div class="mb-0" id="home">
                <!--begin::Wrapper-->
                <div
                    class="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                    style="
                        background-image: url(/media/svg/illustrations/landing.svg);
                    "
                >
                    <!--begin::Header-->
                    <div
                        class="landing-header"
                        data-kt-sticky="true"
                        data-kt-sticky-name="landing-header"
                        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                    >
                        <!--begin::Container-->
                        <div class="container">
                            <!--begin::Wrapper-->
                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <!--begin::Logo-->
                                <div
                                    class="d-flex align-items-center flex-equal"
                                >
                                    <!--begin::Mobile menu toggle-->
                                    <button
                                        class="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                        id="kt_landing_menu_toggle"
                                    >
                                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                                        <span class="svg-icon svg-icon-2hx">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="black"
                                                />
                                                <path
                                                    opacity="0.3"
                                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                    </button>
                                    <!--end::Mobile menu toggle-->
                                    <!--begin::Logo image-->
                                    <a href="/">
                                        <ApplicationLogo
                                            mark-width="32"
                                            logo-text-classes="p-3 text-white"
                                        ></ApplicationLogo>
                                    </a>
                                    <!--end::Logo image-->
                                </div>
                                <!--end::Logo-->
                                <!--begin::Menu wrapper-->
                                <div
                                    class="d-lg-block"
                                    id="kt_header_nav_wrapper"
                                >
                                    <div
                                        class="d-lg-block p-5 p-lg-0"
                                        :class="menuClasses"
                                        data-kt-drawer="true"
                                        data-kt-drawer-name="landing-menu"
                                        data-kt-drawer-activate="{default: true, lg: false}"
                                        data-kt-drawer-overlay="true"
                                        data-kt-drawer-width="200px"
                                        data-kt-drawer-direction="start"
                                        data-kt-drawer-toggle="#kt_landing_menu_toggle"
                                        data-kt-swapper="true"
                                        data-kt-swapper-mode="prepend"
                                        data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                                    >
                                        <!--begin::Menu-->
                                        <div
                                            class="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold"
                                            id="kt_landing_menu"
                                        >
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link active py-3 px-4 px-xxl-6"
                                                    href="/de/#kt_body"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Home</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="/de/#how-it-works"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Wie es funktioniert</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="/de/#how-we-help"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Wie wir helfen</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="/de/#team"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Team</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item">
                                                <!--begin::Menu link-->
                                                <a
                                                    class="menu-link nav-link py-3 px-4 px-xxl-6"
                                                    href="/de/#pricing"
                                                    data-kt-scroll-toggle="true"
                                                    data-kt-drawer-dismiss="true"
                                                    >Preis</a
                                                >
                                                <!--end::Menu link-->
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                        <!--end::Menu-->
                                    </div>
                                </div>
                                <!--end::Menu wrapper-->
                                <!--begin::Toolbar-->
                                <div class="flex-equal text-end ms-1">
                                    <Link
                                        :href="route('dashboard')"
                                        class="btn btn-success"
                                    >
                                        Sign In
                                    </Link>
                                </div>
                                <!--end::Toolbar-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Container-->
                    </div>
                    <!--end::Header-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Curve bottom-->
                <div class="landing-curve landing-dark-color mb-10 mb-lg-20">
                    <svg
                        viewBox="15 12 1470 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
                <!--end::Curve bottom-->
            </div>
            <!--end::Header Section-->
            <!--begin::How It Works Section-->
            <div class="mb-n10 mb-lg-n20 z-index-2">
                <!--begin::Container-->
                <div class="container">
                    <!--begin::Heading-->
                    <div class="text-center mb-17">
                        <!--begin::Title-->
                        <h3
                            class="fs-2hx text-dark mb-5"
                            id="how-it-works"
                            data-kt-scroll-offset="{default: 100, lg: 150}"
                        >
                            Allgemeine Geschäftsbedingungen
                        </h3>
                        <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Row-->
                    <div class="row w-100 gy-10 mb-md-20">
                        <!--begin::Col-->
                        <div class="offset-md-2 col-md-8 px-5">
                            <!--begin::Story-->
                            <div class="mb-10 mb-md-0">
                                <!--begin::Heading-->
                                <div class="mb-5">
                                    <p>Willkommen bei Creaitor.ai!</p>
                                    <p>
                                        Diese Allgemeinen Geschäftsbedingungen
                                        beschreiben die Regeln und Vorschriften
                                        für die Nutzung der Website der Funk
                                        Solutions GmbH, die unter
                                        https://www.creaitor.ai/ zu finden ist.
                                    </p>
                                    <p>
                                        Durch den Zugriff auf diese Website
                                        gehen wir davon aus, dass Sie diese
                                        Bedingungen akzeptieren. Verwenden Sie
                                        Creaitor.ai nicht weiter, wenn Sie nicht
                                        damit einverstanden sind, alle auf
                                        dieser Seite aufgeführten Bedingungen zu
                                        akzeptieren.
                                    </p>
                                    <p>
                                        Die folgende Terminologie gilt für diese
                                        Allgemeinen Geschäftsbedingungen, die
                                        Datenschutzerklärung und den
                                        Haftungsausschluss sowie alle
                                        Vereinbarungen: „Kunde“, „Sie“ und „Ihr“
                                        bezieht sich auf Sie, die Person, die
                                        sich auf dieser Website anmeldet und die
                                        den Allgemeinen Geschäftsbedingungen des
                                        Unternehmens entspricht. „Das
                                        Unternehmen“, „uns“, „wir“, „unser“ und
                                        „uns“ beziehen sich auf unser
                                        Unternehmen. „Partei“, „Parteien“ oder
                                        „uns“ bezieht sich sowohl auf den Kunden
                                        als auch auf uns selbst. Alle
                                        Bedingungen beziehen sich auf das
                                        Angebot, die Annahme und die
                                        Berücksichtigung der Zahlung, die
                                        erforderlich sind, um den Prozess
                                        unserer Unterstützung des Kunden auf die
                                        am besten geeignete Weise durchzuführen,
                                        um ausdrücklich die Bedürfnisse des
                                        Kunden in Bezug auf die Erbringung der
                                        angegebenen Dienstleistungen des
                                        Unternehmens zu erfüllen, in
                                        Übereinstimmung mit und unterliegen dem
                                        geltenden Recht der Niederlande.
                                    </p>
                                    <h3><strong>Cookies</strong></h3>
                                    <p>
                                        Wir verwenden Cookies. Durch den Zugriff
                                        auf Creaitor.ai haben Sie der Verwendung
                                        von Cookies in Übereinstimmung mit der
                                        Datenschutzrichtlinie der Funk Solutions
                                        GmbH zugestimmt.
                                    </p>
                                    <p>
                                        Die meisten interaktiven Websites
                                        verwenden Cookies, damit wir die
                                        Benutzerdaten bei jedem Besuch abrufen
                                        können. Cookies werden von unserer
                                        Website verwendet, um die Funktionalität
                                        bestimmter Bereiche zu ermöglichen und
                                        den Besuchern unserer Website den Besuch
                                        zu erleichtern. Einige unserer
                                        Affiliate-/Werbepartner können ebenfalls
                                        Cookies verwenden.
                                    </p>
                                    <h3><strong>Lizenz</strong></h3>
                                    <p>
                                        Sofern nicht anders angegeben, besitzen
                                        die Funk Solutions GmbH und/oder ihre
                                        Lizenzgeber die geistigen
                                        Eigentumsrechte für alle Materialien auf
                                        Creaitor.ai. Alle geistigen
                                        Eigentumsrechte sind vorbehalten. Sie
                                        können von Creaitor.ai aus für Ihren
                                        eigenen persönlichen Gebrauch darauf
                                        zugreifen, vorbehaltlich der in diesen
                                        Geschäftsbedingungen festgelegten
                                        Einschränkungen.
                                    </p>
                                    <p>Du darfst nicht:</p>
                                    <ul>
                                        <li>
                                            Material von Creaitor.ai
                                            wiederveröffentlichen
                                        </li>
                                        <li>
                                            Verkauf, Vermietung oder
                                            Unterlizenzierung von Material von
                                            Creaitor.ai
                                        </li>
                                        <li>
                                            Material von Creaitor.ai zu
                                            reproduzieren, zu duplizieren oder
                                            zu kopieren
                                        </li>
                                        <li>
                                            Inhalte von Creaitor.ai
                                            weiterverteilen
                                        </li>
                                    </ul>
                                    <p>
                                        Teile dieser Website bieten Benutzern
                                        die Möglichkeit, Meinungen und
                                        Informationen in bestimmten Bereichen
                                        der Website zu veröffentlichen und
                                        auszutauschen. Die Funk Solutions GmbH
                                        filtert, bearbeitet, veröffentlicht oder
                                        prüft Kommentare nicht, bevor sie auf
                                        der Website erscheinen. Kommentare
                                        spiegeln nicht die Ansichten und
                                        Meinungen der Funk Solutions GmbH, ihrer
                                        Vertreter und/oder verbundenen
                                        Unternehmen wider. Kommentare spiegeln
                                        die Ansichten und Meinungen der Person
                                        wider, die ihre Ansichten und Meinungen
                                        veröffentlicht. Soweit nach geltendem
                                        Recht zulässig, haftet die Funk
                                        Solutions GmbH nicht für die Kommentare
                                        oder für jegliche Haftung, Schäden oder
                                        Kosten, die durch die Nutzung und/oder
                                        das Posten und/oder das Erscheinen der
                                        Kommentare verursacht und/oder erlitten
                                        werden auf dieser Webseite.
                                    </p>
                                    <p>
                                        Die Funk Solutions GmbH behält sich das
                                        Recht vor, alle Kommentare zu überwachen
                                        und Kommentare zu entfernen, die als
                                        unangemessen oder beleidigend angesehen
                                        werden oder gegen diese Allgemeinen
                                        Geschäftsbedingungen verstoßen.
                                    </p>
                                    <p>Sie garantieren und versichern, dass:</p>
                                    <ul>
                                        <li>
                                            Sie sind berechtigt, die Kommentare
                                            auf unserer Website zu
                                            veröffentlichen und verfügen über
                                            alle erforderlichen Lizenzen und
                                            Zustimmungen dazu;
                                        </li>
                                        <li>
                                            Die Kommentare verletzen keine
                                            geistigen Eigentumsrechte,
                                            einschließlich, aber nicht
                                            beschränkt auf Urheberrechte,
                                            Patente oder Marken von Dritten;
                                        </li>
                                        <li>
                                            Die Kommentare enthalten kein
                                            diffamierendes, verleumderisches,
                                            beleidigendes, unanständiges oder
                                            anderweitig rechtswidriges Material,
                                            das eine Verletzung der Privatsphäre
                                            darstellt
                                        </li>
                                        <li>
                                            Die Kommentare werden nicht
                                            verwendet, um Geschäfte oder
                                            Gewohnheiten zu erbitten oder zu
                                            fördern oder kommerzielle
                                            Aktivitäten oder rechtswidrige
                                            Aktivitäten zu präsentieren.
                                        </li>
                                    </ul>
                                    <p>
                                        Sie gewähren der Funk Solutions GmbH
                                        hiermit eine nicht-exklusive Lizenz zur
                                        Nutzung, Vervielfältigung, Bearbeitung
                                        und autorisieren andere zur Nutzung,
                                        Vervielfältigung und Bearbeitung Ihrer
                                        Kommentare in allen Formen, Formaten
                                        oder Medien.
                                    </p>
                                    <div class="post-text w-richtext">
                                        <h3>Bestellungen für Waren aufgeben</h3>
                                        <p>
                                            Indem Sie eine Warenbestellung über
                                            den Service aufgeben, garantieren
                                            Sie, dass Sie rechtlich in der Lage
                                            sind, verbindliche Verträge
                                            einzugehen.
                                        </p>
                                        <p>
                                            <strong
                                                ><h3>
                                                    Ihre Informationen
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            Wenn Sie eine Bestellung für Waren
                                            aufgeben möchten, die über den
                                            Service verfügbar sind, werden Sie
                                            möglicherweise aufgefordert,
                                            bestimmte Informationen anzugeben,
                                            die für Ihre Bestellung relevant
                                            sind, einschließlich, aber nicht
                                            beschränkt auf Ihren Namen, Ihre
                                            E-Mail-Adresse, Ihre Telefonnummer,
                                            Ihre Kreditkartennummer, das
                                            Ablaufdatum von Ihre Kreditkarte,
                                            Ihre Rechnungsadresse und Ihre
                                            Versandinformationen.
                                        </p>
                                        <p>
                                            Sie versichern und garantieren,
                                            dass: (i) Sie das gesetzliche Recht
                                            haben, Kredit- oder Debitkarten oder
                                            andere Zahlungsmethoden in
                                            Verbindung mit einer Bestellung zu
                                            verwenden; und dass (ii) die
                                            Informationen, die Sie uns zur
                                            Verfügung stellen, wahr, korrekt und
                                            vollständig sind.
                                        </p>
                                        <p>
                                            Indem Sie solche Informationen
                                            übermitteln, gewähren Sie uns das
                                            Recht, die Informationen an
                                            zahlungsverarbeitende Dritte
                                            weiterzugeben, um den Abschluss
                                            Ihrer Bestellung zu erleichtern.
                                        </p>
                                        <p>
                                            <strong>
                                                <h3>
                                                    Stornierung der Bestellung
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            Wir behalten uns das Recht vor, Ihre
                                            Bestellung jederzeit aus bestimmten
                                            Gründen abzulehnen oder zu
                                            stornieren, einschließlich, aber
                                            nicht beschränkt auf:
                                        </p>
                                        <ul role="list">
                                            <li>Warenverfügbarkeit</li>
                                            <li>
                                                Fehler in der Beschreibung oder
                                                den Preisen für Waren
                                            </li>
                                            <li>Fehler in Ihrer Bestellung</li>
                                        </ul>
                                        <p>
                                            Wir behalten uns das Recht vor, Ihre
                                            Bestellung abzulehnen oder zu
                                            stornieren, wenn Betrug oder eine
                                            nicht autorisierte oder illegale
                                            Transaktion vermutet wird.
                                        </p>
                                        <p>
                                            <strong>
                                                <h3>
                                                    Ihre Widerrufsrechte für
                                                    Ihre Bestellung
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            Alle von Ihnen gekauften Waren
                                            können nur in Übereinstimmung mit
                                            diesen Allgemeinen
                                            Geschäftsbedingungen und unseren
                                            Rückgaberichtlinien zurückgegeben
                                            werden.
                                        </p>
                                        <p>
                                            Unsere Rückgaberichtlinie ist
                                            Bestandteil dieser Allgemeinen
                                            Geschäftsbedingungen. Bitte lesen
                                            Sie unsere Rückgaberichtlinie, um
                                            mehr über Ihr Recht auf Stornierung
                                            Ihrer Bestellung zu erfahren.
                                        </p>
                                        <p>
                                            Ihr Recht, eine Bestellung zu
                                            stornieren, gilt nur für Waren, die
                                            in demselben Zustand zurückgegeben
                                            werden, in dem Sie sie erhalten
                                            haben. Sie sollten auch alle
                                            Produktanweisungen, Dokumente und
                                            Verpackungen beifügen. Waren, die
                                            beschädigt oder nicht in demselben
                                            Zustand sind, in dem Sie sie
                                            erhalten haben, oder die nur über
                                            das Öffnen der Originalverpackung
                                            hinaus abgenutzt sind, werden nicht
                                            erstattet. Sie sollten die gekauften
                                            Waren daher mit angemessener
                                            Sorgfalt behandeln, solange sie sich
                                            in Ihrem Besitz befinden.
                                        </p>
                                        <p>
                                            Wir erstatten Ihnen spätestens 14
                                            Tage ab dem Tag, an dem wir die
                                            zurückgesandte Ware erhalten, den
                                            Betrag zurück. Wir verwenden
                                            dasselbe Zahlungsmittel, das Sie für
                                            die Bestellung verwendet haben, und
                                            Ihnen entstehen keine Gebühren für
                                            eine solche Rückerstattung.
                                        </p>
                                        <p>
                                            Sie haben kein Recht, eine
                                            Bestellung für die Lieferung einer
                                            der folgenden Waren zu stornieren:
                                        </p>
                                        <ul role="list">
                                            <li>
                                                Die Lieferung von Waren, die
                                                nach Ihren Vorgaben gefertigt
                                                oder eindeutig personalisiert
                                                sind.
                                            </li>
                                            <li>
                                                Zur Lieferung von Waren, die
                                                aufgrund ihrer Beschaffenheit
                                                nicht für eine Rücksendung
                                                geeignet sind, schnell verderben
                                                oder das Verfallsdatum
                                                überschritten ist.
                                            </li>
                                            <li>
                                                Die Lieferung von Waren, die aus
                                                Gründen des Gesundheitsschutzes
                                                oder der Hygiene nicht zur
                                                Rückgabe geeignet sind und nach
                                                der Lieferung entsiegelt wurden.
                                            </li>
                                            <li>
                                                Die Lieferung von Waren, die
                                                nach der Lieferung ihrer
                                                Beschaffenheit nach untrennbar
                                                mit anderen Gegenständen
                                                vermischt wurden.
                                            </li>
                                            <li>
                                                Die Lieferung von digitalen
                                                Inhalten, die nicht auf einem
                                                materiellen Datenträger
                                                geliefert werden, wenn die
                                                Ausführung mit Ihrer vorherigen
                                                ausdrücklichen Zustimmung
                                                begonnen hat und Sie Ihr
                                                Widerrufsrecht anerkannt haben.
                                            </li>
                                        </ul>
                                        <p>
                                            <strong>
                                                <h3>
                                                    Verfügbarkeit, Fehler und
                                                    Ungenauigkeiten
                                                </h3></strong
                                            >
                                        </p>
                                        <p>
                                            Wir aktualisieren ständig unsere
                                            Warenangebote im Service. Die auf
                                            unserem Service verfügbaren Waren
                                            können falsch bewertet, ungenau
                                            beschrieben oder nicht verfügbar
                                            sein, und es kann zu Verzögerungen
                                            bei der Aktualisierung von
                                            Informationen zu unseren Waren auf
                                            dem Service und in unserer Werbung
                                            auf anderen Websites kommen.
                                        </p>
                                        <p>
                                            Wir können und werden die
                                            Genauigkeit oder Vollständigkeit von
                                            Informationen, einschließlich
                                            Preisen, Produktbildern,
                                            Spezifikationen, Verfügbarkeit und
                                            Dienstleistungen, nicht garantieren.
                                            Wir behalten uns das Recht vor,
                                            Informationen zu ändern oder zu
                                            aktualisieren und Fehler,
                                            Ungenauigkeiten oder Auslassungen
                                            jederzeit ohne vorherige Ankündigung
                                            zu korrigieren.
                                        </p>
                                        <p>
                                            <strong>
                                                <h3>Preispolitik</h3></strong
                                            >
                                        </p>
                                        <p>
                                            Das Unternehmen behält sich das
                                            Recht vor, seine Preise jederzeit
                                            vor Annahme einer Bestellung zu
                                            ändern.
                                        </p>
                                        <p>
                                            Die angegebenen Preise können vom
                                            Unternehmen nach der Annahme einer
                                            Bestellung im Falle eines
                                            Ereignisses geändert werden, das die
                                            Lieferung aufgrund von staatlichen
                                            Maßnahmen, Schwankungen der Zölle,
                                            erhöhten Versandkosten, höheren
                                            Devisenkosten und anderen
                                            Angelegenheiten, die außerhalb der
                                            Kontrolle des Unternehmens liegen,
                                            beeinträchtigt . In diesem Fall
                                            haben Sie das Recht, Ihre Bestellung
                                            zu stornieren.
                                        </p>
                                        <p>
                                            <strong> <h3>Zahlungen</h3></strong>
                                        </p>
                                        <p>
                                            Alle gekauften Waren unterliegen
                                            einer einmaligen Zahlung. Die
                                            Zahlung kann über verschiedene
                                            Zahlungsmethoden erfolgen, die wir
                                            zur Verfügung stellen, wie Visa,
                                            MasterCard, Affinity Card, American
                                            Express-Karten oder
                                            Online-Zahlungsmethoden (z. B.
                                            PayPal).
                                        </p>
                                        <p>
                                            Zahlungskarten (Kreditkarten oder
                                            Debitkarten) unterliegen
                                            Gültigkeitsprüfungen und
                                            Autorisierung durch Ihren
                                            Kartenaussteller. Wenn wir die
                                            erforderliche Autorisierung nicht
                                            erhalten, haften wir nicht für
                                            Verzögerungen oder Nichtlieferungen
                                            Ihrer Bestellung.
                                        </p>
                                        <h3>
                                            <strong
                                                ><h3>Abonnements</h3></strong
                                            >
                                        </h3>
                                        <p>
                                            <strong> Abonnementzeitraum</strong>
                                        </p>
                                        <p>
                                            Der Dienst oder einige Teile des
                                            Dienstes sind nur mit einem
                                            kostenpflichtigen Abonnement
                                            verfügbar. Abhängig von der Art des
                                            Abonnementplans, den Sie beim Kauf
                                            des Abonnements auswählen, werden
                                            Ihnen wiederkehrende und regelmäßige
                                            (z. B. täglich, wöchentlich,
                                            monatlich oder jährlich) im Voraus
                                            in Rechnung gestellt.
                                        </p>
                                        <p>
                                            Am Ende jedes Zeitraums verlängert
                                            sich Ihr Abonnement automatisch
                                            unter genau denselben Bedingungen,
                                            es sei denn, Sie kündigen es oder
                                            das Unternehmen kündigt es.
                                        </p>
                                        <p>
                                            <strong>
                                                Abonnementkündigungen</strong
                                            >
                                        </p>
                                        <p>
                                            Sie können Ihre
                                            Abonnementverlängerung entweder über
                                            Ihre Kontoeinstellungsseite oder
                                            durch Kontaktaufnahme mit dem
                                            Unternehmen kündigen. Sie erhalten
                                            keine Rückerstattung für die
                                            Gebühren, die Sie bereits für Ihren
                                            aktuellen Abonnementzeitraum bezahlt
                                            haben, und Sie können bis zum Ende
                                            Ihres aktuellen Abonnementzeitraums
                                            auf den Dienst zugreifen.
                                        </p>
                                        <h5><strong>Abrechnung</strong></h5>
                                        <p>
                                            Sie müssen dem Unternehmen genaue
                                            und vollständige
                                            Rechnungsinformationen zur Verfügung
                                            stellen, einschließlich des
                                            vollständigen Namens, der Adresse,
                                            des Bundesstaates, der Postleitzahl,
                                            der Telefonnummer und einer gültigen
                                            Zahlungsmethode.
                                        </p>
                                        <p>
                                            Sollte die automatische
                                            Rechnungsstellung aus irgendeinem
                                            Grund nicht erfolgen, stellt das
                                            Unternehmen eine elektronische
                                            Rechnung aus, aus der hervorgeht,
                                            dass Sie innerhalb einer bestimmten
                                            Frist manuell mit der vollständigen
                                            Zahlung fortfahren müssen, die dem
                                            auf der Rechnung angegebenen
                                            Abrechnungszeitraum entspricht.
                                        </p>
                                        <p>
                                            <strong> Gebührenänderungen</strong>
                                        </p>
                                        <p>
                                            Das Unternehmen kann die
                                            Abonnementgebühren nach eigenem
                                            Ermessen jederzeit ändern. Jede
                                            Änderung der Abonnementgebühr wird
                                            am Ende des jeweils aktuellen
                                            Abonnementzeitraums wirksam.
                                        </p>
                                        <p>
                                            Das Unternehmen wird Sie mit
                                            angemessener Frist im Voraus über
                                            Änderungen der Abonnementgebühren
                                            informieren, um Ihnen die
                                            Möglichkeit zu geben, Ihr Abonnement
                                            zu kündigen, bevor eine solche
                                            Änderung wirksam wird.
                                        </p>
                                        <p>
                                            Ihre fortgesetzte Nutzung des
                                            Dienstes nach Inkrafttreten der
                                            Änderung der Abonnementgebühr stellt
                                            Ihre Zustimmung zur Zahlung des
                                            geänderten Betrags der
                                            Abonnementgebühr dar.
                                        </p>
                                        <p>
                                            <strong> Rückerstattungen</strong>
                                        </p>
                                        <p>
                                            Sofern nicht gesetzlich
                                            vorgeschrieben, sind bezahlte
                                            Abonnementgebühren nicht
                                            erstattungsfähig.
                                        </p>
                                        <p>
                                            Bestimmte Rückerstattungsanträge für
                                            Abonnements können vom Unternehmen
                                            von Fall zu Fall geprüft und nach
                                            alleinigem Ermessen des Unternehmens
                                            gewährt werden.
                                        </p>
                                        <h5>
                                            <strong
                                                >Kostenlose Testphase</strong
                                            >
                                        </h5>
                                        <p>
                                            Das Unternehmen kann nach eigenem
                                            Ermessen ein Abonnement mit einer
                                            kostenlosen Testversion für einen
                                            begrenzten Zeitraum anbieten.
                                        </p>
                                        <p>
                                            Sie müssen Ihre
                                            Rechnungsinformationen nicht
                                            eingeben, um sich für die kostenlose
                                            Testversion anzumelden.
                                        </p>
                                        <p>
                                            Das Unternehmen behält sich das
                                            Recht vor, jederzeit und ohne
                                            Vorankündigung (i) die Bedingungen
                                            des kostenlosen Testangebots zu
                                            ändern oder (ii) dieses kostenlose
                                            Testangebot zu stornieren.
                                        </p>
                                        <p>
                                            <strong
                                                ><em> Werbeaktionen</em></strong
                                            >
                                        </p>
                                        <p>
                                            Alle Werbeaktionen, die über den
                                            Dienst verfügbar gemacht werden,
                                            können von Regeln geregelt werden,
                                            die von diesen Bedingungen getrennt
                                            sind.
                                        </p>
                                        <p>
                                            Wenn Sie an Werbeaktionen
                                            teilnehmen, lesen Sie bitte die
                                            geltenden Regeln sowie unsere
                                            Datenschutzrichtlinie. Wenn die
                                            Regeln für eine Aktion diesen
                                            Bedingungen widersprechen, gelten
                                            die Regeln der Aktion.
                                        </p>
                                        <p>
                                            <strong
                                                ><em>
                                                    Benutzerkonten</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            Wenn Sie ein Konto bei uns
                                            erstellen, müssen Sie uns jederzeit
                                            genaue, vollständige und aktuelle
                                            Informationen zur Verfügung stellen.
                                            Andernfalls stellt dies einen
                                            Verstoß gegen die Bedingungen dar,
                                            der zur sofortigen Kündigung Ihres
                                            Kontos bei unserem Service führen
                                            kann.
                                        </p>
                                        <p>
                                            Sie sind dafür verantwortlich, das
                                            Passwort, das Sie für den Zugriff
                                            auf den Dienst verwenden, und für
                                            alle Aktivitäten oder Aktionen unter
                                            Ihrem Passwort zu schützen,
                                            unabhängig davon, ob Ihr Passwort
                                            bei unserem Dienst oder einem
                                            Social-Media-Dienst eines
                                            Drittanbieters liegt.
                                        </p>
                                        <p>
                                            Sie stimmen zu, Ihr Passwort nicht
                                            an Dritte weiterzugeben. Sie müssen
                                            uns unverzüglich benachrichtigen,
                                            wenn Sie Kenntnis von einer
                                            Sicherheitsverletzung oder einer
                                            unbefugten Nutzung Ihres Kontos
                                            erhalten.
                                        </p>
                                        <p>
                                            Sie dürfen als Benutzernamen nicht
                                            den Namen einer anderen natürlichen
                                            oder juristischen Person verwenden
                                            oder der nicht rechtmäßig zur
                                            Verwendung verfügbar ist, einen
                                            Namen oder eine Marke, die ohne
                                            entsprechende Genehmigung den
                                            Rechten einer anderen natürlichen
                                            oder juristischen Person als Ihnen
                                            unterliegt, oder einen Namen, der
                                            ist anderweitig anstößig, vulgär
                                            oder obszön.
                                        </p>
                                        <p>
                                            <strong><em> Inhalt</em></strong>
                                        </p>
                                        <p>
                                            Ihr<strong>
                                                Recht, Inhalte zu
                                                veröffentlichen</strong
                                            >
                                        </p>
                                        <p>
                                            Unser Service ermöglicht es Ihnen,
                                            Inhalte zu veröffentlichen. Sie sind
                                            verantwortlich für die Inhalte, die
                                            Sie an den Dienst posten,
                                            einschließlich ihrer Rechtmäßigkeit,
                                            Zuverlässigkeit und Angemessenheit.
                                        </p>
                                        <p>
                                            Sie behalten alle Ihre Rechte an
                                            Inhalten, die Sie auf oder über den
                                            Dienst übermitteln, posten oder
                                            anzeigen, und Sie sind für den
                                            Schutz dieser Rechte verantwortlich.
                                        </p>
                                        <p>
                                            Sie versichern und garantieren,
                                            dass: (i) der Inhalt Ihnen gehört
                                            (Sie besitzen ihn) oder Sie das
                                            Recht haben, ihn zu verwenden, und
                                            (ii) das Posten Ihres Inhalts auf
                                            oder über den Dienst nicht die
                                            Datenschutzrechte oder
                                            Veröffentlichungsrechte verletzt ,
                                            Urheberrechte, Vertragsrechte oder
                                            andere Rechte einer Person.
                                        </p>
                                        <p>
                                            <strong>
                                                Inhaltsbeschränkungen</strong
                                            >
                                        </p>
                                        <p>
                                            Das Unternehmen ist nicht
                                            verantwortlich für den Inhalt der
                                            Benutzer des Dienstes. Sie verstehen
                                            und stimmen ausdrücklich zu, dass
                                            Sie allein für den Inhalt und alle
                                            Aktivitäten verantwortlich sind, die
                                            unter Ihrem Konto stattfinden,
                                            unabhängig davon, ob dies von Ihnen
                                            oder einer dritten Person, die Ihr
                                            Konto nutzt, erfolgt.
                                        </p>
                                        <p>
                                            Sie dürfen keine Inhalte
                                            übermitteln, die rechtswidrig,
                                            anstößig, verstörend, anstößig,
                                            bedrohlich, verleumderisch,
                                            verleumderisch, obszön oder
                                            anderweitig anstößig sind. Beispiele
                                            für solche anstößigen Inhalte sind
                                            unter anderem die Folgenden:
                                        </p>
                                        <ul role="list">
                                            <li>
                                                Rechtswidrige oder fördernde
                                                rechtswidrige Aktivitäten.
                                            </li>
                                            <li>
                                                Verleumderische,
                                                diskriminierende oder gemeine
                                                Inhalte, einschließlich Verweise
                                                oder Kommentare zu Religion,
                                                Rasse, sexueller Orientierung,
                                                Geschlecht,
                                                nationaler/ethnischer Herkunft
                                                oder anderen Zielgruppen.
                                            </li>
                                            <li>
                                                Spam, maschinell – oder zufällig
                                                – generiert, die unbefugte oder
                                                unerwünschte Werbung,
                                                Kettenbriefe, jede andere Form
                                                von unbefugter Werbung oder jede
                                                Form von Lotterie oder
                                                Glücksspiel darstellen.
                                            </li>
                                            <li>
                                                Das Enthalten oder Installieren
                                                von Viren, Würmern, Malware,
                                                Trojanischen Pferden oder
                                                anderen Inhalten, die darauf
                                                ausgelegt oder beabsichtigt
                                                sind, die Funktion von Software,
                                                Hardware oder
                                                Telekommunikationsgeräten zu
                                                stören, zu beschädigen oder
                                                einzuschränken oder Daten oder
                                                andere zu beschädigen oder
                                                unbefugten Zugriff darauf zu
                                                erlangen Informationen einer
                                                dritten Person.
                                            </li>
                                            <li>
                                                Verletzung von Eigentumsrechten
                                                einer Partei, einschließlich
                                                Patenten, Marken,
                                                Geschäftsgeheimnissen,
                                                Urheberrechten,
                                                Veröffentlichungsrechten oder
                                                anderen Rechten.
                                            </li>
                                            <li>
                                                Sich als eine natürliche oder
                                                juristische Person ausgeben,
                                                einschließlich des Unternehmens
                                                und seiner Mitarbeiter oder
                                                Vertreter.
                                            </li>
                                            <li>
                                                Verletzung der Privatsphäre
                                                einer dritten Person.
                                            </li>
                                            <li>
                                                Falsche Informationen und
                                                Funktionen.
                                            </li>
                                        </ul>
                                        <p>
                                            Das Unternehmen behält sich das
                                            Recht, aber nicht die Verpflichtung
                                            vor, nach eigenem Ermessen
                                            festzustellen, ob Inhalte angemessen
                                            sind und diesen Bedingungen
                                            entsprechen, diese Inhalte
                                            abzulehnen oder zu entfernen. Das
                                            Unternehmen behält sich ferner das
                                            Recht vor, Inhalte zu formatieren
                                            und zu bearbeiten und die Art und
                                            Weise zu ändern. Das Unternehmen
                                            kann auch die Nutzung des Dienstes
                                            einschränken oder widerrufen, wenn
                                            Sie solche anstößigen Inhalte
                                            posten. Da das Unternehmen nicht
                                            alle Inhalte kontrollieren kann, die
                                            von Benutzern und/oder Dritten auf
                                            dem Dienst veröffentlicht werden,
                                            stimmen Sie zu, den Dienst auf
                                            eigene Gefahr zu nutzen. Sie
                                            verstehen, dass Sie durch die
                                            Nutzung des Dienstes möglicherweise
                                            Inhalten ausgesetzt sind, die Sie
                                            möglicherweise als anstößig,
                                            unanständig, falsch oder anstößig
                                            empfinden, und Sie stimmen zu, dass
                                            das Unternehmen unter keinen
                                            Umständen in irgendeiner Weise für
                                            Inhalte, einschließlich Fehler oder
                                            Auslassungen, haftbar gemacht wird
                                            beliebige Inhalte,
                                        </p>
                                        <p>
                                            <strong> Inhaltssicherungen</strong>
                                        </p>
                                        <p>
                                            Obwohl regelmäßige Sicherungen von
                                            Inhalten durchgeführt werden,
                                            garantiert das Unternehmen nicht,
                                            dass Daten nicht verloren gehen oder
                                            beschädigt werden.
                                        </p>
                                        <p>
                                            Beschädigte oder ungültige
                                            Sicherungspunkte können unter
                                            anderem durch Inhalte verursacht
                                            werden, die vor der Sicherung
                                            beschädigt sind oder sich während
                                            der Durchführung einer Sicherung
                                            ändern.
                                        </p>
                                        <p>
                                            Das Unternehmen leistet Support und
                                            versucht, alle bekannten oder
                                            entdeckten Probleme zu beheben, die
                                            sich auf die Sicherung von Inhalten
                                            auswirken können. Sie erkennen
                                            jedoch an, dass das Unternehmen
                                            keine Haftung in Bezug auf die
                                            Integrität von Inhalten oder das
                                            Versäumnis übernimmt, Inhalte
                                            erfolgreich in einen nutzbaren
                                            Zustand wiederherzustellen.
                                        </p>
                                        <p>
                                            Sie erklären sich damit
                                            einverstanden, eine vollständige und
                                            genaue Kopie aller Inhalte an einem
                                            vom Dienst unabhängigen Ort
                                            aufzubewahren.
                                        </p>
                                        <h2>
                                            <strong
                                                ><em>
                                                    Urheberrechtsrichtlinie</em
                                                ></strong
                                            >
                                        </h2>
                                        <h5>
                                            <strong
                                                >Verletzung von geistigem
                                                Eigentum</strong
                                            >
                                        </h5>
                                        <p>
                                            Wir respektieren die geistigen
                                            Eigentumsrechte anderer. Es ist
                                            unsere Politik, auf jede Behauptung
                                            zu reagieren, dass Inhalte, die auf
                                            dem Dienst veröffentlicht werden,
                                            eine Urheberrechtsverletzung oder
                                            eine andere Verletzung des geistigen
                                            Eigentums einer Person verletzen.
                                        </p>
                                        <p>
                                            Wenn Sie ein Urheberrechtsinhaber
                                            sind oder im Namen eines solchen
                                            autorisiert sind und glauben, dass
                                            das urheberrechtlich geschützte Werk
                                            in einer Weise kopiert wurde, die
                                            eine Urheberrechtsverletzung
                                            darstellt, die über den Dienst
                                            stattfindet, müssen Sie Ihre
                                            Mitteilung schriftlich an richten
                                            wenden Sie sich per E-Mail an
                                            unseren Urheberrechtsbeauftragten
                                            unter hey@jasper.ai und fügen Sie
                                            Ihrer Mitteilung eine detaillierte
                                            Beschreibung der mutmaßlichen
                                            Verletzung bei.
                                        </p>
                                        <p>
                                            Sie können für Schäden
                                            (einschließlich Kosten und
                                            Anwaltsgebühren) haftbar gemacht
                                            werden, wenn Sie fälschlicherweise
                                            angeben, dass Inhalte Ihr
                                            Urheberrecht verletzen.
                                        </p>
                                        <p>
                                            DMCA<strong>
                                                -Hinweis und DMCA-Verfahren für
                                                Ansprüche wegen
                                                Urheberrechtsverletzungen</strong
                                            >
                                        </p>
                                        <p>
                                            Sie können eine Benachrichtigung
                                            gemäß dem Digital Millennium
                                            Copyright Act (DMCA) einreichen,
                                            indem Sie unserem
                                            Urheberrechtsbeauftragten die
                                            folgenden Informationen schriftlich
                                            zukommen lassen (siehe 17 USC
                                            512(c)(3) für weitere Einzelheiten):
                                        </p>
                                        <ul role="list">
                                            <li>
                                                Eine elektronische oder
                                                physische Unterschrift der
                                                Person, die befugt ist, im Namen
                                                des Inhabers des
                                                Urheberrechtsinteresses zu
                                                handeln.
                                            </li>
                                            <li>
                                                Eine Beschreibung des
                                                urheberrechtlich geschützten
                                                Werks, von dem Sie behaupten,
                                                dass es verletzt wurde,
                                                einschließlich der URL (dh
                                                Webseitenadresse) des Ortes, an
                                                dem das urheberrechtlich
                                                geschützte Werk vorhanden ist,
                                                oder einer Kopie des
                                                urheberrechtlich geschützten
                                                Werks.
                                            </li>
                                            <li>
                                                Identifizierung der URL oder
                                                einer anderen spezifischen
                                                Stelle im Dienst, an der sich
                                                das Material befindet, von dem
                                                Sie behaupten, dass es eine
                                                Verletzung darstellt.
                                            </li>
                                            <li>
                                                Ihre Adresse, Telefonnummer und
                                                E-Mail-Adresse.
                                            </li>
                                            <li>
                                                Eine Erklärung von Ihnen, dass
                                                Sie nach Treu und Glauben der
                                                Ansicht sind, dass die strittige
                                                Nutzung nicht vom
                                                Urheberrechtsinhaber, seinem
                                                Vertreter oder dem Gesetz
                                                autorisiert ist.
                                            </li>
                                            <li>
                                                Eine eidesstattliche Erklärung
                                                von Ihnen, dass die obigen
                                                Informationen in Ihrer
                                                Mitteilung korrekt sind und dass
                                                Sie der Urheberrechtsinhaber
                                                sind oder befugt sind, im Namen
                                                des Urheberrechtsinhabers zu
                                                handeln.
                                            </li>
                                        </ul>
                                        <p>
                                            Sie können unseren
                                            Urheberrechtsbeauftragten per E-Mail
                                            unter contact@creaitor.ai
                                            kontaktieren. Nach Erhalt einer
                                            Benachrichtigung wird das
                                            Unternehmen nach eigenem Ermessen
                                            alle Maßnahmen ergreifen, die es für
                                            angemessen hält, einschließlich der
                                            Entfernung des beanstandeten Inhalts
                                            aus dem Dienst.
                                        </p>
                                        <h3>
                                            Geistiges<strong
                                                ><em> Eigentum</em></strong
                                            >
                                        </h3>
                                        <p>
                                            Der Dienst und seine ursprünglichen
                                            Inhalte (mit Ausnahme von Inhalten,
                                            die von Ihnen oder anderen Benutzern
                                            bereitgestellt werden), Merkmale und
                                            Funktionen sind und bleiben das
                                            ausschließliche Eigentum des
                                            Unternehmens und seiner Lizenzgeber.
                                        </p>
                                        <p>
                                            Der Dienst ist durch Urheberrechts-,
                                            Marken- und andere Gesetze sowohl
                                            des Landes als auch anderer Länder
                                            geschützt.
                                        </p>
                                        <p>
                                            Unsere Marken und
                                            Handelsaufmachungen dürfen ohne die
                                            vorherige schriftliche Zustimmung
                                            des Unternehmens nicht in Verbindung
                                            mit Produkten oder Dienstleistungen
                                            verwendet werden.
                                        </p>
                                        <h3>
                                            <strong
                                                ><em
                                                    >Links zu anderen
                                                    Websites</em
                                                ></strong
                                            >
                                        </h3>
                                        <p>
                                            Unser Dienst kann Links zu Websites
                                            oder Diensten von Drittanbietern
                                            enthalten, die nicht Eigentum des
                                            Unternehmens sind oder von diesem
                                            kontrolliert werden.
                                        </p>
                                        <p>
                                            Das Unternehmen hat keine Kontrolle
                                            über und übernimmt keine
                                            Verantwortung für die Inhalte,
                                            Datenschutzrichtlinien oder
                                            Praktiken von Websites oder Diensten
                                            Dritter. Sie erkennen ferner an und
                                            stimmen zu, dass das Unternehmen
                                            weder direkt noch indirekt für
                                            Schäden oder Verluste verantwortlich
                                            oder haftbar ist, die durch oder im
                                            Zusammenhang mit der Nutzung oder
                                            dem Vertrauen auf solche Inhalte,
                                            Waren oder Dienstleistungen, die auf
                                            verfügbar sind, verursacht oder
                                            angeblich verursacht wurden oder
                                            über solche Websites oder Dienste.
                                        </p>
                                        <p>
                                            Wir empfehlen Ihnen dringend, die
                                            Allgemeinen Geschäftsbedingungen und
                                            Datenschutzrichtlinien aller
                                            Websites oder Dienste Dritter, die
                                            Sie besuchen, zu lesen.
                                        </p>
                                        <h3>
                                            <strong><em> Kündigung</em></strong>
                                        </h3>
                                        <p>
                                            Wir können Ihr Konto ohne vorherige
                                            Ankündigung oder Haftung aus
                                            irgendeinem Grund sofort kündigen
                                            oder sperren, einschließlich, aber
                                            nicht beschränkt auf, wenn Sie gegen
                                            diese Allgemeinen
                                            Geschäftsbedingungen verstoßen.
                                        </p>
                                        <p>
                                            Mit der Kündigung erlischt Ihr Recht
                                            zur Nutzung des Dienstes sofort.
                                            Wenn Sie Ihr Konto kündigen möchten,
                                            können Sie die Nutzung des Dienstes
                                            einfach einstellen.
                                        </p>
                                        <h3>
                                            <strong
                                                ><em>
                                                    Haftungsbeschränkung</em
                                                ></strong
                                            >
                                        </h3>
                                        <p>
                                            Ungeachtet etwaiger Schäden, die
                                            Ihnen entstehen könnten, ist die
                                            gesamte Haftung des Unternehmens und
                                            seiner Lieferanten gemäß einer
                                            Bestimmung dieser Bedingungen und
                                            Ihr ausschließlicher Rechtsbehelf
                                            für alle vorstehenden Punkte auf den
                                            tatsächlich von Ihnen über den
                                            Dienst gezahlten Betrag oder 100 USD
                                            beschränkt wenn Sie nichts über den
                                            Service gekauft haben.
                                        </p>
                                        <p>
                                            Soweit nach geltendem Recht
                                            zulässig, haften das Unternehmen
                                            oder seine Lieferanten in keinem
                                            Fall für besondere, zufällige,
                                            indirekte oder Folgeschäden
                                            jeglicher Art (einschließlich, aber
                                            nicht beschränkt auf Schäden aus
                                            entgangenem Gewinn, Datenverlust
                                            oder andere Informationen, für
                                            Betriebsunterbrechung, für
                                            Personenschäden, Verlust der
                                            Privatsphäre, die sich aus oder in
                                            irgendeiner Weise im Zusammenhang
                                            mit der Nutzung oder Unfähigkeit zur
                                            Nutzung des Dienstes, der mit dem
                                            Dienst verwendeten Software von
                                            Drittanbietern und/oder Hardware von
                                            Drittanbietern ergeben, oder
                                            anderweitig in Verbindung mit einer
                                            Bestimmung dieser Bedingungen), auch
                                            wenn das Unternehmen oder ein
                                            Lieferant auf die Möglichkeit
                                            solcher Schäden hingewiesen wurde
                                            und selbst wenn die Abhilfe ihren
                                            wesentlichen Zweck verfehlt.
                                        </p>
                                        <p>
                                            Einige Staaten erlauben den
                                            Ausschluss stillschweigender
                                            Garantien oder die
                                            Haftungsbeschränkung für Neben- oder
                                            Folgeschäden nicht, was bedeutet,
                                            dass einige der oben genannten
                                            Beschränkungen möglicherweise nicht
                                            gelten. In diesen Staaten wird die
                                            Haftung jeder Partei auf den
                                            größtmöglichen gesetzlich zulässigen
                                            Umfang beschränkt.
                                        </p>
                                        <p>
                                            <strong
                                                ><em>
                                                    Haftungsausschluss
                                                </em></strong
                                            >„
                                        </p>
                                        <p>
                                            Der Service wird Ihnen „WIE BESEHEN“
                                            und „WIE VERFÜGBAR“ und mit allen
                                            Fehlern und Mängeln ohne jegliche
                                            Gewährleistung bereitgestellt.
                                            Soweit nach geltendem Recht
                                            zulässig, lehnt das Unternehmen im
                                            eigenen Namen und im Namen seiner
                                            verbundenen Unternehmen und seiner
                                            und ihrer jeweiligen Lizenzgeber und
                                            Dienstleister ausdrücklich alle
                                            ausdrücklichen, stillschweigenden,
                                            gesetzlichen oder sonstigen
                                            Gewährleistungen in Bezug auf die
                                            Service, einschließlich aller
                                            stillschweigenden Garantien der
                                            Marktgängigkeit, Eignung für einen
                                            bestimmten Zweck, Titel und
                                            Nichtverletzung sowie Garantien, die
                                            sich aus dem Geschäftsverlauf, der
                                            Ausführung, der Nutzung oder der
                                            Handelspraxis ergeben können. Ohne
                                            Einschränkung auf das Vorstehende
                                            gibt das Unternehmen keine
                                            Gewährleistung oder Zusage und gibt
                                            keinerlei Zusicherungen ab, dass der
                                            Service Ihre Anforderungen erfüllt,
                                            beabsichtigte Ergebnisse erzielt,
                                        </p>
                                        <p>
                                            Ohne das Vorstehende einzuschränken,
                                            geben weder das Unternehmen noch
                                            einer der Anbieter des Unternehmens
                                            irgendeine ausdrückliche oder
                                            stillschweigende Zusicherung oder
                                            Gewährleistung ab: (i) in Bezug auf
                                            den Betrieb oder die Verfügbarkeit
                                            des Dienstes oder der Informationen,
                                            Inhalte und Materialien oder
                                            Produkte darin enthalten; (ii) dass
                                            der Dienst ununterbrochen oder
                                            fehlerfrei ist; (iii) in Bezug auf
                                            die Genauigkeit, Zuverlässigkeit
                                            oder Aktualität von Informationen
                                            oder Inhalten, die über den Dienst
                                            bereitgestellt werden; oder (iv)
                                            dass der Dienst, seine Server, die
                                            Inhalte oder E-Mails, die von oder
                                            im Namen des Unternehmens gesendet
                                            werden, frei von Viren, Skripten,
                                            Trojanern, Würmern, Malware,
                                            Zeitbomben oder anderen schädlichen
                                            Komponenten sind.
                                        </p>
                                        <p>
                                            Einige Gerichtsbarkeiten erlauben
                                            den Ausschluss bestimmter Arten von
                                            Gewährleistungen oder Beschränkungen
                                            der anwendbaren gesetzlichen Rechte
                                            eines Verbrauchers nicht, sodass
                                            einige oder alle der oben genannten
                                            Ausschlüsse und Beschränkungen
                                            möglicherweise nicht auf Sie
                                            zutreffen. In einem solchen Fall
                                            gelten jedoch die in diesem
                                            Abschnitt dargelegten Ausschlüsse
                                            und Beschränkungen im größtmöglichen
                                            Umfang, der nach geltendem Recht
                                            durchsetzbar ist.
                                        </p>
                                        <p>
                                            Geltendes<strong
                                                ><em> Recht</em></strong
                                            >
                                        </p>
                                        <p>
                                            Die Gesetze des Landes, mit Ausnahme
                                            der Kollisionsnormen, unterliegen
                                            diesen Bedingungen und Ihrer Nutzung
                                            des Dienstes. Ihre Nutzung der
                                            Anwendung kann auch anderen lokalen,
                                            staatlichen, nationalen oder
                                            internationalen Gesetzen
                                            unterliegen.
                                        </p>
                                        <p>
                                            <strong
                                                ><em>
                                                    Streitbeilegung</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            Wenn Sie Bedenken oder
                                            Streitigkeiten bezüglich des
                                            Dienstes haben, stimmen Sie zu,
                                            zunächst zu versuchen, die
                                            Streitigkeit informell zu lösen,
                                            indem Sie sich an das Unternehmen
                                            wenden.
                                        </p>
                                        <h3>
                                            <strong
                                                ><em
                                                    >Für Benutzer der
                                                    Europäischen Union (EU).</em
                                                ></strong
                                            >
                                        </h3>
                                        <p>
                                            If You are a European Union
                                            consumer, you will benefit from any
                                            mandatory provisions of the law of
                                            the country in which you are
                                            resident in.
                                        </p>
                                        <p>
                                            <strong
                                                ><em
                                                    >United States Federal
                                                    Government End Use
                                                    Provisions</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            If You are a U.S. federal government
                                            end user, our Service is a
                                            "Commercial Item" as that term is
                                            defined at 48 C.F.R. §2.101.
                                        </p>
                                        <p>
                                            <strong
                                                ><em
                                                    >United States Legal
                                                    Compliance</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            You represent and warrant that (i)
                                            You are not located in a country
                                            that is subject to the United States
                                            government embargo, or that has been
                                            designated by the United States
                                            government as a "terrorist
                                            supporting" country, and (ii) You
                                            are not listed on any United States
                                            government list of prohibited or
                                            restricted parties.
                                        </p>
                                        <h3>
                                            <strong
                                                ><em
                                                    >Severability and Waiver</em
                                                ></strong
                                            >
                                        </h3>
                                        <p><strong>Severability</strong></p>
                                        <p>
                                            Wenn eine Bestimmung dieser
                                            Bedingungen für nicht durchsetzbar
                                            oder ungültig befunden wird, wird
                                            diese Bestimmung geändert und
                                            ausgelegt, um die Ziele dieser
                                            Bestimmung im größtmöglichen Umfang
                                            nach geltendem Recht zu erreichen,
                                            und die verbleibenden Bestimmungen
                                            bleiben in vollem Umfang in Kraft
                                            und wirksam.
                                        </p>
                                        <p><strong> Verzicht</strong></p>
                                        <p>
                                            Außer wie hierin vorgesehen,
                                            beeinträchtigt das Versäumnis, ein
                                            Recht auszuüben oder die Erfüllung
                                            einer Verpflichtung gemäß diesen
                                            Bedingungen zu verlangen, weder die
                                            Fähigkeit einer Partei, dieses Recht
                                            auszuüben oder eine solche Erfüllung
                                            zu verlangen, noch stellt der
                                            Verzicht auf eine Verletzung einen
                                            Verzicht dar eines späteren
                                            Verstoßes.
                                        </p>
                                        <p>
                                            <strong
                                                ><em>
                                                    Dolmetschen von
                                                </em></strong
                                            >Übersetzungen
                                        </p>
                                        <p>
                                            Diese Allgemeinen
                                            Geschäftsbedingungen wurden
                                            möglicherweise übersetzt, wenn wir
                                            sie Ihnen in unserem Service zur
                                            Verfügung gestellt haben. Sie
                                            stimmen zu, dass im Streitfall der
                                            englische Originaltext maßgebend
                                            ist.
                                        </p>
                                        <p>
                                            Änderungen<strong
                                                ><em>
                                                    dieser Allgemeinen
                                                    Geschäftsbedingungen</em
                                                ></strong
                                            >
                                        </p>
                                        <p>
                                            Wir behalten uns das Recht vor,
                                            diese Bedingungen nach eigenem
                                            Ermessen jederzeit zu ändern oder zu
                                            ersetzen. Wenn eine Überarbeitung
                                            wesentlich ist, werden wir
                                            angemessene Anstrengungen
                                            unternehmen, um Sie mindestens 30
                                            Tage im Voraus über das
                                            Inkrafttreten neuer Bedingungen zu
                                            informieren. Was eine wesentliche
                                            Änderung darstellt, wird nach
                                            unserem alleinigen Ermessen
                                            bestimmt.
                                        </p>
                                        <p>
                                            Indem Sie nach Inkrafttreten dieser
                                            Überarbeitungen weiterhin auf
                                            unseren Dienst zugreifen oder ihn
                                            nutzen, stimmen Sie zu, an die
                                            überarbeiteten Bedingungen gebunden
                                            zu sein. Wenn Sie den neuen
                                            Bedingungen ganz oder teilweise
                                            nicht zustimmen, beenden Sie bitte
                                            die Nutzung der Website und des
                                            Dienstes.
                                        </p>
                                        <p>
                                            Kontaktieren Sie<strong
                                                ><em> uns</em></strong
                                            >
                                        </p>
                                        <p>
                                            Wenn Sie Fragen zu diesen
                                            Allgemeinen Geschäftsbedingungen
                                            haben, können Sie uns kontaktieren:
                                        </p>
                                    </div>
                                    <h3>
                                        <strong
                                            >Hyperlinks zu unseren
                                            Inhalten</strong
                                        >
                                    </h3>
                                    <p>
                                        Die folgenden Organisationen dürfen ohne
                                        vorherige schriftliche Genehmigung auf
                                        unsere Website verlinken:
                                    </p>
                                    <ul>
                                        <li>Regierungsbehörden;</li>
                                        <li>Suchmaschinen;</li>
                                        <li>Nachrichtenorganisationen;</li>
                                        <li>
                                            Anbieter von Online-Verzeichnissen
                                            können auf dieselbe Weise auf unsere
                                            Website verlinken wie auf die
                                            Websites anderer börsennotierter
                                            Unternehmen; und
                                        </li>
                                        <li>
                                            Systemweit akkreditierte
                                            Unternehmen, mit Ausnahme von
                                            werbenden gemeinnützigen
                                            Organisationen, Einkaufszentren für
                                            wohltätige Zwecke und Spendengruppen
                                            für wohltätige Zwecke, die
                                            möglicherweise keinen Hyperlink zu
                                            unserer Website haben.
                                        </li>
                                    </ul>
                                    <p>
                                        Diese Organisationen können auf unsere
                                        Homepage, auf Veröffentlichungen oder
                                        auf andere Website-Informationen
                                        verlinken, solange der Link: (a) in
                                        keiner Weise irreführend ist; (b)
                                        impliziert nicht fälschlicherweise
                                        Sponsoring, Befürwortung oder
                                        Genehmigung der verlinkenden Partei und
                                        ihrer Produkte und/oder
                                        Dienstleistungen; und (c) in den Kontext
                                        der Website der verlinkenden Partei
                                        passt.
                                    </p>
                                    <p>
                                        Wir können andere Verknüpfungsanfragen
                                        von den folgenden Arten von
                                        Organisationen berücksichtigen und
                                        genehmigen:
                                    </p>
                                    <ul>
                                        <li>
                                            allgemein bekannte Verbraucher-
                                            und/oder
                                            Geschäftsinformationsquellen;
                                        </li>
                                        <li>dot.com-Community-Sites;</li>
                                        <li>
                                            Vereine oder andere Gruppen, die
                                            Wohltätigkeitsorganisationen
                                            vertreten;
                                        </li>
                                        <li>
                                            Verteiler von Online-Verzeichnissen;
                                        </li>
                                        <li>Internetportale;</li>
                                        <li>
                                            Wirtschaftsprüfungs-, Anwalts- und
                                            Beratungsunternehmen; und
                                        </li>
                                        <li>
                                            Bildungseinrichtungen und
                                            Wirtschaftsverbände.
                                        </li>
                                    </ul>
                                    <p>
                                        Wir werden Linkanfragen von diesen
                                        Organisationen genehmigen, wenn wir
                                        entscheiden, dass: (a) der Link uns
                                        nicht dazu bringen würde, uns selbst
                                        oder unsere akkreditierten Unternehmen
                                        nachteilig darzustellen; (b) die
                                        Organisation keine negativen
                                        Aufzeichnungen bei uns hat; (c) der
                                        Vorteil für uns aus der Sichtbarkeit des
                                        Hyperlinks kompensiert das Fehlen der
                                        Funk Solutions GmbH; und (d) der Link
                                        steht im Kontext allgemeiner
                                        Ressourceninformationen.
                                    </p>
                                    <p>
                                        Diese Organisationen dürfen auf unsere
                                        Homepage verlinken, solange der Link:
                                        (a) in keiner Weise irreführend ist; (b)
                                        impliziert nicht fälschlicherweise
                                        Sponsoring, Befürwortung oder
                                        Genehmigung der verlinkenden Partei und
                                        ihrer Produkte oder Dienstleistungen;
                                        und (c) in den Kontext der Website der
                                        verlinkenden Partei passt.
                                    </p>
                                    <p>
                                        Wenn Sie eine der in Ziffer 2 oben
                                        aufgeführten Organisationen sind und an
                                        einer Verlinkung unserer Website
                                        interessiert sind, müssen Sie uns dies
                                        per E-Mail an die Funk Solutions GmbH
                                        mitteilen. Bitte geben Sie Ihren Namen,
                                        den Namen Ihrer Organisation,
                                        Kontaktinformationen sowie die URL Ihrer
                                        Website, eine Liste aller URLs an, von
                                        denen Sie beabsichtigen, auf unsere
                                        Website zu verlinken, und eine Liste der
                                        URLs auf unserer Website, zu denen Sie
                                        möchten Verknüpfung. Warten Sie 2-3
                                        Wochen auf eine Antwort.
                                    </p>
                                    <p>
                                        Zugelassene Organisationen können wie
                                        folgt auf unsere Website verlinken:
                                    </p>
                                    <ul>
                                        <li>
                                            Durch die Verwendung unseres
                                            Firmennamens; oder
                                        </li>
                                        <li>
                                            Durch die Verwendung des Uniform
                                            Resource Locators, mit dem verknüpft
                                            ist; oder
                                        </li>
                                        <li>
                                            Durch die Verwendung einer anderen
                                            Beschreibung unserer Website, auf
                                            die verlinkt wird, ist dies im
                                            Kontext und Format des Inhalts auf
                                            der Website der verlinkenden Partei
                                            sinnvoll.
                                        </li>
                                    </ul>
                                    <p>
                                        Die Verwendung des Logos oder anderer
                                        Grafiken der Funk Solutions GmbH für die
                                        Verlinkung ist ohne
                                        Markenlizenzvereinbarung nicht
                                        gestattet.
                                    </p>
                                    <h3><strong>iFrames</strong></h3>
                                    <p>
                                        Ohne vorherige Genehmigung und
                                        schriftliche Genehmigung dürfen Sie
                                        keine Frames um unsere Webseiten herum
                                        erstellen, die die visuelle Darstellung
                                        oder das Erscheinungsbild unserer
                                        Website in irgendeiner Weise verändern.
                                    </p>
                                    <h3>
                                        <strong>Haftung für Inhalte</strong>
                                    </h3>
                                    <p>
                                        Wir übernehmen keine Verantwortung für
                                        Inhalte, die auf Ihrer Website
                                        erscheinen. Sie stimmen zu, uns gegen
                                        alle Ansprüche zu schützen und zu
                                        verteidigen, die auf Ihrer Website
                                        erhoben werden. Auf einer Website dürfen
                                        keine Links erscheinen, die als
                                        verleumderisch, obszön oder kriminell
                                        ausgelegt werden können oder die Rechte
                                        Dritter verletzen, anderweitig verletzen
                                        oder deren Verletzung oder sonstige
                                        Verletzung befürworten.
                                    </p>
                                    <h3><strong>Ihre Privatsphäre</strong></h3>
                                    <p>
                                        Bitte lesen Sie die
                                        Datenschutzrichtlinie
                                    </p>
                                    <h3>
                                        <strong>Vorbehalt von Rechten</strong>
                                    </h3>
                                    <p>
                                        Wir behalten uns das Recht vor, Sie
                                        aufzufordern, alle Links oder bestimmte
                                        Links zu unserer Website zu entfernen.
                                        Sie stimmen zu, auf Anfrage unverzüglich
                                        alle Links zu unserer Website zu
                                        entfernen. Wir behalten uns außerdem das
                                        Recht vor, diese Allgemeinen
                                        Geschäftsbedingungen und ihre
                                        Verlinkungsrichtlinie jederzeit zu
                                        ändern. Indem Sie kontinuierlich auf
                                        unsere Website verlinken, erklären Sie
                                        sich damit einverstanden, an diese
                                        Verlinkungsbedingungen gebunden zu sein
                                        und diese zu befolgen.
                                    </p>
                                    <h3>
                                        <strong
                                            >Entfernung von Links von unserer
                                            Website</strong
                                        >
                                    </h3>
                                    <p>
                                        Wenn Sie einen Link auf unserer Website
                                        finden, der aus irgendeinem Grund
                                        anstößig ist, können Sie uns jederzeit
                                        kontaktieren und informieren. Wir werden
                                        Anfragen zum Entfernen von Links prüfen,
                                        sind jedoch nicht dazu verpflichtet oder
                                        direkt auf Sie zu antworten.
                                    </p>
                                    <p>
                                        Wir garantieren nicht, dass die
                                        Informationen auf dieser Website korrekt
                                        sind, wir garantieren nicht deren
                                        Vollständigkeit oder Genauigkeit; Wir
                                        versprechen auch nicht sicherzustellen,
                                        dass die Website verfügbar bleibt oder
                                        dass das Material auf der Website auf
                                        dem neuesten Stand gehalten wird.
                                    </p>
                                    <h3><strong>Haftungsausschluss</strong></h3>
                                    <p>
                                        Soweit nach geltendem Recht zulässig,
                                        schließen wir alle Zusicherungen,
                                        Gewährleistungen und Bedingungen in
                                        Bezug auf unsere Website und die Nutzung
                                        dieser Website aus. Nichts in diesem
                                        Haftungsausschluss wird:
                                    </p>
                                    <ul>
                                        <li>
                                            unsere oder Ihre Haftung für Tod
                                            oder Körperverletzung einschränken
                                            oder ausschließen;
                                        </li>
                                        <li>
                                            unsere oder Ihre Haftung für Betrug
                                            oder betrügerische Falschdarstellung
                                            einschränken oder ausschließen;
                                        </li>
                                        <li>
                                            unsere oder Ihre Verbindlichkeiten
                                            auf eine Weise einschränken, die
                                            nach geltendem Recht nicht zulässig
                                            ist; oder
                                        </li>
                                        <li>
                                            schließen Sie jegliche unserer oder
                                            Ihrer Verbindlichkeiten aus, die
                                            nach geltendem Recht nicht
                                            ausgeschlossen werden können.
                                        </li>
                                    </ul>
                                    <p>
                                        Die in diesem Abschnitt und an anderer
                                        Stelle in diesem Haftungsausschluss
                                        festgelegten Haftungsbeschränkungen und
                                        -verbote: (a) unterliegen dem
                                        vorstehenden Absatz; und (b) regeln alle
                                        Verbindlichkeiten, die sich aus dem
                                        Haftungsausschluss ergeben,
                                        einschließlich Verbindlichkeiten, die
                                        sich aus Vertrag, unerlaubter Handlung
                                        und Verletzung gesetzlicher Pflichten
                                        ergeben.
                                    </p>
                                    <p>
                                        Solange die Website und die
                                        Informationen und Dienste auf der
                                        Website kostenlos zur Verfügung gestellt
                                        werden, haften wir nicht für Verluste
                                        oder Schäden jeglicher Art.
                                    </p>
                                </div>
                            </div>
                            <!--end::Story-->
                        </div>
                        <!--end::Col-->
                    </div>
                    <!--end::Row-->
                </div>
                <!--end::Container-->
            </div>
            <Footer></Footer>
            <!--begin::Scrolltop-->
            <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                <span class="svg-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <rect
                            opacity="0.5"
                            x="13"
                            y="6"
                            width="13"
                            height="2"
                            rx="1"
                            transform="rotate(90 13 6)"
                            fill="black"
                        />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </div>
            <!--end::Scrolltop-->
        </div>
        <!--end::Root-->
        <!--end::Main-->
    </div>
</template>

<style scoped></style>

<script>
import { defineComponent } from 'vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import Typed from 'typed.js';
import ApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import Footer from './Partials/de/Footer.vue';
import { Carousel } from 'bootstrap';

export default defineComponent({
    components: {
        Footer,
        Head,
        Link,
        ApplicationLogo,
    },

    props: {
        canLogin: Boolean,
        canRegister: Boolean,
        laravelVersion: String,
        phpVersion: String,
    },

    data() {
        return {
            productCarousel: {},
            teamCarousel: {},
        };
    },

    mounted() {
        this.productCarousel = Carousel(
            document.querySelector('#product-carousel')
        );
        this.teamCarousel = Carousel(
            document.querySelector('#team-carousel')
        );
        let typed = new Typed('#hero-text', {
            strings: [
                'Blog headlines',
                'Blog posts',
                'Topic ideas',
                'Facebook ads',
                'Stunning Headlines',
            ],
            typeSpeed: 60,
            loop: true,
        });
    },

    methods: {
        nextSlide(element) {
            bootstrap.Carousel.getInstance(element).next();
        },
        prevSlide(element) {
            bootstrap.Carousel.getInstance(element).prev();
        },
    },
});
</script>
