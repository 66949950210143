<template>
    <div
        class="d-flex align-items-center ms-1 ms-lg-3"
        id="kt_header_user_menu_toggle"
    >
        <!--begin::Menu wrapper-->
        <div
            class="cursor-pointer symbol symbol-30px symbol-md-40px"
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            v-click-away="onClickAway"
            @click="toggle($event)"
        >
            <img :src="$page.props.user.profile_photo_url" alt="user" />
        </div>
        <!--begin::User account menu-->
        <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
            data-kt-menu="true"
            style="
                z-index: 105;
                position: fixed;
                inset: 0px 0px auto auto;
                margin: 0px;
                transform: translate(-30px, 65px);
            "
        >
            <!--begin::Menu item-->
            <div class="menu-item px-3">
                <div class="menu-content d-flex align-items-center px-3">
                    <!--begin::Avatar-->
                    <div class="symbol symbol-50px me-5">
                        <img
                            alt="Logo"
                            :src="$page.props.user.profile_photo_url"
                        />
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Username-->
                    <div class="d-flex flex-column">
                        <div class="fw-bolder d-flex align-items-center fs-5">
                            {{ $page.props.user.name }}
                            <!--<span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span>!-->
                        </div>
                        <a
                            href="#"
                            class="fw-bold text-gray-500 text-hover-primary fs-7"
                            >{{ $page.props.user.email }}</a
                        >
                    </div>
                    <!--end::Username-->
                </div>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu separator-->
            <div class="separator my-2"></div>
            <!--end::Menu separator-->
            <!--begin::Menu item-->
            <div class="menu-item px-5">
                <jet-dropdown-link
                    :href="route('profile.show')"
                    class="menu-link py-3 px-5"
                >
                    {{ __('My Profile') }}
                </jet-dropdown-link>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item px-5">
                <jet-dropdown-link
                    :href="route('api-tokens.index')"
                    v-if="$page.props.jetstream.hasApiFeatures"
                    class="menu-link py-3 px-5"
                >
                    {{ __('API Tokens') }}
                </jet-dropdown-link>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div
                class="menu-item px-5"
                data-kt-menu-trigger="hover"
                data-kt-menu-placement="left-start"
            >
                <a
                    href="#"
                    class="menu-link py-3 px-5"
                    @click.prevent="toggle"
                    v-click-away="onClickAway"
                >
                    <span class="menu-title position-relative fw-normal fs-6"
                        >{{ __('Language') }}
                        <small
                            class="rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
                            >{{
                                languages[$page.props.user.user_info.language]
                                    .name
                            }}
                            <img
                                class="w-15px h-15px rounded-1 ms-2 inline-block"
                                :src="
                                    languages[
                                        $page.props.user.user_info.language
                                    ].flag
                                "
                            />
                        </small>
                    </span>
                </a>
                <!--begin::Menu sub-->
                <div
                    class="menu-sub menu-sub-dropdown position-fixed w-175px py-4 user-language-menu"
                >
                    <!--begin::Menu item-->
                    <template v-for="language in languages">
                        <div
                            v-if="
                                $page.props.user.user_info.language !==
                                language.code
                            "
                            class="menu-item px-3"
                        >
                            <a
                                href="#"
                                @click.prevent="
                                    onSelectLanguage($event, language.code)
                                "
                                class="menu-link d-flex py-2 px-5"
                            >
                                <span class="symbol symbol-20px me-4">
                                    <img
                                        class="rounded-1"
                                        :src="language.flag"
                                        alt="language.name"
                                    /> </span
                                >{{ language.name }}</a
                            >
                        </div>
                    </template>
                    <!--end::Menu item-->
                </div>
                <!--end::Menu sub-->
            </div>
            <!--end::Menu item-->
            <!--begin::Menu separator-->
            <div class="separator my-2"></div>
            <!--end::Menu separator-->
            <!--end::Menu item-->
            <template v-if="hasAdminPermission">
                <!--begin::Menu item-->
                <div class="menu-item px-5">
                    <a href="/billing" class="menu-link py-3 px-5">{{
                        __('Billing')
                    }}</a>
                </div>
                <!--begin::Menu separator-->
                <div class="separator my-2"></div>
                <!--end::Menu separator-->
            </template>
            <!--begin::Menu item-->
            <div class="menu-item px-5">
                <!-- Authentication -->
                <form @submit.prevent="logout">
                    <jet-dropdown-link as="button" class="menu-link py-3 px-5">
                        {{ __('Log out') }}
                    </jet-dropdown-link>
                </form>
            </div>
            <!--end::Menu item-->
        </div>
        <!--end::User account menu-->
        <!--end::Menu wrapper-->
    </div>
</template>

<script>
import JetDropdown from '@/Jetstream/Dropdown.vue';
import JetDropdownLink from '@/Jetstream/DropdownLink.vue';
import { directive } from 'vue3-click-away';
import {router as Inertia} from '@inertiajs/vue3';

export default {
    components: {
        JetDropdown,
        JetDropdownLink,
    },
    directives: {
        ClickAway: directive,
    },
    props: {
        user: Object,
    },
    data() {
        return {
            currentMenu: {
                open: false,
                element: {},
            },

            languages: {
                en: {
                    name: 'English',
                    code: 'en',
                    flag: '/media/flags/united-states.svg',
                },
                de: {
                    name: this.__('Deutsch'),
                    code: 'de',
                    flag: '/media/flags/germany.svg',
                },
                fr: {
                    name: this.__('Français'),
                    code: 'fr',
                    flag: '/media/flags/france.svg',
                },
                es: {
                    name: this.__('Español'),
                    code: 'es',
                    flag: '/media/flags/spain.svg',
                },
                it: {
                    name: this.__('Italiano'),
                    code: 'it',
                    flag: '/media/flags/italy.svg',
                },
            },
        };
    },
    methods: {
        onSelectLanguage(ev, code) {
            let data = {
                language: code,
            };
            axios
                .put(
                    '/api/user-infos/' + this.$page.props.user.user_info.id,
                    data
                )
                .then((response) => {
                    window.location.reload();
                    //Inertia.reload();
                });
            this.toggle(ev);
        },
        toggle($event) {
            let el = $event.target.closest('[data-kt-menu-trigger]');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');

            this.currentMenu.open = true;
            this.currentMenu.element = el;
        },
        onHover(ev) {
            let menuItem = ev.target.closest('.menu-item').classList;
            let parent = ev.target.parentElement.parentElement.classList;

            if (!parent.contains('menu-sub')) {
                menuItem.add('show');
                menuItem.add('menu-dropdown');
            }

            let menuSub = ev.target
                .closest('.menu-item')
                .getElementsByClassName('menu-sub');

            if (menuSub.length > 0) {
                menuSub[0].classList.add('show');
            }
        },
        onLeave(ev) {
            let menuItem = ev.fromElement.classList;
            menuItem.remove('show');
            menuItem.remove('menu-dropdown');
        },
        onClickAway(ev) {
            if (this.currentMenu.open) {
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(ev.target)) {
                    let s = el.classList;
                    let m = el.parentElement.getElementsByClassName('menu-sub-dropdown')[0].classList;

                    // Remove the classes without toggling
                    s.remove('show');
                    s.remove('menu-dropdown');
                    m.remove('show');

                    this.currentMenu.open = false;
                }
            }
        },
        logout() {
            this.$inertia.post(route('logout'));
        },
    },
    computed: {
        hasAdminPermission() {
            return !!(
                this.$page.props.user.team_role &&
                (this.$page.props.user.team_role.key === 'admin' ||
                    this.$page.props.user.team_role.key === 'owner')
            );
        },
    },
};
</script>

<style lang="scss">
.user-language-menu {
    transform: translate3d(-178px, -40px, 10px);
}
</style>
