import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m13.008 2.6 2.7 4.6 5.3 1.2c.9.2 1.3 1.3.7 2l-3.6 4 .5 5.4c.1.9-.9 1.6-1.7 1.2l-4.9-2.2-4.9 2.2c-.9.4-1.8-.3-1.7-1.2l.5-5.4-3.6-4c-.6-.7-.3-1.8.7-2l5.3-1.2 2.7-4.6c.3-.8 1.5-.8 2 0"
    }, null, -1)
  ])))
}
export default { render: render }