<template>
    <div class="d-flex flex-column flex-lg-row-fluid py-10">
        <!--begin::Content-->
        <div class="d-flex flex-column flex-column-fluid">
            <!--begin::Wrapper-->
            <div class="px-10 px-lg-13 h-100">
                <!--begin::Title-->
                <h2 class="fw-bolder d-flex align-items-center mb-10 text-dark">
                    {{ resultTitle }}
                </h2>
                <!--end::Title-->
                <div v-if="!singleView && !loading" class="row">
                    <div v-for="(item, index) in images" class="col-md-6">
                        <div
                            @click="switchToSingleView(item)"
                            class="card image-card card-bordered rounded position-relative p-0 d-flex flex-column align-items-stretch justify-content-between mb-5"
                        >
                            <img
                                :src="item.path"
                                class="img-fluid rounded"
                                alt=""
                            />
                            <div
                                class="d-flex flex-column position-absolute w-100 h-100 border-bottom justify-content-between top-0 end-0 p-4"
                            >
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex"></div>
                                    <div class="d-flex actions">
                                        <button
                                            v-if="inModal"
                                            class="btn btn-icon btn-sm rounded-circle btn-light me-2"
                                            @click.stop="onInsert(item)"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M416 128h-40C362.7 128 352 138.7 352 152c0 13.25 10.75 24 24 24H416c8.836 0 16 7.164 16 16v256c0 8.836-7.164 16-16 16H96c-8.836 0-16-7.164-16-16V192c0-8.836 7.164-16 16-16h40C149.3 176 160 165.3 160 152C160 138.7 149.3 128 136 128H96C60.65 128 32 156.7 32 192v256c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V192C480 156.7 451.3 128 416 128zM143.7 238.6C133.1 247.6 133.4 262.8 142.4 272.6l96 104.1c9.062 9.82 26.19 9.82 35.25 0l96-104.1c9-9.758 8.406-24.95-1.344-33.93c-9.781-9.07-24.97-8.414-33.91 1.344L280 298.9V24.02C280 10.76 269.3 0 256 0S232 10.76 232 24.02v274.9l-54.38-58.95C172.9 234.8 166.5 232.3 160 232.3C154.2 232.3 148.3 234.3 143.7 238.6z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <button
                                            class="btn btn-icon btn-sm rounded-circle btn-light me-2"
                                            @click.stop="copyImage(item)"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <button
                                            class="btn btn-icon btn-sm rounded-circle btn-light btn-active-light-primary"
                                            @click.stop="downloadImage(item)"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M352 512H96c-53.02 0-96-42.98-96-96v-72C0 330.7 10.75 320 24 320c13.26 0 24 10.75 24 24V416c0 26.4 21.6 48 48 48h256c26.4 0 48-21.6 48-48v-72c0-13.25 10.75-24 24-24c13.26 0 24 10.75 24 24V416C448 469 405 512 352 512zM240.4 345.5l136-128c9.688-9.094 10.12-24.28 1.031-33.91c-9.062-9.656-24.25-10.12-33.91-1.031L248 272.4V24C248 10.75 237.3 0 224 0S200 10.75 200 24v248.4L104.4 182.5C94.78 173.4 79.59 173.9 70.53 183.6C66.16 188.2 64 194.1 64 200c0 6.375 2.531 12.75 7.562 17.47l136 128C216.8 354.2 231.2 354.2 240.4 345.5z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex"></div>
                                    <div class="d-flex">
                                        <ApplicationMark
                                            width="32"
                                            height="32"
                                        ></ApplicationMark>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="singleView" class="row">
                    <div class="col-md-12">
                        <div
                            class="card image-card single-view shadow-sm rounded position-relative p-0 d-flex flex-column align-items-stretch justify-content-between mb-5"
                        >
                            <img
                                :src="singleImage.path"
                                class="img-fluid rounded"
                                alt=""
                            />
                            <div
                                class="d-flex flex-column position-absolute w-100 h-100 border-bottom justify-content-between top-0 end-0 p-4"
                            >
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex actions">
                                        <button
                                            class="btn btn-icon btn-sm rounded-circle btn-dark me-2"
                                            @click="singleView = false"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M447.1 256c0 13.25-10.76 24.01-24.01 24.01H83.9l132.7 126.6c9.625 9.156 9.969 24.41 .8125 33.94c-9.156 9.594-24.34 9.938-33.94 .8125l-176-168C2.695 268.9 .0078 262.6 .0078 256S2.695 243.2 7.445 238.6l176-168C193 61.51 208.2 61.85 217.4 71.45c9.156 9.5 8.812 24.75-.8125 33.94l-132.7 126.6h340.1C437.2 232 447.1 242.8 447.1 256z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="d-flex actions">
                                        <button
                                            v-if="inModal"
                                            class="btn btn-icon btn-sm rounded-circle btn-light me-2"
                                            @click.stop="onInsert(singleImage)"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M416 128h-40C362.7 128 352 138.7 352 152c0 13.25 10.75 24 24 24H416c8.836 0 16 7.164 16 16v256c0 8.836-7.164 16-16 16H96c-8.836 0-16-7.164-16-16V192c0-8.836 7.164-16 16-16h40C149.3 176 160 165.3 160 152C160 138.7 149.3 128 136 128H96C60.65 128 32 156.7 32 192v256c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V192C480 156.7 451.3 128 416 128zM143.7 238.6C133.1 247.6 133.4 262.8 142.4 272.6l96 104.1c9.062 9.82 26.19 9.82 35.25 0l96-104.1c9-9.758 8.406-24.95-1.344-33.93c-9.781-9.07-24.97-8.414-33.91 1.344L280 298.9V24.02C280 10.76 269.3 0 256 0S232 10.76 232 24.02v274.9l-54.38-58.95C172.9 234.8 166.5 232.3 160 232.3C154.2 232.3 148.3 234.3 143.7 238.6z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <button
                                            class="btn btn-icon btn-sm rounded-circle btn-light me-2"
                                            @click="copyImage(singleImage)"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <button
                                            class="btn btn-icon btn-sm rounded-circle btn-light btn-active-light-primary"
                                            @click="downloadImage(singleImage)"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M352 512H96c-53.02 0-96-42.98-96-96v-72C0 330.7 10.75 320 24 320c13.26 0 24 10.75 24 24V416c0 26.4 21.6 48 48 48h256c26.4 0 48-21.6 48-48v-72c0-13.25 10.75-24 24-24c13.26 0 24 10.75 24 24V416C448 469 405 512 352 512zM240.4 345.5l136-128c9.688-9.094 10.12-24.28 1.031-33.91c-9.062-9.656-24.25-10.12-33.91-1.031L248 272.4V24C248 10.75 237.3 0 224 0S200 10.75 200 24v248.4L104.4 182.5C94.78 173.4 79.59 173.9 70.53 183.6C66.16 188.2 64 194.1 64 200c0 6.375 2.531 12.75 7.562 17.47l136 128C216.8 354.2 231.2 354.2 240.4 345.5z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex actions">
                                        <button
                                            class="btn btn-icon btn-sm rounded-circle btn-light me-2"
                                            @click="onThumbsUp(singleImage)"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M96 191.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64c17.67 0 32-14.33 32-31.1V223.1C128 206.3 113.7 191.1 96 191.1zM512 227c0-36.89-30.05-66.92-66.97-66.92h-99.86C354.7 135.1 360 113.5 360 100.8c0-33.8-26.2-68.78-70.06-68.78c-46.61 0-59.36 32.44-69.61 58.5c-31.66 80.5-60.33 66.39-60.33 93.47c0 12.84 10.36 23.99 24.02 23.99c5.256 0 10.55-1.721 14.97-5.26c76.76-61.37 57.97-122.7 90.95-122.7c16.08 0 22.06 12.75 22.06 20.79c0 7.404-7.594 39.55-25.55 71.59c-2.046 3.646-3.066 7.686-3.066 11.72c0 13.92 11.43 23.1 24 23.1h137.6C455.5 208.1 464 216.6 464 227c0 9.809-7.766 18.03-17.67 18.71c-12.66 .8593-22.36 11.4-22.36 23.94c0 15.47 11.39 15.95 11.39 28.91c0 25.37-35.03 12.34-35.03 42.15c0 11.22 6.392 13.03 6.392 22.25c0 22.66-29.77 13.76-29.77 40.64c0 4.515 1.11 5.961 1.11 9.456c0 10.45-8.516 18.95-18.97 18.95h-52.53c-25.62 0-51.02-8.466-71.5-23.81l-36.66-27.51c-4.315-3.245-9.37-4.811-14.38-4.811c-13.85 0-24.03 11.38-24.03 24.04c0 7.287 3.312 14.42 9.596 19.13l36.67 27.52C235 468.1 270.6 480 306.6 480h52.53c35.33 0 64.36-27.49 66.8-62.2c17.77-12.23 28.83-32.51 28.83-54.83c0-3.046-.2187-6.107-.6406-9.122c17.84-12.15 29.28-32.58 29.28-55.28c0-5.311-.6406-10.54-1.875-15.64C499.9 270.1 512 250.2 512 227z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <button
                                            class="btn btn-icon btn-sm rounded-circle btn-light btn-active-light-primary"
                                            @click="onThumbsDown(singleImage)"
                                        >
                                            <span
                                                class="svg-icon svg-icon-muted svg-icon-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M128 288V64.03c0-17.67-14.33-31.1-32-31.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64C113.7 320 128 305.7 128 288zM481.5 229.1c1.234-5.092 1.875-10.32 1.875-15.64c0-22.7-11.44-43.13-29.28-55.28c.4219-3.015 .6406-6.076 .6406-9.122c0-22.32-11.06-42.6-28.83-54.83c-2.438-34.71-31.47-62.2-66.8-62.2h-52.53c-35.94 0-71.55 11.87-100.3 33.41L169.6 92.93c-6.285 4.71-9.596 11.85-9.596 19.13c0 12.76 10.29 24.04 24.03 24.04c5.013 0 10.07-1.565 14.38-4.811l36.66-27.51c20.48-15.34 45.88-23.81 71.5-23.81h52.53c10.45 0 18.97 8.497 18.97 18.95c0 3.5-1.11 4.94-1.11 9.456c0 26.97 29.77 17.91 29.77 40.64c0 9.254-6.392 10.96-6.392 22.25c0 13.97 10.85 21.95 19.58 23.59c8.953 1.671 15.45 9.481 15.45 18.56c0 13.04-11.39 13.37-11.39 28.91c0 12.54 9.702 23.08 22.36 23.94C456.2 266.1 464 275.2 464 284.1c0 10.43-8.516 18.93-18.97 18.93H307.4c-12.44 0-24 10.02-24 23.1c0 4.038 1.02 8.078 3.066 11.72C304.4 371.7 312 403.8 312 411.2c0 8.044-5.984 20.79-22.06 20.79c-12.53 0-14.27-.9059-24.94-28.07c-24.75-62.91-61.74-99.9-80.98-99.9c-13.8 0-24.02 11.27-24.02 23.99c0 7.041 3.083 14.02 9.016 18.76C238.1 402 211.4 480 289.9 480C333.8 480 360 445 360 411.2c0-12.7-5.328-35.21-14.83-59.33h99.86C481.1 351.9 512 321.9 512 284.1C512 261.8 499.9 241 481.5 229.1z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="d-flex">
                                        <ApplicationMark
                                            width="32"
                                            height="32"
                                        ></ApplicationMark>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="images.length === 0 && !loading" class="col-md-12">
                    <!--begin::Alert-->
                    <div
                        class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 border-none"
                    >
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-column pe-0 pe-sm-10">
                            <!--begin::Content-->
                            <span class="d-none d-lg-block text-gray-600">{{
                                __(
                                    'Please describe on the left side which image you want to create and click on "Create"'
                                )
                            }}</span>
                            <!--end::Content-->
                        </div>
                        <!--end::Wrapper-->
                    </div>
                    <!--end::Alert-->
                </div>
                <div v-show="loading" class="col-md-12 h-100">
                    <div class="row gx-5 gx-xl-10 h-50">
                        <div
                            class="col-12 d-flex justify-content-center align-items-center"
                        >
                            <CreaitorLoading
                                css-classes="w-100px h-100px"
                            ></CreaitorLoading>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Wrapper-->
        </div>
        <!--end::Content-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import ApplicationMark from '../../../Jetstream/ApplicationMark.vue';
import CreaitorLoading from '@/Components/Loading/CreaitorLoading.vue';

export default defineComponent({
    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods
        return { toast };
    },
    components: {
        ApplicationMark,
        CreaitorLoading,
    },
    props: {
        promptTitle: {
            type: String,
            default: 'Input',
        },
        resultTitle: {
            type: String,
            default: 'Choose from the results',
        },
        images: {
            type: Array,
            default: [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        inModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            copyContent: '',
            copyClicked: false,
            singleView: false,
            singleImage: null,
        };
    },
    methods: {
        switchToSingleView(image) {
            this.singleView = true;
            this.singleImage = image;
        },
        onInsert(image) {
            emitter.emit('document-image-insert', image.path);
        },
        downloadImage(item) {
            const link = document.createElement('a');
            link.href = item.path;
            link.download =
                new Date()
                    .toISOString()
                    .replace(/:/g, '-')
                    .replace(/\..+/, '') + '.png';
            link.click();
        },
        copyImage(item) {
            //copy image to clipboard

            //create a temporary element
            const temp = document.createElement('input');
            //set the value of the temporary element to the image path
            temp.value = item.path;
            //append the temporary element to the body
            document.getElementById('kt_content').appendChild(temp);
            //select the temporary element
            temp.select();
            //copy the temporary element
            document.execCommand('copy');
            //remove the temporary element
            document.getElementById('kt_content').removeChild(temp);
            //show a toast
            this.toast.success('Image copied to clipboard');

            this.copyContent = item.path;
            this.copyClicked = true;
            setTimeout(() => {
                this.copyClicked = false;
            }, 1000);
        },
        copyToClipboard() {
            const el = document.createElement('textarea');
            el.value = this.copyContent;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.toast.success('Copied to clipboard');
        },
        onThumbsUp(item) {
            this.toast.success(this.__('Thanks for your feedback'));
            axios.put('/api/image-generator-collection-images/' + item.id, {
                thumbsUp: 1,
            });
        },
        onThumbsDown(item) {
            this.toast.success(this.__('Thanks for your feedback'));
            axios.put('/api/image-generator-collection-images/' + item.id, {
                thumbsDown: 1,
            });
        },
    },
});
</script>

<style lang="scss">
.image-card {
    cursor: pointer;
    .actions {
        .btn {
            color: #898989;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            &:hover {
                color: #5e5e5e;
            }

            &.btn-dark {
                color: #b0b0b0;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    &:hover {
        .actions {
            .btn {
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
            }
        }
    }

    &.single-view {
        cursor: default;

        .actions {
            .btn {
                opacity: 0.5;
            }
            .btn-dark {
                opacity: 0.9;
            }
        }
        &:hover {
            .actions {
                .btn {
                    opacity: 1;
                }
                .btn-dark {
                    opacity: 1;
                }
            }
        }
    }
}
.choice-text {
    white-space: normal;
}
.favorite {
    .svg-icon svg path {
        fill: var(--bs-gray-400);
    }

    &.is-favorite {
        .svg-icon svg path {
            fill: var(--bs-link-color);
        }
    }

    &:hover {
        .svg-icon svg path {
            fill: var(--bs-link-color);
        }
    }
}
</style>
