<template>
    <div class="prompt d-flex flex-column flex-lg-row-auto" :class="classes">
        <!--begin::Wrapper-->
        <div
            class="d-flex flex-column position-xl-fixed bg-lighten top-0 bottom-0"
            :class="classes"
        >
            <!--begin::Header-->
            <div
                class="col-result d-flex flex-row-fluid flex-column scroll-y mt-xl-18 px-10"
            >
                <div class="mt-5">
                    <div
                        class="d-flex justify-content-center p-4 border-bottom d-none"
                    >
                        <ul class="btn-group w-100 w-lg-50">
                            <li
                                class="btn btn-outline border btn-color-muted btn-active-primary bg-white"
                                :class="[
                                    'generate' === this.currentTab
                                        ? 'active'
                                        : '',
                                ]"
                                @click="onTabChange('generate')"
                            >
                                <span>{{ __('Generate') }}</span>
                            </li>
                            <li
                                class="btn btn-outline border btn-color-muted btn-active-primary bg-white"
                                :class="[
                                    'edit' === this.currentTab ? 'active' : '',
                                ]"
                                @click="onTabChange('edit')"
                            >
                                <span>{{ __('Edit') }}</span>
                            </li>
                            <li
                                class="btn btn-outline border btn-color-muted btn-active-primary bg-white"
                                :class="[
                                    'variate' === this.currentTab
                                        ? 'active'
                                        : '',
                                ]"
                                @click="onTabChange('variate')"
                            >
                                <span>{{ __('Variate') }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="" id="myTabContent">
                            <div v-if="'generate' === this.currentTab">
                                <div class="py-10 d-flex flex-column">
                                    <component
                                        v-for="field in fields"
                                        :key="field.key"
                                        :is="field.layout"
                                        :title="
                                            getJsonObject(
                                                field.attributes.title
                                            )[$page.props.locale]
                                        "
                                        :placeholder="
                                            getJsonObject(
                                                field.attributes.placeholder
                                            )[$page.props.locale]
                                        "
                                        :inherited-value="
                                            fieldValues[field.layout]
                                        "
                                        @value-change="onChangeField"
                                    ></component>

                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{ __('Style') }}</label
                                                >
                                                <Multiselect
                                                    v-model="selectedStyle"
                                                    :options="styleOptions"
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'style',
                                                            selectedStyle
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'style',
                                                            selectedStyle
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{ __('Medium') }}</label
                                                >
                                                <Multiselect
                                                    v-model="selectedMedium"
                                                    :options="mediumOptions"
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'medium',
                                                            selectedMedium
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'medium',
                                                            selectedMedium
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{
                                                        __('Artist/Style')
                                                    }}</label
                                                >
                                                <Multiselect
                                                    v-model="
                                                        selectedArtistStyle
                                                    "
                                                    :options="
                                                        artistStyleOptions
                                                    "
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'artist_style',
                                                            selectedArtistStyle
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'artist_style',
                                                            selectedArtistStyle
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{ __('Mood') }}</label
                                                >
                                                <Multiselect
                                                    v-model="selectedMood"
                                                    :options="moodOptions"
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'mood',
                                                            selectedMood
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'mood',
                                                            selectedMood
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{ __('Color') }}</label
                                                >
                                                <Multiselect
                                                    v-model="selectedColor"
                                                    :options="colorOptions"
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'color',
                                                            selectedColor
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'color',
                                                            selectedColor
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{ __('Lighting') }}</label
                                                >
                                                <Multiselect
                                                    v-model="selectedLighting"
                                                    :options="lightingOptions"
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'lighting',
                                                            selectedLighting
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'lighting',
                                                            selectedLighting
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{ __('Texture') }}</label
                                                >
                                                <Multiselect
                                                    v-model="selectedTexture"
                                                    :options="textureOptions"
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'texture',
                                                            selectedTexture
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'texture',
                                                            selectedTexture
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{ __('Settings') }}</label
                                                >
                                                <Multiselect
                                                    v-model="selectedSetting"
                                                    :options="settingOptions"
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'setting',
                                                            selectedSetting
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'setting',
                                                            selectedSetting
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4">
                                            <div
                                                class="fv-row mb-10 fv-plugins-icon-container"
                                            >
                                                <label
                                                    class="text-base text-gray-800 fw-bold mb-2"
                                                    >{{
                                                        __('Art style')
                                                    }}</label
                                                >
                                                <Multiselect
                                                    v-model="selectedArtStyle"
                                                    :options="artStyleOptions"
                                                    :searchable="true"
                                                    :placeholder="__('None')"
                                                    class="px-1 py-1.5"
                                                    @select="
                                                        onChangeCharacteristic(
                                                            'art_style',
                                                            selectedArtStyle
                                                        )
                                                    "
                                                    @deselect="
                                                        onRemoveCharacteristic(
                                                            'art_style',
                                                            selectedArtStyle
                                                        )
                                                    "
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="'edit' === this.currentTab">
                                <ImageEdit></ImageEdit>
                            </div>

                            <div v-if="'variate' === this.currentTab">
                                <ImageVariation
                                    @change-create-disabled="
                                        createDisabled = $event
                                    "
                                ></ImageVariation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Header-->

            <!--end::Wrapper-->
            <div
                class="d-flex flex-column border-top border-end px-11 py-8"
                style="background: #f9fbfc"
            >
                <div class="d-flex flex-column">
                    <!--begin::Accordion-->
                    <div
                        class="accordion accordion-settings mb-4"
                        id="accordion_prompt_settings"
                    >
                        <div class="accordion-item">
                            <h2
                                class="accordion-header"
                                id="accordion_prompt_settings_header_1"
                            >
                                <button
                                    class="accordion-button text-lg fw-bold px-3 py-2"
                                    :class="{
                                        collapsed: promptSettingsAccordionOpen,
                                    }"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#accordion_prompt_settings_body_1"
                                    :aria-expanded="`${promptSettingsAccordionOpen}`"
                                    aria-controls="accordion_prompt_settings_body_1"
                                >
                                    {{ __('Settings') }}
                                </button>
                            </h2>
                            <div
                                id="accordion_prompt_settings_body_1"
                                class="accordion-collapse collapse"
                                :class="{ show: promptSettingsAccordionOpen }"
                                aria-labelledby="accordion_prompt_settings_header_1"
                                data-bs-parent="#accordion_prompt_settings"
                            >
                                <div class="accordion-body">
                                    <Outputs
                                        @outputs-change="
                                            (ev) => $emit('outputs-change', ev)
                                        "
                                        :defaultOutputs="1"
                                    ></Outputs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Accordion-->
                </div>
            </div>

            <!--end::Wrapper-->
            <div
                class="d-flex flex-column border-top border-end px-11 py-8"
                style="background: #f9fbfc"
            >
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="form-check form-switch form-check-custom form-check-solid me-10">
                            <input
                                class="form-check-input h-20px w-30px"
                                type="checkbox"
                                id="qualitySwitch"
                                v-model="quality"
                                true-value="premium"
                                false-value="standard"/>
                            <label class="form-check-label" for="qualitySwitch">
                                {{ __('Premium') }}
                                <span v-tooltip.html="__('The Premium model represents the best achievable quality. Text integration supported.<br /><b>Cost</b>: 10 credits per image.')">
                                    <font-awesome-icon icon="fa-solid fa-circle-info" />
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <button
                            class="btn btn-primary"
                            :disabled="isCreateDisabled"
                            @click.prevent="onCompose"
                        >
                            <span v-if="!loading" class="indicator-label">{{
                                __('Create')
                            }}</span>
                            <span
                                v-if="!loading"
                                class="svg-icon svg-icon-4 ms-1"
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <rect
                                        opacity="0.5"
                                        x="18"
                                        y="13"
                                        width="13"
                                        height="2"
                                        rx="1"
                                        transform="rotate(-180 18 13)"
                                        fill="currentColor"
                                    ></rect>
                                    <path
                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                        fill="currentColor"
                                    ></path></svg
                            ></span>
                            <span v-else class="indicator-progress d-block"
                                >{{ __('Please wait...') }}
                                <ALoading />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Description from '@/Components/Fields/Description.vue';
import NegativePrompt from '@/Components/Fields/NegativePrompt.vue';
import Name from '@/Components/Fields/Name.vue';
import Keyword from '@/Components/Fields/Keyword.vue';
import Multiselect from '@vueform/multiselect';
import Outputs from '@/Layouts/SettingElements/Outputs.vue';
import ToneOfVoice from '@/Layouts/SettingElements/ToneOfVoice.vue';
import TargetAudience from '@/Layouts/SettingElements/TargetAudience.vue';
import Creativity from '@/Layouts/SettingElements/Creativity.vue';
import Language from '@/Layouts/SettingElements/Language.vue';
import WithEmoijs from '@/Layouts/SettingElements/WithEmoijs.vue';
import ImageVariation from '@/Layouts/ImageGenerator/Partials/ImageVariation.vue';
import ImageEdit from '@/Layouts/ImageGenerator/Partials/ImageEdit.vue';
import ALoading from "@/Components/Atoms/ALoading.vue";

export default defineComponent({
    props: {
        user: Object,
        aiAssistantTemplateUuid: {
            type: String,
        },
        aiAssistantTemplates: {
            type: Object,
            default: {},
        },
        promptTitle: {
            type: String,
            default: 'Input',
        },
        promptInfo: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        fields: {
            type: Object,
        },
        options: {
            type: Object,
        },
        classes: {
            type: String,
            default: 'w-lg-400px w-xxl-600px',
        },
    },
    components: {
        ALoading,
        Description,
        NegativePrompt,
        Name,
        Keyword,
        Multiselect,
        Outputs,
        ToneOfVoice,
        TargetAudience,
        Creativity,
        Language,
        WithEmoijs,
        ImageVariation,
        ImageEdit,
    },

    watch: {
        quality: function (val) {
            this.$emit('quality-change', val);
        },
    },

    data: function () {
        return {
            selectedTemplate: '',
            description: '',
            name: '',
            fieldValues: [],
            promptSettingsAccordionOpen: true,
            selectedColor: null,
            currentTab: 'generate',
            createDisabled: false,
            colorOptions: [
                this.__('Ambient light'),
                this.__('Black & white'),
                this.__('Blue'),
                this.__('Brown'),
                this.__('Colorful'),
                this.__('Green'),
                this.__('Grey'),
                this.__('Orange'),
                this.__('Pink'),
                this.__('Purple'),
                this.__('Red'),
                this.__('White'),
                this.__('Wild'),
            ],
            selectedLighting: null,
            lightingOptions: [
                this.__('Cool'),
                this.__('Flat lighting'),
                this.__('High contrast'),
                this.__('High-key'),
                this.__('Low-key'),
                this.__('Natural'),
                this.__('Subtle'),
                this.__('Vivid'),
                this.__('Warm'),
            ],
            selectedTexture: null,
            textureOptions: [
                this.__('Detailed'),
                this.__('Brick'),
                this.__('Bumpy'),
                this.__('Concrete'),
                this.__('Granite'),
                this.__('Marble'),
                this.__('Rough'),
                this.__('Sandpaper'),
                this.__('Smooth'),
                this.__('Stone'),
                this.__('Texture'),
                this.__('Wood'),
            ],
            selectedSetting: null,
            settingOptions: [
                this.__('Close-up'),
                this.__('Action'),
                this.__('Landscape'),
                this.__('Macro'),
                this.__('Night'),
                this.__('Outdoors'),
                this.__('Portrait'),
                this.__('Sport'),
                this.__('Still life'),
                this.__('Travel'),
            ],
            selectedStyle: null,
            styleOptions: [
                this.__('3D Model'),
                this.__('Analog Film'),
                this.__('Anime'),
                this.__('Cinematic'),
                this.__('Comic Book'),
                this.__('Digital Art'),
                this.__('Enhance'),
                this.__('Fantasy Art'),
                this.__('Isometric'),
                this.__('Line Art'),
                this.__('Low Poly'),
                this.__('Modeling Compound'),
                this.__('Neon Punk'),
                this.__('Origami'),
                this.__('Photographic'),
                this.__('Pixel Art'),
                this.__('Tile Texture'),
                /*this.__('Elegant'),
        this.__('Emotional'),
        this.__('Fierce'),
        this.__('Floral'),
        this.__('Foggy'),
        this.__('Framed'),
        this.__('Fresh'),
        this.__('Glamorous'),
        this.__('Gloomy'),
        this.__('Golden'),
        this.__('Gothic'),
        this.__('Honest'),
        this.__('Iconoc'),
        this.__('Intimate'),
        this.__('Minimal'),
        this.__('Monochrome'),
        this.__('Mysterious'),
        this.__('Nostalgic'),
        this.__('Pastel'),
        this.__('Photorealistic'),
        this.__('Playful'),
        this.__('Powerful'),
        this.__('Retro'),
        this.__('Realistic'),
        this.__('Serene'),
        this.__('Strong'),
        this.__('Sweet'),
        this.__('Tinted'),
        this.__('Traditional'),
        this.__('Trendy'),
        this.__('Urban'),
        this.__('Vintage'),
        this.__('Warm'),*/
            ],
            selectedArtStyle: null,
            artStyleOptions: [
                this.__('3D render'),
                this.__('Abstract'),
                this.__('Anime'),
                this.__('Art deco'),
                this.__('Art nouveau'),
                this.__('Baroque'),
                this.__('Cartoon'),
                this.__('Comic'),
                this.__('Cubism'),
                this.__('Cute'),
                this.__('Dark'),
                this.__('Elegant'),
                this.__('Expressionism'),
                this.__('Fauvism'),
                this.__('Futuristic'),
                this.__('Grunge'),
                this.__('Hand drawn'),
                this.__('Illustration'),
                this.__('Impressionism'),
                this.__('Line art'),
                this.__('Minimal'),
                this.__('Minimalism'),
                this.__('Modern'),
                this.__('Muted'),
                this.__('Neon'),
                this.__('Old school'),
                this.__('Origami'),
                this.__('Pixel art'),
                this.__('Pop art'),
                this.__('Retro'),
                this.__('Rococo'),
                this.__('Romanticism'),
                this.__('Rounded'),
                this.__('Rustic'),
                this.__('Scandinavian'),
                this.__('Simple'),
                this.__('Sketch'),
                this.__('Soft'),
                this.__('Solid'),
                this.__('Stick figure'),
                this.__('Sticker art'),
                this.__('Stylized'),
                this.__('Surrealis'),
                this.__('Vintage'),
                this.__('Watercolor'),
                this.__('Woode'),
            ],
            selectedMood: null,
            moodOptions: [
                this.__('Aggressive'),
                this.__('Angry'),
                this.__('Annoyed'),
                this.__('Anxious'),
                this.__('Apathetic'),
                this.__('Arrogant'),
                this.__('Bored'),
                this.__('Brave'),
                this.__('Calm'),
                this.__('Cheerful'),
                this.__('Confident'),
                this.__('Confused'),
                this.__('Cynical'),
                this.__('Depressed'),
                this.__('Determined'),
                this.__('Disappointed'),
                this.__('Disgusted'),
                this.__('Distracted'),
                this.__('Doubtful'),
                this.__('Eager'),
                this.__('Ecstatic'),
                this.__('Embarrassed'),
                this.__('Enraged'),
                this.__('Excited'),
                this.__('Fearful'),
                this.__('Frustrated'),
                this.__('Gloomy'),
                this.__('Grateful'),
                this.__('Groggy'),
                this.__('Guilty'),
                this.__('Happy'),
                this.__('Hopeful'),
                this.__('Horrified'),
                this.__('Humble'),
                this.__('Hungry'),
                this.__('Impressed'),
                this.__('Indifferent'),
                this.__('Insecure'),
                this.__('Inspired'),
                this.__('Irritated'),
                this.__('Jealous'),
                this.__('Joyful'),
                this.__('Lonely'),
                this.__('Loved'),
                this.__('Loving'),
                this.__('Lucky'),
                this.__('Mad'),
                this.__('Miserable'),
                this.__('Nervous'),
                this.__('Optimistic'),
                this.__('Peaceful'),
                this.__('Pessimistic'),
                this.__('Proud'),
                this.__('Relaxed'),
                this.__('Relieved'),
                this.__('Sad'),
                this.__('Satisfied'),
                this.__('Scared'),
                this.__('Sick'),
                this.__('Sleepy'),
                this.__('Stressed'),
                this.__('Surprised'),
                this.__('Tired'),
                this.__('Uncomfortable'),
                this.__('Unhappy'),
                this.__('Unsure'),
                this.__('Worried'),
            ],
            selectedMedium: '',
            mediumOptions: [
                this.__('Acrylics'),
                this.__('Canvas'),
                this.__('Charcoal'),
                this.__('Clay'),
                this.__('Digital'),
                this.__('Drawing'),
                this.__('Enamel'),
                this.__('Fabric'),
                this.__('Felt'),
                this.__('Fiber'),
                this.__('Foil'),
                this.__('Glass'),
                this.__('Graphite'),
                this.__('Ink'),
                this.__('Marker'),
                this.__('Metal'),
                this.__('Oil'),
                this.__('Paper'),
                this.__('Pastel'),
                this.__('Pencil'),
                this.__('Photography'),
                this.__('Plastic'),
                this.__('Print'),
                this.__('Resin'),
                this.__('Sculpture'),
                this.__('Silk'),
                this.__('Stone'),
                this.__('Watercolor'),
                this.__('Wood'),
            ],
            selectedArtistStyle: '',
            artistStyleOptions: [
                this.__('None'),
                this.__('Andy Warhol'),
                this.__('Ansel Adams'),
                this.__('Claude Monet'),
                this.__('Edvard Munch'),
                this.__('Edgar Degas'),
                this.__('Frida Kahlo'),
                this.__("Georgia O'Keeffe"),
                this.__('Jackson Pollock'),
                this.__('Jean-Michel Basquiat'),
                this.__('Leonardo da Vinci'),
                this.__('Marc Chagall'),
                this.__('Pablo Picasso'),
                this.__('Paul Cezanne'),
                this.__('Paul Gauguin'),
                this.__('Paul Klee'),
                this.__('Pierre-Auguste Renoir'),
                this.__('Pixar'),
                this.__('Salvador Dali'),
                this.__('Vincent van Gogh'),
            ],
            quality: 'standard',
        };
    },

    mounted() {
        this.selectedTemplate = this.aiAssistantTemplateUuid;

        //Inorder to prevent execution in ImageGeneratorModal included in AppLayout.
        if (this.$page.component === 'ImageGenerator/ImageGenerator') {
            this.setUserQueryIfExists();
        }

        let vm = this;

        let promptSettingsAccordion = document.getElementById(
            'accordion_prompt_settings'
        );

        promptSettingsAccordion.addEventListener(
            'hidden.bs.collapse',
            (event) => {
                vm.$emit('prompt-settings-accordion-open-change', false);
            }
        );

        promptSettingsAccordion.addEventListener(
            'shown.bs.collapse',
            (event) => {
                vm.$emit('prompt-settings-accordion-open-change', true);
            }
        );

        this.promptSettingsAccordionOpen =
            this.user.user_info.prompt_settings &&
            this.user.user_info.prompt_settings.hasOwnProperty(
                'promptSettingsAccordionOpen'
            )
                ? this.user.user_info.prompt_settings
                    .promptSettingsAccordionOpen
                : true;

        this.fetchPromptSettings();
    },

    methods: {
        setUserQueryIfExists() {
            const urlParms = new URLSearchParams(window.location.search);
            const query = urlParms.get('query');
            if (query) {
                let fieldValue = {
                    component: 'description',
                    value: query,
                };
                this.fieldValues[fieldValue.component] = fieldValue.value;
                this.$emit('prompt-change', fieldValue);
                this.$emit('compose', this.currentTab);
            }
        },
        fetchPromptSettings() {
            const { document, user } = this.$page?.props || {};

            const documentUuid = document?.uuid;
            if (!documentUuid) {
                const promptSettings =
                    user?.current_project?.prompt_settings ||
                    user?.user_info?.prompt_settings;
                if (promptSettings) {
                    this.promptSettings = promptSettings;
                }
                return;
            }

            axios
                .get(`/api/document/${documentUuid}/get-prompt-settings`)
                .then((response) => {
                    this.promptSettings = response.data.prompt_settings;
                    this.$emit('fetched-prompt-settings', this.promptSettings);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        onTabChange(tab) {
            let availableTabs = ['generate', 'edit', 'variate'];

            this.currentTab = availableTabs.includes(tab) ? tab : 'generate';
        },
        onChangeCharacteristic(characteristic, value) {
            this.$emit('characteristic-change', {
                component: characteristic,
                value: value,
            });
        },
        onRemoveCharacteristic(characteristic, value) {
            this.$emit('characteristic-remove', {
                component: characteristic,
                value: value,
            });
        },
        onChangeField(ev) {
            this.fieldValues[ev.component] = ev.value;
            this.$emit('prompt-change', ev);
        },
        selectCustomLabel({ name, email }) {
            return name + ' (' + email + ')';
        },
        getJsonObject(attribute) {
            return typeof attribute === 'string'
                ? JSON.parse(attribute)
                : attribute;
        },
        resetFieldValues() {
            let vm = this;
            for (const [key, value] of Object.entries(vm.fieldValues)) {
                if (key !== 'description') {
                    vm.fieldValues[key] = '';
                    vm.onChangeField({ component: key, value: '' });
                }
            }
        },
        onCompose() {
            if (!this.fieldValues['description']) {
                console.error('Description cannot be empty');
                return;
            }
            this.$emit('compose', this.currentTab);
        }
    },
    computed: {
        isCreateDisabled() {
            // Checks if description is empty or only contains whitespace
            return !this.fieldValues['description']?.trim() || this.createDisabled || this.loading;
        }
    },
});
</script>
<style lang="scss">
.prompt {
    border-right: #eff1f4 1px solid;

    @media (max-width: 992px) {
        border: none;
    }
}

.multiselect {
    border-color: #e4e6ef;
    border-radius: 0.475rem;
    padding: 13px 0;
}

.multiselect-clear {
    display: none;
}

.ai-image-tabs .nav-pills {
    display: inline-flex;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 8px;

    .nav-link {
        color: inherit;

        &.active {
            color: #fff;
        }
    }
}

.accordion-settings {
    .accordion-item {
        border: none;
        background-color: #eee;

        .accordion-button,
        .accordion-body {
            background-color: #f8fbfc;
        }

        .accordion-button {
            color: #181c32;
            border-bottom: 1px solid #eff2f5;

            &.collapsed {
                border-radius: 0;
            }

            &:after {
                color: #181c32;
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
            }

            &:not(.collapsed) {
                border-bottom: 1px solid #eff2f5;
                box-shadow: none;
            }
        }

        .accordion-body {
            border-bottom: 0;
        }
    }
}

.btn.btn-primary {
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}
</style>
