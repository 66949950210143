<template>
    <!--begin::Modal-->
    <Teleport to="body">
        <div
            class="modal fade"
            id="bulk-processing-output-remove-warning-modal"
            tabindex="-1"
            aria-hidden="true"
            data-bs-backdrop="static"
        >
            <!--begin::Modal dialog-->
            <div class="modal-dialog modal-dialog-centered modal">
                <!--begin::Modal content-->
                <div class="modal-content text-center modal-rounded p-8 relative">
                    <div class="modal-icon m-auto mb-7">
                        <svg
                            width="80"
                            height="80"
                            viewBox="0 0 80 80"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                opacity="0.1"
                                cx="40"
                                cy="40"
                                r="40"
                                fill="#E44A44"
                            />
                            <path
                                d="M49.375 33.4375L42.8125 40L49.375 46.625C50.1875 47.375 50.1875 48.6875 49.375 49.4375C48.625 50.25 47.3125 50.25 46.5625 49.4375L40 42.875L33.375 49.4375C32.625 50.25 31.3125 50.25 30.5625 49.4375C29.75 48.6875 29.75 47.375 30.5625 46.625L37.125 40L30.5625 33.4375C29.75 32.6875 29.75 31.375 30.5625 30.625C31.3125 29.8125 32.625 29.8125 33.375 30.625L40 37.1875L46.5625 30.625C47.3125 29.8125 48.625 29.8125 49.375 30.625C50.1875 31.375 50.1875 32.6875 49.375 33.4375Z"
                                fill="#E44A44"
                            />
                        </svg>
                    </div>
                    <h4 class="mb-4">
                        {{
                            __(
                                'Are you sure that you want to delete this column?'
                            )
                        }}
                    </h4>
                    <p class="text-secondary-emphasis fs-5 mb-7">
                        {{
                            __(
                                'Deleting this column will permanently remove all associated data. This action cannot be undone.'
                            )
                        }}
                    </p>
                    <div class="d-flex gap-3">
                        <button
                            class="btn btn-secondary w-100 justify-center"
                            @click="closeModal"
                        >
                            {{ __('Cancel') }}
                        </button>
                        <button
                            class="btn btn-primary w-100 justify-center"
                            @click="deleteColumn"
                        >
                            {{ __('Delete column') }}
                        </button>
                    </div>
                </div>
                <!--end::Modal content-->
            </div>
            <!--end::Modal dialog-->
        </div>
    </Teleport>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            modal: {},
        };
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById(
                'bulk-processing-output-remove-warning-modal'
            ),
            {
                backdrop: 'dynamic',
            }
        );
        // eslint-disable-next-line no-undef
        emitter.on('open-bulk-processing-output-remove-warning-modal', () =>
            vm.openModal()
        );
        // eslint-disable-next-line no-undef
        emitter.on('close-bulk-processing-output-remove-warning-modal', () =>
            vm.closeModal()
        );
    },

    beforeUnmount() {
        // eslint-disable-next-line no-undef
        emitter.off('open-bulk-processing-output-remove-warning-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-bulk-processing-output-remove-warning-modal');
    },

    methods: {
        openModal() {
            this.modal.show();
        },
        closeModal() {
            this.modal.hide();
        },
        deleteColumn() {
            this.$emit('delete-column');
            this.closeModal();
        },
    },
};
</script>
