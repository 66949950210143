import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g stroke=\"currentColor\"><circle cx=\"12\" cy=\"12\" r=\"9.5\" fill=\"none\" stroke-linecap=\"round\" stroke-width=\"3\"><animate attributeName=\"stroke-dasharray\" calcMode=\"spline\" dur=\"1.5s\" keySplines=\"0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1\" keyTimes=\"0;0.475;0.95;1\" repeatCount=\"indefinite\" values=\"0 150;42 150;42 150;42 150\"></animate><animate attributeName=\"stroke-dashoffset\" calcMode=\"spline\" dur=\"1.5s\" keySplines=\"0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1\" keyTimes=\"0;0.475;0.95;1\" repeatCount=\"indefinite\" values=\"0;-16;-59;-59\"></animate></circle><animateTransform attributeName=\"transform\" dur=\"2s\" repeatCount=\"indefinite\" type=\"rotate\" values=\"0 12 12;360 12 12\"></animateTransform></g>", 1)
  ])))
}
export default { render: render }