<template>
    <div class="accordion-header">
        <div
            class="accordion-button rounded-0 collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#seo_check_accordion_collapse_' + id"
            :aria-controls="'seo_check_accordion_collapse_' + id"
        >
            <div class="accordion-title !text-sm">
                {{ title }}
            </div>
            <div class="accordion-badge-container">
                <span v-if="majorCount" class="badge badge-light-danger me-2 flex items-center gap-1 text-xs">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 512 512"
                        fill="currentColor"
                    >
                        <path
                            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                        />
                    </svg>
                    {{ majorCount }} {{ __('Critical') }}
                </span>
                <span v-if="minorCount" class="badge badge-light-warning me-2 flex items-center gap-1 text-xs">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 448 512"
                        fill="currentColor"
                    >
                        <path
                            d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"
                        />
                    </svg>
                    {{ minorCount }} {{ __('Minor') }}
                </span>
                <span
                    v-if="minorCount === 0 && majorCount === 0"
                    class="badge badge-light-success me-2 flex items-center gap-1 text-xs"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 448 512"
                        fill="currentColor"
                    >
                        <path
                            d="M337 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-143 143L97 127c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l80 80c9.4 9.4 24.6 9.4 33.9 0L337 81zM441 201c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-247 247L41 295c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L143 465c9.4 9.4 24.6 9.4 33.9 0L441 201z"
                        />
                    </svg>
                    {{ __('All done') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SeoCheckAccordionHeader',
    props: {
        title: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        majorCount: {
            type: Number,
            required: false,
            default: 0,
        },
        minorCount: {
            type: Number,
            required: false,
            default: 0,
        },
    },
};
</script>

<style scoped>
.accordion-header,
.accordion-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-title {
    flex-grow: 1;
    font-size: 1rem;
    font-weight: 600;
}

.accordion-badge-container {
    display: flex;
}

.badge {
    margin-left: 5px;
}
</style>
