<template>
    <div
        class="modal fade"
        id="kt_modal_one_click_article_generation"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-fullscreen">
            <!--begin::Modal content-->
            <div class="modal-content bg-gray-100">
                <!--begin::Modal header-->
                <div class="modal-header bg-white">
                    <!--begin::Heading-->
                    <h2>
                        {{ __('One Click Article Wizard') }}
                        <span class="badge badge-light fs-6">
                            {{ __('Beta Release') }}
                        </span>
                    </h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div
                    class="modal-body m-5 mx-0 my-0 p-10"
                    v-if="articleWizardStore.getArticleWizardType === 'one_click'"
                >
                    <div class="mb-15 h-100">
                        <div class="row pb-15">
                            <div class="col-md-6">
                                <div
                                    class="bg-white border border-1 border-secondary p-8 h-100 rounded"
                                >
                                    <Description
                                        :placeholder="
                                            __(
                                                'A small description about the article'
                                            )
                                        "
                                        :title="__('Article topic')"
                                        ref="articleTopic"
                                    >
                                    </Description>

                                    <div class="d-flex flex-column mt-20 mb-10">
                                        <p>
                                            {{
                                                __(
                                                    'Choose your desired language'
                                                )
                                            }}
                                        </p>
                                        <Language
                                            :allowed-languages="
                                                allowedLanguages
                                            "
                                            :lang="aiRequestData.lang"
                                            @language-change="onLanguageChange"
                                            @formality-change="
                                                onFormalityChange
                                            "
                                        >
                                        </Language>
                                    </div>

                                    <button
                                        class="btn btn-primary"
                                        :class="[loading ? 'disabled' : '']"
                                        @click.stop="generateArticle"
                                    >
                                        <span
                                            v-if="!loading"
                                            class="indicator-label"
                                            >{{ __('Create') }}</span
                                        >
                                        <span
                                            v-if="!loading"
                                            class="svg-icon svg-icon-4 ms-1"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x="18"
                                                    y="13"
                                                    width="13"
                                                    height="2"
                                                    rx="1"
                                                    transform="rotate(-180 18 13)"
                                                    fill="currentColor"
                                                ></rect>
                                                <path
                                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span
                                            v-else
                                            class="indicator-progress d-block"
                                        >
                                            {{ __('Please wait...') }}
                                            <ALoading />
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div
                                    class="bg-white border border-1 border-secondary p-8 h-100 rounded"
                                    v-if="showEditor"
                                >
                                    <div
                                        v-if="showEditor"
                                        class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid border border-1 border-secondary"
                                        data-kt-buttons="true"
                                        data-kt-initialized="1"
                                    >
                                        <div
                                            class="d-flex flex-column article-draft"
                                        >
                                            <div class="article-draft-editor">
                                                <menu-bar :editor="editor" />
                                            </div>
                                            <div class="article-draft-content">
                                                <editor-content
                                                    :editor="editor"
                                                />
                                            </div>

                                            <!-- Article Body 1 -->
                                        </div>
                                    </div>
                                    <div class="article-add-btn mt-10">
                                        <button
                                            type="button"
                                            class="btn btn-lg btn-primary"
                                            @click="saveArticleAsDocumentContent"
                                        >
                                            {{ __('Add') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import Language from '@/Layouts/SettingElements/Language.vue';
import { BubbleMenu, Editor, EditorContent, FloatingMenu } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import MenuBar from '@/Components/Editor/MenuBar.vue';
import CharacterCount from '@tiptap/extension-character-count';
import {router as Inertia} from '@inertiajs/vue3';
import Description from '@/Components/Fields/Description.vue';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import { Modal } from 'bootstrap';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';
import ALoading from "@/Components/Atoms/ALoading.vue";

export default {
    mixins: [trialLimitExhausted],
    setup() {
        // Get toast interface
        const toast = useToast();

        //Get article wizard store
        const articleWizardStore = useArticleWizardStore();

        // Make it available inside methods
        return {
            toast,
            articleWizardStore,
        };
    },

    props: {
        user: Object,
    },

    components: {
        ALoading,
        Language,
        Description,
        EditorContent,
        Image,
        MenuBar,
        BubbleMenu,
        FloatingMenu,
        CharacterCount,
    },

    data() {
        return {
            modal: {},
            loading: false,
            aiCommandUuids: {
                articleOutline: 'fa689328-cb21-4f49-b9b7-f36415bd93cf',
                articleBody: 'f156e123-117b-4d64-9851-b48076a2af61',
            },
            aiRequestData: {
                options: {
                    creativity: 0.75,
                    outputs: 1,
                    toneOfVoice: 'friendly',
                    withEmojis: false,
                    frequency_penalty: '0.5',
                    presence_penalty: '0.5',
                },
                lang:
                    this.$page.props.user.user_info.prompt_settings &&
                    this.$page.props.user.user_info.prompt_settings.targetLang
                        ? this.$page.props.user.user_info.prompt_settings
                              .targetLang
                        : 'en',
                formality:
                    this.$page.props.user.user_info.prompt_settings &&
                    this.$page.props.user.user_info.prompt_settings
                        .formality !== undefined
                        ? this.$page.props.user.user_info.prompt_settings
                              .formality
                        : 'default',
            },
            currentSupportedLanguages: [],
            allowedLanguages: [],
            languageDisabled: true,
            editor: null,
            showEditor: false,
            metaContent: {
                title: '',
                description: '',
                domain: '',
                slug: '',
            },
        };
    },
    beforeUnmount() {
        this.editor.destroy();
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_one_click_article_generation')
        );
        this.editor = new Editor({
            content: this.articleContent?.text,
            extensions: [StarterKit, Image, CharacterCount],
        });
        this.fetchSupportedLanguages();
        this.fetchLocations();
    },
    methods: {
        fetchSupportedLanguages() {
            let vm = this;
            axios
                .get('/api/languages/language-codes')
                .then((response) => {
                    vm.currentSupportedLanguages = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchLocations() {
            let vm = this;
            axios
                .get('/api/locations')
                .then((response) => {
                    vm.searchEngineOptions = response.data.filter(
                        (location) => {
                            return location.languages.some((language) => {
                                return vm.currentSupportedLanguages.includes(
                                    language.language_code
                                );
                            });
                        }
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onLanguageChange(ev) {
            this.aiRequestData.lang = ev;
            this.$emit('language-change', ev);
        },
        onFormalityChange(ev) {
            this.$emit('formality-change', ev);
        },
        async generateArticle() {
            this.loading = true;

            if (this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage) {
                let articleOutline = await this.generateOutline();
                let articleBody = articleOutline
                    ? await this.generateArticleBody(articleOutline)
                    : '';

                if (articleBody) {
                    this.showEditor = true;
                    articleBody = articleBody.replace(/```(html|markdown)?\n?/g, '');
                    this.editor.commands.setContent(articleBody);
                }

                Inertia.reload({ only: ['user'] });
                this.loading = false;
            } else {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
                this.loading = false;
            }
        },
        async generateOutline() {
            let requestData = {
                uuid: this.aiCommandUuids.articleOutline,
                prompts: {
                    description: this.$refs.articleTopic.text,
                },
            };

            return await this.sendOpenAiRequest(requestData);
        },
        async generateArticleBody(articleOutline) {
            let requestData = {
                uuid: this.aiCommandUuids.articleBody,
                prompts: {
                    description: articleOutline,
                },
            };

            let vm = this;
            let responseData = '';

            await axios
                .post('/api/article/ai-assistant/one-click-article-body', {
                    ...this.aiRequestData,
                    ...requestData,
                })
                .then((response) => {
                    responseData = response.data.choices[0].text;
                })
                .catch(function (error) {
                    vm.toast.error(
                        error.response.data?.message ||
                            vm.__('Some error occurred')
                    );
                    if (
                        error.response.data.error_code ==
                        'trial_credit_limit_exhausted'
                    ) {
                        vm.showTrialLimitExhaustedPopUp();
                    }
                });

            return responseData;
        },
        async sendOpenAiRequest(requestData) {
            let vm = this;
            let responseData = '';

            await axios
                .post('/api/ai-assistant/compose', {
                    ...this.aiRequestData,
                    ...requestData,
                })
                .then((response) => {
                    responseData = response.data.choices[0].text;
                })
                .catch(function (error) {
                    vm.toast.error(
                        error.response.data?.message ||
                            vm.__('Some error occurred')
                    );
                    if (
                        error.response.data.error_code ==
                        'trial_credit_limit_exhausted'
                    ) {
                        vm.showTrialLimitExhaustedPopUp();
                    }
                });

            return responseData;
        },

        saveArticleAsDocumentContent() {
            let data = {
                name: this.$refs.articleTopic.text,
                content: this.editor.getJSON(),
                meta: this.metaContent,
                keyword: null,
            };

            axios
                .post('/api/documents', data)
                .then((response) => {
                    let documentUrl = `${window.location.origin}/documents/${response.data.uuid}`;
                    window.location.assign(documentUrl);
                })
                .catch((error) => {
                    this.toast.error(this.__('An error occurred'));
                });
        },
    },
    computed: {
        userTeamHasEnoughCredits() {
            return (
                this.user.current_team.team_plan_credits.character_credits >
                    0 ||
                this.user.current_team.team_bonus_credits.character_credits > 0
            );
        },
        userHasUnlimitedUsage() {
            let user = this.user;
            let plans = this.getPlans();

            // Check if the user has a normal standard or professional subscription
            let hasNormalSubscription = user.current_team.subscriptions.some(
                (subscription) => {
                    const paddlePlanId = subscription.paddle_plan;
                    const professionalPlans = plans.professional;
                    const standardPlans = plans.standard;

                    return (
                        professionalPlans.includes(paddlePlanId) ||
                        standardPlans.includes(paddlePlanId)
                    );
                }
            );

            // Check if the user has a special lifetime subscription based on plan name or having more than one lifetime subscription
            let hasLifetimeSubscription =
                user.current_team.lifetime_subscriptions.some(
                    (subscription) => {
                        // Check if the plan name is "professional" or "standard"
                        if (
                            subscription.plan &&
                            ['professional', 'standard'].includes(
                                subscription.plan.toLowerCase()
                            )
                        ) {
                            return true;
                        } else if (
                            subscription.plan &&
                            ['basic'].includes(subscription.plan.toLowerCase())
                        ) {
                            // Check if the plan name is "basic"
                            return false;
                        }

                        // Check if the user has more than one lifetime subscription
                        return (
                            user.current_team.lifetime_subscriptions.length > 0
                        );
                    }
                );

            // Return true if the user has a normal subscription or a special lifetime subscription
            return hasNormalSubscription || hasLifetimeSubscription;
        },
    },
};
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    min-height: 25rem;
    padding: 20px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    hr {
        border: none;
        border-top: 1px solid #0d0d0d82;
        margin: 2rem 0;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    &-focused,
    &:focus,
    &:focus-visible {
        border: none;
        outline: none;
    }
}
</style>
