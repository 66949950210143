<template>
    <app-layout
        :title="__('Image Generator')"
        :view="__('Collections')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Collections') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1 class="text-2xl">{{ __('Collections') }}</h1>
                <div class="text-gray-400 text-sm fw-bold">
                    {{
                        __(
                            'Here you can find all the collections you have created.'
                        )
                    }}
                </div>
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-sm-12 col-md-4">
                <CreditInfoCard
                    :is-loading="isImageDataLoading"
                    :total="totalImageGenerators"
                >
                    {{ __('Total image collections') }}
                </CreditInfoCard>
            </div>
            <div class="col-sm-12 col-md-4">
                <CreditInfoCard
                    :is-loading="isImageDataLoading"
                    :total="totalImageGeneratorImages"
                >
                    {{ __('Total generated images') }}
                </CreditInfoCard>
            </div>
            <div class="col-sm-12 col-md-4">
                <CreditsCard
                    :is-loading="isCreditsLoading"
                    :total-credits="totalCredits"
                    buy-more-credits-modal="buy-more-image-credits"
                ></CreditsCard>
            </div>
        </div>

        <ImageCollectionsTable
            :total-credits="totalCredits"
        ></ImageCollectionsTable>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import ImageCollectionsTable from '@/Components/ImageGenerator/ImageCollectionsTable.vue';
import CreditInfoCard from '../../Components/Cards/CreditInfoCard.vue';
import CreditsCard from '../../Components/Cards/CreditsCard.vue';

export default defineComponent({
    components: {
        Link,
        AppLayout,
        ImageCollectionsTable,
        CreditInfoCard,
        CreditsCard,
    },

    data() {
        return {
            isCreditsLoading: false,
            totalImageGenerators: 0,
            isImageDataLoading: false,
            totalImageGeneratorImages: 0,
        };
    },

    computed: {
        totalCredits() {
            let teamPlanCredits =
                this.$attrs.user?.current_team?.team_plan_credits
                    ?.image_credits || 0;
            let teamBonusCredits =
                this.$attrs.user?.current_team?.team_bonus_credits
                    ?.image_credits || 0;

            // check if subscription_plan_addon has entry with code image and if so add amount to teamAddonCredits
            let subscriptionPlanAddonCredits =
                this.$attrs.user?.current_team?.subscription_plan_addon_credits;
            let teamAddonCredits = 0;

            if (subscriptionPlanAddonCredits) {
                subscriptionPlanAddonCredits.forEach((addon) => {
                    if (addon.subscription_plan_addon.code === 'image') {
                        teamAddonCredits += addon.amount;
                    }
                });
            }

            return teamPlanCredits + teamAddonCredits + teamBonusCredits;
        },
    },

    mounted() {
        this.fetchImageData();
    },

    methods: {
        fetchImageData() {
            this.isImageDataLoading = true;
            axios
                .get(route('get-image-generation-count'))
                .then((response) => {
                    this.totalImageGeneratorImages =
                        response.data.image_collection_count;
                    this.totalImageGenerators = response.data.image_count;
                    this.isImageDataLoading = false;
                })
                .finally(() => {
                    this.isImageDataLoading = false;
                });
        },
    },
});
</script>

<style lang="scss"></style>
