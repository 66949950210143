<template>
    <div class="row">
        <div class="col-sm-12 col-xl-4">
            <StatisticsCard
                :label="__('Files uploaded')"
                :value="statistics.total_uploads"
            />
        </div>
        <div class="col-sm-12 col-xl-4">
            <StatisticsCard
                :label="__('Items processed')"
                :value="statistics.total_rows_processed"
            />
        </div>
        <div class="col-sm-12 col-xl-4">
            <StatisticsCard
                :cardClass="'card-bg-blue'"
                :label="__('Characters available')"
                :value="availableCharacterCredits"
                :isLoading="availableCharacterCredits === null"
                :urlLabel="__('Buy more')"
            >
                <template v-slot:link="{ urlLabel }">
                    <a
                        @click.prevent="buyMoreCredits"
                        class="btn btn-sm btn-white"
                    >
                        <span class="svg-icon svg-icon-muted svg-icon-2">
                            {{ urlLabel }}
                        </span>
                    </a>
                </template>
            </StatisticsCard>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useUserStore } from '@/Stores/UserStore';
import StatisticsCard from '@/Components/Cards/StatisticsCard.vue';

export default defineComponent({
    name: 'BulkProcessingStatistics',

    components: {
        StatisticsCard,
    },

    props: {
        statistics: {
            type: Object,
            required: true,
        },
    },

    setup() {
        // Get user store
        const userStore = useUserStore();

        // Make it available inside methods
        return {
            userStore,
        };
    },

    data() {
        return {
            availableCharacterCredits: null,
        };
    },

    mounted() {
        this.fetchAvailableCharacter('lastFilled');
    },

    methods: {
        buyMoreCredits() {
            emitter.emit('open-buy-more-character-credits');
        },
        fetchAvailableCharacter(range) {
            const vm = this;

            axios
                .get(route('statistics.used-characters', { range }))
                .then((response) => {
                    const totalCredits = response.data?.totalCredits ?? 0;
                    vm.availableCharacterCredits = totalCredits.toLocaleString(
                        vm.userStore.getUserLocale
                    );
                });
        },
    },
});
</script>
<style scoped>
.btn-white{
    background-color: #fff;
    border-collapse: #EBEEF5;
}
.card-bg-blue{
    background: linear-gradient(to bottom, #18ABFF33, #FFF);
}

</style>
