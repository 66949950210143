<template>
    <div class="mb-10">
        <!--begin::Wrapper-->
        <div class="d-flex flex-column align-items-start">
            <!--begin::User-->
            <div
                class="d-flex align-items-center justify-content-between h-35px w-100 flex-wrap"
            >
                <div class="d-flex align-items-center text-end">
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle">
                        <img
                            v-if="!chat.isAi"
                            :src="$page?.props?.user?.profile_photo_url"
                            alt=""
                        />
                        <span
                            v-else
                            class="svg-icon svg-icon-primary profile-icon"
                        >
                            <svg
                                width="100%"
                                height="100%"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 128.98 113.78"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient-508"
                                        x1="0.99"
                                        y1="49.81"
                                        x2="104.18"
                                        y2="108.06"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            offset="0"
                                            stop-color="#91f24a"
                                        ></stop>
                                        <stop
                                            offset="0.64"
                                            stop-color="#2bc7d4"
                                        ></stop>
                                        <stop
                                            offset="0.72"
                                            stop-color="#2ac3d3"
                                        ></stop>
                                        <stop
                                            offset="0.8"
                                            stop-color="#28b7d0"
                                        ></stop>
                                        <stop
                                            offset="0.88"
                                            stop-color="#25a3cb"
                                        ></stop>
                                        <stop
                                            offset="0.96"
                                            stop-color="#2088c4"
                                        ></stop>
                                        <stop
                                            offset="1"
                                            stop-color="#1d75bf"
                                        ></stop>
                                    </linearGradient>
                                    <linearGradient
                                        id="linear-gradient-508-2"
                                        x1="31.47"
                                        y1="-4.18"
                                        x2="134.66"
                                        y2="54.06"
                                        xlink:href="#linear-gradient-508"
                                    ></linearGradient>
                                    <linearGradient
                                        id="linear-gradient-508-3"
                                        x1="-5.65"
                                        y1="61.59"
                                        x2="97.53"
                                        y2="119.83"
                                        xlink:href="#linear-gradient-508"
                                    ></linearGradient>
                                    <linearGradient
                                        id="linear-gradient-508-4"
                                        x1="19.48"
                                        y1="17.06"
                                        x2="122.67"
                                        y2="75.3"
                                        xlink:href="#linear-gradient-508"
                                    ></linearGradient>
                                </defs>
                                <g
                                    id="Creaitor_Mark_508"
                                    data-name="Creaitor_Mark508"
                                >
                                    <g
                                        id="Creaitor_Mark_Layer_508"
                                        data-name="Creaitor_Mark_Layer508"
                                    >
                                        <circle
                                            cx="13.53"
                                            cy="56.89"
                                            r="13.53"
                                            style="
                                                fill: url('#linear-gradient-508');
                                            "
                                        ></circle>
                                        <path
                                            d="M88.26,0a13.43,13.43,0,0,0-7.18,2.07L81,2.14c-.56.46-1.14.9-1.73,1.32A28.25,28.25,0,0,1,62.84,8.67,28.24,28.24,0,0,1,46.46,3.46c-.47-.33-.94-.68-1.39-1l-.34-.27a13.53,13.53,0,1,0-.46,23.05c.26-.23.53-.45.8-.67.61-.48,1.23-.95,1.88-1.39a28.42,28.42,0,0,1,31.79,0,28.71,28.71,0,0,1,2.34,1.77l.33.29A13.53,13.53,0,1,0,88.26,0Z"
                                            style="
                                                fill: url('#linear-gradient-508-2');
                                            "
                                        ></path>
                                        <path
                                            d="M88.26,86.73a13.42,13.42,0,0,0-7.18,2.06l-.12.08c-.56.46-1.14.9-1.73,1.32a28.41,28.41,0,0,1-32.77,0c-.47-.34-.94-.69-1.39-1l-.34-.27a13.53,13.53,0,1,0-.46,23l.8-.66c.61-.49,1.23-1,1.88-1.39a28.42,28.42,0,0,1,31.79,0,26.6,26.6,0,0,1,2.34,1.77l.33.28a13.4,13.4,0,0,0,6.85,1.87,13.53,13.53,0,1,0,0-27Z"
                                            style="
                                                fill: url('#linear-gradient-508-3');
                                            "
                                        ></path>
                                        <path
                                            d="M115.46,43.36a13.49,13.49,0,0,0-7.19,2.07l-.11.07c-.56.47-1.14.9-1.74,1.32a28.37,28.37,0,0,1-32.77,0c-.47-.33-.94-.68-1.39-1l-.34-.28a13.52,13.52,0,1,0-.45,23c.26-.22.52-.45.79-.66.61-.49,1.23-1,1.88-1.4a28.42,28.42,0,0,1,31.79,0,25.63,25.63,0,0,1,2.34,1.77l.34.29a13.53,13.53,0,1,0,6.85-25.19Z"
                                            style="
                                                fill: url('#linear-gradient-508-4');
                                            "
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <!--end::Avatar-->

                    <!--begin::Details-->
                    <div class="chat-message-owner ai" v-if="chat.isAi">
                        <span class="fs-5 fw-bolder me-1">{{
                            __(getUserText())
                        }}</span>
                    </div>

                    <div class="chat-message-owner user" v-else>
                        <span class="fs-5 fw-bolder me-1">{{
                            getUserText()
                        }}</span>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <div
                        class="actions text-end"
                        v-if="chat.isAi && !chat.isLoading"
                    >
                        <a
                            href="#"
                            class="btn btn-icon btn-hover-scale rounded-0 btn-active-color-primary btn-sm"
                            @click.prevent="onInsert(index)"
                        >
                            <span class="svg-icon svg-icon-muted svg-icon-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    fill="currentColor"
                                >
                                    <path
                                        d="M416 128h-40C362.7 128 352 138.7 352 152c0 13.25 10.75 24 24 24H416c8.836 0 16 7.164 16 16v256c0 8.836-7.164 16-16 16H96c-8.836 0-16-7.164-16-16V192c0-8.836 7.164-16 16-16h40C149.3 176 160 165.3 160 152C160 138.7 149.3 128 136 128H96C60.65 128 32 156.7 32 192v256c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V192C480 156.7 451.3 128 416 128zM143.7 238.6C133.1 247.6 133.4 262.8 142.4 272.6l96 104.1c9.062 9.82 26.19 9.82 35.25 0l96-104.1c9-9.758 8.406-24.95-1.344-33.93c-9.781-9.07-24.97-8.414-33.91 1.344L280 298.9V24.02C280 10.76 269.3 0 256 0S232 10.76 232 24.02v274.9l-54.38-58.95C172.9 234.8 166.5 232.3 160 232.3C154.2 232.3 148.3 234.3 143.7 238.6z"
                                    />
                                </svg>
                            </span>
                        </a>
                        <a
                            href="#"
                            class="btn btn-icon btn-hover-scale rounded-0 btn-active-color-primary btn-sm"
                            @click.prevent="onCopy(index)"
                        >
                            <span class="svg-icon svg-icon-muted svg-icon-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    fill="currentColor"
                                >
                                    <path
                                        d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"
                                    />
                                </svg>
                            </span>
                        </a>
                        <a
                            href="#"
                            class="btn btn-icon btn-hover-scale rounded-0 btn-active-color-primary btn-sm"
                            @click.prevent="onThumbsUp"
                            v-if="!chat.isOldChat && !isRated"
                        >
                            <span class="svg-icon svg-icon-muted svg-icon-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    fill="currentColor"
                                >
                                    <path
                                        d="M96 191.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64c17.67 0 32-14.33 32-31.1V223.1C128 206.3 113.7 191.1 96 191.1zM512 227c0-36.89-30.05-66.92-66.97-66.92h-99.86C354.7 135.1 360 113.5 360 100.8c0-33.8-26.2-68.78-70.06-68.78c-46.61 0-59.36 32.44-69.61 58.5c-31.66 80.5-60.33 66.39-60.33 93.47c0 12.84 10.36 23.99 24.02 23.99c5.256 0 10.55-1.721 14.97-5.26c76.76-61.37 57.97-122.7 90.95-122.7c16.08 0 22.06 12.75 22.06 20.79c0 7.404-7.594 39.55-25.55 71.59c-2.046 3.646-3.066 7.686-3.066 11.72c0 13.92 11.43 23.1 24 23.1h137.6C455.5 208.1 464 216.6 464 227c0 9.809-7.766 18.03-17.67 18.71c-12.66 .8593-22.36 11.4-22.36 23.94c0 15.47 11.39 15.95 11.39 28.91c0 25.37-35.03 12.34-35.03 42.15c0 11.22 6.392 13.03 6.392 22.25c0 22.66-29.77 13.76-29.77 40.64c0 4.515 1.11 5.961 1.11 9.456c0 10.45-8.516 18.95-18.97 18.95h-52.53c-25.62 0-51.02-8.466-71.5-23.81l-36.66-27.51c-4.315-3.245-9.37-4.811-14.38-4.811c-13.85 0-24.03 11.38-24.03 24.04c0 7.287 3.312 14.42 9.596 19.13l36.67 27.52C235 468.1 270.6 480 306.6 480h52.53c35.33 0 64.36-27.49 66.8-62.2c17.77-12.23 28.83-32.51 28.83-54.83c0-3.046-.2187-6.107-.6406-9.122c17.84-12.15 29.28-32.58 29.28-55.28c0-5.311-.6406-10.54-1.875-15.64C499.9 270.1 512 250.2 512 227z"
                                    />
                                </svg>
                            </span>
                        </a>
                        <a
                            href="#"
                            class="btn btn-icon btn-hover-scale rounded-0 btn-active-color-primary btn-sm"
                            @click.prevent="onThumbsDown"
                            v-if="!chat.isOldChat && !isRated"
                        >
                            <span class="svg-icon svg-icon-muted svg-icon-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    fill="currentColor"
                                >
                                    <path
                                        d="M128 288V64.03c0-17.67-14.33-31.1-32-31.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64C113.7 320 128 305.7 128 288zM481.5 229.1c1.234-5.092 1.875-10.32 1.875-15.64c0-22.7-11.44-43.13-29.28-55.28c.4219-3.015 .6406-6.076 .6406-9.122c0-22.32-11.06-42.6-28.83-54.83c-2.438-34.71-31.47-62.2-66.8-62.2h-52.53c-35.94 0-71.55 11.87-100.3 33.41L169.6 92.93c-6.285 4.71-9.596 11.85-9.596 19.13c0 12.76 10.29 24.04 24.03 24.04c5.013 0 10.07-1.565 14.38-4.811l36.66-27.51c20.48-15.34 45.88-23.81 71.5-23.81h52.53c10.45 0 18.97 8.497 18.97 18.95c0 3.5-1.11 4.94-1.11 9.456c0 26.97 29.77 17.91 29.77 40.64c0 9.254-6.392 10.96-6.392 22.25c0 13.97 10.85 21.95 19.58 23.59c8.953 1.671 15.45 9.481 15.45 18.56c0 13.04-11.39 13.37-11.39 28.91c0 12.54 9.702 23.08 22.36 23.94C456.2 266.1 464 275.2 464 284.1c0 10.43-8.516 18.93-18.97 18.93H307.4c-12.44 0-24 10.02-24 23.1c0 4.038 1.02 8.078 3.066 11.72C304.4 371.7 312 403.8 312 411.2c0 8.044-5.984 20.79-22.06 20.79c-12.53 0-14.27-.9059-24.94-28.07c-24.75-62.91-61.74-99.9-80.98-99.9c-13.8 0-24.02 11.27-24.02 23.99c0 7.041 3.083 14.02 9.016 18.76C238.1 402 211.4 480 289.9 480C333.8 480 360 445 360 411.2c0-12.7-5.328-35.21-14.83-59.33h99.86C481.1 351.9 512 321.9 512 284.1C512 261.8 499.9 241 481.5 229.1z"
                                    />
                                </svg>
                            </span>
                        </a>
                    </div>
                    <!--end::Details-->
                </div>
            </div>
            <!--end::User-->

            <!--begin::Text-->
            <div class="pb-5 px-13 rounded text-start w-100 text-lg">
                <div
                    v-html="markdownResponse"
                    class="chat-gpt-response"
                    :class="[`chat-index-${index}`]"
                ></div>
                <span v-if="isLastMessage && isStreaming" class="blink-effect"></span>
            </div>
            <!--end::Text-->
        </div>
        <!--end::Wrapper-->
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import moment from 'moment';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Blockquote from '@tiptap/extension-blockquote';
import Text from '@tiptap/extension-text';
import { generateJSON } from '@tiptap/core';
import Swal from 'sweetalert2';
import 'highlight.js/styles/atom-one-dark-reasonable.css';
import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';

export default {
    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast };
    },

    props: {
        chatUuid: {
            type: String,
            default: null,
        },
        chat: {
            type: Object || null,
        },
        index: {
            type: Number || null,
        },
        insertContent: {
            type: Boolean,
            default: false,
        },
        isStreaming: {
            type: Boolean,
            default: false,
        },
        isLastMessage: {
            type: Boolean,
            default: false,
        },

        user: Object,
    },

    data() {
        return {
            isRated: false,
            markdown: null,
            speed: 1,
            displayedResponse: '',
            typingTimer: null,
            isTyping: false,
            chatResponseBuffer: '',
            isLoaded: false,
        };
    },
    async mounted() {
        this.$emit('update-scroll-height');

        this.attachCopyEventListeners();

        this.$nextTick(() => {
            if (!this.chat.isLoading) {
                this.isLoading = this.chat.isLoading;
            }
        });
    },
    computed: {
        markdownResponse() {
            this.markdown = this.markdown
                ? this.markdown
                : this.getMarkdownObject();

            if (!this.chat?.isAi) {
                return this.chat.response;
            }

            return this.markdown.render(this.displayedResponse);
        },
    },
    watch: {
        'chat.response': {
            handler(newValue) {
                if (this.chat?.isAi) {
                    // Clear any existing timer
                    if (this.typingTimer) {
                        clearInterval(this.typingTimer);
                    }
                    // Start new typing effect
                    this.startTypingEffect(newValue);
                }
            },
            immediate: true
        }
    },
    methods: {
        getMarkdownObject() {
            let vm = this;
            let md = new MarkdownIt({
                highlight: function (str, lang) {
                    if (lang && hljs.getLanguage(lang)) {
                        try {
                            return hljs.highlight(str, { language: lang }).value;
                        } catch (__) {}
                    }
                    // Fallback to plaintext if the language is not found
                    return hljs.highlight(str, { language: 'plaintext' }).value;
                },
            });

            md.renderer.rules.fence = function (tokens, idx, options, env, self) {
                let token = tokens[idx];
                let language = token.info.trim() || 'plaintext';

                // Ensuring the language is available or fallback to plaintext
                if (!hljs.getLanguage(language)) {
                    language = 'plaintext';
                }

                let highlightedCode = hljs.highlight(token.content, { language }).value;

                return `<pre class="code">
            <div class="bg-black rounded-md">
                <div class="code-header d-flex justify-content-between align-items-center text-gray-200 bg-gray-800 px-4 py-2">
                    <span class="code-language">${language}</span>
                    <button class="copy-button d-flex align-items-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z" fill="currentColor">
                            </path>
                        </svg>
                        <span class="copy-text">${vm.__('Copy')}</span>
                    </button>
                </div>
                <div class="p-4 overflow-y-auto">
                    <code class="!whitespace-pre hljs language-${language}">${highlightedCode}</code>
                </div>
            </div>
        </pre>`;
            };

            return md;
        },

        updateMarkdown() {
            const markdownDiv = this.$el.querySelector(
                `.chat-index-${this.index}`
            );
            markdownDiv.innerHTML = this.getMarkdownObject().render(
                this.chat.originalResponse
            );
        },

        getUserText() {
            return this.chat?.isAi ? this.__('Creaitor') : this.__('You');
        },

        onInsert(index) {
            let vm = this;
            this.chat.isLoading = true;

            if (this.insertContent) {
                emitter.emit('document-text-insert', this.chat?.response);
            } else {
                let documentName = `${
                    this.user.name.split(' ', 2)[0]
                }'s Document ${moment().format('L LTS')}`;
                let content = this.markdownToTipTapJson(
                    this.encodeHtmlEntity(this.chat?.response)
                );
                let data = {
                    name: documentName,
                    content: content,
                    user_id: this.user.id,
                    chat_gpt_conversation_uuid: this.chatUuid,
                };

                axios.post('/api/documents', data).then((response) => {
                    Swal.fire({
                        text: vm.__('Document created successfully!'),
                        icon: 'success',
                        buttonsStyling: false,
                        showCancelButton: true,
                        confirmButtonText: vm.__('View document'),
                        cancelButtonText: vm.__('Continue chatting'),
                        customClass: {
                            confirmButton: 'btn btn-secondary me-10',
                            cancelButton: 'btn btn-primary',
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.assign(
                                `/documents/${response.data.uuid}`
                            );
                        }
                    });
                });
            }
            this.chat.isLoading = false;
        },

        markdownToTipTapJson(markdownText) {
            const md = new MarkdownIt();
            const tokens = md.parse(markdownText, {});
            return this.tokensToTipTapJson(tokens);
        },

        tokensToTipTapJson(tokens, newDocument = true) {
            let content = [];
            const stack = [{ type: 'doc', content: content }];

            tokens.forEach(token => {
                let current = stack[stack.length - 1];

                switch (token.type) {
                    case 'heading_open':
                        stack.push({
                            type: 'heading',
                            attrs: { level: parseInt(token.tag.charAt(1), 10) },
                            content: []
                        });
                        break;

                    case 'paragraph_open':
                        stack.push({ type: 'paragraph', content: [] });
                        break;

                    case 'blockquote_open':
                        stack.push({ type: 'blockquote', content: [] });
                        break;

                    case 'bullet_list_open':
                        stack.push({ type: 'bulletList', content: [] });
                        break;

                    case 'ordered_list_open':
                        stack.push({ type: 'orderedList', content: [] });
                        break;

                    case 'list_item_open':
                        stack.push({ type: 'listItem', content: [] });
                        break;

                    case 'table_open':
                        stack.push({ type: 'table', content: [] });
                        break;

                    case 'tr_open':
                        stack.push({ type: 'tableRow', content: [] });
                        break;

                    case 'td_open':
                    case 'th_open':
                        stack.push({
                            type: 'tableCell',
                            attrs: {
                                colspan: token.attrGet('colspan') || 1,
                                rowspan: token.attrGet('rowspan') || 1,
                            },
                            content: []
                        });
                        break;

                    case 'image':
                        current.content.push({
                            type: 'image',
                            attrs: {
                                src: token.attrGet('src'),
                                alt: token.attrGet('alt') || '',
                                title: token.attrGet('title') || ''
                            }
                        });
                        break;

                    case 'inline':
                        if (current.type === 'tableCell') {
                            // In case of table cells, wrap inline content in paragraphs
                            current.content.push({
                                type: 'paragraph',
                                content: [{ type: 'text', text: token.content }]
                            });
                        } else {
                            // For other content, add text directly
                            this.handleInline(token, stack);
                        }
                        break;

                    // Handling all closing tags by popping from the stack and adding to the content of the new stack top
                    case 'heading_close':
                    case 'paragraph_close':
                    case 'blockquote_close':
                    case 'list_item_close':
                    case 'bullet_list_close':
                    case 'ordered_list_close':
                    case 'table_close':
                    case 'tr_close':
                    case 'td_close':
                    case 'th_close':
                        let closedNode = stack.pop();
                        stack[stack.length - 1].content.push(closedNode);
                        break;
                }
            });

            return stack[0]; // Return the document node with the correctly structured content
        },

        handleInline(token, stack) {
            let currentBlock = stack[stack.length - 1];
            let content = [];
            let activeMarks = [];
            let previousWasText = false; // Track if the previous token was text to manage spaces

            token.children.forEach(child => {
                if (child.type === 'text') {
                    let text = child.content;
                    if (previousWasText && text.startsWith(' ')) {
                        // If there's leading space and the previous token was text, preserve that space
                        text = ' ' + text.trimStart();
                    }
                    let textNode = {
                        type: 'text',
                        text: text,
                        marks: activeMarks.slice() // Copy current active marks
                    };
                    content.push(textNode);
                    previousWasText = true;
                } else if (child.type.endsWith('_open')) {
                    let markType = this.getTipTapMarkType(child.type);
                    if (markType) {
                        activeMarks.push({ type: markType });
                    }
                    previousWasText = false; // Reset text flag when encountering an open tag
                } else if (child.type.endsWith('_close')) {
                    let markType = this.getTipTapMarkType(child.type.replace('_close', '_open'));
                    activeMarks = activeMarks.filter(mark => mark.type !== markType);
                    previousWasText = false; // Reset text flag when encountering a close tag
                }
            });

            if (currentBlock.type === 'tableCell') {
                currentBlock.content.push({ type: 'paragraph', content: content });
            } else {
                currentBlock.content = currentBlock.content.concat(content);
            }
        },

        getTipTapMarkType(tokenType) {
            switch (tokenType) {
                case 'strong_open':
                case 'strong_close':
                    return 'bold';
                case 'em_open':
                case 'em_close':
                    return 'italic';
                case 'code_open':
                case 'code_close':
                    return 'code';
                case 'underline_open':
                case 'underline_close':
                    return 'underline';
                default:
                    return null;
            }
        },


        // Encodes HTML entities
        encodeHtmlEntity(str) {
            return str.replace(/[\u00A0-\u9999<>\&]/g, i => `&#${i.charCodeAt(0)};`);
        },

        decodeHtmlEntity(str) {
            let textarea = document.createElement('textarea');
            textarea.innerHTML = str;

            return textarea.value;
        },

        convertNewLineToParagraph(str) {
            str = str.replaceAll('\n\n', '</p><br><p>');
            str = str.replaceAll('\n', '</p><p>');

            return `<p>${str}</p>`;
        },

        onCopy(index) {
            this.toast.success(this.__('Content copied to clipboard'));
            emitter.emit(
                'clipboard',
                this.decodeHtmlEntity(this.chat?.response)
            );
        },

        onThumbsUp() {
            this.isRated = true;
            this.toast.success(this.__('Thanks for your feedback'));
            axios.put(
                '/api/chat-gpt-conversation-message/' +
                    this.chat.messageId,
                {
                    thumbs_up: 1,
                    creation_id: this.chat.creationId,
                }
            );
        },

        onThumbsDown() {
            this.isRated = true;
            this.toast.success(
                __('Thanks for your feedback')
            );
            axios.put(
                '/api/chat-gpt-conversation-message/' +
                    this.chat.messageId,
                {
                    thumbs_down: 1,
                    creation_id: this.chat.creationId,
                }
            );
        },

        generateTipTapJsonFromHtml(htmlString) {
            return generateJSON(htmlString, [
                Document,
                Paragraph,
                Text,
                Bold,
                Heading,
                Italic,
                ListItem,
                OrderedList,
                Strike,
                Code,
                CodeBlock,
                Blockquote,
            ]);
        },

        attachCopyEventListeners() {
            const copyButtons = this.$el.querySelectorAll('.copy-button');
            copyButtons.forEach((button) => {
                // Remove existing event listener to prevent duplicates
                button.removeEventListener('click', this.handleCopyClick);
                // Attach a new event listener
                button.addEventListener('click', this.handleCopyClick);
            });
        },

        handleCopyClick(evt) {
            let vm = this;
            let button = evt.target.closest('.copy-button');
            let copyTextSpan = button.querySelector('.copy-text');
            let copyData = button
                .closest('pre')
                .querySelector('code').innerHTML;

            if (copyTextSpan) {
                copyTextSpan.textContent = this.__('Copied!');
            }

            setTimeout(function () {
                if (copyTextSpan) {
                    copyTextSpan.textContent = vm.__('Copy');
                }
            }, 2000);

            copyData = copyData.replace(/<\/?span[^>]*>/g, '');
            copyData = copyData.replaceAll('<br>', '\n');

            emitter.emit('clipboard', this.decodeHtmlEntity(copyData));
        },

        startTypingEffect(text) {
            if (this.typingTimer) {
                clearInterval(this.typingTimer);
            }

            this.isTyping = true;
            const totalLength = text.length;
            let currentIndex = this.displayedResponse.length;

            // Calculate characters to add per tick based on remaining content
            const getRemainingChars = () => {
                const remaining = totalLength - currentIndex;
                // Scale up characters per tick based on remaining length
                if (remaining > 200) return 100;
                if (remaining > 100) return 50;
                if (remaining > 50) return 25;
                if (remaining > 30) return 15;
                return 10;
            };

            this.typingTimer = setInterval(() => {
                if (currentIndex < totalLength) {
                    const charsToAdd = Math.min(getRemainingChars(), totalLength - currentIndex);
                    this.displayedResponse = text.substring(0, currentIndex + charsToAdd);
                    currentIndex += charsToAdd;
                } else {
                    clearInterval(this.typingTimer);
                    this.isTyping = false;
                    this.displayedResponse = text;
                }
            }, 1); // Keep minimal delay
        },
    },

    beforeUnmount() {
        if (this.typingTimer) {
            clearInterval(this.typingTimer);
        }
    }
};
</script>

<style lang="scss">
.actions-icon {
    width: 16px;
    opacity: 0.6;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.user-logo {
    width: 25px;
    height: 25px;
}

.justify-content-end {
    justify-content: flex-end;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.blink-effect {
    display: inline-block;
    width: 2px;
    height: 1em;
    background-color: #000;
    animation: blink 1s linear infinite;
}

.chat-gpt-response {
    transition: all 0.1s ease-out;
}
</style>
