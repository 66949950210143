<template>
    <div class="prompt d-flex flex-column flex-lg-row-auto" :class="classes">
        <!--begin::Wrapper-->
        <div
            class="d-flex flex-column position-xl-fixed bg-lighten top-0 bottom-0"
            :class="classes"
        >
            <!--begin::Header-->
            <div
                class="col-result d-flex flex-row-fluid flex-column scroll-y mt-xl-18 px-10"
            >
                <div class="py-10 d-flex flex-column">
                    <div
                        v-if="selectTemplateOptions.length > 0"
                        class="fv-row mb-10 fv-plugins-icon-container"
                    >
                        <!--begin::Label-->
                        <label class="fs-6 fw-bold mb-2">{{
                            __('Ai Assistant')
                        }}</label>
                        <!--end::Label-->
                        <Multiselect
                            v-model="selectedTemplate"
                            :options="selectTemplateOptions"
                            :searchable="true"
                            :groups="true"
                            :group-select="false"
                            group-label="category"
                            group-options="assistants"
                            trackBy="name"
                            label="name"
                            class="px-1 py-2"
                            :placeholder="__('Type in to search')"
                            @select="onChangeTemplate(selectedTemplate)"
                        >
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <div class="d-flex">
                                        <div
                                            class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                            :style="{
                                                backgroundColor: value.color,
                                            }"
                                        >
                                            <img
                                                :src="'/storage/' + value.icon"
                                            />
                                        </div>
                                        <div
                                            class="d-flex align-items-center ms-2"
                                        >
                                            {{ value.name }}
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <div class="d-flex">
                                    <div
                                        class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                        :style="{
                                            backgroundColor: option.color,
                                        }"
                                    >
                                        <img :src="'/storage/' + option.icon" />
                                    </div>
                                    <div
                                        class="d-flex justify-content-between flex-grow-1"
                                    >
                                        <div
                                            class="d-flex align-items-center ms-2"
                                        >
                                            {{ option.name }}
                                        </div>
                                        <!--                    <a href="#" class="d-flex align-items-center" data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-title="option.description">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                          </svg>
                                        </a>-->
                                    </div>
                                </div>
                            </template>
                        </Multiselect>
                    </div>

                    <div
                        v-if="promptInfo"
                        class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mt-0 mb-10"
                    >
                        <span class="text-gray-600" v-html="promptInfo"></span>
                    </div>

                    <component
                        v-for="field in fields"
                        :is="field.layout"
                        :title="
                            getJsonObject(field.attributes.title)[
                                $page.props.locale
                            ]
                        "
                          :placeholder="
                            getJsonObject(field.attributes.placeholder)[
                                $page.props.locale
                            ]
                        "
                        :max-length="field.attributes.max_length"
                        :inherited-value="fieldValues[field.layout]"
                        @value-change="onChangeField"
                    ></component>
                </div>
            </div>
            <!--end::Header-->

            <!--end::Wrapper-->
            <div
                class="d-flex flex-column border-top border-end px-11 py-8"
                style="background: #f9fbfc"
            >
                <div class="d-flex flex-column">
                    <!--begin::Accordion-->
                    <div
                        class="accordion accordion-settings mb-4"
                        id="accordion_prompt_settings"
                    >
                        <div class="accordion-item">
                            <h2
                                class="accordion-header"
                                id="accordion_prompt_settings_header_1"
                            >
                                <button
                                    class="accordion-button fs-4 fw-bold px-4 py-4"
                                    :class="{
                                        collapsed: promptSettingsAccordionOpen,
                                    }"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#accordion_prompt_settings_body_1"
                                    :aria-expanded="`${promptSettingsAccordionOpen}`"
                                    aria-controls="accordion_prompt_settings_body_1"
                                >
                                    {{ __('Settings') }}
                                </button>
                            </h2>
                            <div
                                id="accordion_prompt_settings_body_1"
                                class="accordion-collapse collapse"
                                :class="{ show: promptSettingsAccordionOpen }"
                                aria-labelledby="accordion_prompt_settings_header_1"
                                data-bs-parent="#accordion_prompt_settings"
                            >
                                <div class="accordion-body">
                                    <Creativity
                                        @creativity-change="
                                            (ev) =>
                                                $emit('creativity-change', ev)
                                        "
                                        :defaultCreativity="
                                            promptSettings?.creativity
                                        "
                                    ></Creativity>
                                    <Outputs
                                        @outputs-change="
                                            (ev) => $emit('outputs-change', ev)
                                        "
                                        :defaultOutputs="
                                            promptSettings?.outputs
                                        "
                                    ></Outputs>
                                    <ToneOfVoice
                                        @tone-of-voice-change="
                                            (ev) =>
                                                $emit(
                                                    'tone-of-voice-change',
                                                    ev
                                                )
                                        "
                                        :defaultToneOfVoice="
                                            promptSettings?.toneOfVoice
                                        "
                                    ></ToneOfVoice>
                                    <TargetAudience
                                        @target-audience-change="
                                            (ev) =>
                                                $emit(
                                                    'target-audience-change',
                                                    ev
                                                )
                                        "
                                        :defaultTargetAudience="
                                            promptSettings?.targetAudience
                                        "
                                    ></TargetAudience>
                                    <WithEmoijs
                                        @with-emojis-change="
                                            (ev) =>
                                                $emit('with-emojis-change', ev)
                                        "
                                    ></WithEmoijs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Accordion-->
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <Language
                        :allowed-languages="allowedLanguages"
                        :lang="promptSettings?.targetLang || 'en'"
                        @language-change="onLanguageChange"
                        @formality-change="onFormalityChange"
                    >
                    </Language>
                    <div class="d-flex">
                        <button
                            class="btn btn-primary"
                            @click.prevent="$emit('compose')"
                        >
                            <span v-if="!loading" class="indicator-label">{{
                                __('Create')
                            }}</span>
                            <span
                                v-if="!loading"
                                class="svg-icon svg-icon-4 ms-1"
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <rect
                                        opacity="0.5"
                                        x="18"
                                        y="13"
                                        width="13"
                                        height="2"
                                        rx="1"
                                        transform="rotate(-180 18 13)"
                                        fill="white"
                                    ></rect>
                                    <path
                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                        fill="white"
                                    ></path></svg
                            ></span>
                            <span v-else class="indicator-progress d-block"
                                >{{ __('Please wait...') }}
                                <ALoading />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Description from '@/Components/Fields/Description.vue';
import Name from '@/Components/Fields/Name.vue';
import Keyword from '@/Components/Fields/Keyword.vue';
import Multiselect from '@vueform/multiselect';
import Outputs from '@/Layouts/SettingElements/Outputs.vue';
import ToneOfVoice from '@/Layouts/SettingElements/ToneOfVoice.vue';
import TargetAudience from '@/Layouts/SettingElements/TargetAudience.vue';
import Creativity from '@/Layouts/SettingElements/Creativity.vue';
import Language from '@/Layouts/SettingElements/Language.vue';
import WithEmoijs from '@/Layouts/SettingElements/WithEmoijs.vue';
import ALoading from "@/Components/Atoms/ALoading.vue";

export default defineComponent({
    props: {
        user: Object,
        aiAssistantTemplateUuid: {
            type: String,
        },
        aiAssistantTemplates: {
            type: Object,
            default: {},
        },
        promptTitle: {
            type: String,
            default: 'Input',
        },
        promptInfo: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        fields: {
            type: Object,
        },
        options: {
            type: Object,
        },
        classes: {
            type: String,
            default: 'w-lg-400px w-xxl-600px',
        },
    },
    components: {
        ALoading,
        Description,
        Name,
        Keyword,
        Multiselect,
        Outputs,
        ToneOfVoice,
        TargetAudience,
        Creativity,
        Language,
        WithEmoijs,
    },

    data() {
        return {
            selectedTemplate: '',
            description: '',
            name: '',
            fieldValues: [],
            promptSettingsAccordionOpen: true,
            allowedLanguages: [],
            promptSettings: {
                creativity: 0.75,
                outputs: 3,
                toneOfVoice: '',
                targetAudience: '',
                withEmojis: false,
            },
        };
    },

    mounted() {
        this.selectedTemplate = this.aiAssistantTemplateUuid;

        let vm = this;

        let promptSettingsAccordion = document.getElementById(
            'accordion_prompt_settings'
        );

        promptSettingsAccordion.addEventListener(
            'hidden.bs.collapse',
            (event) => {
                vm.$emit('prompt-settings-accordion-open-change', false);
            }
        );

        promptSettingsAccordion.addEventListener(
            'shown.bs.collapse',
            (event) => {
                vm.$emit('prompt-settings-accordion-open-change', true);
            }
        );

        this.promptSettingsAccordionOpen =
            this.user.user_info.prompt_settings &&
            this.user.user_info.prompt_settings.hasOwnProperty(
                'promptSettingsAccordionOpen'
            )
                ? this.user.user_info.prompt_settings
                      .promptSettingsAccordionOpen
                : true;

        this.fetchPromptSettings();
    },

    watch:{
        fields(fields){
            const userQuery = this.getUserQueryIfExists();
            if (this.$page.component === 'AiAssistant' && userQuery && fields.length > 0) {
                this.setUserQueryIfExists(userQuery, fields);
            }
        },
    },

    methods: {
        getUserQueryIfExists() {
            const urlParams = new URLSearchParams(window.location.search);
            const query = urlParams.get('query');

            return query || '';
        },
        setUserQueryIfExists(userQuery, fields) {
            const vm = this;

            fields
                .map((field) => field.layout)
                .forEach((field) => {
                    vm.fieldValues[field] = userQuery;
                });
        },
        fetchPromptSettings() {
            const { document, user } = this.$page?.props || {};

            const documentUuid = document?.uuid;
            if (!documentUuid) {
                const promptSettings =
                    user?.current_project?.prompt_settings ||
                    user?.user_info?.prompt_settings;
                if (promptSettings) {
                    this.promptSettings = promptSettings;
                }
                return;
            }

            axios
                .get(`/api/document/${documentUuid}/get-prompt-settings`)
                .then((response) => {
                    this.promptSettings = response.data.prompt_settings;
                    this.$emit('fetched-prompt-settings', this.promptSettings);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        onChangeTemplate(selectedTemplate) {
            this.resetFieldValues();
            this.$emit('change-ai-assistant-template', selectedTemplate);
        },
        onChangeField(ev) {
            this.fieldValues[ev.component] = ev.value;
            this.$emit('prompt-change', ev);
        },
        onLanguageChange(ev) {
            this.promptSettings.targetLang = ev;
            this.$emit('language-change', ev);
        },
        onFormalityChange(ev) {
            this.$emit('formality-change', ev);
        },
        selectCustomLabel({ name, email }) {
            return name + ' (' + email + ')';
        },
        getJsonObject(attribute) {
            return typeof attribute === 'string'
                ? JSON.parse(attribute)
                : attribute;
        },
        resetFieldValues() {
            let vm = this;
            for (const [key, value] of Object.entries(vm.fieldValues)) {
                if (key !== 'description') {
                    vm.fieldValues[key] = '';
                    vm.onChangeField({ component: key, value: '' });
                }
            }
        },
    },

    computed: {
        selectTemplateOptions() {
            let entries = [];
            for (const [key, value] of Object.entries(
                this.aiAssistantTemplates
            )) {
                entries.push({
                    value: key,
                    name: value.name[this.$page.props.locale],
                    description: value.description[this.$page.props.locale],
                    templateCategory:
                        value.ai_assistant_template_category.name[
                            this.$page.props.locale
                        ],
                    color: value.ai_assistant_template_category.color,
                    icon: value.template_icon_path,
                });
            }

            let groupedEntries = entries.reduce((r, a) => {
                r[a.templateCategory] = [...(r[a.templateCategory] || []), a];
                return r;
            }, {});

            let options = [];
            for (const [key, value] of Object.entries(groupedEntries)) {
                options.push({
                    category: key,
                    assistants: value,
                });
            }

            return options;
        },
    },
});
</script>
<style lang="scss">
.prompt {
    border-right: #eff1f4 1px solid;

    @media (max-width: 992px) {
        border: none;
    }
}
.multiselect {
    border-color: #e4e6ef;
    border-radius: 0.475rem;
    padding: 13px 0;
}
.multiselect-clear {
    display: none;
}
.accordion-settings {
    .accordion-item {
        border: none;
        background-color: #eee;

        .accordion-button,
        .accordion-body {
            background-color: #f8fbfc;
        }

        .accordion-button {
            color: #181c32;
            border-bottom: 1px solid #eff2f5;

            &.collapsed {
                border-radius: 0;
            }

            &:after {
                color: #181c32;
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
            }

            &:not(.collapsed) {
                border-bottom: 1px solid #eff2f5;
                box-shadow: none;
            }
        }

        .accordion-body {
            border-bottom: 1px solid #eff2f5;
        }
    }
}
</style>
