<template>
    <div class="flex-lg-row-fluid">
        <!--begin::Messenger-->
        <div class="card chat-messenger-card" id="kt_chat_messenger">
            <!--begin::Card header-->
            <div
                class="card-header bg-gray-100 rounded-0 px-4 py-6"
                id="kt_chat_messenger_header"
            >
                <ChatForm
                    :user="user"
                    :page="$page"
                    :creaitorDocumentChat="creaitorDocumentChat"
                    :is-streaming="isStreaming"
                    @streaming-started="handleStreamingStarted"
                    @streaming-stopped="handleStreamingStopped"
                    @send-button-triggered="sendButtonTriggered($event)"
                    :previousQuestion="previousQuestion"
                    @update-chat-uuid="updateChatUuid"
                    @got-chat-response-error="handleResponseError"
                    @chat-regenerate-response="regenerateResponse"
                    @on-streaming="appendMessageToChat"
                ></ChatForm>
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div
                v-if="wrapper.length"
                class="card-body chat-content chats"
                id="kt_chat_messenger_body"
                ref="cardBody"
            >
                <!--begin::Messages-->
                <div
                    class="scroll-y me-n5 pe-5 h-300px h-lg-auto chat-entries fs-5"
                >
                    <ChatMessage
                        v-for="(chat, i) in wrapper"
                        :chat="chat"
                        :key="i"
                        :index="Number(i)"
                        :user="user"
                        :insertContent="true"
                        :isStreaming="isStreaming"
                        :isLastMessage="Number(i) === wrapper.length - 1"
                        @update-scroll-height="updateScrollHeight"
                    ></ChatMessage>
                </div>
                <!--end::Messages-->
            </div>
            <div
                v-else-if="documentChatStore.isLoading"
                class="d-flex align-items-center justify-content-center h-100 p-5 pb-20"
            >
                <div
                    class="spinner-border spinner-border text-primary"
                    role="status"
                >
                    <span class="visually-hidden">{{ __('Loading...') }}</span>
                </div>
            </div>
            <div v-else class="mx-1 card-body chats mb-15" ref="cardBody">
                <!--begin::Messages-->
                <div
                    class="scroll-y me-n5 pe-5 h-300px h-lg-auto chat-entries"
                    data-kt-element="messages"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                    data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_messenger_body"
                    data-kt-scroll-offset="5px"
                >
                    <div
                        class="p-4 rounded w-100 bg-light-primary"
                        data-kt-element="message-text"
                    >
                        <p class="text-start p-0 m-0 text-sm text-gray-400">
                            {{ __('No chat history yet') }}
                        </p>
                    </div>
                </div>
            </div>
            <!--end::Card body-->
            <div class="card-footer d-flex justify-content-center">
                <button
                    v-if="
                        false &&
                        documentChatStore.getUuid &&
                        !documentChatStore.isLoading
                    "
                    class="regenerate-btn"
                    @click="regenerateResponse"
                >
                    <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo7/dist/../src/media/svg/icons/General/Update.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                        >
                            <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                    d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                />
                            </g>
                        </svg>
                        <!--end::Svg Icon-->
                    </span>
                    {{ __('Regenerate response') }}
                </button>
            </div>
        </div>
        <!--end::Messenger-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ChatMessage from '@/Layouts/AiChat/ChatMessage.vue';
import ChatForm from '@/Layouts/Document/Chat/ChatForm.vue';
import { useDocumentStore } from '@/Stores/DocumentStore';
import { useDocumentChatStore } from '@/Stores/DocumentChatStore';

export default defineComponent({
    setup() {
        // Get document chat store
        const documentStore = useDocumentStore();

        // Get document chat store
        const documentChatStore = useDocumentChatStore();

        return { documentStore, documentChatStore };
    },

    props: {
        user: Object,
    },

    components: {
        ChatMessage,
        ChatForm,
    },

    data() {
        return {
            wrapper: [],
            creaitorDocumentChat: {},
            previousQuestion: '',
            autoScrollEnabled: true,
            isStreaming: false,
        };
    },

    mounted() {
        this.calculateCardBodyHeight();
        window.addEventListener('resize', this.calculateCardBodyHeight);
        // Check the initial value of chatGptConversationUuid when the component is mounted
        if (this.documentStore.chatGptConversationUuid) {
            this.fetchSingleChat();
        }
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.calculateCardBodyHeight);
    },

    methods: {
        appendMessageToChat(message) {
            // Check if the last message is from AI
            if (
                this.wrapper.length > 0 &&
                this.wrapper[this.wrapper.length - 1].isAi
            ) {
                let lastMessage = this.wrapper[this.wrapper.length - 1];
                lastMessage.originalResponse += message.content; // Append the new chunk to the original text
                lastMessage.response = lastMessage.originalResponse;
                lastMessage.isLoading = false;
                lastMessage.messageId = message.messageId;
                lastMessage.creationId = message.creationId;
            } else {
                this.wrapper.push({
                    isAi: true,
                    isLoading: false,
                    originalResponse: message.content,
                    response: message.content,
                    messageId: message.messageId,
                    creationId: message.creationId,
                });
            }

            if (message.isFinalMessage) {
                this.isStreaming = false;
            }

            // If autoscroll is enabled, scroll to the bottom of the chat
            if (this.autoScrollEnabled) {
                this.$nextTick(() => {
                    // this.scrollToBottom();
                });
            }
        },

        sendButtonTriggered(data) {
            this.wrapper.push({
                isAi: false,
                response: data.question,
                isOldChat: false,
            });
            this.wrapper.push({
                isAi: true,
                isLoading: true,
                disableTypingEffect: true,
                originalResponse: '',
                response: '',
                isOldChat: false,
            });
        },

        handleStreamingStarted() {
            this.isStreaming = true;
        },

        handleStreamingStopped() {
            this.isStreaming = false;
        },

        fetchSingleChat() {
            if (this.documentStore.chatGptConversationUuid) {
                let vm = this;
                let requestUrl =
                    '/api/chat-gpt-conversation/' +
                    this.documentStore.chatGptConversationUuid;
                this.documentChatStore.setLoading(true);
                this.wrapper = [];

                // eslint-disable-next-line no-undef
                axios.get(requestUrl).then((response) => {
                    vm.wrapper = response.data.messages.map((conversation) => {
                        return {
                            isAi: conversation.user_id ? false : true,
                            response: conversation.content,
                            isOldChat: true,
                        };
                    });
                    vm.documentChatStore.setLoading(true);
                });
            }
        },

        updateChatUuid(chatUuid) {
            this.documentChatStore.setUuid(chatUuid);
            this.documentStore.setChatGptConversationUuid(chatUuid);
        },

        updateScrollHeight() {
            var scroll = document.querySelector('#kt_chat_messenger_body');
            scroll.scrollTop = parseInt(scroll.scrollHeight);
        },

        regenerateResponse() {
            this.previousQuestion =
                this.wrapper[this.wrapper.length - 2].response;
            this.wrapper.splice(-2, 2);

            this.documentChatStore.setCurrentQuestion(this.previousQuestion);

            // eslint-disable-next-line no-undef
            emitter.emit('document-chat-regenerate-response');
        },

        handleResponseError() {
            this.wrapper.splice(-2);
        },

        calculateCardBodyHeight() {
            // get header container height by class
            const headerContainerHeight =
                document.querySelector('.header-container').offsetHeight;
            const toolbarHeight =
                document.querySelector('#kt_toolbar').offsetHeight;
            const editorSidebarTabsHeight = document.querySelector(
                '#editor-sidebar-sections'
            ).offsetHeight;

            const cardHeaderHeight =
                this.$refs.cardBody.previousElementSibling.offsetHeight;
            const cardFooterHeight =
                this.$refs.cardBody.nextElementSibling.offsetHeight;

            const cardBodyMaxHeight = `calc(100vh - ${headerContainerHeight}px - ${toolbarHeight}px - ${editorSidebarTabsHeight}px - ${cardHeaderHeight}px - ${cardFooterHeight}px)`;

            this.$refs.cardBody.style.maxHeight = cardBodyMaxHeight;
        },
    },

    watch: {
        'documentChatStore.isLoadedFromDocument': function (newValue) {
            if (newValue) {
                // If it's not empty or null
                this.fetchSingleChat();
            }
        },
    },
});
</script>

<style lang="scss">
.chat-messenger-card {
    display: flex;
    flex-direction: column;
    height: calc(
        100% - 116px
    ); // Subtract the height of the navigation and toolbar
    max-height: calc(
        100% - 116px
    ); // Subtract the height of the navigation and toolbar
    position: relative;

    .card-header {
        width: 100%;
        background: #fff;
        padding: 2rem 1rem;
    }

    .intro-card {
        .card-body {
            min-height: 125px;
            display: flex;
            align-items: center;
        }
    }

    .card-body {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;

        @media (min-width: 320px) and (max-width: 992px) {
            min-height: auto;
        }

        .chat-entries {
            @media (max-width: 767px) {
                padding-bottom: 50px;
            }
        }

        .blink-effect {
            animation: blinker 1s linear infinite;
        }
    }

    .card-footer {
        flex-shrink: 0;
        margin-top: auto;
        border: 0;
    }
}

.regenerate-btn {
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    color: #000;
    animation: fadeinout 4s;
    animation-iteration-count: 1;

    @media (max-width: 767px) {
        left: auto;
        top: -45px;
        right: 16px;

        span {
            margin-right: 0 !important;
        }
    }

    span {
        color: #000;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.regenerate-btn {
    color: #000;

    @media (max-width: 767px) {
        padding: 5px !important;
        font-size: 0;
    }

    &:hover,
    &:focus {
        color: #000;
    }

    span {
        color: #000;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .intro-content .col-lg-4 {
        width: 33.3% !important;
        max-width: 33.3% !important;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .chat-messenger-card .intro-card .card-body {
        min-height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
