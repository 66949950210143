<template>
    <div class="d-flex position-relative">
        <div v-if="languages[lang]" class="m-0 toolbar-dropdown-menu">
            <a
                href="#"
                class="btn btn-flex btn-light btn-active-primary fw-bolder text-sm"
                :class="[{ disabled: disabled }, size]"
                @click.prevent.stop="toggle"
                v-click-away="onClickAway"
            >
                <span
                    v-if="
                        showLabel &&
                        languages[lang] &&
                        languages[lang].name[$page.props.locale]
                    "
                    >{{
                        languages[lang].name[$page.props.locale].split(' ')[0]
                    }}</span
                >
                <span v-if="showFlag">
                    <img
                        class="w-15px h-15px rounded-1 ms-2"
                        :src="languages[lang].flag"
                        alt=""
                    />
                </span>
            </a>
            <div
                class="menu-sub menu-sub-dropdown menu-state-bg menu-state-primary language-dropdown menu-gray-600 w-250px mh-250px scroll-y py-4"
            >
                <!--begin::Menu item-->
                <input
                    id="languageSearch"
                    type="text"
                    class="form-control"
                    v-model="languageSearchValue"
                    :placeholder="__('Search...')"
                />
                <div v-for="language in languageList" class="menu-item px-3">
                    <a
                        href="#"
                        @click.prevent.stop="
                            onSelectLanguage($event, language.code)
                        "
                        class="btn btn-sm btn-menu-link menu-link d-flex px-5"
                    >
                        <span v-if="showFlag" class="symbol symbol-20px me-4">
                            <img
                                class="rounded-1"
                                :src="language.flag"
                                alt=""
                            /> </span
                        >{{ language.name[$page.props.locale] }}
                    </a>
                </div>
                <!--end::Menu item-->
            </div>
        </div>
        <div
            v-if="
                languages[lang] &&
                showFormality &&
                hasFormalities.includes(lang)
            "
            class="d-flex"
        >
            <div
                class="d-flex fs-7 text-gray-700 fw-bolder align-items-center ms-2"
            >
                {{ __('Formality') }}:
            </div>
            <div class="toolbar-dropdown-menu m-0 ms-2">
                <a
                    href="#"
                    class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
                    @click.prevent="toggle"
                    v-click-away="onClickAway"
                >
                    {{ formalities[formality].name.split(' ')[0] }}
                </a>
                <div
                    class="menu-sub menu-sub-dropdown menu-state-bg menu-state-primary menu-gray-600 w-175px mh-250px scroll-y py-4"
                    :style="formalityMenuStyle"
                >
                    <!--begin::Menu item-->
                    <div
                        v-for="formality in formalities"
                        class="menu-item px-3"
                    >
                        <a
                            href="#"
                            @click.prevent.stop="
                                onSelectFormality(
                                    $event,
                                    formality.name,
                                    formality.code
                                )
                            "
                            class="btn btn-sm btn-menu-link menu-link d-flex px-5"
                        >
                            {{ formality.name }}
                        </a>
                    </div>
                    <!--end::Menu item-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { directive } from 'vue3-click-away';

export default defineComponent({
    directives: {
        ClickAway: directive,
    },

    props: {
        size: {
            type: String,
            default: 'btn-sm',
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        showFormality: {
            type: Boolean,
            default: true,
        },
        lang: {
            type: String,
            default: 'en',
        },
        allowedLanguages: {
            type: Array,
            default: () => [],
        },
        showFlag: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        let projectSettings = this.user?.current_project?.prompt_settings || {};
        let userSettings = this.$page.props.user.user_info.language || {};

        return {
            languages: {
                en: {
                    name: {
                        en: 'English',
                        de: 'Englisch',
                    },
                    code: 'en',
                    flag: '/media/flags/united-states.svg',
                },
                de: {
                    name: {
                        en: 'German',
                        de: 'Deutsch',
                    },
                    code: 'de',
                    flag: '/media/flags/germany.svg',
                },
            },
            hasFormalities: [
                'de',
                'de-CH',
                'fr',
                'it',
                'es',
                'nl',
                'pl',
                'pt-PT',
                'pt-BR',
                'ru',
            ],
            formality:
                projectSettings.formality ||
                userSettings.formality ||
                'default',
            formalities: {
                default: {
                    name:
                        this.__('Default') + ' (' + this.__('Automatic') + ')',
                    code: 'default',
                },
                more: {
                    name: this.__('Formal'),
                    code: 'more',
                },
                less: {
                    name: this.__('Informal'),
                    code: 'less',
                },
            },
            documentBodyWidth: document.body.clientWidth,
            currentMenu: {
                open: false,
                element: {},
            },
            currentUserSystemLanguage: this.$page?.props?.user?.user_info
                ?.language
                ? this.$page.props.user.user_info.language
                : 'en',
            languageSearchValue: '',
        };
    },
    mounted() {
        this.fetchLanguages();
        window.addEventListener('resize', this.windowResize);
    },
    methods: {
        fetchLanguages() {
            let vm = this;
            axios.get(`/api/languages`).then((response) => {
                vm.languages = response.data;
            });
        },
        toggle($event) {
            let el = $event.target.closest('.toolbar-dropdown-menu');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
            this.currentMenu.open = this.currentMenu.open !== true;
            this.currentMenu.element = el;
        },
        onSelectLanguage(ev, code) {
            this.$emit('language-change', code);
            this.toggle(ev);
        },
        onSelectFormality(ev, name, code) {
            this.formality = code;
            this.$emit('formality-change', code);
            this.toggle(ev);
        },
        onClickAway(ev) {
            if (this.currentMenu.open) {
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(ev.target)) {
                    let s = el.classList;
                    let m =
                        el.parentElement.getElementsByClassName(
                            'menu-sub-dropdown'
                        )[0].classList;

                    s.contains('show') ? s.remove('show') : s.add('show');
                    s.contains('menu-dropdown')
                        ? s.remove('menu-dropdown')
                        : s.add('menu-dropdown');
                    m.contains('show') ? m.remove('show') : m.add('show');
                    this.currentMenu.open = false;
                }
            }
        },
        windowResize() {
            this.documentBodyWidth = document.body.clientWidth;
        },
    },
    computed: {
        languageClass() {
            return {
                active: this.isActive && !this.error,
                'text-danger': this.error && this.error.type === 'fatal',
            };
        },
        languageMenuStyle() {
            let height = 286;

            if (Object.values(this.allowedLanguages).length > 0) {
                height =
                    107 +
                    (Object.values(this.allowedLanguages).length - 1) * 38;
            }

            return this.mobileView
                ? 'transform: translate3d(0px, -' +
                      height +
                      'px, 0px); z-index: 105; position: absolute;'
                : 'transform: translate3d(0px, -' +
                      height +
                      'px, 0px); z-index: 105; position: fixed;';
        },
        formalityMenuStyle() {
            return this.mobileView
                ? 'transform: translate3d(0px, -165px, 0px); z-index: 105; position: absolute;'
                : 'transform: translate3d(0px, -165px, 0px); z-index: 105; position: fixed;';
        },
        mobileView() {
            return this.documentBodyWidth < 992;
        },
        languageList() {
            let languageList =
                this.allowedLanguages.length > 0
                    ? this.allowedLanguages.map((lang) => this.languages[lang])
                    : Object.values(this.languages);
            let vm = this;
            let languageSearchValue =
                this.languageSearchValue.toLocaleLowerCase();

            return languageList.filter((language) => {
                return (
                    language.name &&
                    language.name[vm.currentUserSystemLanguage] &&
                    language.name[vm.currentUserSystemLanguage]
                        .toLocaleLowerCase()
                        .indexOf(languageSearchValue) >= 0
                );
            });
        },
    },
});
</script>

<style lang="scss">
#languageSearch {
    width: 225px;
    padding: 6px;
    margin: 0px 10px;
}
.language-dropdown {
    bottom: 45px;
    position: absolute;
    z-index: 105;
}
.toolbar-dropdown-menu > .btn {
    border: 1px solid #e8e8e8 !important;

    &:hover {
        border-color: inherit !important;
    }
}
</style>
