<template>
    <div class="d-flex align-items-center ms-1 ms-lg-3">
        <!--begin::Menu- wrapper-->
        <div
            class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary !w-[30px] md:!w-[40px] !h-[30px] md:!h-[40px] me-2"
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            v-click-away="onClickAway"
            @click="toggle($event)"
        >
            <span
                class="notification svg-icon svg-icon-primary svg-icon-1 position-relative scale-[0.8]"
            >
                <IconNotification />
                <span
                    v-if="user?.unread_notifications?.length"
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fs-10 min-w-6"
                >
                    {{ user?.unread_notifications?.length }}
                </span>
                <!--end::Svg Icon-->
            </span>
        </div>
        <!--begin::Menu-->
        <div
            class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px menu-pos"
            data-kt-menu="true"
            style="
                z-index: 105;
                position: fixed;
                inset: 0px 0px auto auto;
                margin: 0px;
            "
        >
            <!--begin::Heading-->
            <div
                class="notification-panel d-flex flex-column bgi-no-repeat rounded-top"
            >
                <!--begin::Title-->
                <h3 class="text-white fw-bold px-9 mt-10 mb-6">
                    {{ __('Notifications') }}
                </h3>
                <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Tab content-->
            <div class="">
                <NotificationPanel
                    :user="user"
                    type="AlertNotification"
                ></NotificationPanel>
            </div>
            <!--end::Tab content-->
        </div>
        <!--end::Menu-->
        <!--end::Menu wrapper-->
    </div>
</template>

<script>
import JetDropdown from '@/Jetstream/Dropdown.vue';
import JetDropdownLink from '@/Jetstream/DropdownLink.vue';
import { directive } from 'vue3-click-away';
import NotificationPanel from '@/Layouts/Header/Partials/NotificationPanel.vue';
import {router as Inertia} from '@inertiajs/vue3';
import IconNotification from '@/icons/notification.svg?component';


export default {
    components: {
        JetDropdown,
        JetDropdownLink,
        NotificationPanel,
        IconNotification,
    },
    directives: {
        ClickAway: directive,
    },
    props: {
        user: Object,
    },
    data() {
        return {
            currentMenu: {
                open: false,
                element: {},
            },
        };
    },
    methods: {
        toggle($event) {
            //to read all notifications.
            this.readAllNotifications();
            let el = $event.target.closest('[data-kt-menu-trigger]');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
            this.currentMenu.open = this.currentMenu.open !== true;
            this.currentMenu.element = el;
        },
        onClickAway(event) {
            if (this.currentMenu.open) {
                if (
                    event.srcElement.classList.contains(
                        'inner-clickable-link'
                    ) ||
                    event.srcElement.parentElement.classList.contains(
                        'inner-clickable-link'
                    )
                ) {
                    return;
                }
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(event.target)) {
                    let s = el.classList;
                    let m =
                        el.parentElement.getElementsByClassName(
                            'menu-sub-dropdown'
                        )[0].classList;

                    s.contains('show') ? s.remove('show') : s.add('show');
                    s.contains('menu-dropdown')
                        ? s.remove('menu-dropdown')
                        : s.add('menu-dropdown');
                    m.contains('show') ? m.remove('show') : m.add('show');
                    this.currentMenu.open = false;
                }
            }
        },
        readAllNotifications() {
            if (this.user.unread_notifications.length) {
                axios.put('/api/notifications/read').then(() => {
                    if (this.user.unread_notifications) {
                        this.user.unread_notifications = [];
                    }
                    emitter.emit('new-user-notification');
                });
            }
        },
    },
};
</script>
<style lang="scss">
@media (min-width: 576px) {
    .menu-pos {
        transform: translate(-80px, 65px) !important;
    }
}

@media (max-width: 575px) {
    .menu-pos {
        transform: translate(0px, 65px) !important;
        max-width: 100%;
    }
}
.notification {
    .fa-primary {
        fill: #a1a5b7;
    }
    .fa-secondary {
        opacity: 0.3;
        fill: #a1a5b7;
    }
}

.notification-panel {
    background-color: #194c87;
}
</style>
