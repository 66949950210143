<template>
    <div class="d-flex align-items-center ms-1 ms-lg-3">
        <!--begin::Menu- wrapper-->
        <div
            class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary !w-[30px] md:!w-[40px] !h-[30px] md:!h-[40px]"
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            v-click-away="onClickAway"
            @click="toggle($event)"
        >
            <span class="svg-icon svg-icon-primary svg-icon-2x scale-90">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                >
                    <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                            class="fa-secondary"
                            d="M16.5,4.5 C14.8905,4.5 13.00825,6.32463215 12,7.5 C10.99175,6.32463215 9.1095,4.5 7.5,4.5 C4.651,4.5 3,6.72217984 3,9.55040872 C3,12.6834696 6,16 12,19.5 C18,16 21,12.75 21,9.75 C21,6.92177112 19.349,4.5 16.5,4.5 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                        />
                    </g>
                </svg>
                <!--end::Svg Icon-->
            </span>
        </div>
        <!--begin::Menu-->
        <div
            class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px menu-pos"
            data-kt-menu="true"
            style="
                z-index: 105;
                position: fixed;
                inset: 0px 0px auto auto;
                margin: 0px;
            "
        >
            <!--begin::Heading-->
            <div
                class="referral-panel d-flex flex-column bgi-no-repeat rounded-top"
            >
                <!--begin::Title-->
                <h3 class="text-white fw-bold px-9 mt-10 mb-6">
                    {{ __('Recommend us') }}
                </h3>
                <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Tab content-->
            <form @submit.prevent="sendReferralLink">
                <div class="m-10">
                    <div class="d-flex">
                        <p>
                            {{
                                __(
                                    "For each successful referral you will be rewarded with 100'000 credits."
                                )
                            }}
                        </p>
                    </div>
                    <input
                        type="email"
                        class="form-control"
                        :placeholder="__('Email')"
                        v-model="emailAddress"
                    />
                </div>
                <div class="flex justify-end me-10 mb-10">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        :class="[isLoading ? 'disabled' : '']"
                    >
                        <span v-if="!isLoading">{{ __('Send') }}</span>
                        <span v-else>{{ __('Loading..') }}</span>
                    </button>
                </div>
            </form>
            <!--end::Tab content-->
        </div>
        <!--end::Menu-->
        <!--end::Menu wrapper-->
    </div>
</template>

<script>
import { directive } from 'vue3-click-away';
import { useToast } from 'vue-toastification';

export default {
    directives: {
        ClickAway: directive,
    },

    props: {
        user: Object,
    },

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast };
    },

    data() {
        return {
            currentMenu: {
                open: false,
                element: {},
            },
            isLoading: false,
            emailAddress: '',
        };
    },

    methods: {
        toggle($event) {
            let el = $event.target.closest('[data-kt-menu-trigger]');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
            this.currentMenu.open = this.currentMenu.open !== true;
            this.currentMenu.element = el;
        },
        onClickAway(event) {
            if (this.currentMenu.open) {
                if (
                    event.srcElement.classList.contains(
                        'inner-clickable-link'
                    ) ||
                    event.srcElement.parentElement.classList.contains(
                        'inner-clickable-link'
                    )
                ) {
                    return;
                }
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(event.target)) {
                    let s = el.classList;
                    let m =
                        el.parentElement.getElementsByClassName(
                            'menu-sub-dropdown'
                        )[0].classList;

                    s.contains('show') ? s.remove('show') : s.add('show');
                    s.contains('menu-dropdown')
                        ? s.remove('menu-dropdown')
                        : s.add('menu-dropdown');
                    m.contains('show') ? m.remove('show') : m.add('show');
                    this.currentMenu.open = false;
                }
            }
        },

        sendReferralLink() {
            if (!this.emailAddress) {
                this.toast.error(this.__('An error occurred'));
            }

            this.isLoading = true;
            let vm = this;
            let payload = {
                email: this.emailAddress,
            };

            axios
                .post(`/api/referral`, payload)
                .then((response) => {
                    this.isLoading = false;
                    vm.toast.success(vm.__('Invitation send'));
                    vm.emailAddress = '';
                })
                .catch((responseError) => {
                    this.isLoading = false;
                    if (422 == responseError?.response?.status) {
                        for (let error in responseError?.response?.data
                            .errors) {
                            vm.toast.error(
                                vm.__(
                                    responseError?.response?.data.errors[
                                        error
                                    ][0]
                                )
                            );
                            break;
                        }
                    } else if (409 == responseError?.response?.status) {
                        vm.toast.error(
                            vm.__(responseError?.response?.data?.error)
                        );
                    } else {
                        vm.toast.error(vm.__('Some error occured'));
                    }
                });
        },
    },
};
</script>
<style lang="scss">
@media (min-width: 576px) {
    .menu-pos {
        transform: translate(-80px, 65px) !important;
    }
}

@media (max-width: 575px) {
    .menu-pos {
        transform: translate(0px, 65px) !important;
        max-width: 100%;
    }
}

.fa-secondary {
    opacity: 0.3;
    fill: #a1a5b7;
}

.referral-panel {
    background-color: #194c87;
}
</style>
