<template>
    <div class="menu-item">
        <div class="menu-link switch-profiles !px-0">
            <a
                href="#"
                class="btn-custom text-left !inline-block"
                @click="toggleTeamSelection"
                :class="[{ 'parent-btn-active': isActiveTeamSelection }]"
            >
                <div class="btn-label text-left w-full">
                    <div class="btn-label-head">
                        {{ __('Switch team or project') }}
                    </div>
                    <div class="btn-label-body relative">
                        <h6>{{ currentTeam?.name }}</h6>
                        <p class="mb-0">
                            {{ currentProject?.name }}
                            <small v-if="currentBrandIdentity">
                                ({{ currentBrandIdentity?.brand_name }})
                            </small>
                        </p>
                    </div>
                </div>
                <span
                    class="svg-icon btn-icon svg-icon-2 profile-short-name"
                    :class="isMenuCollapsed && !isMenuHover ? '!flex' : '!hidden'"
                >
                    <span>
                        <img
                            :src="getInitialImage(currentTeam?.name)"
                            alt="user"
                        />
                    </span>
                </span>
            </a>
        </div>
    </div>

    <!--begin::Switch profiles-->
    <div
        class="switch-profiles-outer mt-0"
        :class="[{ 'parent-div-active': isActiveTeamSelection }]"
    >
        <div class="switch-profiles-dropdown position-relative overflow-hidden">
            <div class="dropdown-body">
                <!-- Sub level -->
                <div
                    class="level-one"
                    v-for="(team, index) in allTeams"
                    :key="team.id"
                >
                    <button
                        type="button"
                        class="level-one-button"
                        :class="[
                            {
                                'child-btn-active': index === selectedTeamIndex,
                            },
                        ]"
                        @click="toggleTeamAccordion(index)"
                    >
                        <div
                            class="radio-button active top-1.5"
                            @click="selectTeam(index)"
                        ></div>
                        <div class="btn-text">
                            <h6 class="">
                                {{ team.name }}
                                <span>
                                    {{ team?.projects?.length || 0 }}
                                    {{ __('Projects') }}
                                </span>
                            </h6>
                            <p v-if="currentProject?.team_id === team.id">
                                {{ currentProject.name }}
                            </p>
                        </div>
                    </button>
                    <ProjectSelection
                        :team="team"
                        :projects="team.projects"
                        :brandIdentities="team.brand_identities"
                        :currentProjectId="currentProject?.id"
                        v-show="index == selectedTeamAccordionIndex"
                        @switch-team="switchToTeam($event)"
                    ></ProjectSelection>
                </div>
            </div>
            <div class="dropdown-footer position-sticky start-0 end-0 bottom-0">
                <div class="d-none dropdown-footer-item">
                    <a :href="route('teams.create')">
                        <IconPlus />
                        {{ __('Create Team') }}
                    </a>
                </div>
                <div class="dropdown-footer-item">
                    <a
                        :href="
                            route('teams.show', $page.props.user.current_team)
                        "
                    >
                        <IconSettings />
                        {{ __('Settings') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!--end::Switch profiles-->
</template>

<script>
import ProjectSelection from '@/Layouts/Aside/ProjectSelection.vue';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import IconPlus from '@/icons/plus.svg?component';
import IconSettings from '@/icons/settings.svg?component';


export default {
    components: {
        ProjectSelection,
        IconPlus,
        IconSettings,
    },

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods
        return {
            toast,
        };
    },

    props: {
        isMenuCollapsed: Boolean,
        isMenuHover: Boolean,
    },

    data() {
        return {
            isActiveTeamSelection: false,
            selectedTeamAccordionIndex: null,
            selectedTeamIndex: null,
        };
    },

    mounted() {
        this.selectedTeamIndex = this.allTeams?.findIndex(
            (team) => team.id === this.currentTeam?.id
        );
        this.selectedTeamAccordionIndex = this.selectedTeamIndex;
    },

    computed: {
        currentTeam() {
            return this.$page.props?.user?.current_team || null;
        },
        allTeams() {
            const user = this.$page.props?.user;

            return (
                user?.all_teams?.filter(
                    (team) => team.has_active_subscription
                ) || []
            );
        },
        currentProject() {
            return this.$page.props.user?.current_project || null;
        },
        currentTeamBrandIdentities() {
            return this.currentTeam?.brand_identities || null;
        },
        currentBrandIdentity() {
            let currentProjectBrandIdentityId =
                this.currentProject?.brand_identity_id;

            return (
                this.currentTeamBrandIdentities?.filter(
                    (brandIdentity) =>
                        brandIdentity.id === currentProjectBrandIdentityId
                )[0] || null
            );
        },
    },

    methods: {
        toggleTeamSelection() {
            this.isActiveTeamSelection = !this.isActiveTeamSelection;
        },
        toggleTeamAccordion(index) {
            this.selectedTeamAccordionIndex =
                index === this.selectedTeamAccordionIndex ? null : index;
        },
        selectTeam(index) {
            if (this.selectedTeamIndex == index) {
                return false;
            }

            let teamId = this.allTeams[index].id;

            this.switchToPreviousProject(teamId);
        },
        switchToPreviousProject(teamId) {
            let vm = this;
            let payload = {
                new_team_id: teamId,
            };

            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .put(route('update-project-id-on-team-switch'), payload)
                .then(() => {
                    vm.switchToTeam(teamId);
                })
                .catch((error) => {
                    let errorMessage =
                        error.response?.data?.message ||
                        vm.__('Some error occurred');
                    vm.toast.error(errorMessage);
                });
        },
        switchToTeam(teamId) {
            this.$inertia.put(
                // eslint-disable-next-line no-undef
                route('current-team.update'),
                {
                    team_id: teamId,
                },
                {
                    preserveState: false,
                    onSuccess: () => {
                        // Redirect to another page after updating the current team
                        // eslint-disable-next-line no-undef
                        this.$inertia.visit(route('dashboard'));
                    },
                }
            );
        },
        getInitialImage(str, size = 2) {
            if (!str) {
                return false;
            }

            const abbreviatedFirstTwoWords = this.abbreviateFirstTwoWords(
                str,
                size
            );

            return `https://ui-avatars.com/api/?name=${abbreviatedFirstTwoWords}&color=7F9CF5&background=EBF4FF`;
        },
        abbreviateFirstTwoWords(str, size = 2) {
            // Split the string into words
            const words = str.split(' ');

            // Extract the first letter of the first N words
            const abbreviation = words
                .slice(0, size) // Take the first N words
                .map((word) => word.charAt(0)) // Extract the first letter of each word
                .join(''); // Concatenate the letters

            return abbreviation;
        },
    },
};
</script>
