<template>
    <div class="text-end position-relative action-dropdown-menu w-125px">
        <!-- Button -->
        <a
            href="#"
            ref="dropdownButton"
            class="btn btn-sm btn-light btn-active-primary btn-outline btn-outline-highlighted flex items-center justify-between gap-2"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            @click.prevent="toggle"
            v-click-away="onClickAway"
        >
            {{ __('Actions') }}
            <IconChevronDown />
        </a>

        <!-- Dropdown -->
        <teleport to="body">
            <div
                v-show="isOpen"
                ref="dropdown"
                class="bg-white border border-gray-300 rounded-xl shadow menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                data-kt-menu="true"
                :style="dropdownStyles"
            >
                <slot></slot>
            </div>
        </teleport>
    </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { directive } from 'vue3-click-away';
import IconChevronDown from '@/icons/chevron-down.svg?component';

export default defineComponent({
    directives: {
        ClickAway: directive,
    },

    components: {
        IconChevronDown,
    },

    props: {
        modelValue: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        modelValue(newValue) {
            this.isOpen = newValue;
        }
    },

    data() {
        return {
            isOpen: false,
            dropdownStyles: {},
        };
    },

    methods: {
        toggle($event) {
            $event.preventDefault();
            $event.stopPropagation();

            this.updateDropdownPosition();

            this.$emit('update:modelValue', !this.isOpen);
            this.$emit('toggle', !this.isOpen);
        },

        onClickAway() {
            this.$emit('update:modelValue', false);
            this.$emit('toggle', false);
        },

        updateDropdownPosition() {
            nextTick(() => {
                const button = this.$refs.dropdownButton;
                const dropdown = this.$refs.dropdown;

                if (button && dropdown) {
                    const buttonRect = button.getBoundingClientRect();
                    const { top, left, height } = buttonRect;

                    this.dropdownStyles = {
                        position: 'fixed',
                        top: `${top + height + 5}px`,
                        left: `${left - 5}px`,
                        zIndex: 105,
                    };
                }
            });
        },
    },
});
</script>
