<template>
    <div class="row">
        <div class="col-sm-12 col-xl-4 mb-6 after-minus">
            <StatisticsCard
                :cardClass="'border border-secondary'"
                :label="__('Yout current credit balance')"
                :value="formatCredits(availableCharacterCredits)"
            />
        </div>
        <div class="col-sm-12 col-xl-4 mb-6 after-equal">
            <StatisticsCard
                :cardClass="'border border-secondary'"
                :label="__('Credits required for this processing')"
                :value="formatCredits(characterCount, '~')"
            />
        </div>
        <div class="col-sm-12 col-xl-4 mb-6">
            <StatisticsCard
                :cardClass="'border border-secondary'"
                :label="__('Remaining credits after this processing')"
                :value="formatCredits(remainingCredits, '~')"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useUserStore } from '@/Stores/UserStore';
import StatisticsCard from '@/Components/Cards/StatisticsCard.vue';

export default defineComponent({
    name: 'BulkProcessingOutputPreviewStatistics',

    components: {
        StatisticsCard,
    },

    props: {
        characterCount: {
            type: Number,
            required: true,
        },
    },

    setup() {
        // Get user store
        const userStore = useUserStore();

        // Make it available inside methods
        return {
            userStore,
        };
    },

    data() {
        return {
            availableCharacterCredits: null,
            remainingCredits: null,
        };
    },

    mounted() {
        this.fetchAvailableCharacter('lastFilled');
    },

    computed: {
        isLoading() {
            return this.availableCharacterCredits;
        },
    },

    methods: {
        buyMoreCredits() {
            emitter.emit('open-buy-more-character-credits');
        },
        fetchAvailableCharacter(range) {
            const vm = this;

            axios
                .get(route('statistics.used-characters', { range }))
                .then((response) => {
                    const totalCredits = response.data?.totalCredits ?? 0;
                    vm.availableCharacterCredits = totalCredits;
                    vm.remainingCredits = totalCredits - vm.characterCount;
                });
        },
        formatCredits(credits, prefix = "") {
            if (!credits) {
                return null;
            }
            return `${prefix}${credits.toLocaleString(this.userStore.getUserLocale)}`;
        },
    },
});
</script>
<style scoped>
.after-minus,
.after-equal{
    position: relative;
    padding-right: 20px;
}
.after-minus::after{
    content: '-';
    position: absolute;
    top: calc(50% - 15px);
    right: 0;
    font-size: 30px;
    line-height: 1;
}
.after-equal::after{
    content: '=';
    position: absolute;
    top: calc(50% - 15px);
    right: 0;
    font-size: 20px;
    line-height: 1;
}
</style>