import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M11.434 12.734 7.25 8.55a1.06 1.06 0 0 0-1.5 1.5l5.543 5.543a1 1 0 0 0 1.414 0l5.543-5.543a1.06 1.06 0 1 0-1.5-1.5l-4.184 4.184a.8.8 0 0 1-1.132 0"
    }, null, -1)
  ])))
}
export default { render: render }