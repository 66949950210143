<template>
    <div class="d-flex flex-column flex-sm-row align-items-sm-center py-2">
        <!--begin::Label-->
        <div class="col-2 text-sm text-gray-700 fw-bolder">
            {{ __('Outputs') }}:
        </div>
        <!--end::Label-->
        <!--begin::NoUiSlider-->
        <div class="col-10 d-flex align-items-center ps-4 ms-0 ms-sm-6">
            <div
                :id="'kt_toolbar_slider-' + sliderRandomId"
                class="noUi-target noUi-target-success w-250px w-md-150px noUi-sm"
            ></div>
            <span
                id="kt_toolbar_slider_value"
                class="d-flex flex-center bg-light-success rounded-circle w-35px h-35px ms-4 text-sm fw-bolder text-success"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Set impact level"
                >{{ outputs }}</span
            >
        </div>
        <!--end::NoUiSlider-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default defineComponent({
    components: {
        noUiSlider,
    },

    props: {
        pageType: '',
        defaultOutputs: Number | undefined,
    },

    data() {
        return {
            slider: {},
            outputs: this.defaultOutputs || 3,
            sliderRandomId: Math.floor(Math.random() * 1000000),
        };
    },
    watch: {
        defaultOutputs(newValue) {
            this.outputs = newValue;

            this.slider.updateOptions({
                start: newValue,
            });
        },
    },
    mounted() {
        this.slider = noUiSlider.create(
            document.querySelector('#kt_toolbar_slider-' + this.sliderRandomId),
            {
                start: this.outputs,
                step: 1,
                connect: true,
                range: {
                    min: 1,
                    max: 6,
                },
                format: {
                    to: function (value) {
                        return value;
                    },
                    from: function (value) {
                        return Number(value);
                    },
                },
            }
        );
        let vm = this;
        this.slider.on('slide', function (ev) {
            vm.outputs = ev[0];
            vm.$emit('outputs-change', ev[0]);
        });
    },
});
</script>

<style lang="scss">
.noUi-target.noUi-sm {
    height: 6px;
}
.noUi-target {
    border: 1px solid #9df8bc;
}
.noUi-target.noUi-target-success .noUi-connects {
    background: #9df8bc;
}
.noUi-target.noUi-target-success .noUi-handle {
    border: 1px solid #5cc78d;
    box-shadow: 0 3px 6px -3px rgb(80 205 137 / 70%);
    background: #50cd89;
}
.noUi-target.noUi-sm .noUi-handle {
    width: 20px;
    height: 20px;
    top: -7px;
}
.noUi-target.noUi-horizontal .noUi-handle {
    width: 24px;
    height: 24px;
    top: -9.5px;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
}
.noUi-touch-area {
    height: 100%;
    width: 100%;
}
.noUi-handle::before,
.noUi-handle::after {
    display: none;
}
</style>
