<template>
    <div class="card card-flush my-2" :class="cardClass">
        <!--begin::Header-->
        <div class="card-body">
            <!--begin::Title-->
            <div class="d-flex flex-column">
                <template v-if="isLoading">
                    <div
                        class="d-flex align-items-center justify-content-center p-5"
                    >
                        <div class="spinner-border spinner-border text-primary">
                            <span class="visually-hidden">
                                {{ __('Loading...') }}
                            </span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <!--begin::Info-->
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div class="d-flex">
                            <!--begin::Amount-->
                            <span
                                class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2"
                            >
                                {{ value }}
                            </span>
                        </div>

                        <div class="d-flex" v-if="urlLink || urlLabel">
                            <slot
                                name="link"
                                :urlLink="urlLink"
                                :urlLabel="urlLabel"
                            >
                                <Link
                                    :href="urlLink"
                                    class="btn btn-sm btn-secondary"
                                >
                                    <span
                                        class="svg-icon svg-icon-muted svg-icon-2"
                                    >
                                        {{ urlLabel }}
                                    </span>
                                </Link>
                            </slot>
                        </div>
                    </div>
                    <!--end::Info-->

                    <!--begin::Subtitle-->
                    <span class="text-gray-400 pt-1 fw-semibold fs-6 mb-4">
                        {{ label }}
                    </span>
                </template>
            </div>
            <!--end::Title-->
        </div>
        <!--end::Header-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';

export default defineComponent({
    name: 'StatisticsCard',

    components: {
        Link,
    },

    props: {
        cardClass: {
            type: String,
            required: false,
            default: '',
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        urlLabel: {
            type: String,
            default: null,
        },
        urlLink: {
            type: String,
            default: null,
        },
    },
});
</script>

