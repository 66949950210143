<template>
    <div
        v-if="!outlineItem.isLoading && !outlineItem.error"
        v-for="element in headingLists"
        :key="element.id"
        class="list-group-item border border-gray-300 w-100"
        v-show="
            outlineItem.rank >= currentRankRangeStart &&
            outlineItem.rank <= currentRankRangeEnd
        "
    >
        <!-- H1 Display Section -->
        <div class="h1-element d-flex p-2 ms-0 bg-light">
            <div class="d-flex align-items-baseline">
                <span class="badge badge-white me-2"
                    >#{{ outlineItem.rank }}</span
                >
            </div>
            <div class="d-flex flex-1">
                <span class="headline">{{ element.name }}</span>
            </div>
        </div>

        <draggable
            class="dragArea outline-elements w-100 p-0"
            :list="headingLists"
            :group="{ name: 'outlineHeadings', pull: 'clone', put: false }"
        >
            <div
                v-for="subElement in element.subHeadings"
                :key="subElement.id"
                class="list-group-item p-1 border-none"
                :class="{ 'added-item': isAddedH2(subElement) }"
            >
                <div
                    class="h2-element d-flex align-items-center draggable-element p2 ms-0 my-1"
                    @click="addToCurrentFocusedH2Heading(subElement)"
                >
                    <div class="d-flex align-items-baseline">
                        <span
                            class="arrow-icon text-muted bi bi-grip-vertical"
                        ></span>
                        <span class="badge badge-secondary me-2">H2</span>
                    </div>
                    <div class="d-flex flex-1">
                        <span class="headline">{{ subElement.name }}</span>
                    </div>
                    <div class="d-flex add-action">
                        <span class="arrow-left me-1"
                            ><i class="bi bi-arrow-left"></i
                        ></span>
                    </div>
                </div>
                <draggable
                    v-if="subElement.subHeadings?.length > 0"
                    class="outline-elements w-100 px-2"
                    :list="element.subHeadings"
                    :group="{
                        name: 'outlineHeadings',
                        pull: 'clone',
                        put: false,
                    }"
                >
                    <div
                        v-for="subSubElement in subElement.subHeadings"
                        :key="subSubElement.id"
                        class="list-group-item p-1 border-none"
                        :class="{ 'added-item': isAddedH3(subSubElement) }"
                    >
                        <div
                            class="h3-element d-flex align-items-center draggable-element ms-0"
                            @click="addToCurrentFocusedH3Heading(subSubElement)"
                        >
                            <div class="d-flex align-items-baseline">
                                <span
                                    class="arrow-icon text-muted bi bi-grip-vertical"
                                ></span>
                                <span class="badge badge-light me-2">H3</span>
                            </div>
                            <div class="d-flex flex-1">
                                <span class="headline">{{
                                    subSubElement.name
                                }}</span>
                            </div>
                            <div class="d-flex add-action">
                                <span class="arrow-left"
                                    ><i class="bi bi-arrow-left"></i
                                ></span>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
        </draggable>
    </div>

    <div
        v-else-if="outlineItem.error"
        class="list-group-item border border-gray-300 w-100"
    >
        <!-- H1 Display Section -->
        <div class="h1-element d-flex p-2 ms-0 bg-light">
            <div class="d-flex align-items-baseline">
                <span class="badge badge-white me-2"
                >#{{ outlineItem.rank }}</span
                >
            </div>
            <div class="d-flex flex-1">
                <span class="headline">{{  __( 'Extract error') }}</span>
            </div>
        </div>
        <div class="outline-elements w-100 p-0">
            <div class="list-group-item text-center p-4">
                <span class="text-gray-600">{{
                        __(
                            'The outline could not be extracted'
                        )
                    }}</span>
            </div>
        </div>
    </div>

    <div
        v-else
        class="d-flex align-items-center justify-content-center bg-lighten w-100 px-10 py-20"
    >
        <div class="spinner-border spinner-border" role="status">
            <span class="visually-hidden">{{ __('Loading...') }}</span>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { getCurrentInstance } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { useToast } from 'vue-toastification';

export default {
    setup(props) {
        const instance = getCurrentInstance();
        const toast = useToast();

        // Get article wizard store
        const articleWizardStore = useArticleWizardStore();

        const addToCurrentFocusedH2Heading = (outlineItem) => {
            const clonedOutlineItem = JSON.parse(JSON.stringify(outlineItem));
            articleWizardStore.addToH2HeadingList(clonedOutlineItem);
            articleWizardStore.setCurrentH2Id(clonedOutlineItem.id);
        };
        const addToCurrentFocusedH3Heading = (outlineItem) => {
            const currentH2Id = articleWizardStore.getCurrentH2Id; // fetch current H2 ID
            if (currentH2Id !== null) {
                const clonedOutlineItem = JSON.parse(
                    JSON.stringify(outlineItem)
                );
                articleWizardStore.addToH3HeadingList(
                    currentH2Id,
                    clonedOutlineItem
                );
            } else {
                toast.error(
                    instance.proxy.__('Please select a H2 heading first')
                );
            }
        };

        const isAddedH2 = (h2Element) => {
            return articleWizardStore.getHeadingLists.some(
                (heading) => heading.id === h2Element.id
            );
        };

        const isAddedH3 = (outlineItem) => {
            let isAdded = false;
            const headingLists = articleWizardStore.getHeadingLists;

            headingLists.forEach((h2) => {
                const foundH3 = h2.subHeadings.some((h3) => {
                    return h3.id === outlineItem.id;
                });

                if (foundH3) {
                    isAdded = true;
                }
            });

            return isAdded;
        };

        const headingLists = computed(() => {
            let i = 0;
            const newHeadingLists = [];

            if (props?.outlineItem?.data?.h1) {
                const mainHeading = {
                    type: 'h1',
                    name: props.outlineItem.data.h1,
                    id: `${props.index}-${++i}`,
                    subHeadings: [],
                };

                if (Array.isArray(props.outlineItem.data.h2)) {
                    props.outlineItem.data.h2.forEach((h2Item) => {
                        if (h2Item && h2Item.h2) {
                            const subHeading = {
                                type: 'h2',
                                name: h2Item.h2,
                                id: `${props.index}-${++i}`,
                                subHeadings: Array.isArray(h2Item.h3)
                                    ? h2Item.h3
                                        .filter(h3Item => h3Item)
                                        .map((h3Item) => ({
                                            type: 'h3',
                                            name: h3Item,
                                            id: `${props.index}-${++i}`,
                                        }))
                                    : [],
                            };
                            mainHeading.subHeadings.push(subHeading);
                        }
                    });
                }

                newHeadingLists.push(mainHeading);
            }

            return newHeadingLists;
        });

        return {
            toast,
            addToCurrentFocusedH2Heading,
            addToCurrentFocusedH3Heading,
            isAddedH2,
            isAddedH3,
            headingLists,
        };
    },

    props: {
        outlineItem: {
            type: Object,
            required: true,
            validator: (obj) => {
                return obj && typeof obj === 'object';
            }
        },
        index: {
            type: Number,
            required: true
        },
        currentRankRangeStart: {
            type: Number,
            required: true
        },
        currentRankRangeEnd: {
            type: Number,
            required: true
        },
    },

    components: {
        draggable: VueDraggableNext,
    },
};
</script>

<style lang="scss">
.outline-elements {
    .h3-element {
        margin-left: 10px;
    }

    .list-group-item {
        border-radius: 0;

        &:hover,
        &:focus {
            background-color: rgba(54, 153, 255, 0.1);
        }
    }
}

.added-item {
    > div > div > .headline {
        color: var(--bs-gray-400);
    }
}
</style>
