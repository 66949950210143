import { defineStore } from 'pinia';

function getDefaultState() {
    return {
        aiAssistantTemplates: [],//Data is hydrated while mouting the component
        fileHeaders: [],//Data is hydrated while mouting the component
        languages: [],//Data is hydrated while mouting the component
        outputSelections: [],
        outputFormats: [],//Data is hydrated while mouting the component
        selectedTranslations: [],
        hasBeenSubmitted: false,
    };
}

export const useBulkProcessingStore = defineStore('bulkProcessingStore', {
    state: () => getDefaultState(),
    getters: {
        isValidSubmission: (state) => {
            // Check if outputSelections.column_name exists and is unique
            const columnNames = state.outputSelections
                .filter((selection) => selection.column_name != '')
                .map((selection) => selection.column_name);
            const uniqueColumnNames = new Set(columnNames);
            const isColumnNameUnique =
                columnNames.length === uniqueColumnNames.size;

            // Check if outputSelections.assistant has value
            const hasAssistantValue = state.outputSelections.every(
                (selection) => selection.assistant
            );

            // Check if outputSelections.inputs has element
            const hasInputs = state.outputSelections.every(
                (selection) => selection.inputs.length > 0
            );

            // Return true only if all conditions are met
            return (
                isColumnNameUnique &&
                columnNames.length > 0 &&
                hasAssistantValue &&
                hasInputs &&
                state.selectedTranslations.length > 0
            );
        },
    },
    actions: {
        resetStore() {
            this.$reset();
        },
    },
});
