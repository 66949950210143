<template>
    <div class="fv-row mb-10 fv-plugins-icon-container">
        <!--begin::Label-->
        <label class="fw-bold mb-2 text-base text-gray-800">{{ title }}</label>
        <!--end::Label-->
        <textarea
            ref="textarea"
            class="form-control text-base rounded-xl"
            aria-label="With textarea"
            v-model="text"
            :placeholder="placeholder"
            @input="changeText"
            :style="textareaStyle"
            :maxlength="fieldMaxLength"
        >
        </textarea>
        <div
            class="flex text-gray-600 text-sm"
            :class="{
                'justify-content-between':
                    descriptionCharCount > 0 && descriptionCharCount < 40,
                'justify-content-end':
                    descriptionCharCount === 0 || descriptionCharCount >= 40,
            }"
        >
            <div
                class="align-self-start text-warning"
                :class="{
                    'd-none':
                        descriptionCharCount === 0 ||
                        descriptionCharCount >= 40,
                }"
            >
                <span class="fs-8 fw-bolder"
                    ><i
                        class="bi bi-exclamation-triangle-fill text-warning me-1"
                    ></i
                    >{{
                        __('Too Short. Write more for a better result.')
                    }}</span
                >
            </div>
            <div
                class="align-self-end"
                :class="{
                    'text-warning':
                        descriptionCharCount > 0 && descriptionCharCount < 40,
                }"
            >
                <span>{{ descriptionCharCount }}</span
                >/{{ fieldMaxLength }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        inheritedValue: {
            type: String,
        },
        maxLength: {
            default: 10000,
        },
    },

    data() {
        return {
            text: '',
            textareaStyle: '',
        };
    },

    watch: {
        inheritedValue: function (value) {
            this.text = value;
        },
    },

    mounted() {
        this.text = this.inheritedValue;
        let vm = this;
        this.$emit('value-change', {
            component: 'description',
            value: vm.text,
        });
    },

    methods: {
        changeText() {
            this.$emit('value-change', {
                component: 'description',
                value: this.text,
            });
            this.$refs.textarea.style.cssText = 'height:auto;';
            this.$refs.textarea.style.cssText =
                'height:' + this.$refs.textarea.scrollHeight + 'px';
        },
    },

    computed: {
        descriptionCharCount() {
            return this.text ? this.text.length : 0;
        },
        fieldMaxLength() {
            return Number(this.maxLength) > 0 ? Number(this.maxLength) : 10000;
        },
    },
};
</script>
