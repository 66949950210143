<template>
    <app-layout
        :title="__('SERP Competitors')"
        :view="__('List')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('SERP Competitors') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('SERP Competitors') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __(
                            'Here you can find all the competitors for given keyword.'
                        )
                    }}
                </div>
            </div>
        </div>
        <div class="card card-flush card-bordered">
            <!--begin::Card header-->
            <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                <!--begin::Card title-->
                <div class="card-title"></div>
                <!--end::Card title-->
                <!--begin::Card toolbar-->
                <div
                    class="card-toolbar flex-row-fluid justify-content-end gap-5"
                >
                    <Link
                        href="#"
                        @click="saveSelection"
                        class="btn btn-primary"
                    >
                        {{ __('Next') }}
                    </Link>
                    <!--end::Add product-->
                </div>
                <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
                <!--begin::Table-->
                <div
                    v-show="!articleWizardStore.getLoading"
                    id="serp_beater_competitors_table_wrapper"
                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div class="bootstrap-table-responsive">
                        <table
                            id="serp_beater_competitors_table"
                            class="bootstrap-table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                        >
                            <!--begin::Table head-->
                            <thead>
                                <!--begin::Table row-->
                                <tr
                                    class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                >
                                    <th
                                        class="w-10px pe-2 sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label=""
                                    >
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="allSelected"
                                                @change="selectAll"
                                            />
                                        </div>
                                    </th>
                                    <th
                                        class="text-end min-w-50px sorting"
                                        tabindex="0"
                                        aria-controls="serp_beater_competitors_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Position"
                                    >
                                        {{ __('Position') }}
                                    </th>
                                    <!--
                <th class="min-w-150px sorting" tabindex="0" aria-controls="serp_beater_competitors_table" rowspan="1"
                    colspan="1" aria-label="Content score">
                  {{ __('Content score') }}
                </th>-->
                                    <th
                                        class="min-w-150px sorting"
                                        tabindex="0"
                                        aria-controls="serp_beater_competitors_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Domain"
                                    >
                                        {{ __('Domain') }}
                                    </th>
                                    <th
                                        class="min-w-150px sorting"
                                        tabindex="0"
                                        aria-controls="serp_beater_competitors_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Url"
                                    >
                                        {{ __('Url') }}
                                    </th>
                                    <th
                                        class="text-start min-w-200px sorting"
                                        tabindex="0"
                                        aria-controls="serp_beater_competitors_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Title"
                                    >
                                        {{ __('Title') }}
                                    </th>
                                    <th
                                        class="text-start sorting"
                                        tabindex="0"
                                        aria-controls="serp_beater_competitors_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Description"
                                    >
                                        {{ __('Description') }}
                                    </th>
                                </tr>
                                <!--end::Table row-->
                            </thead>
                            <!--end::Table head-->
                            <!--begin::Table body-->
                            <tbody class="fw-bold text-gray-600">
                                <!--begin::Table row-->

                                <!--end::Table row-->
                                <tr
                                    v-for="(
                                        serpBeaterCompetitor, index
                                    ) in serpBeaterCompetitors"
                                >
                                    <!--begin::Checkbox-->
                                    <td>
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    serpBeaterCompetitorIds
                                                "
                                                @change="select"
                                                :value="serpBeaterCompetitor.id"
                                            />
                                        </div>
                                    </td>
                                    <!--end::Checkbox-->
                                    <!--begin::Position=-->
                                    <td>
                                        <div class="d-flex align-items-center">
                                            {{
                                                serpBeaterCompetitor.rank_group
                                            }}
                                        </div>
                                    </td>
                                    <!--end::Position=-->
                                    <!--begin::Domain=-->
                                    <!--                <td>
                  <div class="d-flex align-items-center">
                    <span class="badge badge-circle w-30px h-30px" :class="contentScoreClass(serpBeaterCompetitor.content_score)">{{ serpBeaterCompetitor.content_score }}</span>
                  </div>
                </td>-->
                                    <!--end::Domain=-->
                                    <!--begin::Domain=-->
                                    <td>
                                        <div class="d-flex align-items-center">
                                            {{ serpBeaterCompetitor.domain }}
                                        </div>
                                    </td>
                                    <!--end::Domain=-->
                                    <!--begin::Url=-->
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <!--begin::Url-->
                                            <a
                                                :href="serpBeaterCompetitor.url"
                                                target="_blank"
                                                class="text-gray-700"
                                            >
                                                {{ serpBeaterCompetitor.url }}
                                            </a>
                                            <!--end::Url-->
                                        </div>
                                    </td>
                                    <!--end::Url=-->
                                    <!--begin::Title=-->
                                    <td class="text-start text-truncate">
                                        {{ serpBeaterCompetitor.title }}
                                    </td>
                                    <!--end::Title=-->
                                    <!--begin::Description=-->
                                    <td
                                        class="text-start text-truncate"
                                        data-order="41"
                                    >
                                        {{ serpBeaterCompetitor.description }}
                                    </td>
                                    <!--end::Description=-->
                                </tr>
                            </tbody>
                            <!--end::Table body-->
                        </table>
                    </div>
                </div>
                <div v-show="articleWizardStore.getLoading" class="h-100">
                    <div class="row gx-5 gx-xl-10 h-50">
                        <div
                            class="col-12 d-flex justify-content-center align-items-center"
                        >
                            <CreaitorLoading
                                css-classes="w-100px h-100px"
                            ></CreaitorLoading>
                        </div>
                    </div>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import AppLayout from '@/Layouts/AppLayout.vue';
import Multiselect from '@vueform/multiselect';
import { directive } from 'vue3-click-away';
import moment from 'moment/moment';
import { useToast } from 'vue-toastification';
import CreaitorLoading from '@/Components/Loading/CreaitorLoading.vue';

export default defineComponent({
    setup() {
        // Get article wizard store.
        const articleWizardStore = useArticleWizardStore();

        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { articleWizardStore, toast };
    },

    components: {
        Link,
        AppLayout,
        Multiselect,
        CreaitorLoading,
    },

    directives: {
        ClickAway: directive,
    },

    data() {
        return {
            search: '',
            serpBeatersUrl: '/api/serp-beaters',
            serpBeaters: {},
            serpBeaterCompetitorsUrl: '/api/serp-beater-competitors',
            serpBeaterCompetitors: {},
            copyClicked: false,
            selected: [],
            allSelected: false,
            serpBeaterCompetitorIds: [],
            currentMenu: {
                open: false,
                element: {},
            },
            moment: moment,
        };
    },

    mounted() {
        this.fetchSerpBeaterCompetitors();
    },

    methods: {
        fetchSerpBeaterCompetitors() {
            let vm = this;
            this.loading = true;

            axios
                .get(this.serpBeatersUrl + '/' + this.$attrs.serp_beater.uuid)
                .then((response) => {
                    vm.serpBeaters = response.data;
                    axios
                        .get(
                            this.serpBeaterCompetitorsUrl +
                                '/' +
                                vm.serpBeaters.id
                        )
                        .then((response) => {
                            vm.serpBeaterCompetitors = response.data;

                            Object.values(vm.serpBeaterCompetitors).forEach(
                                function (serpBeaterCompetitor) {
                                    if (serpBeaterCompetitor.rank_group <= 10) {
                                        vm.serpBeaterCompetitorIds.push(
                                            serpBeaterCompetitor.id
                                        );
                                    }
                                }
                            );
                        });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        selectAll() {
            let vm = this;
            vm.serpBeaterCompetitorIds = [];
            if (this.allSelected) {
                this.serpBeaterCompetitors.forEach(function (serpBeater) {
                    vm.serpBeaterCompetitorIds.push(serpBeaterCompetitor.uuid);
                });
            }
        },

        select() {
            this.allSelected = false;
        },

        saveSelection() {
            let vm = this;
            axios
                .post('/api/serp-beaters/save-selection', {
                    serpBeaterCompetitorIds: vm.serpBeaterCompetitorIds,
                    serpBeaterUuid: vm.serpBeaters.uuid,
                })
                .then((response) => {
                    let url = '/documents/' + response.data.document.uuid;
                    this.$inertia.visit(url);
                });
        },

        toggle($event) {
            let el = $event.target.closest('.action-dropdown-menu');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
            this.currentMenu.open = this.currentMenu.open !== true;
            this.currentMenu.element = el;
        },

        onClickAway(ev) {
            if (this.currentMenu.open) {
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(ev.target)) {
                    let s = el.classList;
                    let m =
                        el.parentElement.getElementsByClassName(
                            'menu-sub-dropdown'
                        )[0].classList;

                    s.contains('show') ? s.remove('show') : s.add('show');
                    s.contains('menu-dropdown')
                        ? s.remove('menu-dropdown')
                        : s.add('menu-dropdown');
                    m.contains('show') ? m.remove('show') : m.add('show');
                    this.currentMenu.open = false;
                }
            }
        },

        contentScoreClass(difficulty) {
            let difficultyClass = 'badge-white';
            switch (true) {
                case difficulty < 1:
                    difficultyClass = 'badge-white';
                    break;
                case difficulty < 33:
                    difficultyClass = 'badge-danger';
                    break;
                case difficulty < 63:
                    difficultyClass = 'badge-warning';
                    break;
                case difficulty < 100:
                    difficultyClass = 'badge-success';
                    break;
            }
            return difficultyClass;
        },
    },
    computed: {},
});
</script>

<style lang="scss">
.action-dropdown-menu {
    .menu-sub-dropdown {
        top: 51px;
        right: 20px;
    }
}
</style>
