<template>
    <div class="d-flex flex-column justify-content-between w-100">
        <div class="d-flex flex-column">
            <!--begin::Heading-->
            <div class="mb-5">
                <!--begin::Title-->
                <h2 class="text-primary">{{ __('SERP Competitors') }}</h2>
                <p class="text-muted">
                    {{
                        __(
                            'Here you can find all the competitors for given keyword.'
                        )
                    }}
                </p>
            </div>
            <!--end::Heading-->
        </div>

        <div class="d-flex flex-column flex-grow-1">
            <!--begin::Table-->
            <div
                id="serp_beater_competitors_table_wrapper"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
                <div class="bootstrap-table-responsive">
                    <table
                        class="bootstrap-table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                        id="serp_beater_competitors_table"
                        aria-label="serp_beater_competitors_table"
                    >
                        <!--begin::Table head-->
                        <thead>
                            <!--begin::Table row-->
                            <tr
                                class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                            >
                                <th
                                    class="w-10px pe-2 sorting_disabled"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label=""
                                >
                                    <div
                                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="allSelected"
                                            @change="selectAll"
                                        />
                                    </div>
                                </th>
                                <th
                                    class="text-end min-w-50px sorting"
                                    tabindex="0"
                                    aria-controls="serp_beater_competitors_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Position"
                                >
                                    {{ __('Position') }}
                                </th>
                                <!--                                <th class="min-w-150px sorting" tabindex="0" aria-controls="serp_beater_competitors_table"
                                    rowspan="1" colspan="1" aria-label="Content score">
                                    {{ __('Content score') }}
                                </th>-->
                                <th class="min-w-20px sorting"</th>
                                <th
                                    class="min-w-150px sorting"
                                    tabindex="0"
                                    aria-controls="serp_beater_competitors_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Domain"
                                >
                                    {{ __('Domain') }}
                                </th>
                                <th
                                    class="min-w-150px sorting"
                                    tabindex="0"
                                    aria-controls="serp_beater_competitors_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Url"
                                >
                                    {{ __('Url') }}
                                </th>
                                <th
                                    class="text-start min-w-200px sorting"
                                    tabindex="0"
                                    aria-controls="serp_beater_competitors_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Title"
                                >
                                    {{ __('Title') }}
                                </th>
                                <th
                                    class="text-start sorting"
                                    tabindex="0"
                                    aria-controls="serp_beater_competitors_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Description"
                                >
                                    {{ __('Description') }}
                                </th>
                            </tr>
                            <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                            <!--begin::Table row-->

                            <!--end::Table row-->
                            <tr
                                v-for="(
                                    serpBeaterCompetitor, index
                                ) in serpBeaterCompetitors"
                                :key="index"
                            >
                                <!--begin::Checkbox-->
                                <td>
                                    <div
                                        class="form-check form-check-sm form-check-custom form-check-solid"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="serpBeaterCompetitorIds"
                                            @change="select"
                                            :value="serpBeaterCompetitor.id"
                                        />
                                    </div>
                                </td>
                                <!--end::Checkbox-->
                                <!--begin::Position=-->
                                <td>
                                    <div class="d-flex align-items-center">
                                        {{ serpBeaterCompetitor.rank_group }}
                                    </div>
                                </td>
                                <!--end::Position=-->
                                <!--begin::Favicon=-->
                                <td>
                                    <div class="d-flex align-items-center">
                                        <img
                                            :src="`https://www.google.com/s2/favicons?domain=${serpBeaterCompetitor.domain}&size=16`"
                                            :alt="serpBeaterCompetitor.domain + ' favicon'"
                                        />
                                    </div>
                                </td>
                                <!--end::Favicon=-->
                                <!--begin::Domain=-->
                                <td>
                                    <div class="d-flex align-items-center">
                                        {{ serpBeaterCompetitor.domain }}
                                    </div>
                                </td>
                                <!--end::Domain=-->
                                <!--begin::Url=-->
                                <td>
                                    <div class="d-flex align-items-center">
                                        <!--begin::Url-->
                                        <a
                                            :href="serpBeaterCompetitor.url"
                                            target="_blank"
                                            class="text-gray-700 d-flex align-items-center"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-arrow-up-right-from-square"
                                                class="me-2 fs-7 text-gray-600"
                                            />
                                            {{ serpBeaterCompetitor.url }}
                                        </a>
                                        <!--end::Url-->
                                    </div>
                                </td>
                                <!--end::Url=-->
                                <!--begin::Title=-->
                                <td class="text-start text-truncate">
                                    {{ serpBeaterCompetitor.title }}
                                </td>
                                <!--end::Title=-->
                                <!--begin::Description=-->
                                <td
                                    class="text-start text-truncate"
                                    data-order="41"
                                >
                                    {{ serpBeaterCompetitor.description }}
                                </td>
                                <!--end::Description=-->
                            </tr>
                        </tbody>
                        <!--end::Table body-->
                    </table>
                </div>
            </div>
            <!--          <div class="h-100">
            <div class="row gx-5 gx-xl-10 h-100">
              <div class="col-12 d-flex justify-content-center align-items-center">
                <CreaitorLoading css-classes="w-150px h-150px" :loading-text="__('Competitor analysis...')"></CreaitorLoading>
              </div>
            </div>
          </div>-->
            <!--end::Table-->
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { useToast } from 'vue-toastification';
import CreaitorLoading from '@/Components/Loading/CreaitorLoading.vue';

export default defineComponent({
    setup() {
        // Get article wizard store.
        const articleWizardStore = useArticleWizardStore();

        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { articleWizardStore, toast };
    },

    components: {
        CreaitorLoading,
    },

    props: {
        user: Object,
    },

    data() {
        return {
            selected: [],
            allSelected: false,
            serpBeaterCompetitorsFetchInterval: null,
        };
    },

    mounted() {
        this.updateLoadingState(true);
    },

    beforeUnmount() {
        clearInterval(this.serpBeaterCompetitorsFetchInterval);
        this.updateLoadingState(false);
    },

    computed: {
        serpBeaterCompetitors: {
            get() {
                return this.articleWizardStore.getSerpBeaterCompetitors;
            },
            set(value) {
                this.articleWizardStore.setSerpBeaterCompetitors(value);
            },
        },
        serpBeaterCompetitorIds: {
            get() {
                return this.articleWizardStore.getSelectedSerpBeaterCompetitorsId;
            },
            set(value) {
                this.articleWizardStore.setSelectedSerpBeaterCompetitorsId(value);
            },
        },
    },

    watch: {
        'articleWizardStore.currentStep': {
            handler(newVal) {
                if (newVal === 1) {
                    this.serpBeaterCompetitorsFetchInterval = setInterval(
                        this.fetchSerpBeaterCompetitors,
                        2000
                    );
                } else {
                    clearInterval(this.serpBeaterCompetitorsFetchInterval);
                }
            },
            immediate: true,
        },
    },

    methods: {
        fetchSerpBeaterCompetitors() {
            let vm = this;
            axios
                .get(
                    `/api/serp-beater-competitors/${this.articleWizardStore?.serpBeater?.id}`
                )
                .then((response) => {
                    // Compare new data with current state before updating
                    if (
                        JSON.stringify(vm.serpBeaterCompetitors) !==
                        JSON.stringify(response.data)
                    ) {
                        vm.serpBeaterCompetitors = response.data;
                        vm.serpBeaterCompetitorIds = []; // Resetting this here, you might want to add conditions for this as well

                        // Stop fetching if competitors are found
                        if (vm.serpBeaterCompetitors.length > 0) {
                            clearInterval(
                                vm.serpBeaterCompetitorsFetchInterval
                            );
                            vm.updateLoadingState(false);
                        }

                        // Update competitor IDs based on rank group
                        vm.serpBeaterCompetitors.forEach(
                            function (serpBeaterCompetitor) {
                                if (serpBeaterCompetitor.rank_group <= 10) {
                                    vm.serpBeaterCompetitorIds.push(
                                        serpBeaterCompetitor.id
                                    );
                                }
                            }
                        );
                    }
                })
                .catch((error) => {
                    clearInterval(vm.serpBeaterCompetitorsFetchInterval);
                    vm.updateLoadingState(false); // You may also want to set the loading state to false here
                });
        },
        selectAll() {
            let vm = this;
            vm.serpBeaterCompetitorIds = [];

            if (this.allSelected) {
                Object.values(this.serpBeaterCompetitors).forEach(
                    function (serpBeaterCompetitor) {
                        vm.serpBeaterCompetitorIds.push(
                            serpBeaterCompetitor.id
                        );
                    }
                );
            }
        },
        select() {
            this.allSelected = false;
        },
        contentScoreClass(difficulty) {
            let difficultyClass = 'badge-white';
            switch (true) {
                case difficulty < 1:
                    difficultyClass = 'badge-white';
                    break;
                case difficulty < 33:
                    difficultyClass = 'badge-danger';
                    break;
                case difficulty < 63:
                    difficultyClass = 'badge-warning';
                    break;
                case difficulty < 100:
                    difficultyClass = 'badge-success';
                    break;
            }
            return difficultyClass;
        },
        updateLoadingState(state = false) {
            this.articleWizardStore.setLoading(state);
        },
    },
});
</script>
