<template>
    <div class="d-flex flex-column flex-sm-row align-items-sm-center my-1 py-2">
        <!--begin::Label-->
        <label class="col-2 text-sm text-gray-700 fw-bolder">{{
            __('Emojis')
        }}:</label>
        <!--end::Label-->
        <div class="col-10 form-check form-check-custom form-check-solid">
            <input
                type="checkbox"
                id="flexCheckDefault"
                class="form-check-input border emojis-input"
                value=""
                @change="$emit('with-emojis-change', withEmojis)"
                v-model="withEmojis"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'WithEmoijs',
    props: {
        pageType: '',
    },
    data() {
        return {
            withEmojis: false,
        };
    },
});
</script>
<style>
.form-check.form-check-solid .emojis-input {
    background-color: white;
}
</style>
