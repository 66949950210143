<template>
    <div class="card ai-assistant mb-5">
        <div class="card-body">
            <div class="">
                <div class="ai-assistant-header mb-20">
                    <h2 class="greeting"><span class="text-gradient">{{ __('Hello') }}, {{ user.name }}.</span></h2>
                    <h2 class="question text-gray-400">{{ __('How can I help you today?') }}</h2>
                </div>
                <div class="ai-assistant-suggestions pt-15 mb-4">
                    <div class="d-flex m-0 p-0">
                        <div
                            v-for="(query, index) in userQueryExamples"
                            :class="['card bg-light', 'mx-2', index === 0 ? 'ms-0' : '', index === userQueryExamples.length - 1 ? 'me-0' : '']"
                            :key="index"
                        >
                            <a href="#"
                               class="badge badge-light text-gray-800 fs-7 me-2"
                               @click.prevent="selectUserQuery(query.text)">{{ query.text }}</a>
                        </div>
                    </div>
                </div>
                <!--begin::Send-->
                <div class="d-flex flex-1">
                    <div class="input-group input-group-solid">
                        <!--begin::Input-->
                        <textarea
                            class="form-control"
                            rows="1"
                            ref="userQueryInput"
                            data-kt-element="input"
                            :placeholder="__('E.g. I need a headline for my article post')"
                            v-model="userQuery"
                            @keydown.enter.prevent="fetchAiToolSuggestion"
                        ></textarea>
                        <!--end::Input-->
                        <button
                            class="btn btn-sm btn-primary submit"
                            :class="[
                            {
                                disabled: templateSuggestionLoading,
                            },
                        ]"
                            @click="fetchAiToolSuggestion"
                            :disabled="templateSuggestionLoading"
                        >
                            <div
                                class="spinner-border spinner-border text-primary"
                                v-if="templateSuggestionLoading"
                            >
                            <span class="visually-hidden">
                                {{ __('Loading...') }}
                            </span>
                            </div>
                            <div v-else>
                                <img src="/media/images/arr-right.svg" />
                            </div>
                        </button>
                    </div>
                </div>
                <!-- This block is only for the search result window -->
                <div
                    v-if="templateSuggestionLoading"
                    class="d-flex align-items-center justify-content-center p-5 mt-5"
                >
                    <div class="spinner-border spinner-border text-primary">
                        <span class="visually-hidden">
                            {{ __('Loading...') }}
                        </span>
                    </div>
                </div>
                <div
                    class="chat-result mt-5"
                    v-if="templateSuggestions.length > 0"
                >
                    <p>{{ __('Choose one suggested solutions') }}:</p>
                    <div class="row">
                        <div
                            class="col-md-3 d-flex mb-4"
                            v-for="suggestion in templateSuggestions"
                            :key="suggestion"
                        >
                            <div class="card border bg-lighten bg-hover-light flex-1">
                                <div class="card-body d-flex flex-column justify-content-between">
                                    <div class="suggest-text">
                                        <h5>
                                            {{ suggestion.name }}
                                        </h5>
                                        <p class="mb-0">
                                            <Link :href="`${suggestion.tool_route}`"
                                                  class="text-gray-700 stretched-link">
                                                {{ suggestion.description }}
                                            </Link>
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <div
                                            class="d-flex flex-center w-30px h-30px border rounded-circle bg-white "
                                        >
                                            <img v-if="suggestion.template_icon_type === 'file'"
                                                :src="suggestion?.template_icon_path"
                                                :alt="suggestion?.name"
                                            />
                                            <font-awesome-icon v-else :icon="suggestion.template_icon_string" />
                                        </div>
                                    </div>
                                </div>

                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { defineComponent } from 'vue';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { Link } from '@inertiajs/vue3';

export default defineComponent({
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        Link,
    },

    props: {
        user: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: {},
        },
    },

    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return {
            toast,
        };
    },

    data() {
        return {
            userQuery: '',
            userQueryExamples: [
                {
                    text: this.__('I want to write a article about remote work.'),
                    icon: 'fa-regular fa-memo',
                },
                {
                    text: this.__('I need an image about dog toys.'),
                    icon: 'fa-regular fa-image',
                },
                {
                    text: this.__('I want two write a LinkedIn post announcing a company milestone.'),
                    icon: 'fa-brands fa-linkedin',
                },
            ],
            templateSuggestions: [],
            templateSuggestionLoading: false,
        };
    },

    methods: {
        selectUserQuery(query) {
            this.userQuery = query;
            const userQueryInputElement = this.$refs.userQueryInput;

            if (userQueryInputElement) {
                userQueryInputElement.focus();
            }
        },
        fetchAiToolSuggestion() {
            let vm = this;
            let payload = {
                query: this.userQuery,
            };

            this.templateSuggestionLoading = true;
            this.templateSuggestions = [];

            if (!this.userQuery) {
                this.templateSuggestionLoading = false;
                return false;
            }

            axios
                // eslint-disable-next-line no-undef
                .post(route('fetch-ai-tool-suggestion'), payload)
                .then((response) => {
                    console.log(response);
                    vm.templateSuggestions = response?.data.templates?.map(
                        (template) => {
                            let iconPath = `/storage/${template.template_icon_path}`;
                            let toolRoute = '';

                            if (template.type === 'image-generation') {
                                // eslint-disable-next-line no-undef
                                toolRoute = route(
                                    'image-generator-collection.new',
                                    { query: template.extracted_query }
                                );
                            } else if (template.type === 'article-wizard') {
                                // eslint-disable-next-line no-undef
                                toolRoute = route(
                                    'wizard',
                                    { query: template.extracted_query }
                                );
                            } else if (template.type === 'keyword-research') {
                                // eslint-disable-next-line no-undef
                                toolRoute = route(
                                    'keyword-research.list',
                                    { query: template.extracted_query }
                                );
                            } else if (template.type === 'serp-beater') {
                                // eslint-disable-next-line no-undef
                                toolRoute = route(
                                    'serp-beater.list',
                                    { query: template.extracted_query }
                                );
                            } else if (template.type === 'ai-chat') {
                                // eslint-disable-next-line no-undef
                                toolRoute = route(
                                    'chat-gpt',
                                    { query: template.extracted_query }
                                );
                            } else {
                                // eslint-disable-next-line no-undef
                                toolRoute = route('ai-assistant', {
                                    uuid: template.identifier,
                                    query: template.extracted_query,
                                });
                            }

                            let language = vm.user?.user_info?.language || 'en';

                            return {
                                ...template,
                                name:
                                    template?.name[language] ||
                                    template.name.en ||
                                    '',
                                description:
                                    template?.description[language] ||
                                    template.description.en ||
                                    '',
                                template_icon_path: iconPath,
                                tool_route: toolRoute,
                            };
                        }
                    );
                })
                .catch((error) => {
                    console.log(error);
                    let errorMessage =
                        error.response?.data.message ||
                        vm.__('Some error occurred');

                    vm.toast.error(errorMessage);
                })
                .then(() => {
                    vm.templateSuggestionLoading = false;
                });
        },
    },
});
</script>

<style lang="scss" scoped>
.ai-assistant {
    h2 {
        font-size: 4.5rem;
        font-weight: 700;
        margin-bottom: 50px;

        &.greeting {
            margin-bottom: 0;
        }
    }
    .query-example {
        font-size: 1.125rem;
        min-height: 100px;
    }
}
.text-gradient {
    color: rgba(0, 0, 0, 0);
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(101deg, #91f24a, #2bc7d4 60%, #2ac3d3 70.98%, #28b7d0 78.19%, #25a3cb 85.35%, #2088c4 92.59%, #2088c4);
    -webkit-background-clip: text;
    background-clip: text;
}
</style>