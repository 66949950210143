<template>
    <app-layout
        :title="__('Creations')"
        :view="__('Output Favorites')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Output Favorites') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('AI Output Favorites') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __(
                            'Here you can find all the AI outputs you have created and marked as favorite.'
                        )
                    }}
                </div>
            </div>
        </div>
        <div class="card card-bordered mb-7">
            <!--begin::Card body-->
            <div class="card-body">
                <!--begin::Compact form-->
                <div class="d-flex row align-items-center">
                    <!--begin::Input group-->
                    <div class="col-12 col-md-6 position-relative mb-4 mb-md-0">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                        <span
                            class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                ></rect>
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <input
                            v-model="search"
                            @input="onChangeSearch"
                            type="text"
                            class="form-control form-control-solid ps-10"
                            name="search"
                            :placeholder="__('Search')"
                        />
                    </div>
                    <!--end::Input group-->
                    <div class="col-12 col-md-6">
                        <!--begin::Select-->
                        <Multiselect
                            v-model="selectedCategory"
                            :options="selectTemplateCategoryOptions"
                            :searchable="true"
                            @change="onChangeCategory"
                            trackBy="name"
                            label="name"
                            class="px-1 py-2"
                            :placeholder="__('Category')"
                        >
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <div class="d-flex">
                                        <div
                                            class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                            :style="{
                                                backgroundColor: value.color,
                                            }"
                                        >
                                            <img
                                                :src="'/storage/' + value.icon"
                                            />
                                        </div>
                                        <div
                                            class="d-flex align-items-center ms-2"
                                        >
                                            {{ value.name[$page.props.locale] }}
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <div class="d-flex">
                                    <div
                                        class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                        :style="{
                                            backgroundColor: option.color,
                                        }"
                                    >
                                        <img :src="'/storage/' + option.icon" />
                                    </div>
                                    <div class="d-flex align-items-center ms-2">
                                        {{ option.name[$page.props.locale] }}
                                    </div>
                                </div>
                            </template>
                        </Multiselect>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-lg-3 g-9">
            <div v-if="creations.length < 1" class="col-md-6 col-lg-12">
                <!--begin::Alert-->
                <div
                    class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10"
                >
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column pe-0 pe-sm-10">
                        <!--begin::Content-->
                        <span class="text-gray-600">{{
                            __(
                                'No output yet. Create a AI-based output on the left side and mark it as favorite.'
                            )
                        }}</span>
                        <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                    <!--end::Close-->
                </div>
                <!--end::Alert-->
            </div>
            <div v-for="(creation, index) in creations" class="col">
                <div class="card card-bordered text-gray-600">
                    <div class="card-header">
                        <div class="card-title">
                            <div
                                class="d-flex flex-center w-35px h-35px rounded-circle bg-opacity-90 me-3"
                                :style="{
                                    backgroundColor:
                                        creation.ai_assistant_template_category
                                            ? creation
                                                  .ai_assistant_template_category
                                                  .color
                                            : '#F5F8FA',
                                }"
                            >
                                <img
                                    :src="
                                        '/storage/' +
                                        (creation.ai_assistant_template_category
                                            ? creation.ai_assistant_template
                                                  .template_icon_path
                                            : 'Xq3uzyEGH9fqVE95bdCouoov5olaS5qK05Gk3CPr.svg')
                                    "
                                />
                            </div>
                            <h3
                                class="card-title card-label fw-bolder text-dark"
                            >
                                {{
                                    creation.ai_assistant_template
                                        ? creation.ai_assistant_template.name[
                                              $page.props.locale
                                          ]
                                        : creation.type === 'open-form'
                                          ? 'Open Form'
                                          : creation.type
                                }}
                            </h3>
                        </div>
                        <div class="card-toolbar">
                            <a
                                href="#"
                                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                                @click.prevent="onCopy(index)"
                            >
                                <span
                                    v-if="!creation.copyClicked"
                                    class="svg-icon svg-icon-muted svg-icon-1"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            opacity="0.3"
                                            d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                <span
                                    v-else
                                    class="svg-icon svg-icon-muted svg-icon-1"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            opacity="0.3"
                                            d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M10.3629 14.0084L8.92108 12.6429C8.57518 12.3153 8.03352 12.3153 7.68761 12.6429C7.31405 12.9967 7.31405 13.5915 7.68761 13.9453L10.2254 16.3488C10.6111 16.714 11.215 16.714 11.6007 16.3488L16.3124 11.8865C16.6859 11.5327 16.6859 10.9379 16.3124 10.5841C15.9665 10.2565 15.4248 10.2565 15.0789 10.5841L11.4631 14.0084C11.1546 14.3006 10.6715 14.3006 10.3629 14.0084Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                            </a>
                            <a
                                href="#"
                                class="btn btn-icon btn-bg-light btn-active-color-primary favorite btn-sm me-1"
                                @click.stop.prevent="
                                    onClickFavorite(creation.id, index)
                                "
                                :class="{ 'is-favorite': creation.is_favorite }"
                            >
                                <span class="svg-icon svg-icon-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div
                        class="card-body card-scroll h-200px"
                        v-html="nl2br(creation.output)"
                    ></div>
                    <div class="card-footer d-flex justify-content-between">
                        <div class="d-flex">
                            <span class="text-gray-400 mt-1 fw-bold fs-8"
                                ><i class="bi bi-tag-fill"></i>
                                {{
                                    creation.ai_assistant_template_category
                                        ? creation
                                              .ai_assistant_template_category
                                              .name[$page.props.locale]
                                        : ''
                                }}</span
                            >
                        </div>
                        <div class="d-flex">
                            <span class="text-gray-400 mt-1 fw-bold fs-8"
                                >{{ __('Characters') }}:
                                {{ creation.character_count }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-stack flex-wrap pt-10">
            <div class="fs-6 fw-bold text-gray-700">
                {{ __('Showing') }} {{ from }} {{ __('to') }} {{ to }}
                {{ __('of') }} {{ total }} {{ __('entries') }}
            </div>
            <!--begin::Pages-->
            <ul class="pagination">
                <li
                    v-for="(link, index) in pageLinks"
                    class="page-item"
                    :class="{
                        'prev previous': index === 0,
                        next: index === 14,
                        active: link.label == currentPage,
                    }"
                >
                    <a
                        href="#"
                        @click.prevent="fetchCreations(link.url)"
                        class="page-link"
                    >
                        <span v-if="index === 0"><i class="previous"></i></span>
                        <span
                            v-else-if="
                                index === Object.keys(pageLinks).length - 1
                            "
                            ><i class="next"></i
                        ></span>
                        <span v-else>{{ link.label }}</span>
                    </a>
                </li>
            </ul>
            <!--end::Pages-->
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
    components: {
        AppLayout,
        Multiselect,
    },
    data() {
        return {
            search: '',
            aiAssistantTemplateCategories: [],
            selectedCategory: null,
            creationsUrl: '/api/creations',
            creations: {},
            copyClicked: false,
            currentPage: 1,
            firstPageUrl: '',
            from: 1,
            to: 1,
            total: 1,
            lastPage: 1,
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            pageLinks: {},
        };
    },
    mounted() {
        this.fetchTemplateCategories();
        this.fetchCreations(this.creationsUrl);
    },
    methods: {
        fetchTemplateCategories() {
            axios
                .get('/api/ai-assistant-template-categories')
                .then((response) => {
                    this.aiAssistantTemplateCategories = response.data;
                });
        },
        fetchCreations(url) {
            if (url) {
                let filterParams =
                    'search=' +
                    this.search +
                    '&category=' +
                    this.selectedCategory +
                    '&filter=favorites&per-page=1000';
                let requestUrl = url.includes('page')
                    ? url + '&' + filterParams
                    : url + '?' + filterParams;

                axios.get(requestUrl).then((response) => {
                    this.creations = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.firstPageUrl = response.data.first_page_url;
                    this.from = response.data.from;
                    this.to = response.data.to;
                    this.total = response.data.total;
                    this.lastPage = response.data.last_page;
                    this.lastPageUrl = response.data.last_page_url;
                    this.nextPageUrl = response.data.next_page_url;
                    this.prevPageUrl = response.data.prev_page_url;
                    this.pageLinks = response.data.links;
                });
            }
        },
        onChangeCategory(ev) {
            this.selectedCategory = ev;
            this.fetchCreations(this.creationsUrl);
        },
        onChangeSearch(ev) {
            this.fetchCreations(this.creationsUrl);
        },
        onCopy(index) {
            let copyText = this.creations[index].output;
            let vm = this;

            this.$copyText(copyText);

            this.creations[index].copyClicked = true;

            setTimeout(function () {
                vm.creations[index].copyClicked = false;
            }, 2000);
        },
        onClickFavorite(id, index) {
            let vm = this;
            vm.creations[index].is_favorite = !vm.creations[index].is_favorite;
            let data = {
                user: {
                    creation_id: id,
                },
            };
            axios
                .put('/api/users/' + this.$page.props.user.id, data)
                .then((response) => {});
        },
        nl2br(str) {
            return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
    },
    computed: {
        templateCategories() {
            return this.aiAssistantTemplateCategories.filter(
                (category) => category.highlight
            );
        },
        selectTemplateCategoryOptions() {
            let entries = [];
            for (const [key, value] of Object.entries(
                this.aiAssistantTemplateCategories
            )) {
                entries.push({
                    value: value.machine_name,
                    name: value.name,
                    color: value.color,
                    icon: value.category_icon_path,
                });
            }
            return entries;
        },
    },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.multiselect {
    border-color: #f5f8fa;
    padding: 13px 0;
}
.favorite {
    .svg-icon svg path {
        fill: var(--bs-gray-300);
    }

    &.is-favorite {
        .svg-icon svg path {
            fill: var(--bs-link-color);
        }
    }

    &:hover {
        .svg-icon svg path {
            fill: var(--bs-link-color);
        }
    }
}
</style>
