<template>
    <app-layout
        :title="__('Translate')"
        :user="$attrs.user"
        wrapper-class="wrapper-fullscreen"
        main-class="main-fullscreen"
        kt-toolbar-height="1px"
        kt-toolbar-height-tablet-and-mobile="1px"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Translate') }}
            </h1>
        </template>

        <div class="d-flex flex-column flex-root bg-white">
            <!--begin::Authentication - Multi-steps-->
            <div
                class="d-flex flex-column flex-lg-row flex-column-fluid"
                data-kt-stepper="true"
            >
                <div
                    class="d-flex flex-column flex-lg-row-auto w-lg-400px w-xxl-600px"
                >
                    <!--begin::Wrapper-->
                    <div
                        class="d-flex flex-column position-xl-fixed top-0 bottom-0 bg-lighten w-lg-400px w-xxl-600px scroll-y"
                    >
                        <!--begin::Header-->
                        <div
                            class="col-result flex flex-col xl:mt-20 p-10 sm:pt-0 xl:pt-20"
                        >
                            <h2 class="text-xl">Translate</h2>
                            <div class="py-10 d-flex flex-column">
                                <Description
                                    :title="promptTitle"
                                    :placeholder="placeholder"
                                    :max-length="10000"
                                    @value-change="onChange"
                                />
                            </div>
                        </div>
                        <!--end::Header-->
                    </div>
                    <!--end::Wrapper-->
                    <div
                        class="flex flex-row flex-wrap justify-between gap-y-4 border-top position-xl-fixed bg-lighten w-lg-400px w-xxl-600px bottom-0 px-10 py-6"
                    >
                        <div class="flex">
                            <div
                                class="flex flex-col sm:flex-row sm:items-center"
                            >
                                <!--begin::Primary button-->
                                <span class="text-xs text-gray-700 fw-bolder pe-3"
                                    >{{ __('From') }}:</span
                                >
                                <Language
                                    :show-formality="false"
                                    :lang="langFrom"
                                    @language-change="
                                        onLanguageChange($event, 'from')
                                    "
                                >
                                </Language>
                                <span
                                    class="text-xs text-gray-700 fw-bolder pe-3 ms-5"
                                    >{{ __('To') }}:</span
                                >
                                <Language
                                    :show-formality="false"
                                    :lang="langTo"
                                    @language-change="
                                        onLanguageChange($event, 'to')
                                    "
                                >
                                </Language>
                            </div>
                        </div>
                        <div class="d-flex">
                            <a
                                href="#"
                                class="align-self-end btn btn-primary flex items-center"
                                @click.prevent="compose"
                            >
                                <span v-if="!loading" class="indicator-label text-sm">{{
                                    __('Create')
                                }}</span>
                                <span
                                    v-if="!loading"
                                    class="svg-icon svg-icon-4 ms-1"
                                >
                                    <IconArrowRight />
                                </span>
                                <span v-else class="indicator-progress !block text-sm"
                                    >{{ __('Please wait...') }}
                                    <ALoading />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column flex-lg-row-fluid py-10">
                    <!--begin::Content-->
                    <div class="d-flex flex-column flex-column-fluid">
                        <!--begin::Wrapper-->
                        <div class="px-8 lg:!px-12">
                            <!--begin::Title-->
                            <h2
                                class="fw-bolder flex items-center mb-10 text-dark text-lg"
                            >
                                {{ resultTitle }}
                            </h2>
                            <div v-if="translatedText !== ''" class="col-md-12">
                                <div
                                    class="border rounded position-relative p-0 d-flex flex-column align-items-stretch justify-content-between mb-5"
                                >
                                    <div
                                        class="d-flex border-bottom justify-content-between top-0 end-0 bg-lighten"
                                    >
                                        <div class="actions">
                                            <a
                                                href="#"
                                                class="btn btn-icon btn-hover-scale rounded-0 border-end btn-active-color-primary btn-sm"
                                                @click.prevent="onCopy"
                                            >
                                                <span
                                                    v-if="!copyClicked"
                                                    class="svg-icon svg-icon-muted svg-icon-1"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            opacity="0.3"
                                                            d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    v-else
                                                    class="svg-icon svg-icon-muted svg-icon-1"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            opacity="0.3"
                                                            d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M10.3629 14.0084L8.92108 12.6429C8.57518 12.3153 8.03352 12.3153 7.68761 12.6429C7.31405 12.9967 7.31405 13.5915 7.68761 13.9453L10.2254 16.3488C10.6111 16.714 11.215 16.714 11.6007 16.3488L16.3124 11.8865C16.6859 11.5327 16.6859 10.9379 16.3124 10.5841C15.9665 10.2565 15.4248 10.2565 15.0789 10.5841L11.4631 14.0084C11.1546 14.3006 10.6715 14.3006 10.3629 14.0084Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center p-10">
                                        <div class="d-block fw-bold text-start">
                                            <div
                                                class="choice-text text-gray-800 fw-bold text-sm"
                                            >
                                                {{ translatedText }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-md-12">
                                <!--begin::Alert-->
                                <div
                                    class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-4 mb-10 border-none"
                                >
                                    <!--begin::Wrapper-->
                                    <div
                                        class="d-flex flex-column pe-0 pe-sm-10"
                                    >
                                        <!--begin::Content-->
                                        <span
                                            class="d-none d-lg-block text-gray-600 text-sm"
                                            >{{
                                                __(
                                                    'Please put in your text on the left and click on create.'
                                                )
                                            }}</span
                                        >
                                        <span class="d-lg-none text-gray-600 text-sm">{{
                                            __(
                                                'Please put in your text above and click on create.'
                                            )
                                        }}</span>
                                        <!--end::Content-->
                                    </div>
                                    <!--end::Wrapper-->
                                    <!--end::Close-->
                                </div>
                                <!--end::Alert-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Authentication - Multi-steps-->
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Toolbar from '@/Layouts/Toolbar/Toolbar.vue';
import Description from '@/Components/Fields/Description.vue';
import Result from '@/Layouts/AiAssistant/Partials/Result.vue';
import Editor from '@tinymce/tinymce-vue';
import { directive } from 'vue3-click-away';
import Language from '@/Layouts/SettingElements/Language.vue';
import IconArrow from "../../../vendor/laravel/nova/resources/js/components/Icons/IconArrow.vue";
import IconArrowRight from '@/icons/arrow-right.svg?component';
import ALoading from "@/Components/Atoms/ALoading.vue";

export default defineComponent({
    components: {
        ALoading,
        IconArrow,
        IconArrowRight,
        AppLayout,
        Toolbar,
        Description,
        Result,
        Language,
        Editor,
    },
    directives: {
        ClickAway: directive,
    },
    data() {
        return {
            promptTitle: this.__('Text to translate'),
            resultTitle: this.__('Your translated text'),
            placeholder: this.__('Text you wish to translate'),
            description: this.__(''),
            prompts: [],
            loading: false,
            copyClicked: false,
            langFrom:
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.targetLang !==
                    undefined
                    ? this.$page.props.user.user_info.prompt_settings.targetLang
                    : 'en',
            langTo: 'en',
            translatedText: '',
        };
    },
    methods: {
        compose() {
            this.loading = true;
            let data = {
                prompts: {
                    description: this.description,
                },
                langFrom: this.langFrom,
                langTo: this.langTo,
            };
            axios.post('/api/compose/translate-text', data).then((response) => {
                this.loading = false;
                this.translatedText = response.data;
            });
        },
        onChange(ev) {
            this.description = ev.value;
        },
        onLanguageChange(code, origin) {
            if (origin === 'from') {
                this.langFrom = code;
            }
            if (origin === 'to') {
                this.langTo = code;
            }
        },
        onCopy() {
            let copyText = this.translatedText;
            let vm = this;

            emitter.emit('clipboard', copyText);

            this.copyClicked = true;

            setTimeout(function () {
                vm.copyClicked = false;
            }, 2000);
        },
    },
});
</script>

<style>
textarea {
    display: none;
}
</style>
