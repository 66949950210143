<template>
    <!--begin::Items-->
    <div v-if="!loading" class="scroll-y mh-325px my-5 px-8">
        <div v-if="notifications.length === 0">
            {{ __('No notifications yet') }}
        </div>
        <!--begin::Item-->
        <div
            v-for="notification in notifications"
            :key="notification.id"
            class="d-flex flex-stack py-4"
        >
            <!--begin::Section-->
            <div class="d-flex align-items-center flex-1">
                <!--begin::Symbol-->
                <div class="symbol symbol-35px me-4">
                    <DynamicIcons
                        :icon="notification.data.symbol"
                    ></DynamicIcons>
                    <span
                        v-if="!notification.read_at"
                        class="notification-new-mark bullet bullet-dot bg-success position-absolute top-0 end-0"
                    ></span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column mb-0 me-4 flex-1">
                    <div class="d-flex justify-content-between">
                        <a
                            href="#"
                            class="fs-6 text-gray-800 text-hover-primary"
                            @click.prevent="showNotificationModal(notification)"
                            :class="notification.read_at ? '' : 'fw-bolder'"
                        >
                            <label class="cursor-pointer">
                                {{ getTitle(notification.data.title) }}
                            </label>
                        </a>
                    </div>
                    <div class="text-gray-400 fs-7">
                        {{ getSubtitle(notification.data.sub_title) }}
                    </div>
                    <div class="d-flex mt-2">
                        <!--begin::Label-->
                        <span class="badge badge-light fs-8">{{
                            age(notification.created_at)
                        }}</span>
                        <!--end::Label-->
                    </div>
                </div>
                <!--end::Title-->
            </div>
            <!--end::Section-->
        </div>
        <!--end::Item-->
    </div>
    <!--end::Items-->

    <!--begin::View more-->
    <div class="py-3 text-center border-top inner-clickable-link">
        <Link
            href="/notifications"
            class="btn btn-color-gray-600 btn-active-color-primary inner-clickable-link flex justify-center items-center gap-1"
        >
            {{ __('View all notifications') }}
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                <IconArrowRight class="!fill-black relative top-0.5 scale-75" />
            <!--end::Svg Icon-->
        </Link>
    </div>
    <!--end::View more-->
</template>

<script>
import DynamicIcons from '@/Components/Icons/DynamicIcons.vue';
import {router as Inertia} from '@inertiajs/vue3';
import moment from 'moment';
import { Link } from '@inertiajs/vue3';
import IconArrowRight from '@/icons/arrow-right.svg?component';

export default {
    components: {
        DynamicIcons,
        Link,
        IconArrowRight,
    },
    props: {
        user: Object,
        type: {
            type: String,
        },
    },
    data() {
        return {
            loading: false,
            language: this.user?.user_info?.language
                ? this.user.user_info.language
                : 'en',
            notifications: [],
        };
    },
    beforeMount() {
        moment.locale(this.language);
    },
    mounted() {
        let vm = this;

        window.Echo.private('creaitor-notifications').listen(
            'BroadcastAdminNotification',
            (e) => {
                this.getNotifications();
            }
        );

        this.getNotifications();

        emitter.on('new-user-notification', function () {
            vm.getNotifications();
        });
    },
    methods: {
        getTitle(title) {
            try {
                return title[this.language]
                    ? title[this.language]
                    : this.__('No title');
            } catch (e) {
                return title ? title : this.__('No title');
            }
        },
        getSubtitle(subtitle) {
            try {
                return subtitle[this.language]
                    ? subtitle[this.language]
                    : this.__('No title');
            } catch (e) {
                return subtitle ? subtitle : this.__('No subtitle');
            }
        },
        age(created) {
            return created ? moment(created).fromNow() : this.__('now');
        },
        showNotificationModal(notification) {
            if (notification.data?.view_url) {
                Inertia.visit(notification.data.view_url);
                return false;
            }

            Inertia.reload({ only: ['user'] });
            emitter.emit('new-user-notification');
            emitter.emit('open-notification-detail-modal', notification.id);
        },
        getNotifications() {
            let vm = this;

            axios
                .get(`/api/notifications/?range-from=year&per-page=5`)
                .then((response) => {
                    vm.notifications = response.data.data;
                })
                .catch((error) => {
                    vm.notifications = [];
                });
        },
    },
};
</script>
<style lang="scss">
.notification-new-mark {
    margin-right: 4px;
    margin-top: 4px;
}
</style>
