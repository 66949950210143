<template>
    <app-layout
        :title="__('Editor')"
        :view="__('Brand Identity')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Brand Identity') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('Brand Identity') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __(
                            'Here you can find all the brand identity you have created.'
                        )
                    }}
                </div>
            </div>
        </div>
        <div class="card card-flush card-bordered">
            <!--begin::Card header-->
            <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                <!--begin::Card title-->
                <div class="card-title">
                    <!--begin::Search-->
                    <div
                        class="d-flex align-items-center position-relative my-1"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                        <span
                            class="svg-icon svg-icon-1 position-absolute ms-4"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                ></rect>
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <input
                            type="text"
                            v-model="searchTerm"
                            @input="onChangeSearch"
                            class="form-control form-control-solid w-250px ps-14"
                            :placeholder="__('Search Brand Identity')"
                        />
                    </div>
                    <!--end::Search-->
                </div>
                <!--end::Card title-->
                <!--begin::Card toolbar-->
                <div
                    class="card-toolbar flex-row-fluid justify-content-end gap-5"
                >
                    <a
                        href="#"
                        @click.prevent="sendDataToModal(null)"
                        class="btn btn-danger"
                        :class="{ disabled: selectedUuids.length === 0 }"
                    >
                        {{ __('Delete') }}
                    </a>
                    <button
                        v-if="$page?.props?.brand_identities?.data?.length < 1"
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_create_brand_identity"
                        @click="resetStore"
                    >
                        {{ __('New') }}
                    </button>
                </div>
                <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
                <!--begin::Table-->
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="bootstrap-table-responsive">
                        <table
                            aria-label="Brand Identity Table"
                            class="bootstrap-table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                            id="brand_identity_table"
                        >
                            <!--begin::Table head-->
                            <thead>
                                <!--begin::Table row-->
                                <tr
                                    class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                >
                                    <th
                                        class="w-10px pe-2 sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label=""
                                    >
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                ref="selectAllCheckbox"
                                                @change="selectAll"
                                            />
                                        </div>
                                    </th>
                                    <th
                                        class="min-w-200px sorting"
                                        tabindex="0"
                                        aria-controls="brand_identity_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Name"
                                    >
                                        {{ __('Brand Name') }}
                                    </th>
                                    <th
                                        class="text-center min-w-50px sorting"
                                        tabindex="0"
                                        aria-controls="brand_identity_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Used in projects"
                                    >
                                        {{ __('Used in projects') }}
                                    </th>
                                    <th
                                        class="text-end min-w-100px sorting"
                                        tabindex="0"
                                        aria-controls="brand_identity_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Updated At"
                                    >
                                        {{ __('Updated At') }}
                                    </th>
                                    <th
                                        class="text-end min-w-100px sorting"
                                        tabindex="0"
                                        aria-controls="brand_identity_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Created At"
                                    >
                                        {{ __('Created At') }}
                                    </th>
                                    <th
                                        class="text-end min-w-70px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Actions"
                                    >
                                        {{ __('Actions') }}
                                    </th>
                                </tr>
                                <!--end::Table row-->
                            </thead>
                            <!--end::Table head-->
                            <!--begin::Table body-->
                            <tbody class="fw-bold text-gray-600">
                                <!--begin::Table row-->

                                <!--end::Table row-->
                                <tr
                                    v-for="(brandIdentity, index) in $page.props
                                        ?.brand_identities?.data"
                                    :key="brandIdentity.uuid"
                                >
                                    <!--begin::Checkbox-->
                                    <td>
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="selectedUuids"
                                                :value="brandIdentity.uuid"
                                            />
                                        </div>
                                    </td>
                                    <!--end::Checkbox-->
                                    <!--begin::Category=-->
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <!--begin::Title-->
                                            <Link href="" class="text-gray-700">
                                                {{ brandIdentity.brand_name }}
                                            </Link>
                                            <!--end::Title-->
                                        </div>
                                    </td>
                                    <!--end::Category=-->
                                    <!--begin::SKU=-->
                                    <td class="text-center">
                                        {{
                                            brandIdentity.projects_count
                                        }}
                                    </td>
                                    <!--end::SKU=-->
                                    <!--begin::SKU=-->
                                    <td class="text-end pe-0">
                                        {{
                                            moment(
                                                brandIdentity.updated_at
                                            ).format('L LTS')
                                        }}
                                    </td>
                                    <!--end::SKU=-->
                                    <!--begin::Qty=-->
                                    <td class="text-end pe-0" data-order="41">
                                        {{
                                            moment(
                                                brandIdentity.created_at
                                            ).format('L LTS')
                                        }}
                                    </td>
                                    <!--end::Qty=-->
                                    <!--begin::Action=-->
                                    <td
                                        class="text-end position-relative action-dropdown-menu"
                                    >
                                        <a
                                            href="#"
                                            class="btn btn-sm btn-light btn-active-primary btn-outline btn-outline-highlighted"
                                            :class="[
                                                {
                                                    show:
                                                        index ===
                                                        selectedActionIndex,
                                                },
                                            ]"
                                            data-kt-menu-trigger="click"
                                            data-kt-menu-placement="bottom-end"
                                            @click.prevent.stop="
                                                selectedActionIndex = index
                                            "
                                            v-click-away="onClickAway"
                                        >
                                            {{ __('Actions') }}
                                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                                            <span
                                                class="svg-icon svg-icon-5 m-0"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <!--begin::Menu-->
                                        <div
                                            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary position-absolute fw-bold fs-7 w-125px py-4"
                                            :class="[
                                                {
                                                    'show menu-dropdown':
                                                        index ===
                                                        selectedActionIndex,
                                                },
                                            ]"
                                            data-kt-menu="true"
                                        >
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_edit_brand_identity"
                                                    class="menu-link px-3"
                                                    @click="
                                                        openEditModal(
                                                            brandIdentity.uuid
                                                        )
                                                    "
                                                >
                                                    {{ __('Edit') }}
                                                </a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div
                                                class="menu-item px-3"
                                                v-if="
                                                    !brandIdentity.projects_count
                                                "
                                            >
                                                <a
                                                    href="#"
                                                    class="menu-link px-3"
                                                    @click.prevent="
                                                        sendDataToModal(
                                                            brandIdentity.uuid
                                                        )
                                                    "
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_delete"
                                                >
                                                    {{ __('Delete') }}
                                                </a>
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                        <!--end::Menu-->
                                    </td>
                                    <!--end::Action=-->
                                </tr>
                            </tbody>
                            <!--end::Table body-->
                        </table>
                    </div>
                    <div class="row">
                        <div
                            class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                        >
                            <div
                                class="dataTables_length"
                                id="brand_identity_table_length"
                            >
                                <label>
                                    {{ __('Showing') }}
                                    {{ $page.props?.brand_identities?.from }}
                                    {{ __('to') }}
                                    {{ $page.props?.brand_identities?.to }}
                                    {{ __('of') }}
                                    {{ $page.props?.brand_identities?.total }}
                                    {{ __('entries') }}
                                </label>
                            </div>
                        </div>
                        <div
                            class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                        >
                            <div
                                class="dataTables_paginate paging_simple_numbers"
                                id="brand_identity_table_paginate"
                            >
                                <ul class="pagination">
                                    <li
                                        v-for="(link, index) in $page.props
                                            ?.brand_identities?.links"
                                        :key="index"
                                        class="page-item"
                                        :class="{
                                            'prev previous': index === 0,
                                            next: index === 14,
                                            active:
                                                link.label ==
                                                $page.props?.brand_identities
                                                    ?.current_page,
                                        }"
                                    >
                                        <Link
                                            @click.prevent="visit(link.url)"
                                            class="page-link"
                                            :class="{ disabled: !link.url }"
                                        >
                                            <span v-if="index === 0">
                                                <i class="previous"></i>
                                            </span>
                                            <span
                                                v-else-if="
                                                    index ===
                                                    $page.props
                                                        ?.brand_identities
                                                        ?.links?.length -
                                                        1
                                                "
                                            >
                                                <i class="next"></i>
                                            </span>
                                            <span v-else>{{ link.label }}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
    </app-layout>
    <CreateBrandIdentity @created="refreshIndex"></CreateBrandIdentity>
    <EditBrandIdentity
        :brandIdentity="selectedBrandIdentity"
        @updated="refreshIndex"
    >
    </EditBrandIdentity>
</template>

<script>
import { defineComponent } from 'vue';
import { directive } from 'vue3-click-away';
import {router as Inertia} from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import { useOnboardProcessStore } from '@/Stores/OnboardProcessStore';

import debounce from 'lodash/debounce';
import moment from 'moment/moment';

import AppLayout from '@/Layouts/AppLayout.vue';
import CreateBrandIdentity from '@/Pages/BrandIdentity/Create.vue';
import EditBrandIdentity from '@/Pages/BrandIdentity/Edit.vue';

export default defineComponent({
    components: {
        AppLayout,
        CreateBrandIdentity,
        EditBrandIdentity,
        Link,
    },

    directives: {
        ClickAway: directive,
    },

    setup() {
        // Get onboardProcess store
        const onboardProcessStore = useOnboardProcessStore();

        // Make it available inside methods
        return {
            onboardProcessStore,
        };
    },

    data() {
        return {
            searchTerm: this.$page.props?.filters?.search || '',
            selectedUuids: [],
            selectedBrandIdentity: {},
            filters: {
                per_page: this.$page.props?.filters?.per_page || 9,
                search: this.$page.props?.filters?.search || '',
            },
            currentMenu: {
                open: false,
                element: {},
            },
            moment: moment,
            selectedActionIndex: null,
            tones: this.$page.props?.tones || [],
        };
    },

    mounted() {
        this.setTones();
    },

    methods: {
        setTones() {
            this.onboardProcessStore.setAvailableTones(this.tones);
        },
        visit(url) {
            if (!url) {
                return false;
            }

            Inertia.visit(url, {
                preserveScroll: true,
                preserveState: true,
                only: ['brand_identities'],
            });
        },
        openEditModal(uuid) {
            let brandIdentities =
                this.$page?.props?.brand_identities?.data?.filter(
                    (brandIdentity) => brandIdentity.uuid === uuid
                ) || [];

            this.selectedBrandIdentity = brandIdentities[0] || {};
        },
        refreshIndex() {
            this.resetStore();

            Inertia.reload({
                preserveScroll: true,
                preserveState: true,
                only: ['brand_identities'],
            });
        },
        sendDataToModal(uuid = null) {
            let payload = {
                url: '/api/brand-identities',
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this brand identity? This procedure is irreversible.'
                ),
                callback: this.refreshIndex,
                ...(uuid ? { uuid } : { ids: this.selectedUuids }),
            };

            emitter.emit('set-delete-candidate', payload);
        },
        onChangeSearch: debounce(function (value) {
            Inertia.visit(
                route('brand-identities.index', { search: this.searchTerm }),
                {
                    preserveScroll: true,
                    preserveState: true,
                    only: ['brand_identities'],
                }
            );
        }, 500),
        selectAll() {
            if (this.$refs.selectAllCheckbox.checked) {
                this.selectedUuids =
                    this.$page.props?.brand_identities?.data?.map(
                        (brandIdentity) => brandIdentity?.uuid
                    ) || [];
            } else {
                this.selectedUuids = [];
            }
        },
        onClickAway(ev) {
            if (this.selectedActionIndex != null) {
                this.selectedActionIndex = null;
            }
        },
        resetStore() {
            this.onboardProcessStore.resetState();
            this.setTones();
        },
    },
});
</script>
