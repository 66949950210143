<template>
    <div>
        <h1 class="intro-heading text-center text-4xl">{{ __('Creaitor Chat') }}</h1>
        <div class="row g-4 mt-4 intro-content">
            <div class="col-lg-4">
                <div class="intro-title flex flex-col items-center">
                    <span class="actions-icon w-6">
                        <span class="icon-xl">
                            <IconSun />
                        </span>
                    </span>
                    <h3 class="text-center text-lg text-gray-900 mt-2 mb-0">{{ __('Examples') }}</h3>
                </div>

                <div
                    class="card intro-card card-bordered mb-4 cursor-pointer"
                    v-for="example in chatExamples"
                    :key="example"
                    @click="$emit('single-example-chat-selected', example)"
                >
                    <div class="card-body text-[13px] !p-6">
                        {{ example }}
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="intro-title flex flex-col items-center">
                    <span class="actions-icon w-6">
                        <span class="icon-xl">
                            <IconThunder />
                        </span>
                    </span>
                    <h3 class="text-center text-lg text-gray-900 mt-2 mb-0">{{ __('Capabilities') }}</h3>
                </div>

                <div class="card intro-card card-bordered mb-4">
                    <div class="card-body text-[13px] !p-6">
                        {{
                            __(
                                'Remembers what user said earlier in the conversation'
                            )
                        }}
                    </div>
                </div>

                <div class="card intro-card card-bordered mb-4">
                    <div class="card-body text-[13px] !p-6">
                        {{ __('Allows user to provide follow-up corrections') }}
                    </div>
                </div>

                <div class="card intro-card card-bordered mb-4">
                    <div class="card-body text-[13px] !p-6">
                        {{ __('Trained to decline inappropriate requests') }}
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="intro-title flex flex-col items-center">
                    <span class="actions-icon w-6">
                        <span class="icon-xl">
                            <IconWarning />
                        </span>
                    </span>
                    <h3 class="text-center text-lg text-gray-900 mt-2 mb-0">{{ __('Limitations') }}</h3>
                </div>

                <div class="card intro-card card-bordered mb-4">
                    <div class="card-body text-[13px] !p-6">
                        {{
                            __(
                                'May occasionally generate incorrect information'
                            )
                        }}
                    </div>
                </div>

                <div class="card intro-card card-bordered mb-4">
                    <div class="card-body text-[13px] !p-6">
                        {{
                            __(
                                'May occasionally produce harmful instructions or biased content'
                            )
                        }}
                    </div>
                </div>

                <div class="card intro-card card-bordered mb-4">
                    <div class="card-body text-[13px] !p-6">
                        {{
                            __(
                                'Limited knowledge of world and events after 2023'
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconSun from '@/icons/sun.svg?component';
import IconThunder from '@/icons/thunder.svg?component';
import IconWarning from '@/icons/warning.svg?component';

export default {
    components: {
        IconSun,
        IconThunder,
        IconWarning,
    },
    data() {
        return {
            chatExamples: [
                this.__('Explain quantum computing in simple terms'),
                this.__('Got any creative ideas for a 10 year old’s birthday?'),
                this.__('How do I make an HTTP request in Javascript?'),
            ],
        };
    },
};
</script>

<style lang="scss">
.intro-heading {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
}

.intro-title {
    margin-bottom: 1rem;

    h3 {
        margin-top: 1rem;
    }

    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
}

.intro-content {
    margin-bottom: 100px;
}

.intro-card {
    border-radius: 8px;
    overflow: hidden;
    background-color: #f4f4f4;

    .card-body {
        padding: 1rem;
        text-align: center;
    }
}

.icon-xl {
    svg {
        width: 100%;
        max-width: 24px;
        max-height: 24px;
        object-fit: contain;
    }
}
</style>
