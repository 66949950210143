<template>
    <div class="mb-10">
        <h4 class="mb-2">
            {{ __('Create or select your output columns:') }}
        </h4>
        <p class="text-muted mb-0">
            {{
                __(
                    'These columns will receive generated content based on the inputs and AI assistant selected'
                )
            }}
        </p>
        <div class="card card-flush mt-5">
            <!--start::Card body-->
            <div class="card-body p-0">
                <draggable
                    class="draggableArea"
                    :list="bulkProcessingStore.outputSelections"
                >
                    <OutputItem
                        v-for="(
                            output, index
                        ) in bulkProcessingStore.outputSelections"
                        v-model:output="
                            bulkProcessingStore.outputSelections[index]
                        "
                        :key="index"
                        :index="index"
                        :input-options="getInputOptions(index)"
                        class="flex-shrink-0"
                        style="width: 100%"
                        @remove="removeOutputWarning"
                    />
                    <!-- begin::Add new output column -->
                    <div
                        class="d-flex justify-content-center flex-shrink-0 p-7 border border-start-0 border-top-0 add-output-column"
                    >
                        <button
                            class="p-0 bg-transparent border-0 w-full add-output-button"
                            @click="addNewOutputItem"
                        >
                            <i class="bi bi-plus-lg text-primary fs-2"></i>
                            <span class="fw-bolder d-block">
                                {{ __('Add new output column') }}
                            </span>
                        </button>
                    </div>
                    <!-- end::Add new output column -->
                </draggable>
            </div>
        </div>
        <OutputRemoveModal @delete-column="removeOutput" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import OutputItem from '@/Pages/BulkProcessing/Partials/Create/OutputItem.vue';
import OutputRemoveModal from '@/Pages/BulkProcessing/Partials/Modals/OutputRemoveModal.vue';
import { useBulkProcessingStore } from '@/Stores/BulkProcessingStore';
import { VueDraggableNext } from 'vue-draggable-next';

export default defineComponent({
    name: 'BulkProcessingOutputContainer',

    components: {
        OutputItem,
        OutputRemoveModal,
        draggable: VueDraggableNext,
    },

    setup() {
        const bulkProcessingStore = useBulkProcessingStore();

        return {
            bulkProcessingStore,
        };
    },

    props: {
        getInputOptions: {
            type: Function,
            required: true,
            default: () => null,
        },
    },

    data() {
        return {
            removeIndex: null,
        };
    },

    mounted() {
        this.addNewOutputItem();
    },

    methods: {
        addNewOutputItem() {
            this.bulkProcessingStore.outputSelections.push({
                column_name: `${this.__('New column')} ${this.bulkProcessingStore.outputSelections.length + 1}`,
                assistant: null,
                output_format: 'plain',
                inputs: [],
            });
        },
        removeOutputWarning(index) {
            this.removeIndex = index;
            // eslint-disable-next-line no-undef
            emitter.emit('open-bulk-processing-output-remove-warning-modal');
        },
        removeOutput() {
            this.bulkProcessingStore.outputSelections.splice(
                this.removeIndex,
                1
            );
        },
    },
});
</script>

<style lang="scss">
.add-output-column {
    pointer-events: none;
    min-height: 230px;
    button {
        pointer-events: auto;
        transition: color 0.3s ease;

        &:hover {
            color: #0186d2;
            background-color:rgba(14, 165, 233, 0.1) !important;
        }
    }
}

.draggableArea {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1280px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (min-width: 1536px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
</style>
